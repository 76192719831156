import axios from "axios";
//constantes
let initialState = {
   fetching_observaciones: false,
   error: "",
   arrary_observaciones: [],
};

let LOG_OUT = "LOG_OUT";

let GET_OBSERVACIONES = "GET_OBSERVACIONES";
let GET_OBSERVACIONES_SUCCESS = "GET_OBSERVACIONES_SUCCESS";
let GET_OBSERVACIONES_ERROR = "GET_OBSERVACIONES_ERRORS";

let URL_NATURALISTA =
   "https://api.inaturalist.org/v1/observations?project_id=tortugas-marinas-en-colombia&per_page=100";

//Reducer
export default function reducer(state = initialState, action) {
   switch (action.type) {
      case LOG_OUT:
         return { ...initialState };

      //Referentes
      case GET_OBSERVACIONES:
         return {
            ...state,
            fetching_observaciones: true,
         };
      case GET_OBSERVACIONES_ERROR:
         return {
            ...state,
            fetching_observaciones: false,
            error: action.payload.error,
         };
      case GET_OBSERVACIONES_SUCCESS:
         return {
            ...state,
            fetching_observaciones: false,
            arrary_observaciones: action.payload.arrary_observaciones,
         };
      default:
         return state;
   }
}

//Action (action creator) --------------------------
// Action 1
export let logOutObservacionesAction = () => (dispatch, getState) => {
   // Aqui la funcion para cerrar sesion en django
   dispatch({
      type: LOG_OUT,
   });
};

// Action 1 - obtener las observaciones de inaturalist
export let getObservacionesAction = () => (dispatch, getState) => {
   // fetching_referentes true para iniciar el consumo de la api

   dispatch({
      type: GET_OBSERVACIONES,
   });

   axios
      .get(URL_NATURALISTA, {
         crossdomain: true,
      })
      .then((res) => {
         dispatch({
            type: GET_OBSERVACIONES_SUCCESS,
            payload: {
               arrary_observaciones: res.data.results,
            },
         });
         // saveStorage(getState());
      })
      .catch((error) => {
         console.log("referentes", error);
         dispatch({
            type: GET_OBSERVACIONES_ERROR,
            payload: {
               error: error.response,
            },
         });
      });
};

// aux 1
function saveStorage(storage) {
   localStorage.storage = JSON.stringify(storage);
}

//aux 2
export let restoreNaturalistaAction = () => (dispatch, getState) => {
   let storage = localStorage.getItem("storage");
   storage = JSON.parse(storage);
   if (storage) {
      dispatch({
         type: GET_OBSERVACIONES_SUCCESS,
         payload: {
            arrary_observaciones: storage.naturalista.arrary_observaciones,
         },
      });
   }
};
