import React, { useState } from "react";
import { TXT_APP, URL_APP } from "Routes";
import { useHistory } from "react-router-dom";
import {
   updateMonitoreoAction,
   setUpdatingMonitoreoAction,
} from "redux/monitoreoDuck";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";

function ListaMonitoreos({
   access,
   lista,
   modulo,
   updateMonitoreoAction,
   setUpdatingMonitoreoAction,
   onCerrar,
}) {
   let txt = TXT_APP();
   let url = URL_APP();
   const history = useHistory();
   const [show, setShow] = useState(false);
   const [mensajeCurador, setMensajeCurador] = useState(false);
   const [showMonitoreoCerrado, setShowMonitoreoCerrado] = useState(false);
   const [idEstacion, setidEstacion] = useState(0);

   //ir a donde corresponda
   let ir = (monitoreo) => {
      updateMonitoreoAction(monitoreo);
      if (modulo === txt.modulo_cutrado)
         history.push(url.curar_detalle_muestreo);
      else history.push(url.detalle_muestreo);
   };
   // Modal
   const handleShow = () => {
      setShow(true);
   };
   const handleClose = () => {
      setShow(false);
   };

   let mensajeNocurado = (msg) => {
      setMensajeCurador(msg);
      handleShow();
   };
   // Modal monitoreo cerrado correctamente
   const handleShowMonitoreoCerrado = (id_estacion) => {
      setidEstacion(id_estacion);
      setShowMonitoreoCerrado(true);
   };

   const handleCloseMonitoreoCerrado = () => {
      setShowMonitoreoCerrado(false);
      onCerrar(idEstacion);
   };

   //  let texto = "REC,REVISAR, CAMPOS DE HUEVOS PUESTOS"
   //  if(texto.split(",", 1)[0] ==="REC" )
   //     alert("igual")

   return (
      <div className='tab-content' id='myTabContent'>
         <div
            className='tab-pane fade show active'
            id='monitoreos'
            role='tabpanel'
            aria-labelledby='monitoreos-tab'
         >
            <p>&nbsp;</p>
            <h6>Registros de Monitoreo</h6>
            <p>&nbsp;</p>
            {lista.map((monitoreo) => (
               <div class='row registro-monitoreo mx-1 d-flex align-items-end'>
                  {/* <div class="col-6 col-sm-2 col-md-2">
                    <b>Muestreo</b><br/>
                        {monitoreo.id_muestreotx}
                    </div> */}

                  <div class='col-6 col-sm-2 col-md-3'>
                     <b>Cod. Ingreso</b>
                     <br />
                     {monitoreo.prefijo_estacion}
                  </div>
                  <div class='col-6 col-sm-2 col-md-2'>
                     <b>Fechas</b>
                     <br />
                     {monitoreo.fecha_inicial_mtortuga}
                  </div>

                  {/* <div class="col-6 col-sm-2 col-md-2">
                    <b>Departamento</b><br/>
                        {monitoreo.departamento}
                    </div> */}
                  <div class='col-6 col-sm-2 col-md-2'>
                     <b> municipio</b>
                     <br />
                     {monitoreo.municipio}
                  </div>
                  {/* estados del monitoreo segun el rol */}
                  <div class='col-6 col-sm-2 col-md-2'>
                     {modulo === txt.modulo_cutrado ? ( //-------------------INVESTIGADOR
                        monitoreo.vigente === "S" ? (
                           <span class='mr-5 btn btn btn btn-success btn-w-110 f-14'>
                              Validado
                           </span>
                        ) : (
                           <span class='mr-5 btn btn-danger btn-w-110 f-14'>
                              No validado
                           </span>
                        )
                     ) : (
                        //-------------------INVESTIGADOR

                        (monitoreo.notas_generales.split(",", 1)[0] ===
                           "REC" && (
                           <span
                              class='mr-5 btn btn btn btn-danger btn-w-110 f-14'
                              onClick={() =>
                                 mensajeNocurado(
                                    monitoreo.notas_generales.substring(4, 200)
                                 )
                              }
                           >
                              Rechazado
                           </span>
                        )) ||
                        (monitoreo.notas_generales.split(",", 1)[0] ===
                           "ACT" && (
                           <span
                              class='mr-5 btn btn btn btn-success btn-w-110 f-14'
                              onClick={() =>
                                 mensajeNocurado(
                                    "Registro validado por administrador."
                                 )
                              }
                           >
                              Validado
                           </span>

                           // colocar en estado de revision
                        )) ||
                        (monitoreo.notas_generales === "FIN" && (
                           <span
                              class='mr-5 btn btn btn btn-info  btn-w-110 f-14'
                              onClick={() =>
                                 handleShowMonitoreoCerrado(
                                    monitoreo.id_estacion
                                 )
                              }
                           >
                              Finalizado
                           </span>
                        )) ||
                        (monitoreo.notas_generales === "CER" && (
                           <span
                              class='mr-5 btn btn btn btn-warning btn-w-110 f-14'
                              onClick={() =>
                                 mensajeNocurado(
                                    "Registro validado por administrador."
                                 )
                              }
                           >
                              En revision
                           </span>
                        )) ||
                        (monitoreo.notas_generales.split(",", 1)[0] ===
                           "PEN" && (
                           <span
                              class='mr-5 btn btn btn btn-secondary btn-w-110 f-14'
                              onClick={() =>
                                 mensajeNocurado(
                                    monitoreo.notas_generales.substring(4, 200)
                                 )
                              }
                           >
                              Pendiente
                           </span>
                        ))
                     )}
                  </div>
                  <div class='col-6 col-sm-2 col-md-2'>
                     <button
                        className='mr-4 btn btn-primary btn-w-110 f-14'
                        onClick={() => ir(monitoreo)}
                     >
                        Ver
                     </button>
                  </div>
               </div>
            ))}
         </div>
         {/* Modal */}
         <Modal show={show}>
            <Modal.Header style={{ color: "#30345b" }}>
               <b>Mensaje de curador del monitoreo</b>
            </Modal.Header>
            <Modal.Body>
               <p>{mensajeCurador}</p>
            </Modal.Body>
            <Modal.Footer>
               <Button variant='secondary' onClick={handleClose}>
                  Aceptar
               </Button>
            </Modal.Footer>
         </Modal>

         {/* Modal  monitoreo cerrado corectamente */}
         <Modal show={showMonitoreoCerrado}>
            <Modal.Header style={{ color: "#30345b" }}>
               <b>Monitoreo</b>
            </Modal.Header>
            <Modal.Body>
               <p>confirme cerrar este Monitoreo</p>
            </Modal.Body>
            <Modal.Footer>
               <Button
                  variant='secondary'
                  onClick={handleCloseMonitoreoCerrado}
               >
                  Cancelar
               </Button>
               <Button variant='primary' onClick={handleCloseMonitoreoCerrado}>
                  Aceptar
               </Button>
            </Modal.Footer>
         </Modal>
      </div>
   );
}
let mapState = (state) => {
   console.log(state.monitoreos.arrary_all_monitoreos);
   return {
      access: state.user.user.access,
   };
};
const mapDispatch = (dispatch) => {
   return {
      updateMonitoreoAction: (props) => dispatch(updateMonitoreoAction(props)),
   };
};
// connect(mapState,mapDispatch)(this funtion)
export default connect(mapState, mapDispatch)(ListaMonitoreos);
