import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Nav from "components/base/navs/NavHome";
import Header from "components/base/headers/Header";
import Footer from "components/base/Footer";
import { Link } from "react-router-dom";
import { URL_APP,TXT_APP } from "Routes";
import { resetReferentesAction } from "redux/referentesDuck";
import MenuRaiz from "components/base/navs/NavRaiz";
function Panel({ resetReferentesAction }) {
   let url = URL_APP();
   let txt = TXT_APP();
   useEffect(() => {
      //Reset de los referentes
      resetReferentesAction();
   }, []);

   return (
      <div>
         <div className='content-principal  content-internas'>
            <div className='temporal-cerar'></div>
            <header className=' internas-h '>
               <Nav />
               <Header titulo={txt.tituloPanelInvestigador} />
            </header>
            <section class='menubar'>
               <MenuRaiz />
            </section>
            <section className='contents-interna sig interna-componente'>
               <div className='container-xl my-3'>
                  <div className='row'>
                     <div className='col-md-4'>
                        <Link exact to='/investigador/formulario'>
                           <div className='card w-100 mb-5 mx-0'>
                              <img
                                 src={ process.env.PUBLIC_URL +'/static/images/componente-t-t/formMonitoreo@2x.jpg'}
                                 className='card-img-top'
                                 alt='...'
                              />
                              <div className='card-body'>
                                 <p className='card-text'>
                                    <i
                                       className='fa fa-list-alt'
                                       aria-hidden='true'
                                    ></i>{" "}
                                    Formulario de Monitoreo
                                 </p>
                              </div>
                           </div>
                        </Link>
                     </div>

                     <div className='col-md-4'>
                        <Link exact to={"/investigador/listado-monitoreos"}>
                           <div className='card w-100 mb-5 mx-0'>
                              <img
                                 src={ process.env.PUBLIC_URL +'/static/images/componente-t-t/listadoMonitorieos@2x.jpg'}
                                 className='card-img-top'
                                 alt='...'
                              />
                              <div className='card-body'>
                                 <p className='card-text'>
                                    <i
                                       className='fa fa-list'
                                       aria-hidden='true'
                                    ></i>{" "}
                                    Listado de monitoreos
                                 </p>
                              </div>
                           </div>
                        </Link>
                     </div>

                     <div className='col-md-4'>
                        <Link exact to={url.graficas}>
                           <div className='card w-100 mb-5 mx-0'>
                              <img
                                 src={ process.env.PUBLIC_URL +'/static/images/componente-t-t/curacioUsuarios@2x.jpg'}
                                 className='card-img-top'
                                 alt='...'
                              />
                              <div class='card-body'>
                                 <p class='card-text'>
                                    <i
                                       class='fa fa-list-alt'
                                       aria-hidden='true'
                                    ></i>{" "}
                                    Reporte de monitoreos
                                 </p>
                              </div>
                           </div>
                        </Link>
                     </div>
                     <div class='col-md-4'>
                        <a href={url.naturalista} target='blank'>
                           <div class='card w-100 mb-5 mx-0'>
                              <img
                                 src={ process.env.PUBLIC_URL +'/static/images/componente-t-t/large.jpg'}
                                 class='card-img-top'
                                 alt='...'
                              />
                              <div class='card-body'>
                                 <p class='card-text'>
                                    <i
                                       class='fa fa-list-alt'
                                       aria-hidden='true'
                                    ></i>{" "}
                                    Registrar avistamientos
                                 </p>
                              </div>
                           </div>
                        </a>
                     </div>
                     <div class='col-md-4'>
                        <a
                           href={ process.env.PUBLIC_URL +'/static/file/Tortugas_Monitoreo_20201201m.xlsm'}
                        >
                           <div class='card w-100 mb-5 mx-0' >
                              <img
                                 src={ process.env.PUBLIC_URL +'/static/images/componente-t-t/descargaMasiva.jpg'}
                                 class='card-img-top'
                                 alt='...'
                              />
                              <div class='card-body'>
                                 <p class='card-text'>
                                    <i
                                       class='fa fa-upload'
                                       aria-hidden='true'
                                    ></i>{" "}
                                    Carga de datos masivos
                                 </p>
                              </div>
                           </div>
                        </a>
                     </div>
                  </div>
                  <p>&nbsp;</p>
               </div>
            </section>
            <Footer />
         </div>
      </div>
   );
}

let mapState = (state) => {
   return {};
};

const mapDispatch = (dispatch) => {
   return {
      resetReferentesAction: () => dispatch(resetReferentesAction()),
   };
};
export default connect(mapState, mapDispatch)(Panel);
