import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { TXT_APP, URL_APP } from "Routes";
import { Button, Form, Alert } from "react-bootstrap";
import { Link, Redirect, useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";
import {
   setUpdatingMonitoreoAction,
   updateMonitoreoAction,
} from "redux/monitoreoDuck";
import Progress from "components/base/Progress";
// disabledbutton
function Hederform({
   user,
   onCerrarMonitoreo,
   state,
   access,
   monitoreo_update,
   setUpdatingMonitoreoAction,
   updateMonitoreoAction,
   fetching_monitoreo_update,
   modulo,
}) {
   let url = URL_APP();
   let txt = TXT_APP();
   //llenando el formulario con el usuario a validar

   const history = useHistory();
   const [show, setShow] = useState(false);
   const [show_descartar, setShow_descartar] = useState(false);
   const [fetchingUpdate, setfetchingUpdate] = useState(false);

   useEffect(() => {
      if (fetching_monitoreo_update) {
         setfetchingUpdate(true);
         setTimeout(function () {
            setShow(false);
            setfetchingUpdate(false);
            history.push(url.curar_monitoreo);
         }, 100);
      }
   }, [fetching_monitoreo_update]);

   //Se llena el formulario
   const { register, handleSubmit, errors } = useForm({
      defaultValues: {
         ...monitoreo_update,
         cod_ingreso: monitoreo_update.prefijo_estacion,
         Responsable: user.userInfo.nombre,
         id_auc: monitoreo_update.uac_des,
         id_institucion: monitoreo_update.entidades,
         id_zonas_protegidas: monitoreo_update.zona_protegida,
         playa: monitoreo_update.nom_estacion,
         id_departamento: monitoreo_update.departamento,
         id_municipio: monitoreo_update.municipio,
         fecha_inicio_monitoreo: monitoreo_update.fecha_inicial_mtortuga,
         fecha_fin_monitoreo: monitoreo_update.fecha_final_mtortuga,
         latitud_inicial: monitoreo_update.latitudinicio_loc,
         latitud_final: monitoreo_update.latitudfin_loc,
         longitud_inicial: monitoreo_update.longitudinicio_loc,
         longitud_final: monitoreo_update.longitudfin_loc,
      },
   });

   //Valida el monitoreo true
   const habilitar = () => {
      setUpdatingMonitoreoAction({
         token: access,
         id_estacion: monitoreo_update.id_estacion,
         is_active: "S", //<--------------------SI ACTIVO EN ESTADO ESTACION
         observacion: "ACT", //<----------------ACTIVO EN EL NOTA MUESTREO
      });
      setShow(false);
   };

   //Descarta el monitoreo false
   const Inhabilitar = (data) => {
      setUpdatingMonitoreoAction({
         token: access,
         id_estacion: monitoreo_update.id_estacion,
         is_active: "N", //<------------------------------------NO ACTIVO EN ESTADO ESTACION
         observacion: "REC," + data.observacion, //<-------------RECHAZADO + LA OBSEERVACION
      });
      setShow_descartar(false);
   };

   const cancelarUpdate = () => {
      updateMonitoreoAction([]);
      history.push(url.curar_monitoreo);
   };

   const handleClose_descartar = () => {
      setShow_descartar(false);
   };

   const handleShow_descartar = () => {
      setShow_descartar(true);
   };

   const handleClose = () => {
      setShow(false);
   };

   const handleShow = () => {
      setShow(true);
   };

   return (
      <div>
         <div className='encabezado-form '>
            <h6 className='mb-3'>Datos del encabezado</h6>
            <div className='form-row'>
               <div className='col-sm-12 col-md-3 mb-3 '>
                  <label for='v01'>Código de ingresos</label>
                  <input
                     ref={register({
                        /*required: true*/
                     })}
                     type='text'
                     className='form-control  deshabilitado'
                     id='cod_ingreso'
                     name='cod_ingreso'
                     placeholder='Codigo ingreso'
                     readonly
                  />
               </div>
               <div className='col-md-3 col-md-3 mb-3'>
                  <label for='v05'>Responsable</label>
                  <input
                     ref={register({
                        // /*required: true*/
                     })}
                     type='text'
                     className='form-control   deshabilitado'
                     id='Responsable'
                     name='Responsable'
                     placeholder='Responsable'
                     readonly
                  />
                  <input
                     id='id_responsable'
                     name='id_responsable'
                     type='hidden'
                     ref={register({
                        /*required: true*/
                     })}
                  ></input>
               </div>
               <div className='col-sm-6 col-md-6 mb-3'>
                  <label for='v01'>Unid. costeras</label>
                  <input
                     ref={register({
                        /*required: true*/
                     })}
                     type='text'
                     className='form-control   deshabilitado'
                     id='id_auc'
                     name='id_auc'
                     placeholder='Responsable'
                     readonly
                  />
               </div>
            </div>
            <div className='form-row'>
               <div className='col-sm-6 col-md-4 mb-3'>
                  <label for='v02'>Institución</label>

                  <input
                     ref={register({
                        /*required: true*/
                     })}
                     type='text'
                     className='form-control   deshabilitado'
                     id='id_institucion'
                     name='id_institucion'
                     placeholder='Responsable'
                     readonly
                  />
               </div>
               <div className='col-sm-12 col-md-4 mb-3'>
                  <label for='v06'>Area protegida</label>{" "}
                  <input
                     ref={register({
                        /*required: true*/
                     })}
                     type='text'
                     className='form-control   deshabilitado'
                     id='id_zonas_protegidas'
                     name='id_zonas_protegidas'
                     placeholder='Responsable'
                     readonly
                  />
               </div>
               <div className='col-sm-4 col-md-4 mb-3'>
                  <label for='v07'>Playa</label>
                  <input
                     ref={register({
                        /*required: true*/
                     })}
                     type='text'
                     className='form-control'
                     id='playa'
                     name='playa'
                     placeholder='Lugar'
                  />
               </div>
            </div>
            <div className='form-row'>
               <div className='col-sm-4 col-md-3 mb-3'>
                  <label for='v02'>Departamento</label>
                  <input
                     ref={register({
                        /*required: true*/
                     })}
                     type='text'
                     className='form-control   deshabilitado'
                     id='id_departamento'
                     name='id_departamento'
                     placeholder='Responsable'
                     readonly
                  />
               </div>
               <div className='col-sm-4 col-md-3 mb-3'>
                  <label for='v02'>Municipio</label>
                  <input
                     ref={register({
                        /*required: true*/
                     })}
                     type='text'
                     className='form-control   deshabilitado'
                     id='id_municipio'
                     name='id_municipio'
                     placeholder='Responsable'
                     readonly
                  />
               </div>
               <div className='col-sm-4 col-md-3 mb-3'>
                  <label for='v03'>Fecha inicial</label>
                  <input
                     ref={register({})}
                     type='text'
                     className='form-control'
                     id='fecha_inicio_monitoreo'
                     name='fecha_inicio_monitoreo'
                     readonly
                  />
               </div>
               <div className='col-sm-4 col-md-3 mb-3'>
                  <label for='v04'>Fecha final</label>
                  <input
                     ref={register({
                        /*required: true*/
                     })}
                     type='text'
                     className='form-control'
                     name='fecha_fin_monitoreo'
                     id='fecha_fin_monitoreo'
                     placeholder='dd/mm/aa'
                     readonly
                  />
               </div>
               <div className='col-sm-6 col-md-6 mb-3'>
                  <div className='grupo-inputs'>
                     <span className='tit-grupo-inputs-c'>
                        Coordenadas iniciales
                     </span>
                     <div className='form-row'>
                        <div className='col-sm-6 col-md-6 mb-3'>
                           <label for='v09'>Latitud</label>
                           <input
                              ref={register({
                                 /*required: true*/
                              })}
                              type='text'
                              className='form-control'
                              id='latitud_inicial'
                              name='latitud_inicial'
                              // placeholder="00,000000"
                           />
                        </div>
                        <div className='col-sm-6 col-md-6 mb-3'>
                           <label for='v10'>Longitud</label>
                           <input
                              ref={register({})}
                              type='text'
                              className='form-control'
                              id='longitud_inicial'
                              name='longitud_inicial'
                           />
                        </div>
                     </div>
                  </div>
               </div>
               <div className='col-sm-6 col-md-6 mb-3'>
                  <div className='grupo-inputs'>
                     <span className='tit-grupo-inputs-c'>
                        Coordenadas finales
                     </span>
                     <div className='form-row'>
                        <div className='col-sm-6 col-md-6 mb-3'>
                           <label for='v07'>Latitud</label>
                           <input
                              ref={register({
                                 /*required: true*/
                              })}
                              type='text'
                              className='form-control'
                              id='latitud_final'
                              name='latitud_final'
                              placeholder='00,000000'
                           />
                        </div>
                        <div className='col-sm-6 col-md-6 mb-3'>
                           <label for='v08'>Longituddd</label>
                           <input
                              ref={register({
                                 /*required: true*/
                              })}
                              type='text'
                              className='form-control'
                              id='longitud_final'
                              name='longitud_final'
                              placeholder='00,000000'
                           />
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            {/* botones de investigador y curador */}
            <div className='form-row'>
               {state === txt.state_editar && (
                  <div className='col-md-12 mb-3 d-flex align-items-end'>
                     <div>
                        <Link
                           className='btn btn-success f-14'
                           to={url.add_muestra}
                        >
                           Guardar
                        </Link>
                        &nbsp;{" "}
                        <Link
                           className='btn btn-light f-14'
                           to={url.detalle_muestreo}
                        >
                           Atras
                        </Link>
                     </div>
                  </div>
               )}
               {modulo === txt.modulo_investigador ? (
                  <div className='col-md-12 mb-3 d-flex align-items-end'>
                     <div>
                        {monitoreo_update.notas_generales === "ACT" ||
                        monitoreo_update.notas_generales === "CER" ? (
                           []
                        ) : (
                           <Link
                              className='btn btn-success f-14'
                              to={url.add_muestra}
                           >
                              Agregar Evento
                           </Link>
                        )}
                        &nbsp;{" "}
                        <Link
                           className='btn btn-light f-14'
                           to={url.listado_monitoreos}
                        >
                           Atras
                        </Link>
                     </div>
                  </div>
               ) : (
                  <div className='col-md-4 mb-3 d-flex align-items-end'>
                     <div>
                        {monitoreo_update.vigente === "S" ? (
                           <>
                              <button
                                 className='btn btn-danger btn-w-110 mb-3'
                                 onClick={handleShow_descartar}
                              >
                                 Inhabilitar
                              </button>
                              &nbsp;{" "}
                           </>
                        ) : (
                           <button
                              className='btn btn-success btn-w-110 mb-3'
                              onClick={handleShow}
                           >
                              Validar
                           </button>
                        )}
                        &nbsp;{" "}
                        <button
                           className='btn btn-secondary btn-w-110 mb-3'
                           onClick={cancelarUpdate}
                        >
                           Cancelar
                        </button>
                     </div>
                  </div>
               )}
            </div>
         </div>
         {/* descartar  */}
         <Modal show={show_descartar}>
            <Modal.Header style={{ color: "#30345b" }}>
               <b>Descartar Usuario </b>
            </Modal.Header>

            <Form onSubmit={handleSubmit(Inhabilitar)}>
               <Modal.Body>
                  {fetchingUpdate ? (
                     <Progress />
                  ) : (
                     <div class='form-row'>
                        <div class='col-12 col-md-12 my-3'>
                           <label for='v1'>Observaciones</label>
                           <textarea
                              ref={register({})}
                              class='form-control'
                              id='v1'
                              rows='3'
                              name='observacion'
                           ></textarea>
                        </div>
                     </div>
                  )}
               </Modal.Body>

               <Modal.Footer>
                  <Button variant='secondary' onClick={handleClose_descartar}>
                     Cancelar
                  </Button>
                  <button type='submit' className='btn btn-danger'>
                     Inhabilitar
                  </button>
               </Modal.Footer>
            </Form>
         </Modal>
         {/* Confirmar la validacion */}
         <Modal show={show} name='modal_validar_monitoreo'>
            <Modal.Header style={{ color: "#30345b" }}>
               <b>Confimar </b>
            </Modal.Header>
            <Modal.Body>
               {fetching_monitoreo_update ? (
                  <div
                     style={{
                        width: "100%",
                        height: "100",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                     }}
                  >
                     <Loader
                        type='ThreeDots'
                        color='#11273c'
                        height='100'
                        width='100'
                     />
                  </div>
               ) : (
                  <p>¿Confirma validar este usuario?</p>
               )}
            </Modal.Body>
            <Modal.Footer>
               <Button variant='secondary' onClick={handleClose}>
                  Cancelar
               </Button>

               <Button variant='success' onClick={() => habilitar()}>
                  Validar
               </Button>
            </Modal.Footer>
         </Modal>
      </div>
   );
}

let mapState = (state) => {
   return {
      access: state.user.user.access,
      user: state.user.user,
      fetching_monitoreo_update: state.monitoreos.fetching_monitoreo_update,
   };
};

const mapDispatch = (dispatch) => {
   return {
      setUpdatingMonitoreoAction: (props) =>
         dispatch(setUpdatingMonitoreoAction(props)),
      updateMonitoreoAction: (props) => dispatch(updateMonitoreoAction(props)),
   };
};
export default connect(mapState, mapDispatch)(Hederform);
