import React from "react";

export default function B({ titulo }) {
   return (
      <section className='titseccion bg-titleseccion'>
         <div
            className='container-xl h-100 d-flex align-items-center'
            style={{ flexWrap: "wrap" }}
         >
            <div className='col-12 col-sm-6 col-md-6'>
               <h2 className='title-modulo'>{titulo}</h2>
            </div>
            <div className='col-12 col-sm-6 col-md-6 d-flex justify-content-end'>
               <a href='https://minambiente.gov.co'>
                  <img
                     src={ process.env.PUBLIC_URL +'http://www.invemar.org.co/Invemar3.0-theme/images/img/logos/min-ambiente-gov.jpg'}
                     width='240px'
                     alt=''
                  />
               </a>
            </div>
         </div>
      </section>
   );
}
