import React from "react";
import Nav from "components/base/navs/NavHome";
import Footer from "components/base/Footer";
import { URL_APP } from "Routes"
import {Link} from "react-router-dom"
export default function TCarey() {
  let url = URL_APP();
  return (
    <div>
      <div className="content-principal  content-internas">
        <div className="temporal-cerar"></div>
        <header className=" internas-h ">
          <Nav />
          <section class="titseccion bg-bluelight">
                <div class="container-xl h-100 d-flex align-items-center" style={{flexWrap: "wrap"}}>

                    <div class="col-12 col-sm-6 col-md-6">
                        <h4><i>Eretmochelys imbricata</i> - Tortuga carey <span class="add-n-especie">(Linnaeus,1766)</span>
                        </h4>
                    </div>
                    {/* <!--div class="col-12 col-sm-6 col-md-6 d-flex justify-content-end">
                    <a href="https://minambiente.gov.co"><img src={ process.env.PUBLIC_URL +"http://www.invemar.org.co/Invemar3.0-theme/images/img/logos/min-ambiente-gov.jpg" width="240px" alt=""></a>
                  </div--> */}

                </div>
            </section>
        </header>
        <div
          class="content-principal  content-internas"
          style={{background: "#ffffff"}}
        >
            <section className="contents-interna content-blank interna-componente">
              <nav aria-label="breadcrumb px-0">
                <ol className="breadcrumb px-0">
                  <li className="breadcrumb-item">
                    <Link to={url.home}>Tortugas Marinas en Colombia</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Tortuga carey
                  </li>
                </ol>
              </nav>
              <div className="container-xl mb-3">
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <div className="row mb-3">
                      <div className="col-md-5">
                        <div className="con_arbolfichaesp">
                          <div className="cont_listarbol">
                            <ul className="listaarbol_ficha">
                              <li
                                className="ini-arbol"
                                style={{fontStyle: "italic"}}
                              >
                                <span>REINO:</span> ANIMALIA
                                <ul>
                                  <li>
                                    <span>PHYLUM:</span> CHORDATA
                                    <ul>
                                      <li>
                                        <span>CLASE:</span> REPTILIA
                                        <ul>
                                          <li>
                                            <span>ORDEN:</span> TESTUDINES
                                            <ul>
                                              <li>
                                                <span>FAMILIA:</span>{" "}
                                                CHELONIIDAE
                                                <ul>
                                                  <li>
                                                    <span>GÉNERO:</span>{" "}
                                                    ERETMOCHELYS
                                                  </li>
                                                </ul>
                                              </li>
                                            </ul>
                                          </li>
                                        </ul>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <h6>Sinonimias</h6>
                        <p>
                          <i>Eretmochelys squamosa, Testudo imbricata </i>
                        </p>
                      </div>
                      <div className="col-md-4 mb-3">
                        <div
                          id="carouselExampleControls"
                          className="carousel slide"
                          data-ride="carousel"
                        >
                          <div className="carousel-inner">
                            <div className="carousel-item active">
                              <img
                                src={ process.env.PUBLIC_URL +"/static/images/componente-t-t/tcarey-f1.jpg"}
                                className="d-block w-100"
                                alt="..."
                              />
                            </div>
                            <div className="carousel-item">
                              <img
                                src={ process.env.PUBLIC_URL +"/static/images/componente-t-t/tcarey-f2.jpg"}
                                className="d-block w-100"
                                alt="..."
                              />
                            </div>
                            <div className="carousel-item">
                              <img
                                src={ process.env.PUBLIC_URL +"/static/images/componente-t-t/tcarey-f3.jpg"}
                                className="d-block w-100"
                                alt="..."
                              />
                            </div>
                          </div>
                          <a
                            className="carousel-control-prev"
                            href="#carouselExampleControls"
                            role="button"
                            data-slide="prev"
                          >
                            <span
                              className="carousel-control-prev-icon"
                              aria-hidden="true"
                            ></span>
                            <span className="sr-only">Previous</span>
                          </a>
                          <a
                            className="carousel-control-next"
                            href="#carouselExampleControls"
                            role="button"
                            data-slide="next"
                          >
                            <span
                              className="carousel-control-next-icon"
                              aria-hidden="true"
                            ></span>
                            <span className="sr-only">Next</span>
                          </a>
                        </div>
                      </div>
                      <div className="col-md-3 mb-3">
                        <h6>Especies de tortugas marinas en Colombia</h6>
                        <div className="btn-group-vertical f-especie">
                          <a href="javascript:void(0)" className="btn btn-light active">
                            {" "}
                            <i>Eretmochelys imbricata</i>
                          </a>
                          <a href="ficha-tortuga-verde" className="btn btn-light">
                            {" "}
                            <i>Chelonia mydas</i>
                          </a>
                          <a href="ficha-tortuga-caguama" className="btn btn-light">
                            {" "}
                            <i>Caretta caretta</i>
                          </a>
                          <a href="ficha-tortuga-laud" className="btn btn-light">
                            {" "}
                            <i>Dermochelys coriacea </i>
                          </a>
                          <a href="ficha-tortuga-golfina" className="btn btn-light">
                            {" "}
                            <i>Lepidochelys olivacea</i>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12" style={{textAlign: "justify"}}>
                        <h6>Nombres comunes</h6>
                        <p>
                          <i>
                            Carey de concha, Tortuga parape, Tortuga carey,
                            Tortuga de carey, Tortuga fina, Hawksbill
                          </i>
                        </p>
                        <h6>Categoría de amenaza </h6>
                        <p>
                          Global: En peligro crítico (CR) A2bd <hr /> Nacional:
                          En peligro crítico (CR)
                        </p>
                        <hr />

                        <div
                          className="row d-flex align-items-center"
                          style={{flexWrap: "wrap"}}
                        >
                          <div className="col-md-6">
                            <h6>Descripción</h6>
                            <p>
                              Es una tortuga de cabeza angosta, con un pico
                              recto y puntiagudo en su mandíbula superior,
                              parecido al de un halcón, con dos pares de escamas
                              pre-frontales. Su caparazón es de forma ovalada,
                              más largo que ancho, con cuatro escudos imbricados
                              (traslapados) laterales a cada lado, el primero de
                              éstos separado del escudo nucal (Pritchard y
                              Mortimer, 2000). Los adultos tienen entre 60-95,5
                              cm de longitud recta del caparazón (LRC) (Chacón,
                              2009). Aletas anteriores con dos uñas visibles.
                              Desde que son juveniles el caparazón presenta una
                              fuerte pigmentación con vetas que van desde un
                              marrón oscuro hasta ámbar; más oscuras en
                              ejemplares del océano Pacífico. Plastrón con
                              matices amarillo-pálido a blanco, cuatro escudos
                              inframarginales sin poros a cada lado.
                            </p>
                          </div>
                          <div className="col-md-6 text-center ">
                            <img
                              className="img-fluid"
                              src={ process.env.PUBLIC_URL +"/static/images/componente-t-t/tcarey-cd.jpg"}
                              alt=""
                            />
                            <cite>
                              "Tomada de Ecker <i>et al</i>., 2000. Técnicas de
                              Investigación y Manejo para la Conservación de las
                              Tortugas Marinas"
                            </cite>
                          </div>
                        </div>
                        <hr />
                        <h6>Distribución y hábitat</h6>
                        <div className="row mt-3">
                          <div
                            className="col-md-12 d-flex justify-content-center"
                            style={{flexWrap: "wrap"}}
                          >
                            <img
                              className="w-75"
                              src={ process.env.PUBLIC_URL +"/static/images/componente-t-t/tcarey-md.jpg"}
                              alt=""
                            />
                            <cite>
                              Mapa de distribución de{" "}
                              <i>Eretmochelys imbricata</i>.
                              www.fao.org/fishery/en., Acceso 27 Nov. 2020.
                            </cite>
                            <p className="mt-3">
                              Distribución circumglobal, en aguas tropicales y
                              en menor medida en aguas subtropicales del océano
                              Atlántico, Índico y Pacífico. La especie anida en
                              por lo menos 60 países (Groombridge y Luxmoore,
                              1989). En Colombia se ha registrado en los
                              departamentos de Antioquia, Archipiélago de San
                              Andrés, Providencia y Santa Catalina, Atlántico,
                              Bolívar, Cauca, Chocó, Córdoba, La Guajira,
                              Magdalena, Nariño, Sucre y Valle del Cauca. La
                              especie presenta migración latitudinal,
                              longitudinal, transfronteriza y local. En el
                              Caribe hay rutas de migración entre Cuba, Honduras
                              y Puerto Rico. Pabón-Aldana <i>et al</i>. (2012)
                              hicieron seguimientos satelital a un juvenil de
                              carey liberado en el Parque Nacional Natural
                              Tayrona, y observaron que la tortuga permaneció
                              los primeros 21 días en las aguas del Parque y
                              luego se fue alejando de la costa, con movimientos
                              asociados a las corrientes superficiales, llegando
                              finalmente a Bocas del Toro (Panamá), un lugar
                              identificado como zona favorable de alimentación
                              para la especie (Meylan <i>et al</i>. 2006). En el
                              Pacífico aún no se ha identificado claramente la
                              dirección de las rutas de migración.
                            </p>
                          </div>
                        </div>
                        <hr />
                        <h6>Ciclo de vida</h6>
                        <p>
                          Los neonatos migran a zonas pelágicas, donde
                          permanecen en las masas flotantes de algas (
                          <i>Sargassum</i> spp), alimentándose de la fauna que
                          vive allí, como copépodos, animales gelatinosos,
                          babosas de mar e hidroides. La dieta de juveniles y
                          adultos se especializa en esponjas, alimentándose casi
                          exclusivamente de unas pocas especies de esponjas y de
                          manera oportunista de pequeños invertebrados, anemonas
                          y algas. La especie alcanzan la madurez sexual entre
                          20 a 40 años (Chaloupka y Musick, 1997), se reproduce
                          cada dos o cuatro años (Witzell, 1983; Mortimer y
                          Bresson, 1999). Pueden reanidar de dos hasta cinco
                          veces por temporada, con un intervalo de dos semanas
                          entre posturas. Las nidadas tienen entre 120 a 180
                          huevos, con un diámetro de 32 a 36 mm y la LRC de los
                          neonatos va de 39 a 46 mm (Pritchard y Mortimer,
                          2000). En el Caribe colombiano la estación o temporada
                          reproductiva se extiende desde abril hasta noviembre
                          (Kaufmann, 1967), con dos picos de anidación en mayo y
                          septiembre (Barrientos-Muñoz y Ramírez-Gallego obs.
                          pers.). En el Pacífico colombiano se desconoce su
                          época de anidación, siendo la especie avistada
                          principalmente en áreas de alimentación del Parque
                          Nacional Natural Gorgona (Gaos <i>et al</i>. 2010,
                          Tobón-López y Amorocho, 2014). Presenta determinación
                          sexual dependiente de la temperatura, en el Caribe la
                          temperatura pivotal promedio varía de 29,2 a 29,6 °C.
                          Al concluir la estación reproductiva las hembras
                          migran a zonas de forrajeo cercanas, considerándose
                          esta como la especie más “residente” entre las
                          tortugas marinas.
                        </p>
                        <hr />
                        <h6>Estado de Conservación</h6>
                        <p>
                          Es la especie con distribución más amplia, pero la
                          menos estudiada y con baja abundancia de nidos en el
                          Caribe colombiano. En el Pacífico se han registrado
                          muy pocos avistamientos, siendo el Parque Nacional
                          Natural Gorgona el sitio donde se han registrado más
                          individuos en zonas de alimentación, con 25 individuos
                          registrados entre 1982 a 2009 (Gaos <i>et al</i>.
                          2010), y 16 individuos en el 2010 (Tobón-López y
                          Amorocho, 2014). En el Parque Nacional Natural Utría,
                          se han observado juveniles de carey alrededor de los
                          parches de coral de Punta Diego y el arrecife de la
                          Aguada (Ramírez-Gallego obs. pers.). Junto con{" "}
                          <i>Chelonia mydas</i>, es de las especies más
                          comercializadas por su carne y su caparazón en
                          Riohacha y Maicao (Rueda <i>et al</i>. 1992). A nivel
                          mundial es la especie de tortuga con mayor presión
                          antropogénica debido al tráfico ilegal de su
                          caparazón, del cual se obtiene el “carey”, también
                          conocido como “tortoiseshell” o “bekko”, que se usa
                          para la fabricación de marcos de gafas, uñas para
                          guitarras, espuelas para gallos de pelea, objetos
                          ornamentales para las mujeres, entre otras artesanías,
                          siendo ésta la causa principal de su descenso
                          poblacional.
                        </p>
                        <hr />
                        <h6>Medidas de Conservación</h6>
                        <p>
                          Fue la primera especie de tortuga marina vedada en
                          todo el territorio nacional en 1977 (Resolución 1032
                          de 1977 del Inderena) y protegida por medidas
                          generales como el Decreto 1681 de 1978 del Inderena,
                          Acuerdo 021 de 1991 del Inderena, Resolución 2879 de
                          1995 de Corpoguajira, Resolución 1644 de 1998 de
                          Corpamag y Artículo 328 del Código Penal. Sin embargo,
                          la implementación de las medidas establecidas no
                          cuentan con estrategias eficientes; artesanías de
                          carey en todo el país son vendidas sin regulación o
                          aplicación de ley (Barrienttos-Muños <i>et al</i>.,
                          2015). A nivel internacional, la especie se encuentra
                          en el Apéndice I de CITES, prohibiendo su
                          comercialización totalmente a nivel global, en el
                          Apéndice I y II de la Convención de Bonn y en el Anexo
                          II del Protocolo SPAW, lo cual genera acciones de
                          cooperación para la conservación de la especie.
                        </p>
                        <hr />
                        <h6>Ficha SiAM</h6>
                        <a href="https://siam.invemar.org.co/sibm-historia-natural-especies-ficha/70.55.3568">
                          https://siam.invemar.org.co/sibm-historia-natural-especies-ficha/70.55.3568
                        </a>
                        <h6>Ficha SIB</h6>
                        <a href="https://catalogo.biodiversidad.co/file/5ce8210e8fe61bf73db1181e/summary">
                          https://catalogo.biodiversidad.co/file/5ce8210e8fe61bf73db1181e/summary
                        </a>
                        <hr />
                        <h6>Referencias Generales</h6>
                        <p>
                          *Amorocho, D. 2014. <i>Eretmochelys imbricata</i>.
                          247-250. En: Amaya-Espinel, J. y Zapata, L. (Eds).
                          Guía de las especies migratorias de la biodiversidad
                          en Colombia. Insectos, murciélagos, tortugas marinas,
                          mamíferos marinos y dulceacuícolas. Vol. 3. Ministerio
                          de Ambiente y Desarrollo Sostenible / WWF-Colombia.
                          Bogotá, D.C. Colombia. 370 p. *Barrientos-Muñoz, K.,
                          Ramírez, C. y Páez, V. 2015.{" "}
                          <i>Eretmochelys imbricata</i>. 127-131. En:
                          Morales-Betancourt, M., Lasso, C., Páez, V. y Bock, B.
                          Libro rojo de reptiles de Colombia. Instituto de
                          Investigación de Recursos Biológicos Alexander von
                          Humboldt (IAvH), Universidad de Antioquia. Bogotá,
                          D.C. Colombia. 258 p. *Mortimer, J. y Donnelly, M.
                          (IUCN SSC Marine Turtle Specialist Group). 2008. 
                          <i>Eretmochelys imbricata</i>. The IUCN Red List of
                          Threatened Species 2008: e.T8005A12881238.
                          https://dx.doi.org/10.2305/IUCN.UK.2008.RLTS.T8005A12881238.en. 
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <Footer />
      </div>
    </div>
  );
}
