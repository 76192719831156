import React, { useState, useEffect } from "react";
import { URL_APP } from "Routes";
import { doLoginAction } from "redux/userDuck";
import { connect } from "react-redux";
import { Alert } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useHistory,Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Progress from "../Progress";

function ModalLogin({
  show,
  hide,
  loggedIn,
  error,
  fetching,
  roles,
  doLoginAction,
}) {
  let url = URL_APP();
  const history = useHistory();
  const { register, errors } = useForm();

  //    const [show, setShow] = useState(false);
  // useEffect(() => {
  //    if (loggedIn) {
  //    }
  // }, [loggedIn]);

  const onSubmit = (e) => {
    // e.preventDefault();
    doLoginAction({
      username: e.target[0].value,
      password: e.target[1].value,
    });
  };

  if (loggedIn) {
    console.log("iniciar = ", show);
    roles.filter(
      (rol) =>
        (rol.id === 216 && history.push(url.panel_curador)) ||
        (rol.id === 219 && history.push(url.panel_investigador))
    );
    hide();
  }

  return (
    <Modal
      show={show}
      class="modal fade"
      id="modal_login"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <Modal.Header>
        <Modal.Title style={{ color: "#30345b" }}>
          Acceso a los módulos del componente Tortugas Marinas
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error.length > 0 ? (
          <Alert variant={"danger"} className="container-xl my-3">
            {error}
          </Alert>
        ) : (
          []
        )}
        {fetching ? (
          <Progress />
        ) : (
          <div class="d-flex justify-content-center">
            <div class="col-md-10">
              <form onSubmit={(e) => onSubmit(e)}>
                <div class="form-group">
                  <label for="exampleInputEmail1">Correo electrónico</label>
                  <input
                    type="email"
                    className="form-control"
                    id="exampleInputEmail1"
                    name="email"
                    ref={register({ required: true })}
                    aria-describedby="emailHelp"
                    placeholder="Enter email"
                  />
                  {errors.nombre && <span>This field is required</span>}
                </div>
                <div class="form-group">
                  <label for="exampleInputPassword1">Contraseña</label>

                  <input
                    type="password"
                    className="form-control"
                    id="exampleInputPassword1"
                    name="password"
                    ref={register({ required: true })}
                    placeholder="Password"
                  />
                  {errors.password && <span>This field is required</span>}
                </div>
                <button type="submit" className="btn btn-primary btn-block">
                  Iniciar
                </button>
                <button
                  type="button"
                  className="btn btn-secondary btn-block"
                  onClick={hide}
                >
                  Cancelar
                </button>
                <div class="row justify-content-center">
                  <div class="col-6">
                    {" "}
                    <Link to={url.registro_usauario}>
                      <small id="emailHelp" class="form-text text-primary">
                        Registrarse
                      </small>
                    </Link>
                  </div>
                  <div class="col-6">
                    {" "}
                    <Link to={url.forgot}>
                      <small id="emailHelp" class="form-text text-primary">
                        ¿Olvidaste tu contraseña?
                      </small>
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <small class="form-text text-muted">
          Si tiene dificultades técnicas para acceder a la herramienta de
          recopilación de datos de monitoreo de Tortugas marinas de Colombia por
          favor comuníquese al correo
          <a href="mailto:julian.pizarro@invemar.org.co">
            {" "}
            julian.pizarro@invemar.org.co
          </a>
        </small>
        <small class="form-text text-muted">
          Si necesita apoyo temático (descargar datos, subir datos, integrarse
          al grupo de investigadores) comuníquese con el administrador del
          sistema
          <a href="mailto:julian.pizarro@invemar.org.co">
            {" "}
            luis.chasqui@invemar.org.co
          </a>
        </small>
      </Modal.Footer>
    </Modal>
  );
}
// sacar los state de (store) que tiene redux y los coloque en las props de este componente.
let mapState = (state) => {
  if (state && state.user.loggedIn) {
    // console.log(state.user.user.userInfo.roles);
    return {
      roles: state.user.user.userInfo.roles,
      fetching: state.user.fetching,
      loggedIn: state.user.loggedIn,
      error: state.user.error,
    };
  }
  return {
    fetching: state.user.fetching,
    loggedIn: state.user.loggedIn,
    error: state.user.error,
  };
};
// sacar los dispatch de (store) que tiene redux y los coloque en las props de este componente.
const mapDispatch = (dispatch) => {
  return {
    doLoginAction: (props) => dispatch(doLoginAction(props)),
  };
};

// connect(mapState,mapDispatch)(this funtion)
export default connect(mapState, mapDispatch)(ModalLogin);
