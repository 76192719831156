import React from "react";
import Nav from "components/base/navs/NavHome";
import Footer from "components/base/Footer";
import { URL_APP } from "Routes"
import {Link} from "react-router-dom"


export default function TVerde() {
  let url = URL_APP();
  return (
    <div>
      <div className="content-principal  content-internas">
        <header className=" internas-h ">
          <Nav />
          <section class="titseccion bg-bluelight">
                <div class="container-xl h-100 d-flex align-items-center" style={{flexWrap: "wrap"}}>

                    <div class="col-12 col-sm-6 col-md-6">
                        <h4><i>Chelonia mydas</i> - Tortuga verde <span class="add-n-especie">(Linnaeus, 1758)</span>
                        </h4>
                    </div>
                    {/* <!--div class="col-12 col-sm-6 col-md-6 d-flex justify-content-end">
                    <a href="https://minambiente.gov.co"><img src={ process.env.PUBLIC_URL +"http://www.invemar.org.co/Invemar3.0-theme/images/img/logos/min-ambiente-gov.jpg" width="240px" alt=""></a>
                  </div--> */}

                </div>
            </section>
        </header>
        <div
          class="content-principal  content-internas"
          style={{background: "#ffffff"}}
        >
          
            <section className="contents-interna content-blank interna-componente">
              <nav aria-label="breadcrumb px-0">
                <ol className="breadcrumb px-0">
                  <li className="breadcrumb-item">
                    <Link to={url.home}>Tortugas Marinas en Colombia</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Tortuga verde
                  </li>
                </ol>
              </nav>
              <div className="container-xl mb-3">
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <div className="row mb-3">
                      <div className="col-md-5">
                        <div className="con_arbolfichaesp">
                          <div className="cont_listarbol">
                            <ul className="listaarbol_ficha">
                              <li
                                className="ini-arbol"
                               style={{fontStyle: "italic"}}
                              >
                                <span>REINO:</span> ANIMALIA
                                <ul>
                                  <li>
                                    <span>PHYLUM:</span> CHORDATA
                                    <ul>
                                      <li>
                                        <span>CLASE:</span> REPTILIA
                                        <ul>
                                          <li>
                                            <span>ORDEN:</span> TESTUDINES
                                            <ul>
                                              <li>
                                                <span>FAMILIA:</span>{" "}
                                                CHELONIIDAE
                                                <ul>
                                                  <li>
                                                    <span>GÉNERO:</span>{" "}
                                                    CHELONIA
                                                  </li>
                                                </ul>
                                              </li>
                                            </ul>
                                          </li>
                                        </ul>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <h6>Sinonimias</h6>
                        <p>
                          <i>
                            Chelonia japonica, Chelonia viridis, Testudo mydas{" "}
                          </i>
                        </p>
                      </div>
                      <div className="col-md-4 mb-3">
                        <div
                          id="carouselExampleControls"
                          className="carousel slide"
                          data-ride="carousel"
                        >
                          <div className="carousel-inner">
                            <div className="carousel-item active">
                              <img
                                src={ process.env.PUBLIC_URL +"/static/images/componente-t-t/t-verde@2x.jpg"}
                                className="d-block w-100"
                                alt="..."
                              />
                            </div>
                            <div className="carousel-item">
                              <img
                                src={ process.env.PUBLIC_URL +"/static/images/componente-t-t/tverde2.jpg"}
                                className="d-block w-100"
                                alt="..."
                              />
                            </div>
                            <div className="carousel-item">
                              <img
                                src={ process.env.PUBLIC_URL +"/static/images/componente-t-t/tverde-f3.jpg"}
                                className="d-block w-100"
                                alt="..."
                              />
                            </div>
                          </div>
                          <a
                            className="carousel-control-prev"
                            href="#carouselExampleControls"
                            role="button"
                            data-slide="prev"
                          >
                            <span
                              className="carousel-control-prev-icon"
                              aria-hidden="true"
                            ></span>
                            <span className="sr-only">Previous</span>
                          </a>
                          <a
                            className="carousel-control-next"
                            href="#carouselExampleControls"
                            role="button"
                            data-slide="next"
                          >
                            <span
                              className="carousel-control-next-icon"
                              aria-hidden="true"
                            ></span>
                            <span className="sr-only">Next</span>
                          </a>
                        </div>
                      </div>
                      <div className="col-md-3 mb-3">
                        <h6>Especies de tortugas marinas en Colombia</h6>
                        <div className="btn-group-vertical f-especie">
                          <a href="ficha-tortuga-carey" className="btn btn-light">
                            {" "}
                            <i>Eretmochelys imbricata</i>
                          </a>
                          <a href="javascript:void(0)" className="btn btn-light active">
                            {" "}
                            <i>Chelonia mydas</i>
                          </a>
                          <a href="ficha-tortuga-caguama" className="btn btn-light">
                            {" "}
                            <i>Caretta caretta</i>
                          </a>
                          <a href="ficha-tortuga-laud" className="btn btn-light">
                            {" "}
                            <i>Dermochelys coriacea </i>
                          </a>
                          <a href="ficha-tortuga-golfina" className="btn btn-light">
                            {" "}
                            <i>Lepidochelys olivacea</i>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12" style={{textAlign: "justify"}}>
                        <h6>Nombres comunes</h6>
                        <p>
                          <i>
                            Tortuga verde del pacífico, moro, tortuga verde,
                            tortuga blanca, tortuga negra, Green sea turle
                          </i>
                        </p>
                        <h6>Categoría de amenaza </h6>
                        <p>
                          Global: En peligro (EN) A2bd 
                          <br /> Nacional: En peligro (EN)
                        </p>
                        <hr />
                        <h6>Descripción</h6>
                        <div className="row">
                          <div className="col-md-6">
                            <p>
                              La especie tiene una cabeza pequeña y roma,
                              cubierta por grandes escamas simétricas de color
                              café-rojizo, dos escamas prefrontales alargadas
                              entre las órbitas y cuatro escamas postorbitales.
                              Aletas con una sola uña visible. Su caparazón es
                              oval, liso y deprimido con margen ocasionalmente
                              ondulado pero no aserrado (Pritchard y Mortimer,
                              2000) con cuatro pares de escamas laterales
                              yuxtapuestas, el primer par no está en contacto
                              con la escama nucal. Las hembras anidantes
                              presentan de 81-111 cm de longitud recta del
                              caparazón (LRC) y pesos entre 104-130 kg.
                              Coloración en adultos desde tonos pálidos hasta
                              muy oscuros y frecuentemente café-oliváceo con
                              escamas que presentan figuras con vetas radiales
                              amarillas o verdes y manchas negras (Márquez,
                              1990); los individuos cubiertos de algas verdes
                              microscópicas, tienen apariencia verdosa. El
                              plastrón es blanco, o blanco amarillento y tiene
                              cuatro pares de escamas inframarginales. Las
                              poblaciones del Pacífico (
                              <i>Chelonia mydas agassizii</i>) son por lo
                              general más melánicas dorsalmente (gris oscuro a
                              negro), con plastrón gris oscuro o azul-verdoso.
                              El caparazón de los neonatos es marrón oscuro o
                              casi negro, con bordes de color blanco, al igual
                              que el plastrón.
                            </p>
                          </div>
                          <div className="col-md-6 text-center ">
                            <img
                              className="img-fluid"
                              src={ process.env.PUBLIC_URL +"/static/images/componente-t-t/tverde-cd.jpg"}
                              alt=""
                            />
                            <cite>
                              Tomada de Ecker <i>et al</i>., 2000. Técnicas de
                              Investigación y Manejo para la Conservación de las
                              Tortugas Marinas
                            </cite>
                          </div>
                        </div>
                        <hr />
                        <h6>Distribución y hábitat</h6>
                        <div className="row mt-3">
                          <div
                            className="col-md-12 d-flex justify-content-center"
                            style={{flexWrap: "wrap"}}
                          >
                            <img
                              className="w-75"
                              src={ process.env.PUBLIC_URL +"/static/images/componente-t-t/tverde-md.jpg"}
                              alt=""
                            />
                            <cite>
                              Mapa de distribución de <i>Chelonia mydas</i>.
                              www.fao.org/fishery/en., Acceso 27 Nov. 2020.
                            </cite>
                            <p className="mt-3">
                              Distribución circumglobal, principalmente en aguas
                              tropicales (NOAA Fisheries 2006). Los adultos son
                              herbívoros, y se pueden encontrar asociado a
                              praderas de fanerógamas marinas. En Colombia se ha
                              registrado en los departamentos de Antioquia,
                              Archipiélago de San Andrés, Providencia y Santa
                              Catalina, Atlántico, Bolívar, Cauca, Chocó,
                              Córdoba, La Guajira, Magdalena, Nariño, Sucre y
                              Valle del Cauca. Se han reportado playas de
                              anidación en la península de La Guajira, las islas
                              del Rosario, el Golfo de Morrosquillo, el
                              Archipiélago de San Andrés y Providencia, la isla
                              Gorgona, y la playa El Valle en el Chocó. La
                              especie presenta migración latitudinal,
                              longitudinal, transfronteriza y local. Se conocen
                              migraciones desde las islas Miskitos (Nicaragua) y
                              Tortuguero (Costa Rica), hasta las praderas de
                              pastos marinos en las costas colombianas, en la
                              península de la Guajira, con destino a isla de
                              Aves (Venezuela), Surinam o el Atlántico brasileño
                              (Rueda, 2001), y desde las playas del costado
                              Pacífico, en Maruata y Colola (México), hacia
                              costas de Centroamérica, Colombia, Ecuador y las
                              islas Galápagos.
                            </p>
                          </div>
                        </div>
                        <hr />
                        <h6>Ciclo de vida</h6>
                        <p>
                          La tortuga verde presenta un ciclo de vida Tipo II
                          (Bolten, 2003), en que los más jóvenes se encuentran
                          en la zona oceánica. Al alcanzar tamaños cercanos a
                          los 20-25 cm LRC, se desplazan a hábitats neríticos
                          (ambiente marino costero, con profundidades menores a
                          los 200 m). Los adultos se dispersan de las zonas de
                          forrajeo neríticas hacia corredores oceánicos para el
                          apareamiento. Los recién nacidos hasta tallas
                          juveniles son carnívoros y los adultos son
                          principalmente herbívoros, alimentándose de pastos
                          marinos (
                          <i>
                            Thalassia testudinum, Halodule sp., Syringodium sp.
                          </i>
                          ), algas bentónicas y esponjas, aunque también
                          consumen en menor proporción, briozoos, crustáceos,
                          moluscos y erizos de mar (Márquez, 1990; Amorocho y
                          Reina, 2007, 2008; Amaya- Espinel y Zapata, 2014).
                          Existe variación geográfica de la edad de madurez
                          sexual, entre 19-30 años (Heppell <i>et al</i>. 2003).
                          Estas tortugas se reproducen cada dos o cuatro años, y
                          pueden reanidar de dos hasta cinco veces por estación,
                          con un intervalo aproximado de dos semanas entre
                          posturas. En el Caribe colombiano la estación de
                          anidación se extiende desde julio a noviembre,
                          posiblemente con un comportamiento similar en el
                          Pacífico (Álvarez-León, 2001; Barrientos <i>et al</i>.
                          2013). Las posturas varían entre 85-150 huevos (con
                          promedio de 111 huevos). El diámetro promedio de los
                          huevos varía entre 43-53 mm y el promedio de la LRC de
                          los neonatos está entre 47-54 mm (Márquez, 1990).
                        </p>
                        <hr />
                        <h6>Estado de Conservación</h6>
                        <p>
                          Según Amorocho (2014) no más de 50 hembras adultas se
                          reproducen en los cayos del Archipiélago de San
                          Andrés, Providencia y Santa Catalina. Los registros
                          más numerosos de hembras anidantes fueron en Riohacha
                          (La Guajira) (Rueda <i>et al</i>. 1992) y Cartagena
                          (Bolívar) (Medem, 1983). Según Álvarez- León (2001)
                          otros registros de avistamientos de hembras anidantes
                          no superan los ocho individuos por localidad.
                          Amaya-Espinel y Zapata (2014), afirmaron que hay una
                          disminución de la población en La Guajira. En el
                          Pacífico Oriental la reducción en los últimos 20 años
                          ha sido superior al 95% (Amorocho com. pers.). En 1988
                          en Riohacha, alrededor de 400 individuos adultos
                          fueron sacrificados para el consumo de su carne (Rueda{" "}
                          <i>et al</i>. 1992). Además del consumo de carne, el
                          saqueo de huevos en las playas de anidación, la
                          pérdida de hábitat, el desarrollo costero
                          (urbanización, turismo) y cambio climático son las
                          amenazas que enfrenten las tortugas marinas.{" "}
                          <i>Chelonia mydas</i> es la especie con mayores
                          registros de captura incidental por pesquerías en el
                          Pacífico colombiano.
                        </p>
                        <hr />
                        <h6>Medidas de Conservación</h6>
                        <p>
                          En el ámbito nacional está protegida por medidas
                          generales como el Decreto 1681 de 1978 del Inderena,
                          Acuerdo 021 de 1991 del Inderena, Resolución 2879 de
                          1995 de Corpoguajira, Resolución 1644 de 1998 de
                          Corpamag y Artículo 328 del Código Penal. A nivel
                          internacional, la especie se encuentra en el Apéndice
                          I de CITES, en el Apéndice I y II de la Convención de
                          Bonn y en el Anexo II del Protocolo SPAW, lo cual
                          genera acciones de cooperación para la conservación de
                          la especie.
                        </p>
                        <hr />
                        <h6>Ficha SiAM</h6>
                        <a href="https://siam.invemar.org.co/sibm-historia-natural-especies-ficha/70.55.3564">
                          https://siam.invemar.org.co/sibm-historia-natural-especies-ficha/70.55.3564
                        </a>
                        <h6>Ficha SIB</h6>
                        <a href="https://catalogo.biodiversidad.co/file/56d9077f3c16479905cba982/summary">
                          https://catalogo.biodiversidad.co/file/56d9077f3c16479905cba982/summary
                        </a>
                        <hr />
                        <h6>Referencias Generales</h6>
                        <p>
                          *Amorocho, D. 2014. <i>Chelonia mydas</i>. 241-261.
                          En: Amaya-Espinel, J. y Zapata, L. (Eds). Guía de las
                          especies migratorias de la biodiversidad en Colombia.
                          Insectos, murciélagos, tortugas marinas, mamíferos
                          marinos y dulceacuícolas. Vol. 3. Ministerio de
                          Ambiente y Desarrollo Sostenible / WWF-Colombia.
                          Bogotá, D.C. Colombia. 370 p. *Páez, V., Ramírez, C. y
                          Barrientos-Muñoz, K. 2015. <i>Chelonia mydas</i>.
                          153-156. En: Morales-Betancourt, M., Lasso, C., Páez,
                          V. y Bock, B. Libro rojo de reptiles de Colombia.
                          Instituto de Investigación de Recursos Biológicos
                          Alexander von Humboldt (IAvH), Universidad de
                          Antioquia. Bogotá, D.C. Colombia. 258 pp. *Seminoff,
                          J. (Southwest Fisheries Science Center, U.S.). 2004. 
                          <i>Chelonia mydas.</i> The IUCN Red List of Threatened
                          Species 2004 e.T4615A11037468.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          <Footer />
        </div>
      </div>
    </div>
  );
}
