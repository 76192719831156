import React, { useState, useEffect } from "react";
import { URL_APP } from "../../../../Routes";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { Button } from "react-bootstrap";
import ContentsIndividuo from "./ContentsIndividuo";
import ContentsNido from "./ContentsNido";
import ContentsNidoRelo from "./ContentsNidoRelo";
import { Alert, Modal } from "react-bootstrap";
import { useHistory,Link } from "react-router-dom";
import ContentsOcultos from "./ContentsOcultos";
import defaultVariables from "Routes";
import {
   setRegistrarMonitoreoAction,
   getMonitoreoAction,
} from "../../../../../src/redux/monitoreoDuck";

import {
   getInstitucionesAction,
   getEspeciesAction,
   getUacAction,
   getDepartamentosAction,
   getMunicipiosAction,
   getZonasProtegidasAction,
   getReferentesAction,
} from "../../../../../src/redux/referentesDuck";

//formatos de fechas
function convertirFecha(fecha, formato) {
   var date = new Date(fecha);
   if (formato === "f1") {
      if (date.getHours() < 10 && date.getMinutes() < 10)
         return (
            date.getDate() +
            "/" +
            (date.getMonth() + 1) +
            "/" +
            date.getFullYear() +
            " 0" +
            date.getHours() +
            ":0" +
            date.getMinutes()
         );
      else if (date.getHours() < 10)
         return (
            date.getDate() +
            "/" +
            (date.getMonth() + 1) +
            "/" +
            date.getFullYear() +
            " 0" +
            date.getHours() +
            ":" +
            date.getMinutes()
         );
      else if (date.getMinutes() < 10)
         return (
            date.getDate() +
            "/" +
            (date.getMonth() + 1) +
            "/" +
            date.getFullYear() +
            " " +
            date.getHours() +
            ":0" +
            date.getMinutes()
         );
      else
         return (
            date.getDate() +
            "/" +
            (date.getMonth() + 1) +
            "/" +
            date.getFullYear() +
            " " +
            date.getHours() +
            ":" +
            date.getMinutes()
         );
   } else if (formato === "f2") {
      if (date.getDate() < 10)
         return (
            date.getFullYear() +
            "" +
            (date.getMonth() + 1) +
            "0" +
            date.getDate()
         );
      else
         return (
            date.getFullYear() +
            "" +
            (date.getMonth() + 1) +
            "" +
            date.getDate()
         );
   } else {
      return (
         date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
      );
   }
}

function Contents({
   //listas controladas
   arrary_referentes,
   arrary_instituciones,
   arrary_especies,
   arrary_uac,
   arrary_departamentos,
   arrary_municipios,
   arrary_zonas_protegidas,
   //usuario
   user,
   access,
   //muestreo o monitoreos hablo de lo mismo
   getMonitoreoAction,
   setRegistrarMonitoreoAction,
   //muestras
   msg_set_monitoreos,
   isRegister,
   isError,

   //Referentes
   getInstitucionesAction,
   getEspeciesAction,
   getUacAction,
   getDepartamentosAction,
   getMunicipiosAction,
   getZonasProtegidasAction,
   getReferentesAction,
}) {
   let url = URL_APP();
   let dfv = defaultVariables();
   const history = useHistory();
   const { register, handleSubmit, errors, onChange } = useForm();
   const [acunCodigoIngreso, setAcunCodigoIngreso] = useState(["", "", ""]);
   const [codigoIngreso, setcodigoIngreso] = useState("");
   const [nroHuevos, setNroHuevos] = useState(0);
   const [nroNeonatosEmergente, setNroNeonatosEmergente] = useState(0);
   const [nroHuevosEclosionados, setNroHuevosEclosionados] = useState(0);
   const [porcentajeEclosion, setPorcentajeEclosion] = useState(0);
   const [porcentajeEmergencia, setPorcentajeEmergencia] = useState(0);
   const [encontroNido, setEncontroNido] = useState(false);
   const [encontroHembra, setEncontroHembra] = useState(false);
   const [nidoRelocalizado, setNidoRelocalizado] = useState(false);
   const [latitudNido, setLatitudNido] = useState("");
   const [longitudNido, setLongitudNido] = useState("");
   const [muestreo_enlace, setMuestreo_enlace] = useState("");
   const [fechaInicial, setFechaInicial] = useState("");
   const [fechaFinal, setFechaFinal] = useState("");
   const [fechaAvistamiento, setFechaAvistamiento] = useState("");
   const [fechaEclosion, setFechaEclosion] = useState("");
   const [id_estacion, setId_estacion] = useState(0);
   const [disabledTxt, setDisabledTxt] = useState(false);
   const [show, setShow] = useState(false);

   //==========================================================================================================================
   useEffect(() => {
      getInstitucionesAction();
      getEspeciesAction();
      getUacAction();
      getDepartamentosAction();
      getMunicipiosAction();
      getZonasProtegidasAction();
      getReferentesAction();
   }, []);

   useEffect(() => {
      //peticiones para descargar los referentes de los select

      if (isRegister) {
         getMonitoreoAction({
            token: access,
            id_monitoreo: codigoIngreso,
         });

         // mostrar el modal de notificacion
         handleShow();
         //se ocultan datos (individuo , nido , relocalizacion nido)
         setEncontroHembra(false);
         setEncontroNido(false);
         setNidoRelocalizado(false);
         setDisabledTxt(true);
         // history.push(url.detalle_muestreo)
      }
   }, [isRegister]);

   const onSubmit = (data, e) => {
      // console.log("encontro henbra:",encontroHembra)
      //  console.log("LA DATA:",data)
      // console.log(data.target.cod_ingreso)

      /*Antes de guardar se verifica el estado del monitoreo con los siguientes criterios:
      1. Campos Requerido en Datos generales
         1.1. Consecutivo
         1.2. Especie
         1.3. Núm. de transecto
         1.4. Núm. de sector
         1.5. Latitud
         1.6. Longitud
         1.7. Fecha y hora del registro */

      // encontroNido        nidoRelocalizado;
      let respuesta_error = false;
      let observacion_error = "";

      if (!Boolean(data.consecutivo)) {
         respuesta_error = true;
         observacion_error = observacion_error + "Consecutivo,";
      }
      if (!Boolean(data.id_especie)) {
         respuesta_error = true;
         observacion_error = observacion_error + "Especie,";
      }
      if (!Boolean(data.transecto)) {
         respuesta_error = true;
         observacion_error = observacion_error + "Transecto,";
      }
      if (!Boolean(data.sector)) {
         respuesta_error = true;
         observacion_error = observacion_error + "Sector,";
      }
      if (!Boolean(data.latitud_individuo)) {
         respuesta_error = true;
         observacion_error = observacion_error + "Latitud individuo,";
      }
      if (!Boolean(data.longitud_individuo)) {
         respuesta_error = true;
         observacion_error = observacion_error + "Longitud individuo,";
      }

      if (!Boolean(data.hora)) {
         respuesta_error = true;
         observacion_error = observacion_error + "Hora avistamiento,";
      }
      /* 2. Si seleciono Interceptar hembras por lo menos debe de tener estos campos diligenciado:
         2.1. Cod. marca por lo menos que tenga dos no importando si es encontrada o implantada
         2.2. posicion de la marca seleccionada(que coorresponda a la los codigo que lleno anterioermente)
         2.3. Lcc (largo curvo del caparazon)
         2.4. Acc (Ancho curvo del caparazon)*/
      if (encontroHembra) {
         // 2.1.
         let isCodMarca = 0;
         let obsCodMarca = "";
         if (!Boolean(data.cdg_marca_encont_01)) {
            obsCodMarca = obsCodMarca + "Codigo Marca encontrada 1,";
         } else isCodMarca++;
         if (!Boolean(data.cdg_marca_encont_02)) {
            obsCodMarca = obsCodMarca + "Codigo Marca encontrada 2,";
         } else isCodMarca++;
         if (!Boolean(data.cdg_marca_impl_01)) {
            obsCodMarca = obsCodMarca + "Codigo Marca implantada 1,";
         } else isCodMarca++;
         if (!Boolean(data.cdg_marca_impl_02)) {
            obsCodMarca = obsCodMarca + "Codigo Marca implantada 2,";
         } else isCodMarca++;

         if (isCodMarca < 2) {
            observacion_error = observacion_error + obsCodMarca;
            respuesta_error = true;
         }

         //2.2.
         let isCodPosicionMarca = 0;
         let obsCodPosicionMarca = "";
         if (!Boolean(data.id_p_enc_1)) {
            obsCodPosicionMarca =
               obsCodPosicionMarca + "Posicion Marca encontrada 1,";
         } else isCodPosicionMarca++;
         if (!Boolean(data.id_p_enc_2)) {
            obsCodPosicionMarca =
               obsCodPosicionMarca + "Posicion Marca encontrada 2,";
         } else isCodPosicionMarca++;
         if (!Boolean(data.id_p_imp_1)) {
            obsCodPosicionMarca =
               obsCodPosicionMarca + "Posicion Marca implantada 1,";
         } else isCodPosicionMarca++;
         if (!Boolean(data.id_p_imp_2)) {
            obsCodPosicionMarca =
               obsCodPosicionMarca + "Posicion Marca implantada 2,";
         } else isCodPosicionMarca++;

         if (isCodPosicionMarca < 2) {
            observacion_error = observacion_error + obsCodPosicionMarca;
            respuesta_error = true;
         }

         //2.3. Lcc (largo curvo del caparazon)
         if (!Boolean(data.largo_caparazon)) {
            respuesta_error = true;
            observacion_error = observacion_error + "Largo caparazon,";
         }

         //2.4. Acc (Ancho curvo del caparazon)*/
         if (!Boolean(data.ancho_caparazon)) {
            respuesta_error = true;
            observacion_error = observacion_error + "Ancho caparazon,";
         }
      } //encontroHembra

      // console.log(respuesta_error)
      // console.log(observacion_error)

      /* 3. Si se encontro nido por lo menos debe de tener estos campos diligenciado:
         3.1. Posición del nido
         3.2. Manejo del nido
         3.3. Núm. Huevos
         3.4. Fecha de eclosion
         3.5. Núm. huevos eclosionado
         3.6. Dias de incubación
         3.7. Núm. Neonatos Emergente
         3.8. Núm. Neonatos liberados*/
      if (encontroNido) {
         if (!Boolean(data.posicion_nido)) {
            respuesta_error = true;
            observacion_error = observacion_error + "Posicion nido,";
         }
         if (!Boolean(data.manejo)) {
            respuesta_error = true;
            observacion_error = observacion_error + "Manejo,";
         }
         if (!Boolean(data.numero_huevos_puestos)) {
            respuesta_error = true;
            observacion_error = observacion_error + "Numero huevos,";
         }
         if (!Boolean(data.fecha_eclosion)) {
            respuesta_error = true;
            observacion_error = observacion_error + "Fecha eclosion,";
         }
         if (!Boolean(data.nro_huevos_eclosionado)) {
            respuesta_error = true;
            observacion_error = observacion_error + "Nro. huevos eclosionado,";
         }
         if (!Boolean(data.dias_incubacion)) {
            respuesta_error = true;
            observacion_error = observacion_error + "Dias incubacion,";
         }
         if (!Boolean(data.neonatos_emergentes)) {
            respuesta_error = true;
            observacion_error = observacion_error + "Neonatos emergentes,";
         }
         if (!Boolean(data.nro_neonatos_libr)) {
            respuesta_error = true;
            observacion_error = observacion_error + "Nro. neonatos libr,";
         }
      }

      /* 4. Si selecciono el Manejo del nido es diferente (Seguimineto in situ)a por lo menos debe de tener estos campos diligenciado:
         4.1. Latitud de relocalizacion
         4.2. Longitud de relocalizacion
         4.3. Prof. original en cm
         4.4. Prof. relocalización en cm
         4.5. Hora del siembra */
      if (nidoRelocalizado) {
         if (!Boolean(data.latitud_relo)) {
            respuesta_error = true;
            observacion_error = observacion_error + "Latitud relo.,";
         }
         if (!Boolean(data.longitud_relo)) {
            respuesta_error = true;
            observacion_error = observacion_error + "Longitud relo.,";
         }
         if (!Boolean(data.profundidad_original)) {
            respuesta_error = true;
            observacion_error = observacion_error + "Profundidad original,";
         }
         if (!Boolean(data.profundidad_rlc)) {
            respuesta_error = true;
            observacion_error = observacion_error + "Profundidad rlc.,";
         }
         if (!Boolean(data.hora_siembra)) {
            respuesta_error = true;
            observacion_error = observacion_error + "Hora siembra,";
         }
      }

      // console.log(respuesta_error?observacion_error:"H")

      setRegistrarMonitoreoAction({
         token: access,
         data: data,
         nota: respuesta_error ? "PEN," + observacion_error : "FIN",
      });
      // data.target.reset()
   };

   let onChanges = (e) => {
      switch (e.target.name) {
         case "id_auc":
            acunCodigoIngreso[0] = e.target.value;
            break;
         case "id_institucion":
            acunCodigoIngreso[1] = e.target.value;
            break;
         case "fecha_inicio_monitoreo":
            setFechaInicial(convertirFecha(e.target.value, "f1"));
            acunCodigoIngreso[2] = convertirFecha(e.target.value, "f2");
            break;
         case "fecha_fin_monitoreo":
            setFechaFinal(convertirFecha(e.target.value, "f1"));
            break;
         case "fechaEclosion":
            setFechaEclosion(convertirFecha(e.target.value, "f1"));
            break;
         case "avistamiento":
            setFechaAvistamiento(convertirFecha(e.target.value, "f1"));
            break;
         case "encontro_nido":
            if (e.target.value === "1") setEncontroNido(true);
            else {
               setEncontroNido(false);
               setNidoRelocalizado(false);
            }
            break;
         case "intercepto_hembra":
            if (e.target.value === "1") setEncontroHembra(true);
            else setEncontroHembra(false);
            setNidoRelocalizado(false);
            break;
         case "manejo":
            if (e.target.value !== "IS") setNidoRelocalizado(true);
            else setNidoRelocalizado(false);
            break;
         case "neonatos_emergentes":
            setNroNeonatosEmergente(e.target.value);
            break;
         case "numero_huevos_puestos":
            setNroHuevos(e.target.value);
            break;
         case "nro_huevos_eclosionado":
            setNroHuevosEclosionados(e.target.value);
            break;
         case "longitud_individuo":
            setLongitudNido(e.target.value);
            break;
         case "latitud_individuo":
            setLatitudNido(e.target.value);
            break;
         default:
            break;
      }

      //campo q enlaza a una hembra con un nido
      if (encontroNido && encontroHembra) setMuestreo_enlace("11");
      else if (encontroHembra) setMuestreo_enlace("1");
      else if (encontroNido) setMuestreo_enlace("10");
      else setMuestreo_enlace("");

      //construyendo codigo de ingreso
      setcodigoIngreso(
         acunCodigoIngreso[0] +
            " " +
            acunCodigoIngreso[1] +
            " " +
            acunCodigoIngreso[2]
      );
      //construyendo porcentaje de eclosion
      setPorcentajeEclosion(
         parseFloat((nroHuevosEclosionados / nroHuevos) * 100).toFixed(2)
      );
      //porcentaje de emergencia
      setPorcentajeEmergencia(
         parseFloat((nroNeonatosEmergente / nroHuevos) * 100 * 10).toFixed(1)
      );
   };
   // modal monitoreo guardado
   const handleShow = () => {
      setShow(true);
   };
   const handleClose = () => {
      setShow(false);
   };
   //ir al listado de monitoreos
   let irBack = () => {
      setShow(false);
      history.push(url.listado_monitoreos);
   };
   return (
      <div>
         {false ? (
            <div></div>
         ) : (
            <section className='contents-interna sig interna-componente'>
               <div className='container-xl my-3'>
                  <div className='row'>
                     <div className='col-md-12'>
                        <div class='container-xl my-3'>
                           <h3 className='title-form-monitoreo'>
                              Formulario de monitoreo de playas de reproducción
                              de tortugas marinas en Colombia
                           </h3>
                           {/* Alert mensajes */}
                           <div>
                              {msg_set_monitoreos && (
                                 <Alert
                                    variant={"success"}
                                    className='container-xl my-3'
                                 >
                                    {msg_set_monitoreos}
                                 </Alert>
                              )}
                              {isError ? (
                                 <Alert
                                    variant={"danger"}
                                    className='container-xl my-3'
                                 >
                                    {msg_set_monitoreos}
                                 </Alert>
                              ) : (
                                 <div></div>
                              )}
                           </div>
                           <form onSubmit={handleSubmit(onSubmit)}>
                              <div className='encabezado-form'>
                                 <h6 className='mb-3'>Datos del encabezado</h6>
                                 <div className='form-row'>
                                    <div className='col-sm-12 col-md-3 mb-3 '>
                                       <label for='v01'>
                                          Código de ingresos *{" "}
                                       </label>
                                       <input
                                          ref={register({
                                             required: true,
                                          })}
                                          type='text'
                                          className='form-control  deshabilitado'
                                          id='cod_ingreso'
                                          name='cod_ingreso'
                                          value={codigoIngreso}
                                          placeholder='Codigo ingreso'
                                          readonly
                                       />
                                       <span className='text-danger text-small d-block mb-2'>
                                          {errors?.cod_ingreso?.message}
                                       </span>
                                    </div>
                                    <div className='col-md-3 col-md-3 mb-3'>
                                       <label for='v05'>Responsable * </label>
                                       <input
                                          ref={register({
                                             required: true,
                                          })}
                                          type='text'
                                          className='form-control   deshabilitado'
                                          id='Responsable'
                                          name='Responsable'
                                          placeholder='Responsable'
                                          value={user.userInfo.nombre}
                                          readonly
                                       />
                                       <input
                                          id='id_responsable'
                                          name='id_responsable'
                                          type='hidden'
                                          value={user.userInfo.id}
                                          ref={register({
                                             required: true,
                                          })}
                                       ></input>
                                       <span className='text-danger text-small d-block mb-2'>
                                          {errors.id_responsable && (
                                             <span>
                                                Este campo es requerido
                                             </span>
                                          )}
                                       </span>
                                    </div>
                                    <div className='col-sm-6 col-md-6 mb-3'>
                                       <label for='v01'>Unid. costeras *</label>

                                       <select
                                          ref={register({
                                             required: true,
                                          })}
                                          className='custom-select'
                                          id='id_auc'
                                          name='id_auc'
                                          onChange={(e) => onChanges(e)}
                                       >
                                          <option selected disabled value=''>
                                             Seleccione...
                                          </option>
                                          {arrary_uac.map((item) => (
                                             <option
                                                value={item.codigo}
                                                key={item.codigo}
                                             >
                                                {item.descripcion}
                                             </option>
                                          ))}
                                       </select>
                                       <span className='text-danger text-small d-block mb-2'>
                                          {errors.id_auc && (
                                             <span>
                                                Este campo es requerido
                                             </span>
                                          )}
                                       </span>
                                    </div>
                                 </div>
                                 <div className='form-row'>
                                    <div className='col-sm-6 col-md-4 mb-3'>
                                       <label for='v02'>Institución *</label>
                                       <select
                                          ref={register({
                                             required: true,
                                          })}
                                          className='custom-select'
                                          id='id_institucion'
                                          name='id_institucion'
                                          onChange={(e) => onChanges(e)}
                                       >
                                          <option selected disabled value=''>
                                             Seleccione...
                                          </option>
                                          {arrary_instituciones.map((item) => (
                                             <option
                                                value={item.codigo_in}
                                                key={item.codigo_in}
                                             >
                                                {item.nombre_in}
                                             </option>
                                          ))}
                                       </select>
                                       <span className='text-danger text-small d-block mb-2'>
                                          {errors.id_institucion && (
                                             <span>
                                                Este campo es requerido
                                             </span>
                                          )}
                                       </span>
                                    </div>
                                    <div className='col-sm-12 col-md-4 mb-3'>
                                       <label for='v06'>Area protegida *</label>{" "}
                                       <select
                                          ref={register({
                                             required: true,
                                          })}
                                          className='custom-select'
                                          id='id_zonas_protegidas'
                                          name='id_zonas_protegidas'
                                          onChange={(e) => onChanges(e)}
                                       >
                                          <option selected disabled value=''>
                                             Seleccione...
                                          </option>
                                          {arrary_zonas_protegidas.map(
                                             (item) => (
                                                <option
                                                   value={item.codigo}
                                                   key={item.codigo}
                                                >
                                                   {item.descripcion}
                                                </option>
                                             )
                                          )}
                                       </select>
                                       <span className='text-danger text-small d-block mb-2'>
                                          {errors.id_zonas_protegidas && (
                                             <span>
                                                Este campo es requerido
                                             </span>
                                          )}
                                       </span>
                                    </div>
                                    <div className='col-sm-4 col-md-4 mb-3'>
                                       <label for='v07'>Playa *</label>
                                       <input
                                          ref={register({
                                             required: true,
                                          })}
                                          type='text'
                                          className='form-control'
                                          id='playa'
                                          name='playa'
                                          placeholder='Lugar'
                                       />
                                       <span className='text-danger text-small d-block mb-2'>
                                          {errors.playa && (
                                             <span>
                                                Este campo es requerido
                                             </span>
                                          )}
                                       </span>
                                    </div>
                                 </div>
                                 <div className='form-row'>
                                    <div className='col-sm-4 col-md-3 mb-3'>
                                       <label for='v02'>Departamento *</label>
                                       <select
                                          ref={register({
                                             required: true,
                                          })}
                                          className='custom-select'
                                          id='id_departamento'
                                          name='id_departamento'
                                          onChange={(e) => onChanges(e)}
                                       >
                                          <option selected disabled value=''>
                                             Seleccione...
                                          </option>
                                          {arrary_departamentos.map((item) => (
                                             <option
                                                value={item.toponimo_tp}
                                                key={item.toponimo_tp}
                                             >
                                                {item.toponimo_texto}
                                             </option>
                                          ))}
                                       </select>
                                       <span className='text-danger text-small d-block mb-2'>
                                          {errors.id_departamento && (
                                             <span>
                                                Este campo es requerido
                                             </span>
                                          )}
                                       </span>
                                    </div>
                                    <div className='col-sm-4 col-md-3 mb-3'>
                                       <label for='v02'>Municipio *</label>
                                       <select
                                          ref={register({
                                             required: true,
                                          })}
                                          className='custom-select'
                                          id='id_municipio'
                                          name='id_municipio'
                                          onChange={(e) => onChanges(e)}
                                       >
                                          <option selected disabled value=''>
                                             Seleccione...
                                          </option>
                                          {arrary_municipios.map((item) => (
                                             <option
                                                value={item.toponimo_tp}
                                                key={item.toponimo_tp}
                                             >
                                                {item.toponimo_texto}
                                             </option>
                                          ))}
                                       </select>
                                       <span className='text-danger text-small d-block mb-2'>
                                          {errors.id_municipio && (
                                             <span>
                                                Este campo es requerido
                                             </span>
                                          )}
                                       </span>
                                    </div>
                                    <div className='col-sm-4 col-md-3 mb-3'>
                                       <label for='v03'>Fecha inicial *</label>
                                       <input
                                          ref={register({
                                             required: true,
                                          })}
                                          onChange={(e) => onChanges(e)}
                                          type='datetime-local'
                                          className='form-control'
                                          id='fecha_inicio_monitoreo'
                                          name='fecha_inicio_monitoreo'
                                          placeholder='dd/mm/aa 00:00'
                                       />

                                       <span className='text-danger text-small d-block mb-2'>
                                          {errors.fecha_inicio_monitoreo && (
                                             <span>
                                                Este campo es requerido *
                                             </span>
                                          )}
                                       </span>
                                    </div>
                                    <div className='col-sm-4 col-md-3 mb-3'>
                                       <label for='v04'>Fecha final *</label>
                                       <input
                                          ref={register({
                                             required: true,
                                          })}
                                          onChange={(e) => onChanges(e)}
                                          type='datetime-local'
                                          className='form-control'
                                          name='fecha_fin_monitoreo'
                                          id='fecha_fin_monitoreo'
                                          placeholder='dd/mm/aa'
                                       />
                                       <span className='text-danger text-small d-block mb-2'>
                                          {errors.fecha_fin_monitoreo && (
                                             <span>
                                                Este campo es requerido
                                             </span>
                                          )}
                                       </span>
                                    </div>
                                    <div className='col-sm-6 col-md-6 mb-3'>
                                       <div className='grupo-inputs'>
                                          <span className='tit-grupo-inputs-c'>
                                             Coordenadas iniciales
                                          </span>
                                          <div className='form-row'>
                                             <div className='col-sm-6 col-md-6 mb-3'>
                                                <label for='v09'>
                                                   Latitud *
                                                </label>
                                                <input
                                                   ref={register({
                                                      required: true,
                                                   })}
                                                   type='number'
                                                   className='form-control'
                                                   id='latitud_inicial'
                                                   name='latitud_inicial'
                                                   placeholder='00.000000'
                                                />
                                                <span className='text-danger text-small d-block mb-2'>
                                                   {errors.latitud_inicial && (
                                                      <span>
                                                         Este campo es requerido
                                                      </span>
                                                   )}
                                                </span>
                                             </div>
                                             <div className='col-sm-6 col-md-6 mb-3'>
                                                <label for='v10'>
                                                   Longitud *
                                                </label>
                                                <input
                                                   ref={register({
                                                      required: true,
                                                   })}
                                                   type='number'
                                                   className='form-control'
                                                   id='longitud_inicial'
                                                   name='longitud_inicial'
                                                   placeholder='-00.000000'
                                                />

                                                <span className='text-danger text-small d-block mb-2'>
                                                   {errors.longitud_inicial && (
                                                      <span>
                                                         Este campo es requerido
                                                      </span>
                                                   )}
                                                </span>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className='col-sm-6 col-md-6 mb-3'>
                                       <div className='grupo-inputs'>
                                          <span className='tit-grupo-inputs-c'>
                                             Coordenadas finales
                                          </span>
                                          <div className='form-row'>
                                             <div className='col-sm-6 col-md-6 mb-3'>
                                                <label for='v07'>
                                                   Latitud *
                                                </label>
                                                <input
                                                   ref={register({
                                                      required: true,
                                                   })}
                                                   type='number'
                                                   className='form-control'
                                                   id='latitud_final'
                                                   name='latitud_final'
                                                   placeholder='00.000000'
                                                />
                                                <span className='text-danger text-small d-block mb-2'>
                                                   {errors.latitud_final && (
                                                      <span>
                                                         Este campo es requerido
                                                      </span>
                                                   )}
                                                </span>
                                             </div>
                                             <div className='col-sm-6 col-md-6 mb-3'>
                                                <label for='v08'>
                                                   Longitud *
                                                </label>
                                                <input
                                                   ref={register({
                                                      required: true,
                                                   })}
                                                   type='number'
                                                   className='form-control'
                                                   id='longitud_final'
                                                   name='longitud_final'
                                                   placeholder='-00.000000'
                                                />
                                                <span className='text-danger text-small d-block mb-2'>
                                                   {errors.longitud_final && (
                                                      <span>
                                                         Este campo es requerido
                                                      </span>
                                                   )}
                                                </span>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className='form-row'>
                                    <div className='col-md-4 mb-3 d-flex align-items-end'>
                                       <div>
                                          <Link
                                             className='btn btn-light f-14'
                                             to={url.detalle_muestreo}
                                          >
                                             Ir lista de monitoreos
                                          </Link>
                                          &nbsp;{" "}
                                          {/* <button
                                             type ="button"
                                                className="btn btn-light f-14"
                                                onClick={onCerrarMonitoreo}>
                                                Cerrar monitoreo
                                             </button>                                       */}
                                       </div>
                                    </div>
                                 </div>
                              </div>

                              {/* Datos generales */}
                              <div className='cuerpo-form'>
                                 <h6>Datos generales</h6>
                                 <div className='form-row'>
                                    <div className='col-sm-3 col-md-3 mb-3'>
                                       <label for='v11'>Consecutivo</label>
                                       <input
                                          ref={register({
                                             /*required: true*/
                                          })}
                                          type='text'
                                          className='form-control'
                                          id='consecutivo'
                                          name='consecutivo'
                                          placeholder='i-00000'
                                       />
                                       <span className='text-danger text-small d-block mb-2'>
                                          {errors.consecutivo && (
                                             <span>
                                                Este campo es requerido
                                             </span>
                                          )}
                                       </span>
                                    </div>
                                    <div className='col-sm-3 col-md-5 mb-3'>
                                       <label for='v12'>Especie</label>
                                       <select
                                          ref={register({
                                             /*required: true*/
                                          })}
                                          className='custom-select'
                                          id='id_especie'
                                          name='id_especie'
                                       >
                                          <option selected disabled value=''>
                                             Seleccione...
                                          </option>
                                          {arrary_especies.map((item) => (
                                             <option
                                                value={item.cod_letras}
                                                key={item.cod_letras}
                                             >
                                                {item.descripcion}
                                             </option>
                                          ))}
                                       </select>
                                       <span className='text-danger text-small d-block mb-2'>
                                          {errors.id_especie && (
                                             <span>
                                                Este campo es requerido
                                             </span>
                                          )}
                                       </span>
                                    </div>
                                    <div className='col-sm-3 col-md-2 mb-3'>
                                       <label for='v13'>
                                          Núm. de transecto
                                       </label>
                                       <input
                                          ref={register({
                                             /*required: true*/
                                          })}
                                          type='text'
                                          className='form-control'
                                          id='transecto'
                                          name='transecto'
                                          placeholder=''
                                       />
                                       <span className='text-danger text-small d-block mb-2'>
                                          {errors.transecto && (
                                             <span>
                                                Este campo es requerido
                                             </span>
                                          )}
                                       </span>
                                    </div>
                                    <div className='col-sm-3 col-md-2 mb-3'>
                                       <label for='v14'>Núm. de sector</label>
                                       <input
                                          ref={register({
                                             /*required: true*/
                                          })}
                                          type='text'
                                          className='form-control'
                                          id='sector'
                                          name='sector'
                                          placeholder=''
                                       />
                                       <span className='text-danger text-small d-block mb-2'>
                                          {errors.sector && (
                                             <span>
                                                Este campo es requerido
                                             </span>
                                          )}
                                       </span>
                                    </div>
                                 </div>
                                 <div className='form-row'>
                                    <div className='col-sm-3 col-md-2 mb-3'>
                                       <label for='v15'>Se encontro nido</label>
                                       <select
                                          className='custom-select'
                                          id='encontro_nido'
                                          name='encontro_nido'
                                          onChange={(e) => onChanges(e)}
                                       >
                                          <option selected disabled value=''>
                                             Seleccione...
                                          </option>
                                          <option
                                             key='encontro_nido_1'
                                             value='1'
                                          >
                                             Si
                                          </option>
                                          <option
                                             key='encontro_nido_2'
                                             value='0'
                                          >
                                             No
                                          </option>
                                       </select>
                                       <span className='text-danger text-small d-block mb-2'>
                                          {errors.encontro_nido && (
                                             <span>
                                                Este campo es requerido
                                             </span>
                                          )}
                                       </span>
                                    </div>
                                    <div className='col-sm-3 col-md-2 mb-3'>
                                       <label for='v16'>
                                          Interceptar hembras
                                       </label>
                                       <select
                                          ref={register({
                                             /*required: true*/
                                          })}
                                          className='custom-select'
                                          id='intercepto_hembra'
                                          name='intercepto_hembra'
                                          onChange={(e) => onChanges(e)}
                                       >
                                          <option selected disabled value=''>
                                             Seleccione...
                                          </option>
                                          <option
                                             key='intercepto_hembra_1'
                                             value='1'
                                          >
                                             Si
                                          </option>
                                          <option
                                             key='intercepto_hembra_2'
                                             value='0'
                                          >
                                             No
                                          </option>
                                       </select>
                                       <span className='text-danger text-small d-block mb-2'>
                                          {errors.intercepto_hembra && (
                                             <span>
                                                Este campo es requerido
                                             </span>
                                          )}
                                       </span>
                                    </div>
                                    <div className='col-sm-3 col-md-2 mb-3'>
                                       <label for='v15'>Latitud</label>

                                       {/* <input
                                          ref={register({
                                             required: true,
                                             // max: dfv.latitud_max,
                                             // min: dfv.latitud_min,
                                             // maxLength: 12,
                                             pattern: /00.000000/i,
                                          })}
                                          onChange={(e) => onChanges(e)}
                                          type='number'
                                          className='form-control'
                                          placeholder={"dfv.latitud_min"}
                                          id='latitud_individuo'
                                          name='latitud_individuo'
                                       />
                                       <span className='text-danger text-small d-block mb-2'>
                                          {errors.latitud_individuo && (
                                             <span>
                                                Este campo es requerido
                                             </span>
                                          )}
                                       </span> */}

                                       <input
                                          ref={register({
                                             // pattern: /00.000000/,
                                             required: true,
                                          })}
                                          // onChange={(e) => onChanges(e)}
                                          type='number'
                                          className='form-control'
                                          id='latitud_individuo'
                                          name='latitud_individuo'
                                          placeholder='00.000000'
                                       />
                                       <span className='text-danger text-small d-block mb-2'>
                                          <span>
                                             {
                                                errors?.latitud_individuo
                                                   ?.message
                                             }
                                          </span>
                                       </span>
                                    </div>
                                    <div className='col-sm-3 col-md-2 mb-3'>
                                       <label for='v16'>Longitud</label>
                                       <input
                                          ref={register({})}
                                          onChange={(e) => onChanges(e)}
                                          type='number'
                                          className='form-control'
                                          id='longitud_individuo'
                                          name='longitud_individuo'
                                          placeholder='-00.000000'
                                       />
                                       <span className='text-danger text-small d-block mb-2'>
                                          {errors.longitud_individuo && (
                                             <span>
                                                Este campo es requerido
                                             </span>
                                          )}
                                       </span>
                                    </div>
                                    <div className='col-sm-3 col-md-4 mb-3'>
                                       <label for='v14'>
                                          Fecha y hora del registro
                                       </label>
                                       <input
                                          ref={register({})}
                                          onChange={(e) => onChanges(e)}
                                          type='datetime-local'
                                          className='form-control'
                                          id='avistamiento'
                                          name='avistamiento'
                                          placeholder='00:00'
                                       />
                                       <span className='text-danger text-small d-block mb-2'>
                                          {errors.avistamiento && (
                                             <span>
                                                Este campo es requerido
                                             </span>
                                          )}
                                       </span>
                                    </div>
                                 </div>

                                 {/* individuo, nido, relocalizacion */}
                                 <div>
                                    {/* individuo */}
                                    {encontroHembra && (
                                       <ContentsIndividuo
                                          errors={errors}
                                          register={register}
                                          arrary_referentes={arrary_referentes}
                                       />
                                    )}
                                    {/* nido */}
                                    {encontroNido && (
                                       <ContentsNido
                                          errors={errors}
                                          register={register}
                                          onChanges={onChanges}
                                          arrary_referentes={arrary_referentes}
                                          porcentajeEclosion={
                                             porcentajeEclosion
                                          }
                                          porcentajeEmergencia={
                                             porcentajeEmergencia
                                          }
                                          longitudNido={longitudNido}
                                          latitudNido={latitudNido}
                                          fechaAvistamiento={fechaAvistamiento}
                                       />
                                    )}
                                    {/* nido relocalizado */}
                                    {nidoRelocalizado && (
                                       <ContentsNidoRelo
                                          errors={errors}
                                          register={register}
                                       />
                                    )}
                                 </div>
                                 {/* fotos y btn guardar */}
                                 <div className='form-row'>
                                    <div className='col-md-6'>
                                       <div className='form-group'>
                                          {/* <label for="exampleFormControlFile1">
                                                Seleccione foto del individuo
                                             </label>
                                             <input
                                                ref={register({})}
                                                type="file"
                                                className="form-control-file"
                                                name="file"
                                                id="exampleFormControlFile1"
                                             />
                                             <span className="text-danger text-small d-block mb-2">
                                                {errors.codigo_in && (
                                                   <span>Este campo es requerido</span>
                                                )}
                                             </span> */}
                                       </div>
                                    </div>
                                    <div className='col-md-6 align-btns'>
                                       <Button
                                          type='submit'
                                          className='btn btn-primary '
                                       >
                                          <i className='fa fa-plus'> Guardar</i>
                                       </Button>
                                    </div>
                                 </div>
                                 <div>
                                    {/* campos ocultos */}
                                    <ContentsOcultos
                                       errors={errors}
                                       register={register}
                                       encontroNido={encontroNido}
                                       encontroHembra={encontroHembra}
                                       nidoRelocalizado={nidoRelocalizado}
                                       muestreo_enlace={muestreo_enlace}
                                       id_estacion={id_estacion}
                                       fechaInicial={fechaInicial}
                                       fechaFinal={fechaFinal}
                                       fechaEclosion={fechaEclosion}
                                       fechaAvistamiento={fechaAvistamiento}
                                    />
                                 </div>
                              </div>
                              <div>
                                 {/* campos ocultos */}
                                 <ContentsOcultos
                                    errors={errors}
                                    register={register}
                                    encontroNido={encontroNido}
                                    encontroHembra={encontroHembra}
                                    nidoRelocalizado={nidoRelocalizado}
                                    muestreo_enlace={muestreo_enlace}
                                    id_estacion={id_estacion}
                                    fechaInicial={fechaInicial}
                                    fechaFinal={fechaFinal}
                                    fechaEclosion={fechaEclosion}
                                    fechaAvistamiento={fechaAvistamiento}
                                 />
                              </div>
                           </form>
                        </div>
                        <p>&nbsp;</p>
                     </div>
                  </div>
                  <p>&nbsp;</p>
               </div>
            </section>
         )}
         {/* modal msg monitoreo realizado  */}
         <Modal show={show}>
            <Modal.Header style={{ color: "#30345b" }}>
               <b>Monitoreo</b>
            </Modal.Header>
            <Modal.Body>
               <p>Monitoreo registrado correctamente</p>
            </Modal.Body>
            <Modal.Footer>
               <a
                  className='btn btn-primary '
                  href='javascript:void(0)'
                  onClick={irBack}
               >
                  Ver monitoreo
               </a>
            </Modal.Footer>
         </Modal>
      </div>
   );
}
let mapState = (state) => {
   return {
      arrary_referentes: state.referentes.arrary_referentes,
      arrary_instituciones: state.referentes.arrary_instituciones,
      arrary_especies: state.referentes.arrary_especies,
      arrary_uac: state.referentes.arrary_uac,
      arrary_departamentos: state.referentes.arrary_departamentos,
      arrary_municipios: state.referentes.arrary_municipios,
      arrary_zonas_protegidas: state.referentes.arrary_zonas_protegidas,
      error_referentes: state.referentes.error,

      // fetching
      fetching_get_monitoreos: state.monitoreos.fetching_get_monitoreos,
      msg_set_monitoreos: state.monitoreos.msg_set_monitoreos,
      isRegister: state.monitoreos.isRegister,
      isError: state.monitoreos.isError,

      //usuarios
      user: state.user.user,
      access: state.user.user.access,
   };
};

const mapDispatch = (dispatch) => {
   return {
      setRegistrarMonitoreoAction: (props) =>
         dispatch(setRegistrarMonitoreoAction(props)),
      getMonitoreoAction: (props) => dispatch(getMonitoreoAction(props)),

      //Referentes
      getInstitucionesAction: () => dispatch(getInstitucionesAction()),
      getEspeciesAction: () => dispatch(getEspeciesAction()),
      getUacAction: () => dispatch(getUacAction()),
      getDepartamentosAction: () => dispatch(getDepartamentosAction()),
      getMunicipiosAction: () => dispatch(getMunicipiosAction()),
      getZonasProtegidasAction: () => dispatch(getZonasProtegidasAction()),
      getReferentesAction: () => dispatch(getReferentesAction()),
   };
};
export default connect(mapState, mapDispatch)(Contents);
