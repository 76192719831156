import React, { useState } from "react";
import Nav from "components/base/navs/NavHome";
import MenuHome from "components/base/navs/NavRaiz";
import Header from "components/base/headers/Header";
import Footer from "components/base/Footer";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { Alert } from "react-bootstrap";
import Progress from "components/base/Progress";

import { forgotAction } from "redux/userDuck";

function PassRecuperar({
  msg_forgot,
  fetching_forgot,
  forgotAction,
  error_forgot,
}) {
  const { register, handleSubmit, errors } = useForm();
  const [resetForm, setResetForm] = useState({});

  const onSubmit = (data, e) => {
    e.preventDefault();
    forgotAction(data);
    setResetForm(e);
  };

  return (
    <div className="content-principal  content-internas">
      <div className="temporal-cerar"></div>
      <header className=" internas-h ">
        <Nav />
        <Header />
      </header>
      <section class="menubar">
        <MenuHome />
      </section>

      <section class="contents-interna sig interna-componente">
        {error_forgot.length > 0 && (
          <Alert variant={"danger"} className="container-xl my-3">
            {error_forgot}
          </Alert>
        )}

        <form onSubmit={handleSubmit(onSubmit)} class="container-xl my-3">
          <div class="row justify-content-center">
            <div class="col-sm-6 col-md-6 mb-3">
              <div class="row justify-content-center">
                <h6>¿Olvidaste tu contraseña?</h6>
              </div>
              <br />
              <div>
                <center>
                  <p>
                    Para restaurar tu contraseña, ingresa tu dirección de correo
                    electrónico. Es posible que tengas que consultar tu carpeta
                    de spam o desbloquear la dirección no-reply@invemar.org.co.
                  </p>
                </center>
              </div>

              {fetching_forgot ? (
                <Progress />
              ) : (
                <>
                  {msg_forgot.length > 0 ? (
                    // {/* {resetForm.target.reset()} */}
                    <Alert variant={"success"} className="container-xl my-3">
                      {msg_forgot}
                    </Alert>
                  ) : (
                    <>
                      <input
                        type="email"
                        class="form-control"
                        id="email"
                        name="email"
                        placeholder="Correo electrónico"
                        autocomplete="off"
                        ref={register({ required: true })}
                      />
                      <span className="text-danger text-small d-block mb-2">
                        {errors.email && <span>Este campo es requerido</span>}
                      </span>
                      <div class="row mb-3">
                        <div class="col-md-6 my-3">
                          <button type="submit" className="btn btn-primary">
                            Enviar
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="form-row"></div>
            </div>
          </div>
        </form>
      </section>

      <Footer />
    </div>
  );
}

let mapState = (state) => {
  return {
    msg_forgot: state.user.msg_forgot,
    fetching_forgot: state.user.fetching_forgot,
    error_forgot: state.user.error_forgot,
  };
};

// console.log("entra")
const mapDispatch = (dispatch) => {
  return {
    forgotAction: (props) => dispatch(forgotAction(props)),
  };
};
export default connect(mapState, mapDispatch)(PassRecuperar);
