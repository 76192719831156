import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import userReducer, { restoreSessionAction } from "./userDuck";
import referentesReducer, { restoreReferentesAction } from "./referentesDuck";
import monitoreoReducer, { restoreMonitoreosAction } from "./monitoreoDuck";
import naturalistaReducer, {
   restoreNaturalistaAction,
   getObservacionesAction,
} from "./naturalistaDuck";

import thunk from "redux-thunk";

let rootReducer = combineReducers({
   user: userReducer,
   referentes: referentesReducer,
   monitoreos: monitoreoReducer,
   naturalista: naturalistaReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function generateStore() {
   let store = createStore(
      rootReducer,
      composeEnhancers(applyMiddleware(thunk))
   );
   restoreSessionAction()(store.dispatch, store.getState);
   restoreReferentesAction()(store.dispatch, store.getState);
   restoreMonitoreosAction()(store.dispatch, store.getState);
   restoreNaturalistaAction()(store.dispatch, store.getState);

   return store;
}
