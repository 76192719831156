import axios from "axios";
import React, { useState } from "react";

//constantes
let initialState = {
  loggedIn: false,
  fetching: false,
  fetching_login_register: false,
  fetching_user_all: false,
  fetching_user_updating: false,
  error: "",
  msg_success: "",
  user: [],
  arrary_user_all: [],
  msg: "",
  user_update: [],
  isUpdate: false,
  message_usurio: "",

  fetching_forgot: false,
  msg_forgot: "",
  error_forgot: "",

  fetching_update_psw: false,
  msg_update_psw: "",
  error_update_psw: "",

  
};
let TIME = 5000;

let USER_UPDATE = "USER_UPDATE";

let USER_UPDATING = "USER_UPDATING";
let USER_UPDATING_SUCCESS = "USER_UPDATING_SUCCESS";
let USER_UPDATING_ERROR = "USER_UPDATING_ERROR";

let USER_ALL = "USER_ALL";
let USER_ALL_SUCCESS = "USER_ALL_SUCCESS";
let USER_ALL_ERROR = "USER_ALL_ERROR";

let LOGIN = "LOGIN";
let LOGIN_SUCCESS = "LOGIN_SUCCESS";
let LOGIN_ERROR = "LOGIN_ERROR";
let LOG_OUT = "LOG_OUT";

let LOGIN_REGISTER = "LOGIN_REGISTER";
let LOGIN_REGISTER_SUCCESS = "LOGIN_REGISTER_SUCCESS";
let LOGIN_REGISTER_ERROR = "LOGIN_REGISTER_ERROR";

let FORGOT = "FORGOT";
let FORGOT_SUCCESS = "FORGOT_SUCCESS";
let FORGOT_ERROR = "FORGOT_ERROR";

let UPDATE_PSW = "UPDATE_PSW";
let UPDATE_PSW_SUCCESS = "UPDATE_PSW_SUCCESS";
let UPDATE_PSW_ERROR = "UPDATE_PSW_ERROR";

let URL_LOGIN = process.env.REACT_APP_API_URL + "/usr/login";
let URL_REGISTER = process.env.REACT_APP_API_URL + "/usr/user-create/";
let URL_USER_ALL =  process.env.REACT_APP_API_URL + "/usr/user-all";
let URL_USER_UPDATE =  process.env.REACT_APP_API_URL + "/usr/user-update/";

let URL_FORGOT =  process.env.REACT_APP_API_URL + "/forgot/";
let URL_UPDATE_PSW =  process.env.REACT_APP_API_URL + "/set-forgot-psw/";

// let URL_FORGOT = "http://localhost:8000/tortugas/api/forgot/";
// let URL_UPDATE_PSW = "http://localhost:8000/tortugas/api/set-forgot-psw/"

//Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOG_OUT:
      return { ...initialState };

    case LOGIN_SUCCESS:
      return {
        ...state,
        fetching: false,
        user: action.payload.user,
        loggedIn: true,
      };
    case LOGIN_ERROR:
      return {
        ...state,
        fetching: action.payload.fetching,
        error: action.payload.error,
        loggedIn: false,
      };
    case LOGIN:
      return {
        ...state,
        fetching: true,
        loggedIn: false,
      };
    //   LOGIN REGISTER
    case LOGIN_REGISTER_SUCCESS:
      // console.log("nuevo", action.payload.msg);
      return {
        ...state,
        fetching_login_register: false,
        msg: action.payload.msg,
      };
    case LOGIN_REGISTER_ERROR:
      return {
        ...state,
        fetching_login_register: false,
        error: action.payload.error,
      };
    case LOGIN_REGISTER:
      return {
        ...state,
        fetching_login_register: action.payload.fetching_login_register,
      };
    //   USER_ALL
    case USER_ALL_SUCCESS:
      return {
        ...state,
        fetching_user_all: false,
        arrary_user_all: action.payload.arrary_user_all,
      };
    case USER_ALL_ERROR:
      return {
        ...state,
        fetching_user_all: false,
        error: action.payload.error,
      };
    case USER_ALL:
      return {
        ...state,
        fetching_user_all: true,
      };

    //CARGANDO USUARIO PARA UPDATE ACTIVO
    case USER_UPDATE:
      return {
        ...state,
        user_update: action.payload.user_update,
        isUpdate: action.payload.isUpdate,
      };

    //UPDATE USER
    case USER_UPDATING:
      return {
        ...state,
        fetching_user_updating: true,
      };
    case USER_UPDATING_SUCCESS:
      return {
        ...state,
        msg_success: action.payload.msg_success,
        fetching_user_updating: false,
        user_update: [],
        isUpdate: true,
      };
    case USER_UPDATING_ERROR:
      return {
        ...state,
        fetching_user_updating: false,
        error: action.payload.error,
      };
    case FORGOT:
      return {
        ...state,
        fetching_forgot: true,
      };
    case FORGOT_SUCCESS:
      return {
        ...state,
        msg_forgot: action.payload.msg_forgot,
        fetching_forgot: false,
      };
    case FORGOT_ERROR:
      return {
        ...state,
        fetching_forgot: false,
        error_forgot: action.payload.error_forgot,
      };
   
      case UPDATE_PSW:
        return {
          ...state,
          fetching_update_psw: true,
        };
      case UPDATE_PSW_SUCCESS:
        return {
          ...state,
          msg_update_psw: action.payload.msg_update_psw,
          fetching_update_psw: false,
        };
      case UPDATE_PSW_ERROR:
        return {
          ...state,
          fetching_update_psw: false,
          error_update_psw: action.payload.error_update_psw,
        };
    default:
      return state;
  }
}


// Action  para cambiar la contraseña
export let update_psw_Action = (data) => async (dispatch, getState) => {
  dispatch({
    type: UPDATE_PSW,
  });

  const request = async (data) => {
    const response = await axios({
      method: "POST",
      url: URL_UPDATE_PSW,
      data: data,
    });
    const resp = await response.data;
    return resp;
  };

  request(data)
    .then((res) => {
      dispatch({
        type: UPDATE_PSW_SUCCESS,
        payload: {
          msg_update_psw: res.message,
        },
      });
    })
    .catch((error) => {
      if (error.response) {
        dispatch({
          type: UPDATE_PSW_ERROR,
          payload: {
            error_update_psw: error.response.data.message,
          },
        });
      } else if (error.request) {
        dispatch({
          type: UPDATE_PSW_ERROR,
          payload: {
            error_update_psw: error.message,
          },
        });
      } else {
        dispatch({
          type: UPDATE_PSW_ERROR,
          payload: {
            error_update_psw: error.message,
          },
        });
      }
      setTimeout(function () {
        dispatch({
          type: UPDATE_PSW_ERROR,
          payload: {
            error_update_psw: "",
          },
        });
      }, 10000);
    });
};


// Action  para enviar el email para recuperar contraseña
export let forgotAction = (data) => async (dispatch, getState) => {
  dispatch({
    type: FORGOT,
  });

  const request = async (data) => {
    const response = await axios({
      method: "POST",
      url: URL_FORGOT,
      data: data,
    });
    const resp = await response.data;
    return resp;
  };

  //PETICION POST
  request(data)
    .then((res) => {
      dispatch({
        type: FORGOT_SUCCESS,
        payload: {
          msg_forgot: res.message,
        },
      });
    })
    .catch((error) => {
      if (error.response) {
        dispatch({
          type: FORGOT_ERROR,
          payload: {
            error_forgot: error.response.data.message,
          },
        });
      } else if (error.request) {
        dispatch({
          type: FORGOT_ERROR,
          payload: {
            error_forgot: error.message,
          },
        });
      } else {
        dispatch({
          type: FORGOT_ERROR,
          payload: {
            error_forgot: error.message,
          },
        });
      }
      setTimeout(function () {
        dispatch({
          type: FORGOT_ERROR,
          payload: {
            error_forgot: "",
          },
        });
      }, 10000);
    });
};

//aux-----------------------------------------------
function saveStorage(storage) {
  localStorage.storage = JSON.stringify(storage);
}

//Action (action creator) --------------------------

// Action 1
export let logOutAction = () => (dispatch, getState) => {
  // Aqui la funcion para cerrar sesion en django
  console.log("ELIMINAR STORAGE");
  dispatch({
    type: LOG_OUT,
  });
  localStorage.removeItem("storage");
};

// Action 2 - login
export let doLoginAction = (data) => async (dispatch, getState) => {
  // fetching true para iniciar el consumo de la api
  dispatch({
    type: LOGIN,
  });
  // console.log("Esta es la data ", data);

  const request = async (data) => {
    console.log(URL_LOGIN)
    const response = await axios.post(URL_LOGIN, data);
    const user = await response.data;
    return user;
  };

  request(data)
    .then((user) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          user: user,
        },
      });
      saveStorage(getState());
    })
    .catch((error) => {
      // console.log("MSG",error.response)
      dispatch({
        type: LOGIN_ERROR,
        payload: {
          fetching: false,
          error: error.response ? error.response.data.message : error.message, //error.response.data.msg
        },
      });
      setTimeout(function () {
        dispatch({
          type: LOGIN_ERROR,
          payload: {
            error: "",
          },
        });
      }, TIME);
    });
};

// Action 3 - register
export let registerLoginAction = (data) => async (dispatch, getState) => {
  // console.log("llega", data);
  // fetching_login_register true para iniciar el consumo de la api
  dispatch({
    type: LOGIN_REGISTER,
    payload: {
      fetching_login_register: true,
    },
  });

  const request = async (data) => {
    const response = await axios.post(URL_REGISTER, data);
    const user = await response.data;
    return user;
  };

  //PETICION POST
  request(data)
    .then((res) => {
      console.log(res.message);
      dispatch({
        type: LOGIN_REGISTER_SUCCESS,
        payload: {
          msg: res.message,
        },
      });

      setTimeout(function () {
        dispatch({
          type: LOGIN_REGISTER_SUCCESS,
          payload: {
            msg: "",
          },
        });
      }, TIME);
    })
    .catch((error) => {
      dispatch({
        type: LOGIN_REGISTER_ERROR,
        payload: {
          error: error.response.data.message,
        },
      });

      setTimeout(function () {
        dispatch({
          type: LOGIN_REGISTER_ERROR,
          payload: {
            error: "", // error.response.data.msg
          },
        });
      }, TIME);
    });
};

// Action 4 - obtener todos los usuarios
export let getUserAllAction = (token) => async (dispatch, getState) => {
  // fetching_user_all true para iniciar el consumo de la api

  dispatch({
    type: USER_ALL,
  });
  //limpiar update monitoreo
  dispatch({
    type: USER_UPDATE,
    payload: {
      isUpdate: false,
      user_update: [],
    },
  });

  const request = async (token) => {
    // console.log("ACCESAccess22:", token);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const response = await axios.get(URL_USER_ALL, config);
    const user = await response.data;
    return user;
  };

  //PETICION GET
  request(token)
    .then((res) => {
      //  console.log(res);
      dispatch({
        type: USER_ALL_SUCCESS,
        payload: {
          arrary_user_all: res,
        },
      });
    })
    .catch((error) => {
      // console.log(error);
      dispatch({
        type: USER_ALL_ERROR,
        payload: {
          error: error.message, // error.response.data.msg
        },
      });
    });
};

// Action 5 - obtener usuario para actualizar
export let updateUserAction = (user) => (dispatch, getState) => {
  if (user.length === 0) {
    dispatch({
      type: USER_UPDATE,
      payload: {
        user_update: user,
        isUpdate: false,
      },
    });
  } else {
    dispatch({
      type: USER_UPDATE,
      payload: {
        user_update: user,
        isUpdate: true,
      },
    });
  }
  saveStorage(getState());
};

// Action 6 - actualizar usuario
export let updatingUserAction = ({ token, user }) => (dispatch, getState) => {
  dispatch({
    type: USER_UPDATING,
  });

  const request = async (token, user) => {
    const response = await axios({
      method: "put",
      url: URL_USER_UPDATE,
      crossdomain: true,
      headers: { Authorization: `Bearer ${token}` },
      data: user,
    });
    const data = await response.data;
    return data;
  };

  //PETICION GET
  request(token, user)
    .then((res) => {
      dispatch({
        type: USER_UPDATING_SUCCESS,
        payload: {
          msg_success: res.message,
        },
      });
      saveStorage(getState());
    })
    .catch((error) => {
      dispatch({
        type: USER_UPDATING_ERROR,
        payload: {
          error: error.message,
        },
      });
    });
};

//aux 1
export let restoreSessionAction = () => (dispatch, getState) => {
  let storage = localStorage.getItem("storage");
  storage = JSON.parse(storage);
  if (storage) {
    //if(storage.user.loggedIn){
    console.log("login");
    // dispatch({
    //    type: LOGIN_SUCCESS,
    //    payload: storage.user,
    // });
    // dispatch({
    //    type: USER_UPDATE,
    //    payload: {
    //       user_update: storage.user.user_update,
    //       isUpdate: storage.user.isUpdate,
    //    },
    // });
    // dispatch({
    //    type: USER_ALL_ERROR,
    //    payload: {
    //       user_update: storage.user.user_update,
    //       fetching_user_all: storage.user.fetching_user_all,
    //       error: false,
    //    },
    // });
    // dispatch({
    //    type: USER_ALL_SUCCESS,
    //    payload: {
    //       arrary_user_all: storage.user.arrary_user_all,
    //    },
    // });
    //}
  }
};
