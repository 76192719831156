import React from "react";
import { Link } from "react-router-dom";
import HeaderHome from "components/base/headers/HeaderHome";
import Footer from "components/base/Footer";
import { URL_APP } from "Routes";
export default function index() {
   let url = URL_APP();
   return (
      <>
         <HeaderHome />

         <section class='btn-inicio-siam'>
            <nav class='nav-siam'>
               <ul>
                  <li class='nav-siam-item col4'>
                     <a href='#s2' target='_self'>
                        <span>
                           <div class='icon-nav-siam'>
                              <img
                                 src={process.env.PUBLIC_URL + '/static/images/home-options/comp-tourtugas/biologia-tortugas.png'}
                                 alt=''
                              />
                           </div>
                           <span>
                              <h5>Conozca Su biología y ecología</h5>
                              <p>
                                 Descubra la biología y ecología de estos
                                 fascinantes reptiles marinos.
                                 </p>
                           </span>
                        </span>
                     </a>
                  </li>

                  <li class='nav-siam-item col4'>
                     <a href='#s3' target='_self'>
                        <span>
                           <div class='icon-nav-siam'>
                              <img
                                 src={process.env.PUBLIC_URL + '/static/images/home-options/comp-tourtugas/pq-tortugas.png'}
                                 alt=''
                              />
                           </div>
                           <span>
                              <h5>Importancia de las Tortugas</h5>
                              <p>
                                 Entérate que importancia tienen las Tortugas
                                 Marinas en su ecosistema.
                                 </p>
                           </span>
                        </span>
                     </a>
                  </li>

                  <li class='nav-siam-item col4'>
                     <a href='#s4' target='_self'>
                        <span>
                           <div class='icon-nav-siam'>
                              <img
                                 src={process.env.PUBLIC_URL + '/static/images/home-options/comp-tourtugas/pe-tortugas.png'}
                                 alt=''
                              />
                           </div>
                           <span>
                              <h5>¿Por qué están amenazadas?</h5>
                              <p>
                                 Descubra porque las Tortugas Marinas se
                                 consideran amenazadas.
                                 </p>
                           </span>
                        </span>
                     </a>
                  </li>

                  <li class='nav-siam-item col4'>
                     <a href='#s5' target='_self'>
                        <span>
                           <div class='icon-nav-siam'>
                              <img
                                 src={process.env.PUBLIC_URL + '/static/images/home-options/comp-tourtugas/leg-tortugas.png'}
                                 alt=''
                              />
                           </div>
                           <span>
                              <h5>Legislación y planes</h5>
                              <p>
                                 Encuentre las leyes más recientes y planes de
                                 conservación de la Tortugas Marinas.
                                 </p>
                           </span>
                        </span>
                     </a>
                  </li>
               </ul>
            </nav>
         </section>

         <section class='content-componente-t-t content-blank'>
            <nav class='navbar navbar-expand-lg sticky-top navbar-dark bg-dark'>
               <a
                  class='navbar-brand'
                  title='invemar'
                  href='javascript:void(0)'
               >
                  <img src={process.env.PUBLIC_URL + '/static/images/logo-invemar.png'} alt='invemar' />
                     Tortugas Marinas de Colombia
                  </a>
               <button
                  class='navbar-toggler'
                  type='button'
                  data-toggle='collapse'
                  data-target='#navbarSupportedContent'
                  aria-controls='navbarSupportedContent'
                  aria-expanded='false'
                  aria-label='Toggle navigation'
               >
                  <span class='navbar-toggler-icon'></span>
               </button>

               <div
                  class='collapse navbar-collapse'
                  id='navbarSupportedContent'
               >
                  <ul class='navbar-nav ml-auto'>
                     <li class='nav-item active'>
                        <a class='nav-link' href='#s1'>
                           Generalidades <span class='sr-only'>(current)</span>
                        </a>
                     </li>
                     <li class='nav-item'>
                        <a class='nav-link' href='#s2'>
                           Conozca su biología y ecología
                           </a>
                     </li>
                     <li class='nav-item'>
                        <a class='nav-link' href='#s3'>
                           ¿Cuál es su importancia?
                           </a>
                     </li>
                     <li class='nav-item'>
                        <a class='nav-link' href='#s4'>
                           ¿Por qué están amenazadas?
                           </a>
                     </li>
                     <li class='nav-item'>
                        <Link class='nav-link' to={url.page_avistamiento}>
                           Monitoreo y Avistamiento
                           </Link>
                     </li>
                     <li class='nav-item'>
                        <a class='nav-link' href='#s5'>
                           Legislación y planes
                           </a>
                     </li>
                     <li class='nav-item'>
                        <a class='nav-link' href="https://n2t.net/ark:/81239/m9796w" target="_blank" >
                           Manual de usuario
                        </a>
                     </li>
                  </ul>
               </div>
            </nav>

            <section id='s1' class='general'>
               <div class='container-fluid'>
                  <div class='row d-flex align-items-center'>
                     <div class='col-md-6 pl-0'>
                        <img
                           src={process.env.PUBLIC_URL + "/static/images/componente-t-t/tortuga-seccion-inicial.jpg"}
                           class='img-tortuga'
                           alt=''
                        />
                     </div>
                     <div class='col-md-5'>
                        <div class='container'>
                           <h2 class='title-sec-comp'>Generalidades</h2>
                           <p class='mb-5 text-justify'>
                              Las tortugas marinas son especies longevas, de
                              crecimiento corporal lento, madurez tardía y alta
                              mortalidad en sus primeros estadios de vida.
                              </p>
                           <div class='info bg-dark my-4'>
                              <img
                                 src={process.env.PUBLIC_URL + '/static/images/home-options/comp-tourtugas/info-tortugas.png'}
                                 class='s-icon-64 mr-3'
                                 alt=''
                              />{" "}
                              <h5>
                                 Han habitado la Tierra hace cerca de 200
                                 millones de años.
                                 </h5>{" "}
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>

            <section id='s2' class='biologia'>
               <div class='container'>
                  <div class='row d-flex align-items-center'>
                     <div class='col-md-6'>
                        <h2 class='title-sec-comp'>
                           Biología y Ecología de las Tortugas
                           </h2>
                        <p class='text-justify'>
                           A diferencia de las tortugas continentales,
                           terrestres o dulceacuícolas, las tortugas marinas no
                           retraen sus extremidades y cabeza en el caparazón y
                           pasan la mayor parte de su vida en el mar.
                           Interactúan en diferentes hábitats marinos como aguas
                           costeras o estuarinas, ambientes pelágicos y
                           corrientes marinas para su alimentación, refugio,
                           reproducción y migración y solo tocan tierra para
                           anidar en playa de arena, generalmente en la costa
                           continental, además de islas o archipiélagos.
                           </p>
                     </div>
                     <div class='col-md-6'>
                        <img
                           class='img-fluid p-5'
                           src={process.env.PUBLIC_URL + '/static/images/componente-t-t/tortugas-apareo.jpg'}
                           alt=''
                        />
                     </div>
                  </div>
                  <div class='row'>
                     <div class='media ico mb-3'>
                        <img
                           src={process.env.PUBLIC_URL + '/static/images/componente-t-t/icon-info-circle.png'}
                           class='mr-3'
                           alt='...'
                        />
                        <div class='media-body text-justify'>
                           Son reptiles del orden Testudines se agrupan en dos
                              familias, <i>Cheloniidae</i>, cuyos caparazones óseos
                              están recubiertos por placas duras dispuestas en
                              forma de mosaico y la familia <i>Dermochelydae</i>,
                              cuyo único representante es{" "}
                           <i>Dermochelys coriacea</i>, que en lugar de una dura
                              concha ósea tiene una piel muy gruesa que actúa como
                              aislamiento térmico por su gran contenido de grasa.
                           </div>
                     </div>
                     <div class='media ico mb-3'>
                        <img
                           src={process.env.PUBLIC_URL + '/static/images/componente-t-t/icon-temp.png'}
                           class='mr-3'
                           alt='...'
                        />
                        <div class='media-body text-justify'>
                           La determinación del sexo de las tortugas marinas
                           depende de la temperatura de incubación, temperaturas
                           más bajas determinan machos y temperaturas altas
                           hembras. Existe un rango de temperatura “pivote” o
                           “umbral” a la cual la proporción sexual en la nidada
                           será 1:1, es decir 50% machos, 50% hembras.
                           </div>
                     </div>
                  </div>
                  <div class='row mt-5 d-flex align-items-center'>
                     <div class='col-md-6'>
                        <h5>Ciclo de vida de las tortugas marinas</h5>
                        <p class='text-justify'>
                           Tanto las hembras como los machos se desplazan desde
                           pocas decenas a miles de kilómetros, de regreso a una
                           región específica de alimentación o reproducción
                           después de recorridos de migración o exploración,
                           esta alta fidelidad se conoce como Filopatría. Se
                           cree que el mecanismo de navegación para retornar al
                           lugar es por la percepción del campo magnético de la
                           Tierra, identificando el ángulo de inclinación y la
                              intensidad del campo (Lohmann <i>et al</i>., 2007).
                           </p>
                        <p class='text-justify'>
                           Este grupo marino, longevo y de crecimiento tardío,
                           puede alcanzar la madurez sexual después de varias
                           décadas, se cree que entre 30 y 50 años dependiendo
                           de la especie. Los machos adultos se diferencian de
                           las hembras por su larga cola, que emplean para la
                           cópula.
                           </p>
                        <p class='text-justify'>
                           En general las hembras de tortugas marinas no se
                           reproducen todos los años, pero pueden anidar varias
                           veces durante la época reproductiva, y cada nido
                           puede tener aproximadamente 100 huevos. Sin embargo,
                           son tantas las amenaza que enfrentan las tortugas
                           marinas que solo una de mil crías llegará a la edad
                           adulta.
                           </p>
                     </div>
                     <div class='col-md-6'>
                        <img
                           class='img-fluid my-3'
                           src={process.env.PUBLIC_URL + '/static/images/componente-t-t/cicloTortugas.png'}
                           alt=''
                        />
                        <p>
                           <small>
                              Tomado de Amorocho y Zapata (2014). Guía de
                              conservación y observación de tortugas marinas en
                              los Parques Nacionales Naturales de Colombia.
                              </small>
                        </p>
                     </div>
                  </div>
                  <hr />
               </div>
            </section>

            <section class='especies'>
               <div class='container'>
                  <div class='row'>
                     <div class='col-md-12'>
                        <h2 class='title-sec-comp'>
                           Especies de Tortugas Marinas en Colombia
                           </h2>
                        <div id='tca' class='row my-5'>
                           <div class='col-md-12'>
                              <p class='title-especie'>
                                 <i>Eretmochelys imbricata</i> - Tortuga carey
                                 </p>
                           </div>
                           <div class='col-md-3 mb-3'>
                              <img
                                 class='img-fluid'
                                 src={process.env.PUBLIC_URL + '/static/images/componente-t-t/t-carey@2x.jpg'}
                                 alt=''
                              />
                           </div>
                           <div class='col-md-9'>
                              <p class='text-justify'>
                                 Puede alcanzar 95 cm de largo. Tiene una cabeza
                                 angosta con un pico recto y puntiagudo que les
                                 permite cortar y alcanzar su alimento entre
                                 grietas; los adultos son especialistas en el
                                 consumo de esponjas y erizos. Las escamas o
                                 escudos del caparazón están imbricados y con
                                 bordes aserrados, con vetas marrón oscuro o
                                 ámbar. Por su coloración tan característica y
                                 la resistencia y flexibilidad de los escudos de
                                 carey, han sido muy empleados en la elaboración
                                 de artesanías afectando gravemente la
                                 conservación de la especie. Los neonatos de
                                 tortuga carey son marrón oscuro muy similares a
                                    los de <i>Caretta caretta</i>, se diferencian
                                    porque esta última tiene cinco pares de escudos
                                    lateras.
                                    <Link to={url.ficha_t_carey}>
                                    Ver más sobre la especie
                                    </Link>
                              </p>
                           </div>
                        </div>
                        <div id='tve' class='row my-5'>
                           <div class='col-md-12'>
                              <p class='title-especie'>
                                 <i>Chelonia mydas</i> - Tortuga verde
                                 </p>
                           </div>
                           <div class='col-md-3 mb-3'>
                              <img
                                 class='img-fluid'
                                 src={process.env.PUBLIC_URL + '/static/images/componente-t-t/t-verde@2x.jpg'}
                                 alt=''
                              />
                           </div>
                           <div class='col-md-9'>
                              <p class='text-justify'>
                                 Esta es la segunda tortuga marina más grande.
                                 Tiene cabeza pequeña y roma con dos escamas
                                 prefrontales y cuatro postorbitales, su
                                 caparazón es oval, liso y deprimido en el
                                 margen, sin ser aserrado; el plastrón o parte
                                 ventral es blanco, característica distintiva de
                                 sus crías. En las poblaciones del Pacífico los
                                 individuos son de coloración gris oscuro a
                                 negro a veces con puntos más claros, morfotipo
                                 que se conoce localmente como tortuga negra o
                                 verde del Pacífico, que algunos consideran como
                                    una subespecie <i>Chelonia mydas agassizii</i>.
                                    Las tortugas verdes son herbívoras, consumen
                                    algas y pastos marinos, encontrándose asociadas
                                    a zonas de arrecifes de coral y praderas de
                                    fanerógamas marinas.
                                    <Link to={url.ficha_t_verde}>
                                    Ver más sobre la especie
                                    </Link>
                              </p>
                           </div>
                        </div>
                        <div id='tbo' class='row my-5'>
                           <div class='col-md-12'>
                              <p class='title-especie'>
                                 <i>Caretta caretta</i> - Tortuga caguama
                                 </p>
                           </div>
                           <div class='col-md-3 mb-3'>
                              <img
                                 class='img-fluid'
                                 src={process.env.PUBLIC_URL + '/static/images/componente-t-t/tcaguama-f1.jpg'}
                                 alt=''
                              />
                           </div>
                           <div class='col-md-9'>
                              <p class='text-justify'>
                                 La tortuga caguama, boba, gogó o cabezona puede
                                 alcanzar hasta 124 cm de largo. Su cabeza es
                                 relativamente grande respecto al cuerpo, pues
                                 tiene una musculatura fuerte para romper
                                 caparazón y conchas de sus presas, que suelen
                                 ser caracoles, bivalvos, crustáceos entre
                                 otros. Su caparazón más largo que ancho, con
                                 cinco pares de escudos laterales, de coloración
                                    rojiza o marrón y plastrón amarillo-marrón.  {" "}
                                 <Link to={url.ficha_t_caguama}>
                                    Ver más sobre la especie
                                    </Link>
                              </p>
                           </div>
                        </div>
                        <div id='tla' class='row my-5'>
                           <div class='col-md-12'>
                              <p class='title-especie'>
                                 <i>Dermochelys coriacea</i> - Tortuga laúd
                                 </p>
                           </div>
                           <div class='col-md-3 mb-3'>
                              <img
                                 class='img-fluid'
                                 src={process.env.PUBLIC_URL + '/static/images/componente-t-t/tlaud-f1.jpg'}
                                 alt=''
                              />
                           </div>
                           <div class='col-md-9'>
                              <p class='text-justify'>
                                 Es la tortuga marina más grande, puede alcanzar
                                 hasta 180 cm de largo del caparazón y pesar
                                 hasta 500 kg. Su cuerpo no tiene escamas
                                 epidérmicas, en su lugar presenta una cubierta
                                 de tejido suave y liso con textura semejante al
                                 cuero, de ahí que su nombre científico se
                                 relacione con piel y cuero. Tiene aletas
                                 delanteras muy largas y fuertes. Caparazón con
                                 siete surcos longitudinales o quillas que
                                 termina en un vértice casi cónico. Los neonatos
                                 o crías son muy parecidos al adulto, de color
                                 negro con puntos blancos en el borde del
                                 cuerpo. Su pico termina en forma de W, lo que
                                 le permite atrapar el alimento que generalmente
                                 consiste de organismos gelatinosos como medusas
                                 y ctenóforos. Pueden hacer inmersiones largas y
                                 profundas, y tolerar temperaturas muy bajas.
                                    <Link to={url.ficha_t_laud}>
                                    Ver más sobre la especie
                                    </Link>
                              </p>
                           </div>
                        </div>

                        <div id='tlo' class='row my-5'>
                           <div class='col-md-12'>
                              <p class='title-especie'>
                                 <i>Lepidochelys olivacea</i> - Tortuga golfina
                                 </p>
                           </div>
                           <div class='col-md-3 mb-3'>
                              <img
                                 class='img-fluid'
                                 src={process.env.PUBLIC_URL + '/static/images/componente-t-t/tgolfina-f1.jpg'}
                                 alt=''
                              />
                           </div>
                           <div class='col-md-9'>
                              <p class='text-justify'>
                                 También se conoce como tortuga olivácea por la
                                 tonalidad de su caparazón, verde oliva o gris
                                 oscuro, y su plastrón o parte ventral color
                                 crema. Es de las tortugas más pequeñas,
                                 alcanzando solo alrededor de 70 cm de largo. Su
                                 caparazón es casi circular, con seis o más
                                 escudos laterales; frecuentemente es
                                 asimétrica, lo que la diferencia de las demás
                                 tortugas. Se alimenta principalmente de
                                 cangrejos, almejas, mejillones y camarones.
                                    <Link to={url.ficha_t_golfina}>
                                    Ver más sobre la especie
                                    </Link>
                              </p>
                           </div>
                        </div>
                        <hr />
                     </div>
                  </div>
               </div>
            </section>

            <section id='s3' class='su-importancia'>
               <div class='container'>
                  <div class='row d-flex align-items-center'>
                     <div class='col-md-6'>
                        <img
                           class='img-fluid'
                           src={process.env.PUBLIC_URL + '/static/images/componente-t-t/cual-es-su-importancia@2x.jpg'}
                           alt=''
                        />
                     </div>
                     <div class='col-md-6'>
                        <h2 class='title-sec-comp'>
                           ¿Cuál es su importancia en el ecosistema marino?
                           </h2>
                        <p class='text-justify'>
                           Son especies carismáticas, de importancia para la
                           protección de los diferentes ecosistemas
                           marino-costeros, pues están en el tope de las redes
                           tróficas. Promueven la diversidad, ayudan a controlar
                           el crecimiento de esponjas en los arrecifes de coral,
                           disminuyendo la competencia por espacio entre
                           corales, esponjas y algas; también controlan la
                           proliferación de medusas y pequeños crustáceos.
                           Pueden mover grandes cantidades de arena y aportar
                           nutrientes a las playas durante la actividad de
                           anidación. Por sus largas migraciones y la variedad
                           de hábitats que ocupan promueven el flujo de materia
                           y energía entre el mar y la tierra.
                           </p>
                     </div>
                  </div>
                  <hr />
               </div>
            </section>

            <section id='s4' class='pq-amenazadas'>
               <div class='container'>
                  <div class='row d-flex align-items-center'>
                     <div class='col-md-6'>
                        <h2 class='title-sec-comp'>
                           ¿Por qué se consideran amenazadas?
                           </h2>
                        <p class='text-justify'>
                           Su alta mortalidad natural, depredación en los
                           diferentes estadios de vida, nidos inundados por las
                           mareas altas o exceso de lluvia afectan a las
                           tortugas marinas. A esto se suma la presión por
                           factores antrópicos como:
                           </p>
                        <ul>
                           <li>Consumo de carne y huevos.</li>
                           <li>Comercio ilegal del carey para artesanías.</li>
                           <li>
                              Muerte por consumo de plásticos (especialmente
                              bolsas).
                              </li>
                           <li>
                              Ahogamiento por interacción con artes de pesca
                              como anzuelos y redes.
                              </li>
                           <li>
                              Degradación de los hábitats naturales de refugio,
                              alimentación y anidación por construcciones,
                              contaminación de aguas negras y desechos
                              inorgánicos.
                              </li>
                        </ul>
                        <p class='text-justify'>
                           Esas amenazas han afectado a muchas poblaciones
                           locales a tal punto que se encuentran por desaparecer
                           o han sido eliminadas en el territorio nacional
                           (Minambiente, 2002).
                           </p>
                        <div class='media ico mb-3'>
                           <h3>
                              <i
                                 class='fa fa-tag mr-3 text-primary'
                                 aria-hidden='true'
                              ></i>
                           </h3>
                           <div class='media-body text-justify'>
                              Se estima que en las décadas de los 70’ y 80’
                                 cerca de 500 a 3.000 individuos de{" "}
                              <i>Lepidochelys olivacea</i> fueron sacrificados
                                 para el comercio de pieles en el Pacífico
                                 colombiano.
                              </div>
                        </div>
                        <div class='media ico mb-3'>
                           <h3>
                              <i
                                 class='fa fa-cutlery mr-3 text-primary'
                                 aria-hidden='true'
                              ></i>
                           </h3>
                           <div class='media-body text-justify'>
                              En la década de los 60’ cerca de 70.000 huevos y
                              2.000 individuos por temporada se sacrificaban
                              para el consumo en el Caribe colombiano.
                              </div>
                        </div>
                     </div>
                     <div class='col-md-6'>
                        <img
                           class='img-fluid'
                           src={process.env.PUBLIC_URL + '/static/images/componente-t-t/pq-estan-amenazadas@2x.jpg'}
                           alt=''
                        />
                     </div>
                  </div>
                  <hr />
               </div>
            </section>

            <section id='s5' class='leg-planes'>
               <div class='container'>
                  <div class='row'>
                     <div class='col-md-12'>
                        <h2 class='title-sec-comp'>
                           Legislación y Planes de Conservación en Colombia
                           </h2>
                        <p>
                           Encuentre las leyes más recientes y planes de
                           conservación de las Tortugas Marinas.
                           </p>
                        <ul class='lista-leg-planes'>
                           <li>
                              <a href='javascript:void(0)'>
                                 Resolución 167 de 1966 del Inderena.
                                 </a>{" "}
                                 Reglamenta la pesca de arrastre en la costa caribe
                                 y pacífica, y prohibiéndola en distancias menores
                                 a una milla náutica (1.852 metros) de todas las
                                 costas, islas y cayos del territorio Nacional.
                              </li>
                           <li>
                              <a href='javascript:void(0)'>
                                 Resolución No. 1032 de 1977 del Inderena.
                                 </a>{" "}
                                 Veda en todo el territorio Nacional para la
                                 captura de la tortuga carey,{" "}
                              <i>Eretmochelys imbricata.</i>
                           </li>
                           <li>
                              <a href='javascript:void(0)'>
                                 Las Resoluciones 726 de 1974 y 709 de 1981 y
                                 los Acuerdos 24 de 1983 y 54 de 1988 del
                                 Inderena
                                 </a>
                                 , prohibieron la pesca de arrastre en áreas de
                                 gran concentración de tortugas marinas como el
                                 Golfo de Morrosquillo, el Archipiélago de San
                                 Bernardo, el Golfo de Urabá y el litoral guajiro
                                 de aguas someras, ubicado entre San Juan de la
                                 Guía y Punta Espada.
                              </li>
                           <li>
                              <a href='javascript:void(0)'>
                                 Decreto 1681 de 1978.
                                 </a>{" "}
                                 establece los requisitos mínimos e indispensables
                                 para el aprovechamiento de la fauna silvestre y de
                                 sus derivados, fijando prohibiciones de carácter
                                 general, como el saquear los nidos y neonatos,
                                 acosar a las hembras anidantes, destruir o
                                 deteriorar las áreas de reproducción de las
                                 tortugas marinas.
                              </li>
                           <li>
                              <a href='javascript:void(0)'>
                                 Acuerdo 021 de 1991 del Inderena.
                                 </a>{" "}
                                 Estableció normas de protección específicas para
                                 todas las especies de tortugas marinas, así como
                                 sus playas de anidación y áreas de forrajeo.
                              </li>
                           <li>
                              <a href='javascript:void(0)'>
                                 Resolución 108 de 1992 del INPA.
                                 </a>{" "}
                                 Prohíbe el aprovechamiento de las tortugas marinas
                                 capturadas incidentalmente en las faunas de pesca
                                 de arrastre de camarón.
                              </li>
                           <li>
                              <a href='javascript:void(0)'>
                                 Resolución 157 de 1993 del INPA.
                                 </a>{" "}
                                 Exige el uso constante de un Dispositivo Excluidor
                                 de Tortugas (DETS) para la flota camaronera de
                                 arrastre del Caribe colombiano, la cual fue
                                 parcialmente modificada por la Resolución 148 de
                                 1994, que obligó a utilizar el DETS-tipo duro.
                              </li>
                           <li>
                              <a href='javascript:void(0)'>
                                 Resolución 107 de 1996 del INPA.
                                 </a>{" "}
                                 Se estableció el uso de Dispositivos Excluidores
                                 de Tortugas (DETS) en la flota camaronera de
                                 arrastre del Océano Pacífico colombiano, y la
                                 Resolución 68 de 1999 modificó parcialmente las
                                 Resoluciones 148 y 157, ampliando el uso de otros
                                 DETS, tipo duro, y reglamentó los materiales para
                                 su construcción.{" "}
                           </li>
                           <li>
                              <a href='javascript:void(0)'>
                                 Resolución 2879 de 1995 de Corpoguajira.
                                 </a>{" "}
                                 Establece veda regional al aprovechamiento de
                                 tortugas marinas.
                              </li>
                           <li>
                              <a href='javascript:void(0)'>
                                 Resolución 1644 de 1998 de Corpamag.
                                 </a>{" "}
                                 Establece veda temporal a la captura y comercio de
                                 tortugas marinas en el departamento.
                              </li>

                           <li>
                              <a href='javascript:void(0)'>
                                 Resolución 1912 de 2017 del Ministerio Ambiente
                                 y Desarrollo Sostenible.
                                 </a>{" "}
                                 Por la cual se establece el listado de las
                                 especies silvestres amenazadas de la diversidad
                                 biológica colombiana continental y marino costera
                                 que se encuentran en el territorio nacional.
                              </li>
                        </ul>
                        <p class='text-justify'>
                           A nivel Nacional desde la década de los setenta, se
                           han adelantado esfuerzo por diferentes entidades
                           gubernamentales, no gubernamentales, la academia y
                           las comunidades, generando información base de la
                           dinámica de las poblaciones de Tortugas Marinas, que
                           contribuye a establecer una serie de normativas para
                           promoveer la conservación del grupo.
                           </p>
                     </div>
                  </div>
               </div>
            </section>

            <section class='libros'>
               <div class='container'>
                  <h2 class='title-sec-comp'>Publicaciones</h2>
                  <div class='row d-flex align-items-center'>
                     <div class='col-4 col-md-2 mb-3 text-center'>
                        <a href='https://www.widecast.org/Resources/Docs/Programa_conservacion_de_tortugas_Colombia.pdf'>
                           <img
                              src={process.env.PUBLIC_URL + '/static/images/componente-t-t/libro-1@2x.jpg'}
                              width='70%'
                              alt=''
                           />
                        </a>
                     </div>
                     <div class='col-4 col-md-2 mb-3 text-center'>
                        <a href='https://www.minambiente.gov.co/images/BosquesBiodiversidadyServiciosEcosistemicos/pdf/Programas-para-la-gestion-de-fauna-y-flora/4020_100909_areas_anidacion_tortugas_marinas.pdf'>
                           <img
                              src={process.env.PUBLIC_URL + '/static/images/componente-t-t/libro-2@2x.jpg'}
                              width='70%'
                              alt=''
                           />
                        </a>
                     </div>
                     <div class='col-4 col-md-2 mb-3 text-center'>
                        <a href='http://www.humboldt.org.co/es/estado-de-los-recursos-naturales/item/905-libro-rojo-reptiles'>
                           <img
                              src={process.env.PUBLIC_URL + '/static/images/componente-t-t/libro-3@2x.jpg'}
                              width='70%'
                              alt=''
                           />
                        </a>
                     </div>
                     <div class='col-4 col-md-2 mb-3 text-center'>
                        <a href='http://cpps.dyndns.info/cpps-docs-web/planaccion/biblioteca/pordinario/023.Informe%20Tortugas%20Marinas%20Colombia.pdf'>
                           <img
                              src={process.env.PUBLIC_URL + '/static/images/componente-t-t/libro-4@2x.jpg'}
                              width='70%'
                              alt=''
                           />
                        </a>
                     </div>
                     <div class='col-4 col-md-2 mb-3 text-center'>
                        <a href='https://www.wwf.org.co/?191042/Plan-Nacional-de-Especies-Migratorias'>
                           <img
                              src={process.env.PUBLIC_URL + '/static/images/componente-t-t/libro-5@2x.jpg'}
                              width='70%'
                              alt=''
                           />
                        </a>
                     </div>
                     <div class='col-4 col-md-2 mb-3 text-center'>
                        <a href='https://www.wwf.org.co/?241150/Especies-migratorias-en-Colombia'>
                           <img
                              src={process.env.PUBLIC_URL + '/static/images/componente-t-t/libro-6@2x.jpg'}
                              width='70%'
                              alt=''
                           />
                        </a>
                     </div>
                     <div class='col-4 col-md-2 mb-3 text-center'>
                        <img
                           src={process.env.PUBLIC_URL + '/static/images/componente-t-t/libro-7@2x.jpg'}
                           width='70%'
                           alt=''
                        />
                     </div>
                     <div class='col-4 col-md-2 mb-3 text-center'>
                        <a href='https://www.minambiente.gov.co/index.php/asuntos-marinos-y-costeros-y-recursos-acuaticos/conservacion-de-la-biodivesidad-marina-costera-e-insular-y-ecosistemicos/manejo-de-los-recursos-hidrobiologicos-marinos-y-costeros/actividad-reproductiva-de-las-tortugas-marinas-en-colombia'>
                           <img
                              src={process.env.PUBLIC_URL + '/static/images/componente-t-t/libro-8@2x.jpg'}
                              width='70%'
                              alt=''
                           />
                        </a>
                     </div>
                     <div class='col-4 col-md-2 mb-3 text-center'>
                        <a href='https://www.wwf.org.co/?287710/planes-de-manejo-de-PNN-Gorgona'>
                           <img
                              src={process.env.PUBLIC_URL + '/static/images/componente-t-t/PM-PNN-GOR.jpg'}
                              width='70%'
                              alt=''
                           />
                        </a>
                     </div>
                  </div>
                  <h5>Literatura Citada</h5>
                  <cite>
                     Amorocho, D. y Zapata, L. 2014. Guía de conservación y
                     observación de tortugas marinas en los Parques Nacionales
                     Naturales de Colombia. Ministerio del Ambiente y Desarrollo
                     Sostenible y WWF-Colombia, 3ª ed. Cali. 20 p.
                     </cite>
                  <br />
                  <cite>
                     Colombia. Ministerio del Medio Ambiente – MMA. 2002.
                     Programa nacional para la conservación de las tortugas
                     marinas y continentales en Colombia. Ministerio del Medio
                     Ambiente. 64p.
                     </cite>
                  <br />
                  <cite>
                     Lohmann, K., Lohmann, C. and Putman, N. 2007. Magnetic maps
                     in animals: nature’s GPS. The Journal of Experimental
                     Biology 210: 3697-3705
                     </cite>
               </div>
            </section>
         </section>
         <Footer />
      </>
   );
}
