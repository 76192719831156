import axios from "axios";
//constantes
let initialState = {
   fetching_referentes: false,
   fetching_instituciones: false,
   fetching_especies: false,
   fetching_uac: false,
   fetching_departamentos: false,
   fetching_municipios: false,
   fetching_zonas_protegidas: false,

   error: "",
   arrary_referentes: [],
   arrary_instituciones: [],
   arrary_especies: [],
   arrary_uac: [],
   arrary_departamentos: [],
   arrary_municipios: [],
   arrary_zonas_protegidas: [],
};

let RESET = "RESET";

let GET_DEPARTAMENTOS = "GET_DEPARTAMENTOS";
let GET_DEPARTAMENTOS_SUCCESS = "GET_DEPARTAMENTOS_SUCCESS";
let GET_DEPARTAMENTOS_ERROR = "GET_DEPARTAMENTOS_ERRORS";

let GET_MUNICIPIOS = "GET_MUNICIPIOS";
let GET_MUNICIPIOS_SUCCESS = "GET_MUNICIPIOS_SUCCESS";
let GET_MUNICIPIOS_ERROR = "GET_MUNICIPIOS_ERRORS";

let GET_ZONAS_PROTEGIDAS = "GET_ZONAS_PROTEGIDAS";
let GET_ZONAS_PROTEGIDAS_SUCCESS = "GET_ZONAS_PROTEGIDAS_SUCCESS";
let GET_ZONAS_PROTEGIDAS_ERROR = "GET_ZONAS_PROTEGIDAS_ERRORS";

let GET_UAC = "GET_UAC";
let GET_UAC_SUCCESS = "GET_UAC_SUCCESS";
let GET_UAC_ERROR = "GET_UAC_ERRORS";

let GET_ESPECIES = "GET_ESPECIES";
let GET_ESPECIES_SUCCESS = "GET_ESPECIES_SUCCESS";
let GET_ESPECIES_ERROR = "GET_ESPECIES_ERRORS";

let GET_REFERENTES = "GET_REFERENTES";
let GET_REFERENTES_SUCCESS = "GET_REFERENTES_SUCCESS";
let GET_REFERENTES_ERROR = "GET_REFERENTES_ERRORS";

let GET_INSTITUCIONES = "GET_INSTITUCIONES";
let GET_INSTITUCIONES_SUCCESS = "GET_INSTITUCIONESS_SUCCESS";
let GET_INSTITUCIONES_ERROR = "GET_INSTITUCIONES_ERRORS";

let URL_AG_LOV = `${process.env.REACT_APP_API_URL}/get-referentes`;
let URL_INSTITUCIONES = `${process.env.REACT_APP_API_URL}/get-entidades`;
let URL_ESPECIES = `${process.env.REACT_APP_API_URL}/get-especies`;
let URL_UAC = `${process.env.REACT_APP_API_URL}/get-uac`;

let URL_DEPARTAMENTOS = `${process.env.REACT_APP_API_URL}/get-departamentos`;
let URL_MUNICIPIOS = `${process.env.REACT_APP_API_URL}/get-municipios`;
let URL_ZONAS_PROTEGIDAS =  `${process.env.REACT_APP_API_URL}/get-zonas-protegidas`;

//Reducer
export default function reducer(state = initialState, action) {
   switch (action.type) {
      case RESET:
         return { ...initialState };

      //Referentes
      case GET_REFERENTES:
         return {
            ...state,
            fetching_referentes: true,
         };
      case GET_REFERENTES_ERROR:
         return {
            ...state,
            fetching_referentes: false,
            error: action.payload.error,
         };
      case GET_REFERENTES_SUCCESS:
         return {
            ...state,
            arrary_referentes: action.payload.arrary_referentes,
            fetching_referentes: false,
         };
      //instituciones
      case GET_INSTITUCIONES:
         return {
            ...state,
            fetching_instituciones: true,
         };
      case GET_INSTITUCIONES_ERROR:
         return {
            ...state,
            fetching_instituciones: action.payload.fetching_instituciones,
            error: action.payload.error,
         };
      case GET_INSTITUCIONES_SUCCESS:
         return {
            ...state,
            arrary_instituciones: action.payload.arrary_instituciones,
            fetching_instituciones: action.payload.fetching_instituciones,
         };
      //Especies
      case GET_ESPECIES:
         return {
            ...state,
            fetching_especies: false,
         };
      case GET_ESPECIES_ERROR:
         return {
            ...state,
            fetching_especies: action.payload.fetching_especies,
            error: action.payload.error,
         };
      case GET_ESPECIES_SUCCESS:
         return {
            ...state,
            arrary_especies: action.payload.arrary_especies,
            fetching_especies: action.payload.fetching_especies,
         };

      //Uac
      case GET_UAC:
         return {
            ...state,
            fetching_uac: false,
         };
      case GET_UAC_ERROR:
         return {
            ...state,
            fetching_uac: action.payload.fetching_uac,
            error: action.payload.error,
         };
      case GET_UAC_SUCCESS:
         return {
            ...state,
            arrary_uac: action.payload.arrary_uac,
            fetching_uac: action.payload.fetching_uac,
         };
      //Departamentos
      case GET_DEPARTAMENTOS:
         return {
            ...state,
            fetching_departamentos: false,
         };
      case GET_DEPARTAMENTOS_ERROR:
         return {
            ...state,
            fetching_departamentos: action.payload.fetching_departamentos,
            error: action.payload.error,
         };
      case GET_DEPARTAMENTOS_SUCCESS:
         return {
            ...state,
            arrary_departamentos: action.payload.arrary_departamentos,
            fetching_departamentos: action.payload.fetching_departamentos,
         };

      //Uac
      case GET_MUNICIPIOS:
         return {
            ...state,
            fetching_municipios: false,
         };
      case GET_MUNICIPIOS_ERROR:
         return {
            ...state,
            fetching_municipios: action.payload.fetching_municipios,
            error: action.payload.error,
         };
      case GET_MUNICIPIOS_SUCCESS:
         return {
            ...state,
            arrary_municipios: action.payload.arrary_municipios,
            fetching_municipios: action.payload.fetching_municipios,
         };
      //Zonas protegidas
      case GET_ZONAS_PROTEGIDAS:
         return {
            ...state,
            fetching_zonas_protegidas: false,
         };
      case GET_ZONAS_PROTEGIDAS_ERROR:
         return {
            ...state,
            fetching_zonas_protegidas: action.payload.fetching_zonas_protegidas,
            error: action.payload.error,
         };
      case GET_ZONAS_PROTEGIDAS_SUCCESS:
         return {
            ...state,
            arrary_zonas_protegidas: action.payload.arrary_zonas_protegidas,
            fetching_zonas_protegidas: action.payload.fetching_zonas_protegidas,
         };
      default:
         return state;
   }
}

//Action (action creator) --------------------------

// Action 1 - reset referentes
export let resetReferentesAction = () => (dispatch, getState) => {
   // Aqui la funcion para cerrar sesion en django
   dispatch({
      type: RESET,
   });
};

// Action 2 - get referentes
export let getReferentesAction = () => (dispatch, getState) => {
   // fetching_referentes true para iniciar el consumo de la api
   dispatch({
      type: GET_REFERENTES,
   });
   axios
      .get(URL_AG_LOV, {
         crossdomain: true,
      })
      .then((res) => {
         dispatch({
            type: GET_REFERENTES_SUCCESS,
            payload: {
               arrary_referentes: res.data,
            },
         });
         // saveStorage(getState());
      })
      .catch((error) => {
         console.log("referentes", error);
         dispatch({
            type: GET_REFERENTES_ERROR,
            payload: {
               error: error.response,
            },
         });
      });
};

// Action 3 -get instituciones
export let getInstitucionesAction = () => (dispatch, getState) => {
   // fetching_referentes true para iniciar el consumo de la api
   dispatch({
      type: GET_INSTITUCIONES,
   });
   //consultando la api
   axios
      .get(URL_INSTITUCIONES, {
         crossdomain: true,
      })
      .then((res) => {
         dispatch({
            type: GET_INSTITUCIONES_SUCCESS,
            payload: {
               arrary_instituciones: res.data,
               fetching_instituciones: false,
            },
         });
         // saveStorage(getState());
      })
      .catch((error) => {
         console.log("instituciones", error);
         dispatch({
            type: GET_INSTITUCIONES_ERROR,
            payload: {
               error: error.response,
            },
         });
      });
};

// Action 4 - get especies
export let getEspeciesAction = () => (dispatch, getState) => {
   // fetching_especies true para iniciar el consumo de la api
   dispatch({
      type: GET_ESPECIES,
   });
   axios
      .get(URL_ESPECIES, {
         crossdomain: true,
      })
      .then((res) => {
         dispatch({
            type: GET_ESPECIES_SUCCESS,
            payload: {
               arrary_especies: res.data,
               fetching_especies: false,
            },
         });
      })
      .catch((error) => {
         dispatch({
            type: GET_ESPECIES_ERROR,
            payload: {
               error: error.response,
            },
         });
      });
};
// Action 5 - get uac
export let getUacAction = () => (dispatch, getState) => {
   // fetching_uac true para iniciar el consumo de la api
   dispatch({
      type: GET_UAC,
   });
   axios
      .get(URL_UAC, {
         crossdomain: true,
      })
      .then((res) => {
         dispatch({
            type: GET_UAC_SUCCESS,
            payload: {
               arrary_uac: res.data,
               fetching_uac: false,
            },
         });
      })
      .catch((error) => {
         dispatch({
            type: GET_UAC_ERROR,
            payload: {
               error: error.response,
            },
         });
      });
};

// Action 6 - get departamentos
export let getDepartamentosAction = () => (dispatch, getState) => {
   // fetching_departamentos true para iniciar el consumo de la api
   dispatch({
      type: GET_DEPARTAMENTOS,
   });
   axios
      .get(URL_DEPARTAMENTOS, {
         crossdomain: true,
      })
      .then((res) => {
         dispatch({
            type: GET_DEPARTAMENTOS_SUCCESS,
            payload: {
               arrary_departamentos: res.data,
               fetching_departamentos: false,
            },
         });
      })
      .catch((error) => {
         dispatch({
            type: GET_DEPARTAMENTOS_ERROR,
            payload: {
               error: error.response,
            },
         });
      });
};

// Action 7 - get municipios
export let getMunicipiosAction = () => (dispatch, getState) => {
   // fetching_municipios true para iniciar el consumo de la api
   dispatch({
      type: GET_MUNICIPIOS,
   });
   axios
      .get(URL_MUNICIPIOS, {
         crossdomain: true,
      })
      .then((res) => {
         dispatch({
            type: GET_MUNICIPIOS_SUCCESS,
            payload: {
               arrary_municipios: res.data,
               fetching_municipios: false,
            },
         });
      })
      .catch((error) => {
         dispatch({
            type: GET_MUNICIPIOS_ERROR,
            payload: {
               error: error.response,
            },
         });
      });
};

// Action 8 - get zonas_protegidas
export let getZonasProtegidasAction = () => (dispatch, getState) => {
   // fetching_zonas_protegidas true para iniciar el consumo de la api
   dispatch({
      type: GET_ZONAS_PROTEGIDAS,
   });
   axios
      .get(URL_ZONAS_PROTEGIDAS, {
         crossdomain: true,
      })
      .then((res) => {
         dispatch({
            type: GET_ZONAS_PROTEGIDAS_SUCCESS,
            payload: {
               arrary_zonas_protegidas: res.data,
               fetching_zonas_protegidas: false,
            },
         });
      })
      .catch((error) => {
         dispatch({
            type: GET_ZONAS_PROTEGIDAS_ERROR,
            payload: {
               error: error.response,
            },
         });
      });
};

// aux 1
function saveStorage(storage) {
   localStorage.storage = JSON.stringify(storage);
}

//aux 2
export let restoreReferentesAction = () => (dispatch, getState) => {
   let storage = localStorage.getItem("storage");
   storage = JSON.parse(storage);
   if (storage && storage.user) {
      // dispatch({
      //    type: GET_INSTITUCIONES_SUCCESS,
      //    payload: {
      //       arrary_instituciones: storage.referentes.arrary_instituciones,
      //    },
      // });
      // dispatch({
      //    type: GET_REFERENTES_SUCCESS,
      //    payload: {
      //       arrary_referentes: storage.referentes.arrary_referentes,
      //    },
      // });
      // dispatch({
      //    type: GET_ESPECIES_SUCCESS,
      //    payload: {
      //       arrary_especies: storage.referentes.arrary_especies,
      //    },
      // });
      // dispatch({
      //    type: GET_UAC_SUCCESS,
      //    payload: {
      //       arrary_uac: storage.referentes.arrary_uac,
      //    },
      // });
      // dispatch({
      //    type: GET_DEPARTAMENTOS_SUCCESS,
      //    payload: {
      //       arrary_departamentos: storage.referentes.arrary_departamentos,
      //    },
      // });
      // dispatch({
      //    type: GET_MUNICIPIOS_SUCCESS,
      //    payload: {
      //       arrary_municipios: storage.referentes.arrary_municipios,
      //    },
      // });
      // dispatch({
      //    type: GET_ZONAS_PROTEGIDAS_SUCCESS,
      //    payload: {
      //       arrary_zonas_protegidas: storage.referentes.arrary_zonas_protegidas,
      //    },
      // });
   }
};
