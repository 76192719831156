import React, { useState, useEffect, useRef } from "react";
import Nav from "components/base/navs/NavHome";
import Header from "components/base/headers/Header";
import MenuHome from "components/base/navs/NavRaiz";
import Footer from "components/base/Footer";
import { useLocation,Link } from "react-router-dom";
import { URL_APP, TXT_APP } from "Routes";
import { connect } from "react-redux";
import { getObservacionesAction } from "redux/naturalistaDuck";
import { getAllMonitoreoAction } from "redux/monitoreoDuck";


import { CSVLink } from "react-csv";
import Progress from "components/base/Progress";

function ListaConsulta({
  arrary_observaciones,
  getObservacionesAction,
  getAllMonitoreoAction,
  arrary_all_monitoreos,
  fetching_observaciones,
  fetching_get_all_monitoreos
}) {
  const ref = useRef();
  const ref1 = useRef();
  const location = useLocation().state;
  console.log(location);
  let url = URL_APP();
  let txt = TXT_APP();

  // const [monitoreosF, setMonitoreosF] = useState(location.monitoreos);
  // const [avistamientosF, setAvistamientosF] = useState(location.observaciones);

  const [monitoreosF, setMonitoreosF] = useState([]);
  const [avistamientosF, setAvistamientosF] = useState([]);


  useEffect(() => {

      if (location) {
        console.log("===CON DATOS===",location)
        setAvistamientosF(location.observaciones)
        setMonitoreosF(location.monitoreos)
      } else if (arrary_observaciones.length>0) {
        console.log("==SIN DATOS====",location)
        setAvistamientosF( arrary_observaciones.map((observaciones) => {
            return {
              ...observaciones,
              lat: observaciones.geojson.coordinates[1],
              long: observaciones.geojson.coordinates[0],
            };
        })) 

        setMonitoreosF( arrary_all_monitoreos.map((monitoreos) => {
          return {
            ...monitoreos,
            lat: monitoreos.geojson.coordinates[1],
            long: monitoreos.geojson.coordinates[0],
          };
      }))

      }
      else {
          getObservacionesAction();
          getAllMonitoreoAction({ token: "" });
      }

  }, [arrary_observaciones,arrary_all_monitoreos]);






  return (
    <div className="content-principal  content-internas">
      <div className="temporal-cerar"></div>
      <header className=" internas-h ">
        <Nav />
        <Header titulo={txt.tituloDetalleMonitoreos} />
      </header>
      <section class="menubar">
        <MenuHome />
      </section>
      <section className="contents-interna sig interna-componente">
        <div className="container-xl my-3">
          <div className="row">
            <div className="float-left my-3 ml-3">
              <div className="btn-group" role="group">
                <Link exac to={url.visor} className="btn btn-light f-14">
                  <i className="fa fa-map-marker"></i> Mapa
                </Link>
                <a href="javascript:void(0)" className="btn btn-light f-14 active">
                  <i className="fa fa-list"></i> Lista
                </a>
              </div>
            </div>
          </div>
          <p>&nbsp;</p>
          <h6>Avistamientos</h6>
          <div
            className="row d-flex justify-content-end mb-3 mr-3"
            style={{ flexWrap: "wrap" }}
          >
            {/* <a className="btn btn-light mr-2 mt-3" href="javascript:void(0)">
              {" "}
              <i className="fa fa-file-excel-o"></i> Exportar
            </a> */}



            <a href="javascript:void(0)">
              <CSVLink
                data={avistamientosF}
                filename={"Avistamientos.csv"}
                className="btn btn-light mr-2 mt-3"
                target="_blank"
              >
                <i className="fa fa-file-text-o"></i> Exportar CSV
              </CSVLink>
              
            </a>

            {/* <ReactToPrint
              trigger={() => (
                <button
                  onClick={() => window.print()}
                  className="btn btn-light mt-3"
                  href="javascript:void(0)"
                >
                  {" "}
                  <i className="fa fa-file-pdf-o"></i> Exportar
                </button>
              )}
              content={() => ref.current}
            /> */}
          </div>
         
         
         
          {fetching_observaciones? <Progress/>: 
          <div ref={ref}>
            {avistamientosF.map((avistamiento) => (
              <div className="row resultado-avistamiento">
                <div
                  className="col-sm-1 col-md-1 py-0"
                  style={{ margin: "auto" }}
                >
                  <img
                    // src={avistamiento.taxon.default_photo.medium_url}
                    src={
                      avistamiento.photos[0]
                        ? avistamiento.photos[0].url
                        : avistamiento.taxon.default_photo.medium_url
                    }
                    alt=""
                    width="75px"
                    height="75px"
                  />
                </div>
                <div className="col-sm-6 col-md-6 py-3">
                  <b>{avistamiento.species_guess}</b>{" "}
                  <i>{avistamiento.taxon.name}</i> <br />
                  <b>Fecha avistamiento:</b> {avistamiento.observed_on_string}{" "}
                  <br />
                  <b>Lugar:</b> {avistamiento.place_guess}
                  <br />
                  <b>Observador</b>{" "}
                  {avistamiento.user.name
                    ? avistamiento.user.name
                    : avistamiento.user.login}
                </div>
                <div className="col-sm-5 col-md-5 py-3">
                  <b>URI: </b>
                  <a href={avistamiento.uri}>{avistamiento.uri}</a>
                  <br />
                  <b>Estado de amenaza: </b>{" "}
                  {avistamiento.taxon.conservation_status
                    ? avistamiento.taxon.conservation_status.status_name
                    : "--"}
                  <br />
                  <b>Descripcion: </b>{" "}
                  {avistamiento.desciption ? avistamiento.desciption : ""}
                </div>
              </div>
            ))}
          </div>
          }
          <p>&nbsp;</p>
          <h6>Registros de Monitoreo</h6>
          <div
            className="row d-flex justify-content-end mb-3 mr-3"
            style={{ flexWrap: "wrap" }}
          >
            {/* <a className="btn btn-light mr-2 mt-3" href="javascript:void(0)">
              {" "}
              <i className="fa fa-file-excel-o"></i> Exportar
            </a> */}
           {monitoreosF.length > 0 && 
           
           (<a href="javascript:void(0)">
           <CSVLink
             data={monitoreosF}
             filename={"Monitoreos.csv"}
             className="btn btn-light mr-2 mt-3"
             target="_blank"
           >
             <i className="fa fa-file-text-o"></i> Exportar CSV
           </CSVLink>
           
         </a>)}

            {/* <ReactToPrint
              copyStyles={true}
              trigger={() => (
                <button
                  onClick={() => window.print()}
                  className="btn btn-light mt-3"
                  href="javascript:void(0)"
                >
                  {" "}
                  <i className="fa fa-file-pdf-o"></i> Exportar
                </button>
              )}
              content={() => ref1.current}
            /> */}
          </div>

          
     {fetching_get_all_monitoreos? <Progress/>: 
          <div ref={ref1}>
            {monitoreosF.map((m) => (
              <div className="row registro-monitoreo mx-1 d-flex align-items-end">
                <div className="col-6 col-sm-2 col-md-2">
                  <b>Cod. Ingreso</b>
                  <br />
                  {m.id_muestreo}
                </div>
                <div className="col-6 col-sm-2 col-md-1">
                  <b>Institución</b>
                  <br />
                  {m.entidades}
                </div>
                <div className="col-6 col-sm-2 col-md-1">
                  <b>F. inicial</b>
                  <br />
                  {m.fecha_inicial_mtortuga}
                </div>
                <div className="col-6 col-sm-2 col-md-1">
                  <b>F. final</b>
                  <br />
                  {m.fecha_final_mtortuga}
                </div>
                <div className="col-6 col-sm-2 col-md-2">
                  <b>Lugar</b>
                  <br />
                  {m.zona_protegida}
                </div>
                <div className="col-6 col-sm-2 col-md-2">
                  <b>Playa</b>
                  <br />
                  {m.nom_estacion}
                </div>
                <div className="col-6 col-sm-2 col-md-3">
                  <b>Participantes</b>
                  <br />
                  {m.participantes}
                </div>
              </div>
            ))}
            {monitoreosF.length > 0 ? ([]):(
              <center><p>No hay monitoreos registrados </p></center>
            )}
          </div>
          }

        

     
        </div>
        <p>&nbsp;</p>
      </section>
      <Footer />
    </div>
  );
}
let mapState = (state) => {
 
  return {
    //naturalista 
    fetching_observaciones: state.naturalista.fetching_observaciones,
    arrary_observaciones: state.naturalista.arrary_observaciones,
    //monitoreos 
    arrary_all_monitoreos: state.monitoreos.arrary_all_monitoreos,
    fetching_get_all_monitoreos: state.monitoreos.fetching_get_all_monitoreos
  };
};
// sacar los dispatch de (store) que tiene redux y los coloque en las props de este componente.
const mapDispatch = (dispatch) => {
  return {
    getObservacionesAction: (props) => dispatch(getObservacionesAction(props)),
    getAllMonitoreoAction: (props) => dispatch(getAllMonitoreoAction(props)),
  };
};


// connect(mapState,mapDispatch)(this funtion)
export default connect(mapState, mapDispatch)(ListaConsulta);
