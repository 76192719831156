import { Switch, Route, Redirect } from "react-router-dom";

import DetalleMuestreo from "./components/modulos/investigador/DetalleMuestreo";
import ListadoMonitoreos from "./components/modulos/investigador/ListadoMonitoreos";
import Home from "./components/modulos/pages/Home";
import Formulario from "./components/modulos/investigador/formulario/Formulario";
import AvistamientosPage from "./components/modulos/pages/AvistamientosPage";
import Register from "./components/modulos/pages/Registar";
import PanelInvestigador from "./components/modulos/investigador/PanelInvestigador";
import Visor from "./components/modulos/pages/Visor";
import ListaConsulta from "./components/modulos/pages/ListaConsulta";
import Panel_curador from "./components/modulos/curador/Panel";
import CurarUsuarios from "./components/modulos/curador/CurarUsuarios";
import CurarMonitoreos from "./components/modulos/curador/CurarMuestreos";
import CurarDetalleMuestreo from "./components/modulos/curador/CurarDetalleMuestreo";
import DetalleUsuarios from "./components/modulos/curador/DetalleUsuarios";
import CurarDetalleMuestras from "./components/modulos/curador/CurarDetalleMuestras";
import Tcaguama from "./components/modulos/pages/fichaEspecies/Tcaguama";
import Tcarey from "./components/modulos/pages/fichaEspecies/Tcarey";
import Tgolfina from "./components/modulos/pages/fichaEspecies/Tgolfina";
import Tverde from "./components/modulos/pages/fichaEspecies/Tverde";
import Tlaud from "./components/modulos/pages/fichaEspecies/Tlaud";
import Graficas from "./components/modulos/investigador/Graficas";
import AddMuestra from "./components/modulos/investigador/AddMuestra";
import EditMuestra from "./components/modulos/investigador/editar/EditMuestra";
import PassRecuperar from "./components/modulos/pages/PassRecuperar";
import Changepass from "./components/modulos/pages/Changepass";


function PrivateRoute({ path, component, ...rest }) {
   let storage = localStorage.getItem("storage");
   storage = JSON.parse(storage);

   if (storage && storage.user.loggedIn) {
      return <Route path={path} component={component} {...rest} />;
   } else {
      return <Redirect to='/login' {...rest} />;
   }
}

export default function Routes() {
   let url = URL_APP();

   return (
      <Switch>
         <Route exact path={url.forgot} component={PassRecuperar} />
         <Route exact path={url.cambiar_psw}  component={Changepass} />         
         <Route exact path={url.curar_detalle_muestras} component={CurarDetalleMuestras} />
         <Route exact path={url.curar_detalle_usuario} component={DetalleUsuarios} />
         <Route exact path={url.curar_detalle_muestreo} component={CurarDetalleMuestreo} />
         <PrivateRoute exact path={url.curar_monitoreo} component={CurarMonitoreos}/>
         <Route exact path={url.curar_usuarios} component={CurarUsuarios} />
         <Route exact path={url.panel_curador} component={Panel_curador} />
         <Route exact path={url.visor} component={Visor} />
         <Route path={url.detalle_muestreo} component={DetalleMuestreo} />
         <Route path={url.ficha_t_caguama} component={Tcaguama} />
         <Route path={url.ficha_t_carey} component={Tcarey} />
         <Route path={url.ficha_t_golfina} component={Tgolfina} />
         <Route path={url.ficha_t_verde} component={Tverde} />
         <Route path={url.ficha_t_laud} component={Tlaud} />
         <Route path={url.listado_monitoreos} component={ListadoMonitoreos} />
         <Route path='/investigador/formulario' component={Formulario} />
         <Route path={url.panel_investigador} component={PanelInvestigador} />
         <Route path={url.page_avistamiento} component={AvistamientosPage} />
         <Route path={url.registro_usauario} component={Register} />
         <Route exact path={url.page_avistamiento} component={AvistamientosPage}/>
         <Route exact path={url.home} component={Home} />
         <Route exact path={url.lista_consulta} component={ListaConsulta} />
         <Route exact path={url.graficas} component={Graficas} />
         <Route exact path={url.add_muestra} component={AddMuestra} />
         <Route exact path={url.edit_muestra} component={EditMuestra} />
      </Switch>
   );
}



// export default function Routes() {
//    let url = URL_APP();

//    return (
//       <Switch>
//          <Route
//             exact
//             path={url.curar_detalle_muestras}
//             component={CurarDetalleMuestras}
//          />
//          <Route
//             exact
//             path={url.curar_detalle_usuario}
//             component={DetalleUsuarios}
//          />
//          <Route
//             exact
//             path={url.curar_detalle_muestreo}
//             component={CurarDetalleMuestreo}
//          />
//          <PrivateRoute
//             exact
//             path={url.curar_monitoreo}
//             component={CurarMonitoreos}
//          />
//          <Route exact path={url.curar_usuarios} component={CurarUsuarios} />
//          <Route exact path={url.panel_curador} component={Panel_curador} />
//          <Route exact path={url.visor} component={Visor} />
//          <Route path={url.detalle_muestreo} component={DetalleMuestreo} />
//          <Route path={url.ficha_t_caguama} component={Tcaguama} />
//          <Route path={url.ficha_t_carey} component={Tcarey} />
//          <Route path={url.ficha_t_golfina} component={Tgolfina} />
//          <Route path={url.ficha_t_verde} component={Tverde} />
//          <Route path={url.ficha_t_laud} component={Tlaud} />
//          <Route path={url.listado_monitoreos} component={ListadoMonitoreos} />
//          <Route path='/investigador/formulario' component={Formulario} />
//          <Route path={url.panel_investigador} component={PanelInvestigador} />
//          <Route path={url.page_avistamiento} component={AvistamientosPage} />
//          <Route path={url.registro_usauario} component={Register} />
//          <Route
//             exact
//             path={url.page_avistamiento}
//             component={AvistamientosPage}
//          />
//          <Route exact path={url.home} component={Home} />
//          <Route exact path={url.lista_consulta} component={ListaConsulta} />
//          <Route exact path={url.graficas} component={Graficas} />
//          <Route exact path={url.add_muestra} component={AddMuestra} />
//          {/* <Route exact path={url.edit_muestra} component={EditMuestra} /> */}
//       </Switch>
//    );
// }


export let defaultVariables = () =>{

   return {
      latitud_min:"11.000000",
      latitud_max:"13.000000",
      longitud_min:"-70.00000",
      longitud_max:"-75.00000",
      input_num_min:"0",
      placeholder_coordinates:"00.000000",
   }
}

export let URL_APP = () => {
   return {
      home: "/",
      login: "/login",
      visor: "/visor",
      graficas: "/investigador/graficas",
      ficha_t_caguama: "/ficha-tortuga-caguama",
      ficha_t_carey: "/ficha-tortuga-carey",
      ficha_t_golfina: "/ficha-tortuga-golfina",
      ficha_t_verde: "/ficha-tortuga-verde",
      ficha_t_laud: "/ficha-tortuga-laud",
      lista_consulta: "/lista-consulta",
      registro_usauario: "/registrar",
      page_avistamiento: "/avistamiento",
      panel_investigador: "/panel-investigador",
      listado_monitoreos: "/investigador/listado-monitoreos",
      reporte_avistamiento: "/investigador/reporte-avistamiento",
      reporte_monitoreos: "/investigador/reporte-monitoreos",
      panel_curador: "/curador",
      curar_usuarios: "/curador/usuarios",
      curar_monitoreo: "/curador/monitoreo",
      curar_detalle_muestreo: "/curador/curar-detalle-muestreo",
      curar_detalle_usuario: "/curador/curar-detalle-usuarios",
      curar_detalle_muestras: "/curador/curar-detalle-muestras",
      formulario: "/investigador/formulario",
      carga_masiva: "/investigador/carga-masiva",
      detalle_muestreo: "/investigador/detalle-muestreo",
      add_muestra: "/investigador/crear-muestra",
      formulario_edit: "/investigador/editar-muestra",
      naturalista:
         "https://colombia.inaturalist.org/projects/tortugas-marinas-en-colombia",
      forgot : "/recuperar",   
      cambiar_psw:"/forgot"
   
   };
};

export let TXT_APP = () => {
   return {
      tituloAvistamiento: "Reporte de Avistamiento",
      tituloMonitoreo: "Reporte de Monitoreo",
      tituloFormulario:
         "Formulario de monitoreo de Tortugas Marinas de Colombia",
      tituloPanelInvestigador:
         "Panel de administración - Tortugas Marinas de Colombia",
      titulolistarMonitoreos: "Listado de Monitoreos",
      tituloDetalleMonitoreos: "Detalle del Monitoreos",
      tituloVisor: "Visor Tortugas Marinas de Colombia",
      // tituloVisor: "Visor Tortugas Marinas de Colombia",
      tituloPanelCurador:
         "Panel de administración - Tortugas Marinas de Colombia",
      curarUsuarios: "Curación de Usuarios",
      curarMonitoreo: "Curación de Monitoreo",
      curarDetalleMuestreo: "Curación de Detalle del Monitoreo",
      fichaCarey: "Chelonia mydas - Tortuga verde (Linnaeus, 1758)",
      detalleUsuarios: "Curación de Usuario",
      curarDetalleMuestras: "Curar Detalle Muestras",
      modalDescartarMuestra: "Descartar Muestra",
      HOME: "HOME",
      PANEL_CURADOR: "PANEL_CURADOR",
      PANEL_INVESTIGADOR: "PANEL_INVESTIGADOR",
      modulo_cutrador: "CURADOR",
      modulo_investigador: "INVESTIGADOR",
      state_editar: "STATE_EDITAR",
      state_view: "STATE_EDITAR",
      detalle_muestra: "Detalle de la muestra",
   };
};

// {logOutAction,loggedIn}
// let mapState = (state) => {
//     return{
//         loggedIn: state.user.loggedIn,
//     }
//   }

//   const mapDispatch = (dispatch) =>{
//     return{
//         logOutAction : () => dispatch(logOutAction()),
//     }
//   }
// export default connect(mapState,mapDispatch)(Routes)

// if (loggedIn) {

//    rol.roles.map((item) => {
//       if (item.id === 216) {
//          //Administrador temático
//          history.push("/curador");
//       } else if (item.id === 219) {
//          //Usuario Involucrado
//          history.push("/investigador");
//       } else {
//       }
//    });
