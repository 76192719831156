import React, { useState, useEffect } from "react";
import { URL_APP } from "../../../../Routes";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { Button } from "react-bootstrap";
import Nav from "components/base/navs/NavHome";
import Header from "components/base/headers/Header";
import Footer from "components/base/Footer";
import MenuInvestigador from "components/base/navs/NavInvestigador";
import ContentsIndividuo from "../formulario/ContentsIndividuo";
import ContentsNido from "../formulario/ContentsNido";
import Nido from "./Nido";
import Hembra from "./Hembra";
import { Alert, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import ContentsOcultos from "../formulario/ContentsOcultos";
import { TXT_APP } from "../../../../Routes";
import {
   setRegistrarMonitoreoAction,
   getMuestreosAction,
   getMuestrasAction,
   getMonitoreoAction,
   updateMuestraAction,
   setUpdatingMuestraAction,
} from "../../../../redux/monitoreoDuck";

import Encabezado from "./Encabezado";
import DatosGenerales from "./DatosGenerales";

//formatos de fechas
function convertirFecha(fecha, formato) {
   var date = new Date(fecha);
   if (formato === "f1") {
      return (
         date.getDate() +
         "/" +
         (date.getMonth() + 1) +
         "/" +
         date.getFullYear() +
         " " +
         date.getHours() +
         ":" +
         date.getMinutes()
      );
   } else if (formato === "f2") {
      if (date.getDate() < 10)
         return (
            date.getFullYear() +
            "" +
            (date.getMonth() + 1) +
            "0" +
            date.getDate()
         );
      else
         return (
            date.getFullYear() +
            "" +
            (date.getMonth() + 1) +
            "" +
            date.getDate()
         );
   } else {
      return (
         date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
      );
   }
}

function EditMuestra({
   //listas controladas
   arrary_referentes,
   arrary_instituciones,
   arrary_especies,
   arrary_uac,
   arrary_departamentos,
   arrary_municipios,
   arrary_zonas_protegidas,

   //usuario
   user,
   access,

   //muestreo o monitoreos hablo de lo mismo
   getMonitoreoAction,
   setRegistrarMonitoreoAction,
   getMuestreosAction,
   getMuestrasAction,

   //muestras
   error,
   arrary_muestreos,
   arrary_muestras,
   fetching_get_muestreos,
   fetching_get_muestras,
   fetching_set_monitoreos,

   msg_set_monitoreos,
   isRegister,
   isError,
   arrary_monitoreos,
   isAddMonitoreo,
   monitoreo_update,

   //actualizar
   updateMuestraAction,
   setUpdatingMuestraAction,
   isUpdate_muestra_user,

   //muestra para update
   muestra_update,
}) {
   let txt = TXT_APP();
   let url = URL_APP();
   const history = useHistory();
   const [acunCodigoIngreso, setAcunCodigoIngreso] = useState(["", "", ""]);
   const [codigoIngreso, setcodigoIngreso] = useState("");
   const [nroHuevos, setNroHuevos] = useState(0);
   const [nroNeonatosEmergente, setNroNeonatosEmergente] = useState(0);
   const [nroHuevosEclosionados, setNroHuevosEclosionados] = useState(0);
   const [porcentajeEclosion, setPorcentajeEclosion] = useState(0);
   const [porcentajeEmergencia, setPorcentajeEmergencia] = useState(0);

   const [latitudNido, setLatitudNido] = useState("");
   const [longitudNido, setLongitudNido] = useState("");
   const [muestreo_enlace, setMuestreo_enlace] = useState("");
   const [fechaInicial, setFechaInicial] = useState("");
   const [fechaFinal, setFechaFinal] = useState("");
   const [fechaAvistamiento, setFechaAvistamiento] = useState("");
   const [fechaEclosion, setFechaEclosion] = useState("");
   const [id_estacion, setId_estacion] = useState(0);
   const [disabledTxt, setDisabledTxt] = useState(false);
   const [show, setShow] = useState(false);
   const [showUpdate, setShowUpdate] = useState(false);

   //==========================================================================================================================

   //Se llena el encabezado del formulario
   const { register, handleSubmit, errors, onChange, setValue } = useForm({
      defaultValues: {
         //  ...monitoreo_update,
         ...muestra_update,
         cod_ingreso: monitoreo_update.prefijo_estacion,
         Responsable: user.userInfo.nombre,
         id_auc: monitoreo_update.uac_des,
         id_institucion: monitoreo_update.entidades,
         id_zonas_protegidas: monitoreo_update.zona_protegida,
         playa: monitoreo_update.nom_estacion,
         id_departamento: monitoreo_update.departamento,
         id_municipio: monitoreo_update.municipio,
         fecha_inicio_monitoreo: monitoreo_update.fecha_inicial_mtortuga,
         fecha_fin_monitoreo: monitoreo_update.fecha_final_mtortuga,
         latitud_inicial: monitoreo_update.latitudinicio_loc,
         latitud_final: monitoreo_update.latitudfin_loc,
         longitud_inicial: monitoreo_update.longitudinicio_loc,
         longitud_final: monitoreo_update.longitudfin_loc,
         avistamiento: monitoreo_update.hora_avistamiento,

         //datos generales
         consecutivo: muestra_update.id_consecutivo,
         encontro_nido: muestra_update.id_muestreo_enlace === 11 ? "Si" : "No",
         intercepto_hembra:
            muestra_update.id_muestreo_enlace === 11 ? "Si" : "No",
         numero_huevos_puestos: muestra_update.nro_huevos_puestos,
         nro_huevos_eclosionado: muestra_update.nro_huevos_eclosion,
         nro_neonatos_libr: muestra_update.nro_neonatos_lbr,
         sector: muestra_update.sector,
      },
   });

   const [encontroNido, setEncontroNido] = useState(false);
   const [encontroHembra, setEncontroHembra] = useState(false);
   const [nidoRelocalizado, setNidoRelocalizado] = useState(false);

   let auxencontroHembra = false;
   let auxnidoRelocalizado = false;
   let auxencontroNido = false;

   if (monitoreo_update.id_muestreo_enlace === 1) {
      // console.log("ENLACE 1")
      auxencontroHembra = true;
      auxnidoRelocalizado = false;
      auxencontroNido = false;
   }
   if (monitoreo_update.id_muestreo_enlace === 10) {
      // console.log("ENLACE 10")
      auxencontroNido = true;
      auxencontroHembra = false;
      auxencontroHembra = false;
   }
   if (monitoreo_update.id_muestreo_enlace === 11) {
      auxencontroHembra = true;
      auxencontroNido = true;
      if (muestra_update.manejo !== "IS") {
         auxnidoRelocalizado = true;
      }
   }

   useEffect(() => {
      if (isUpdate_muestra_user) {
         // mostrar el modal de notificacion
         handleShowUpdate();
      }
   }, [isUpdate_muestra_user]);

   // Editar muestra
   const OnEdicion = (id) => {
      history.push(url.curar_detalle_muestras);
   };

   const onSubmit = (data, e) => {
      /*Antes de gusrdar se verifica el estado del monitoreo con los siguientes criterios:
      1. Campos Requerido en Datos generales
         1.1. Consecutivo
         1.2. Especie
         1.3. Núm. de transecto
         1.4. Núm. de sector
         1.5. Latitud
         1.6. Longitud
         1.7. Fecha y hora del registro */

      // encontroNido        nidoRelocalizado;
      let respuesta_error = false;
      let observacion_error = "";

      if (!Boolean(data.consecutivo)) {
         respuesta_error = true;
         observacion_error = observacion_error + "Consecutivo,";
      }
      if (!Boolean(data.id_especie)) {
         respuesta_error = true;
         observacion_error = observacion_error + "Especie,";
      }
      if (!Boolean(data.transecto)) {
         respuesta_error = true;
         observacion_error = observacion_error + "Transecto,";
      }
      if (!Boolean(data.sector)) {
         respuesta_error = true;
         observacion_error = observacion_error + "Sector,";
      }
      if (!Boolean(data.latitud_individuo)) {
         respuesta_error = true;
         observacion_error = observacion_error + "Latitud individuo,";
      }
      if (!Boolean(data.longitud_individuo)) {
         respuesta_error = true;
         observacion_error = observacion_error + "Longitud individuo,";
      }

      // if(!Boolean(data.hora)){
      //    respuesta_error = true
      //    observacion_error = observacion_error + "Hora avistamiento,"
      // }

      /* 2. Si seleciono Interceptar hembras por lo menos debe de tener estos campos diligenciado:
      2.1. Cod. marca por lo menos que tenga dos no importando si es encontrada o implantada
      2.2. posicion de la marca seleccionada(que coorresponda a la los codigo que lleno anterioermente)
      2.3. Lcc (largo curvo del caparazon)
      2.4. Acc (Ancho curvo del caparazon)*/
      if (encontroHembra) {
         // 2.1.
         let isCodMarca = 0;
         let obsCodMarca = "";
         if (!Boolean(data.cdg_marca_encont_01)) {
            obsCodMarca = obsCodMarca + "Codigo Marca encontrada 1,";
         } else isCodMarca++;
         if (!Boolean(data.cdg_marca_encont_02)) {
            obsCodMarca = obsCodMarca + "Codigo Marca encontrada 2,";
         } else isCodMarca++;
         if (!Boolean(data.cdg_marca_impl_01)) {
            obsCodMarca = obsCodMarca + "Codigo Marca implantada 1,";
         } else isCodMarca++;
         if (!Boolean(data.cdg_marca_impl_02)) {
            obsCodMarca = obsCodMarca + "Codigo Marca implantada 2,";
         } else isCodMarca++;

         if (isCodMarca < 2) {
            observacion_error = observacion_error + obsCodMarca;
            respuesta_error = true;
         }

         //2.2.
         let isCodPosicionMarca = 0;
         let obsCodPosicionMarca = "";
         if (!Boolean(data.id_p_enc_1)) {
            obsCodPosicionMarca =
               obsCodPosicionMarca + "Posicion Marca encontrada 1,";
         } else isCodPosicionMarca++;
         if (!Boolean(data.id_p_enc_2)) {
            obsCodPosicionMarca =
               obsCodPosicionMarca + "Posicion Marca encontrada 2,";
         } else isCodPosicionMarca++;
         if (!Boolean(data.id_p_imp_1)) {
            obsCodPosicionMarca =
               obsCodPosicionMarca + "Posicion Marca implantada 1,";
         } else isCodPosicionMarca++;
         if (!Boolean(data.id_p_imp_2)) {
            obsCodPosicionMarca =
               obsCodPosicionMarca + "Posicion Marca implantada 2,";
         } else isCodPosicionMarca++;

         if (isCodPosicionMarca < 2) {
            observacion_error = observacion_error + obsCodPosicionMarca;
            respuesta_error = true;
         }

         //2.3. Lcc (largo curvo del caparazon)
         if (!Boolean(data.largo_caparazon)) {
            respuesta_error = true;
            observacion_error = observacion_error + "Largo caparazon,";
         }

         //2.4. Acc (Ancho curvo del caparazon)*/
         if (!Boolean(data.ancho_caparazon)) {
            respuesta_error = true;
            observacion_error = observacion_error + "Ancho caparazon,";
         }
      } //encontroHembra

      /* 3. Si se encontro nido por lo menos debe de tener estos campos diligenciado:
      3.1. Posición del nido
      3.2. Manejo del nido
      3.3. Núm. Huevos
      3.4. Fecha de eclosion
      3.5. Núm. huevos eclosionado
      3.6. Dias de incubación
      3.7. Núm. Neonatos Emergente
      3.8. Núm. Neonatos liberados*/
      if (encontroNido) {
         if (!Boolean(data.posicion_nido)) {
            respuesta_error = true;
            observacion_error = observacion_error + "Posicion nido,";
         }
         if (!Boolean(data.manejo)) {
            respuesta_error = true;
            observacion_error = observacion_error + "Manejo,";
         }
         if (!Boolean(data.numero_huevos_puestos)) {
            respuesta_error = true;
            observacion_error = observacion_error + "Numero huevos,";
         }
         if (!Boolean(data.fecha_eclosion)) {
            respuesta_error = true;
            observacion_error = observacion_error + "Fecha eclosion,";
         }
         if (!Boolean(data.nro_huevos_eclosionado)) {
            respuesta_error = true;
            observacion_error = observacion_error + "Nro. huevos eclosionado,";
         }
         if (!Boolean(data.dias_incubacion)) {
            respuesta_error = true;
            observacion_error = observacion_error + "Dias incubacion,";
         }
         if (!Boolean(data.neonatos_emergentes)) {
            respuesta_error = true;
            observacion_error = observacion_error + "Neonatos emergentes,";
         }
         if (!Boolean(data.nro_neonatos_libr)) {
            respuesta_error = true;
            observacion_error = observacion_error + "Nro. neonatos libr,";
         }
      }

      /* 4. Si selecciono el Manejo del nido es diferente (Seguimineto in situ)a por lo menos debe de tener estos campos diligenciado:
      4.1. Latitud de relocalizacion
      4.2. Longitud de relocalizacion
      4.3. Prof. original en cm
      4.4. Prof. relocalización en cm
      4.5. Hora del siembra */
      if (nidoRelocalizado) {
         if (!Boolean(data.latitud_relo)) {
            respuesta_error = true;
            observacion_error = observacion_error + "Latitud relo.,";
         }
         if (!Boolean(data.longitud_relo)) {
            respuesta_error = true;
            observacion_error = observacion_error + "Longitud relo.,";
         }
         if (!Boolean(data.profundidad_original)) {
            respuesta_error = true;
            observacion_error = observacion_error + "Profundidad original,";
         }
         if (!Boolean(data.profundidad_rlc)) {
            respuesta_error = true;
            observacion_error = observacion_error + "Profundidad rlc.,";
         }
         if (!Boolean(data.hora_siembra)) {
            respuesta_error = true;
            observacion_error = observacion_error + "Hora siembra,";
         }
      }
      console.log(monitoreo_update);
      // const [camposRequire, setCamposRequire] = useState(false);
      // setCamposRequire(respuesta_error)
      setUpdatingMuestraAction({
         id_muestra: monitoreo_update.id_muestratex,
         token: access,
         data: data,
         nota: respuesta_error ? "PEN," + observacion_error : "FIN",
         id_muestreo: monitoreo_update.id_muestreotx,
      });
   };

   const onCerrarMonitoreo = () => {
      //validar monitoreo
      history.push(url.listado_monitoreos);
   };

   let onChanges = (e) => {
      switch (e.target.name) {
         case "id_auc":
            acunCodigoIngreso[0] = e.target.value;
            break;
         case "id_institucion":
            acunCodigoIngreso[1] = e.target.value;
            break;
         case "fecha_inicio_monitoreo":
            setFechaInicial(convertirFecha(e.target.value, "f1"));
            acunCodigoIngreso[2] = convertirFecha(e.target.value, "f2");
            break;
         case "fecha_fin_monitoreo":
            setFechaFinal(convertirFecha(e.target.value, "f1"));
            break;
         case "fechaEclosion":
            setFechaEclosion(convertirFecha(e.target.value, "f1"));
            break;
         case "avistamiento":
            setFechaAvistamiento(convertirFecha(e.target.value, "f1"));
            break;
         case "encontro_nido":
            if (e.target.value === "1") setEncontroNido(true);
            else setEncontroNido(false);
            break;
         case "intercepto_hembra":
            if (e.target.value === "1") setEncontroHembra(true);
            else setEncontroHembra(false);
            setNidoRelocalizado(false);
            break;
         case "manejo":
            if (e.target.value !== "IS") setNidoRelocalizado(true);
            else setNidoRelocalizado(false);
            break;
         case "neonatos_emergentes":
            setNroNeonatosEmergente(e.target.value);
            break;
         case "numero_huevos_puestos":
            setNroHuevos(e.target.value);
            break;
         case "nro_huevos_eclosionado":
            setNroHuevosEclosionados(e.target.value);
            break;
         case "longitud_individuo":
            setLongitudNido(e.target.value);
            break;
         case "latitud_individuo":
            setLatitudNido(e.target.value);
            break;
         default:
            break;
      }

      //campo q enlaza a una hembra con un nido
      if (encontroNido && encontroHembra) setMuestreo_enlace("11");
      else if (encontroHembra) setMuestreo_enlace("1");
      else if (encontroNido) setMuestreo_enlace("10");
      else setMuestreo_enlace("");

      //construyendo codigo de ingreso
      setcodigoIngreso(
         acunCodigoIngreso[0] +
            " " +
            acunCodigoIngreso[1] +
            " " +
            acunCodigoIngreso[2]
      );
      //construyendo porcentaje de eclosion
      setPorcentajeEclosion(
         parseFloat((nroHuevosEclosionados / nroHuevos) * 100)
      );
      //porcentaje de eclosion
      setPorcentajeEmergencia(
         parseFloat((nroNeonatosEmergente / nroHuevos) * 100)
      );
   };
   // modal monitoreo guardado
   const handleShow = () => {
      setShow(true);
   };

   const handleClose = () => {
      setShow(false);
   };

   // modal monitoreo guardado
   const handleShowUpdate = () => {
      setShowUpdate(true);
   };
   const handleCloseUpdate = () => {
      setShowUpdate(false);
   };

   //ir al listado de monitoreos
   let irBack = () => {
      setShow(false);
      history.push(url.detalle_muestreo);
   };
   return (
      <div>
         {false ? (
            <div></div>
         ) : (
            <div>
               <div className='content-principal  content-internas'>
                  <div className='temporal-cerar'></div>
                  <header className=' internas-h '>
                     <Nav />
                     <Header titulo={txt.tituloFormulario} />
                  </header>
                  <section class='menubar'>
                     <MenuInvestigador />
                  </section>
                  <section className='contents-interna sig interna-componente'>
                     <div className='container-xl my-3'>
                        <div className='row'>
                           <div className='col-md-12'>
                              <div class='container-xl my-3'>
                                 <h3 className='title-form-monitoreo'>
                                    Formulario de monitoreo de playas de
                                    reproducción de tortugas marinas en Colombia
                                 </h3>
                                 {/* Alert mensajes */}
                                 <div>
                                    {msg_set_monitoreos && (
                                       <Alert
                                          variant={"success"}
                                          className='container-xl my-3'
                                       >
                                          {msg_set_monitoreos}
                                       </Alert>
                                    )}
                                    {isError ? (
                                       <Alert
                                          variant={"danger"}
                                          className='container-xl my-3'
                                       >
                                          {msg_set_monitoreos}
                                       </Alert>
                                    ) : (
                                       <div></div>
                                    )}
                                 </div>
                                 <form onSubmit={handleSubmit(onSubmit)}>
                                    <Encabezado register={register} />
                                    {/* Datos generales */}
                                    <div className='cuerpo-form'>
                                       <DatosGenerales
                                          register={register}
                                          errors={errors}
                                          onChanges={onChanges}
                                       />
                                       {/* individuo, nido, relocalizacion */}
                                       <div>
                                          {monitoreo_update.id_muestreo_enlace ===
                                             1 && (
                                             <>
                                                <Hembra
                                                   monitoreo_update={
                                                      muestra_update
                                                   }
                                                   errors={errors}
                                                   register={register}
                                                   arrary_referentes={
                                                      arrary_referentes
                                                   }
                                                />
                                             </>
                                          )}
                                          {monitoreo_update.id_muestreo_enlace ===
                                             10 && (
                                             <>
                                                <Nido
                                                   monitoreo_update={
                                                      muestra_update
                                                   }
                                                   errors={errors}
                                                   register={register}
                                                   onChanges={onChanges}
                                                   arrary_referentes={
                                                      arrary_referentes
                                                   }
                                                   porcentajeEclosion={
                                                      porcentajeEclosion
                                                   }
                                                   porcentajeEmergencia={
                                                      porcentajeEmergencia
                                                   }
                                                   longitudNido={longitudNido}
                                                   latitudNido={latitudNido}
                                                   fechaAvistamiento={
                                                      fechaAvistamiento
                                                   }
                                                />
                                             </>
                                          )}
                                          {monitoreo_update.id_muestreo_enlace ===
                                             11 && (
                                             <>
                                                <Hembra
                                                   monitoreo_update={
                                                      muestra_update
                                                   }
                                                   errors={errors}
                                                   register={register}
                                                   arrary_referentes={
                                                      arrary_referentes
                                                   }
                                                />
                                                <Nido
                                                   monitoreo_update={
                                                      muestra_update
                                                   }
                                                   errors={errors}
                                                   register={register}
                                                   onChanges={onChanges}
                                                   arrary_referentes={
                                                      arrary_referentes
                                                   }
                                                   porcentajeEclosion={
                                                      porcentajeEclosion
                                                   }
                                                   porcentajeEmergencia={
                                                      porcentajeEmergencia
                                                   }
                                                   longitudNido={longitudNido}
                                                   latitudNido={latitudNido}
                                                   fechaAvistamiento={
                                                      fechaAvistamiento
                                                   }
                                                />
                                             </>
                                          )}
                                       </div>
                                       {/* fotos y btn guardar */}
                                       <div className='form-row'>
                                          <div className='col-md-6'>
                                             <div className='form-group' hidden="true">
                                                <label for='exampleFormControlFile1'>
                                                   Seleccione foto del individuo
                                                </label>
                                                <input
                                                   ref={register({})}
                                                   type='file'
                                                   className='form-control-file'
                                                   name='file'
                                                   id='exampleFormControlFile1'
                                                />
                                                <span className='text-danger text-small d-block mb-2'>
                                                   {errors.codigo_in && (
                                                      <span>
                                                         Este campo es requerido
                                                      </span>
                                                   )}
                                                </span>
                                             </div>
                                          </div>
                                          <div className='col-md-6 align-btns'>
                                             <Button
                                                type='submit'
                                                className='btn btn-primary '
                                             >
                                                <i className='fa fa-plus'>
                                                   {" "}
                                                   Guardar
                                                </i>
                                             </Button>
                                          </div>
                                       </div>
                                    </div>
                                    <ContentsOcultos
                                       encontroNido={auxencontroNido}
                                       encontroHembra={auxencontroHembra}
                                       nidoRelocalizado={auxnidoRelocalizado}
                                       register={register}
                                       muestreo_enlace={muestreo_enlace}
                                       id_estacion={id_estacion}
                                       fechaInicial={fechaInicial}
                                       fechaFinal={fechaFinal}
                                       fechaEclosion={fechaEclosion}
                                       fechaAvistamiento={fechaAvistamiento}
                                    />
                                 </form>
                              </div>
                              <p>&nbsp;</p>
                           </div>
                        </div>
                        <p>&nbsp;</p>
                     </div>
                  </section>
                  <Footer />
               </div>
            </div>
         )}

         {/* modal msg monitoreo realizado  */}
         <Modal show={show}>
            <Modal.Header style={{ color: "#30345b" }}>
               <b>Monitoreo</b>
            </Modal.Header>
            <Modal.Body>
               <p>
                  Monitoreo registrado correctamente, puede seguir agregando
                  eventos de lo contrario finalizar monitoreo
               </p>
            </Modal.Body>
            <Modal.Footer>
               <a className='btn btn-success' href='\\' onClick={handleClose}>
                  Seguir agregando eventos
               </a>
               <a className='btn btn-primary ' href='\\' onClick={irBack}>
                  Finalizar monitoreo
               </a>
            </Modal.Footer>
         </Modal>

         {/* modal msg monitoreo realizado  */}
         <Modal show={showUpdate}>
            <Modal.Header style={{ color: "#30345b" }}>
               <b>MUestra</b>
            </Modal.Header>
            <Modal.Body>
               <p>Muestra Editada correctamente</p>
            </Modal.Body>
            <Modal.Footer>
               <a className='btn btn-primary ' href='\\' onClick={irBack}>
                  Aceptar
               </a>
            </Modal.Footer>
         </Modal>
      </div>
   );
}
let mapState = (state) => {
   return {
      arrary_referentes: state.referentes.arrary_referentes,
      arrary_instituciones: state.referentes.arrary_instituciones,
      arrary_especies: state.referentes.arrary_especies,
      arrary_uac: state.referentes.arrary_uac,
      arrary_departamentos: state.referentes.arrary_departamentos,
      arrary_municipios: state.referentes.arrary_municipios,
      arrary_zonas_protegidas: state.referentes.arrary_zonas_protegidas,
      error_referentes: state.referentes.error,

      // get muestras - muestreos - monitoreos
      arrary_monitoreos: state.monitoreos.arrary_monitoreos,
      arrary_muestreos: state.monitoreos.arrary_muestreos,
      arrary_muestras: state.monitoreos.arrary_muestras,

      // fetching
      fetching_get_muestreos: state.monitoreos.fetching_get_muestreos,
      fetching_get_muestras: state.monitoreos.fetching_get_muestras,
      fetching_set_monitoreos: state.monitoreos.fetching_set_monitoreos,
      fetching_get_monitoreos: state.monitoreos.fetching_get_monitoreos,
      msg_set_monitoreos: state.monitoreos.msg_set_monitoreos,
      isAddMonitoreo: state.monitoreos.isAddMonitoreo,
      isRegister: state.monitoreos.isRegister,
      isError: state.monitoreos.isError,

      //usuarios
      user: state.user.user,
      access: state.user.user.access,

      //update
      monitoreo_update: state.monitoreos.monitoreo_update,
      muestra_update: state.monitoreos.muestra_update,

      isUpdate_muestra_user: state.monitoreos.isUpdate_muestra_user,
      isUpdate: state.monitoreos.isUpdate,
   };
};

const mapDispatch = (dispatch) => {
   return {
      setRegistrarMonitoreoAction: (props) =>
         dispatch(setRegistrarMonitoreoAction(props)),
      getMuestreosAction: () => dispatch(getMuestreosAction()),
      getMuestrasAction: () => dispatch(getMuestrasAction()),
      getMonitoreoAction: (props) => dispatch(getMonitoreoAction(props)),
      updateMuestraAction: (props) => dispatch(updateMuestraAction(props)),
      setUpdatingMuestraAction: (props) =>
         dispatch(setUpdatingMuestraAction(props)),
   };
};
export default connect(mapState, mapDispatch)(EditMuestra);
