import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { connect } from "react-redux";
import Nav from "components/base/navs/NavHome";
import Header from "components/base/headers/Header";
import Footer from "components/base/Footer";
import MenuRaiz from "components/base/navs/NavRaiz";
import { getObservacionesAction } from "redux/naturalistaDuck";
import {
   getAllMonitoreoAction,
   getMonitoreoUserAction,
} from "redux/monitoreoDuck";

//formatos de fechas
function convertirFecha(fecha, formato) {
   var date = new Date(fecha);
   if (formato === "f1") {
      return (
         date.getDate() +
         "/" +
         (date.getMonth() + 1) +
         "/" +
         date.getFullYear() +
         " " +
         date.getHours() +
         ":" +
         date.getMinutes()
      );
   } else if (formato === "f2") {
      if (date.getDate() < 10)
         return (
            date.getFullYear() +
            "" +
            (date.getMonth() + 1) +
            "0" +
            date.getDate()
         );
      else
         return (
            date.getFullYear() +
            "" +
            (date.getMonth() + 1) +
            "" +
            date.getDate()
         );
   } else {
      return (
         date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
      );
   }
}

function APP({
   user,
   arrary_monitoreos_user,
   getMonitoreoUserAction,
   fetching_get_all_monitoreos,
   access,
   getAllMonitoreoAction,
   arrary_all_monitoreos,
   fetching_observaciones,
   error_user,
   arrary_observaciones,
   getObservacionesAction,
}) {
   const [countespecieTcaguama, setCountEspecieTcaguama] = useState(0);
   const [countespecieTtcarey, setCountEspecieTtcarey] = useState(0);
   const [countespecieTgolfina, setCountEspecieTgolfina] = useState(0);
   const [countespecieTlaud, setCountEspecieTlaud] = useState(0);
   const [countespecieTverde, setCountEspecieTverde] = useState(0);

   const [meses, setMeses] = useState([]);

   // {
   //   name: "Eretmochelys imbricata",
   //   data: [0, 2, 3, 2, 1, 5, 3, 9, 6, 4, 9, 8],
   // },
   let Tlaud = 0;
   let Tgolfina = 0;
   let Ttcarey = 0;
   let Tverde = 0;
   let Tcaguama = 0;

   useEffect(() => {
      // getAllMonitoreoAction({token:access})
      getMonitoreoUserAction({ token: user.access, id: user.userInfo.id });
   }, []);

   // Dermochelys coriacea
   arrary_monitoreos_user
      .filter((ent) => ent.id_especie === "Dc")
      .map(
         (muestra) => (Tlaud = Tlaud + 1)
         // setCountEspecieTlaud(countespecieTlaud + 1)
      );

   //Lepidochelys olivacea
   arrary_monitoreos_user
      .filter((ent) => ent.id_especie === "Lo")
      .map(
         (muestra) => (Tgolfina = Tgolfina + 1)
         //console.log(muestra)
         //  setCountEspecieTgolfina(countespecieTgolfina + 1)
      );

   //Eretmochelys imbricata
   arrary_monitoreos_user
      .filter((ent) => ent.id_especie === "Ei")
      .map(
         (muestra) => (Ttcarey = Ttcarey + 1)
         //console.log(muestra)
         // setCountEspecieTtcarey(countespecieTtcarey + 1)
      );

   //Chelonia mydas
   arrary_monitoreos_user
      .filter((ent) => ent.id_especie === "Cc")
      .map(
         (muestra) => (Tverde = Tverde + 1)
         //console.log(muestra)
         // setCountEspecieTverde(countespecieTverde + 1)
      );

   //Caretta caretta
   arrary_monitoreos_user
      .filter((ent) => ent.id_especie === "Cm")
      .map(
         (muestra) => (Tcaguama = Tcaguama + 1)
         //console.log(muestra)
         // setCountEspecieTcaguama(countespecieTcaguama + 1)
      );

   let total = Tlaud + Tgolfina + Ttcarey + Tverde + Tcaguama;

   const options = {
      chart: {
         type: "line",
      },
      xAxis: {
         categories: [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre",
         ],
      },
      title: {
         text: "Eventos registrados",
      },
      yAxis: {
         min: 0,
         title: {
            text: "Número de eventos",
         },
      },
      series: [
         {
            // name:"Avistamiento de tortugas",
            // data: [1, 2, 1, 4, 3, 6, 10, 5, 3, 7, 4, 16],

            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, total],
         },
      ],
   };
   const options1 = {
      chart: {
         type: "column",
      },
      title: {
         text: "Eventos por especie",
      },
      xAxis: {
         categories: [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre",
         ],
      },
      yAxis: {
         min: 0,
         title: {
            text: "Número de eventos",
         },
         stackLabels: {
            enabled: true,
            style: {
               fontWeight: "bold",
               color:
                  // theme
                  (Highcharts.defaultOptions.title.style &&
                     Highcharts.defaultOptions.title.style.color) ||
                  "gray",
            },
         },
      },
      legend: {
         align: "right",
         x: -30,
         verticalAlign: "top",
         y: 25,
         floating: true,
         backgroundColor:
            Highcharts.defaultOptions.legend.backgroundColor || "white",
         borderColor: "#CCC",
         borderWidth: 1,
         shadow: false,
      },
      tooltip: {
         headerFormat: "<b>{point.x}</b><br/>",
         pointFormat:
            "<i>{series.name}</i>: {point.y}<br/>Total: {point.stackTotal}",
      },
      plotOptions: {
         column: {
            stacking: "normal",
            dataLabels: {
               enabled: true,
            },
         },
      },
      series: [
         {
            name: "Eretmochelys imbricata", //Ttcarey
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, Ttcarey],
         },
         {
            name: "Chelonia mydas", //Tverde
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, Tverde],
         },
         {
            name: "Lepidochelys olivacea", //Tgolfina
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, Tgolfina],
         },
         {
            name: "Dermochelys coriacea", //Tlaud
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, Tlaud],
         },
         {
            name: "Caretta caretta", //Tcaguama
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, Tcaguama],
         },
      ],

      // series: [
      //   {
      //     name: "Eretmochelys imbricata",
      //     data: [0, 2, 3, 2, 1, 5, 3, 9, 6, 4, 9, 8],
      //   },
      //   {
      //     name: "Chelonia mydas",
      //     data: [3, 4, 4, 4, 5, 8, 6, 3, 7, 5, 1, 2],
      //   },
      //   {
      //     name: "Lepidochelys olivacea",
      //     data: [3, 4, 4, 2, 5, 4, 7, 8, 5, 2, 6, 9],
      //   },
      //   {
      //     name: "Dermochelys coriacea",
      //     data: [3, 1, 4, 2, 5, 8, 6, 2, 3, 1, 2, 3],
      //   },
      //   {
      //     name: "Caretta caretta",
      //     data: [0, 3, 4, 7, 2, 5, 8, 6, 3, 1, 7, 8],
      //   },
      // ],
   };
   return (
      <div className='content-principal  content-internas'>
         <header className=' internas-h '>
            <Nav />
            <Header />
         </header>
         <section class='menubar'>
            <MenuRaiz />
         </section>
         <div className='container-fluid'>
            <div class='row'>
               <div class='col-6' style={{ padding: "0px" }}>
                  <HighchartsReact highcharts={Highcharts} options={options} />
               </div>
               <div class='col-6' style={{ padding: "0px" }}>
                  <HighchartsReact highcharts={Highcharts} options={options1} />
               </div>
            </div>
         </div>
         <Footer />
      </div>
   );
}
// sacar los state de (store) que tiene redux y los coloque en las props de este componente.
let mapState = (state) => {
   return {
      fetching_observaciones: state.naturalista.fetching_observaciones,
      error_user: state.naturalista.error,
      arrary_observaciones: state.naturalista.arrary_observaciones,
      arrary_all_monitoreos: state.monitoreos.arrary_all_monitoreos,
      fetching_get_all_monitoreos: state.monitoreos.fetching_get_all_monitoreos,
      access: state.user.user.access,
      arrary_monitoreos_user: state.monitoreos.arrary_monitoreos_user,
      user: state.user.user,
   };
};
// sacar los dispatch de (store) que tiene redux y los coloque en las props de este componente.
const mapDispatch = (dispatch) => {
   return {
      getObservacionesAction: (props) =>
         dispatch(getObservacionesAction(props)),
      getAllMonitoreoAction: (props) => dispatch(getAllMonitoreoAction(props)),
      getMonitoreoUserAction: (props) =>
         dispatch(getMonitoreoUserAction(props)),
   };
};

// connect(mapState,mapDispatch)(this funtion)
export default connect(mapState, mapDispatch)(APP);
