import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Nav from "components/base/navs/NavHome";
import MenuHome from "components/base/navs/NavRaiz";
import Header from "components/base/headers/Header";
import Footer from "components/base/Footer";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { Alert } from "react-bootstrap";
import Progress from "components/base/Progress";
import ModalLogin from "components/base/modales/ModalLogin";

import { update_psw_Action } from "redux/userDuck";

function Changepass({
  fetching_update_psw,
  msg_update_psw,
  error_update_psw,
  update_psw_Action,
}) {
  const { register, handleSubmit, errors, onChange } = useForm();
  const [resetForm, setResetForm] = useState({});
  const [error_psw, setError_psw] = useState("");

  const location = useLocation();
  const token = location.search.split("?token=")[1];
  const [modal, setModal] = useState(false);

  useEffect(() => {
    // forgotAction();
  }, []);

  const onSubmit = (data, e) => {
    e.preventDefault();

    if (data.psw1 === data.psw2) {
      let datos = {
        ...data,
        token,
      };
      update_psw_Action(datos);
      setResetForm(e);
    } else {
      setError_psw("Las contraseñas no coinciden; inténtalo de nuevo.");
      setTimeout(function () {
        setError_psw("");
      }, 3000);
    }
    // registerLoginAction(data);
  };

  const hide = () => {
    setModal(false);
  };
  let show = () => {
    setModal(true);
  };

  return (
    <div className="content-principal  content-internas">
      <div className="temporal-cerar"></div>
      <header className=" internas-h ">
        <Nav />
        <Header />
      </header>
      <section class="menubar">
        <MenuHome />
      </section>
      <section class="contents-interna sig interna-componente">
        {error_update_psw.length > 0 ||
          (error_psw.length > 0 && (
            <Alert variant={"danger"} className="container-xl my-3">
              {error_psw}
              {error_update_psw}
            </Alert>
          ))}

        {fetching_update_psw ? (
          <>
            <br />
            <div class="row justify-content-center">
              <div class="col-sm-6 col-md-6 mb-3">
                <div class="row justify-content-center">
                  <h6>Cambiando contraseña, espere por favor</h6>
                </div>
                <br />
              </div>
            </div>
            <Progress />{" "}
          </>
        ) : (
          <>
            {msg_update_psw.length > 0 ? (
              <>
                <Alert variant={"success"} className="container-xl my-3">
                  {msg_update_psw}
                </Alert>
                <a
                  href="javascript:void(0)"
                  onClick={show}
                  class="btn-primary botong boton-vermas"
                >
                  Iniciar sesión
                </a>
              </>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)} class="container-xl my-3">
                <div class="row justify-content-center">
                  <div class="col-sm-6 col-md-6 mb-3">
                    <div class="row justify-content-center">
                      <h6>¿Olvidaste tu contraseña?</h6>
                    </div>
                    <br />
                    <div>
                      <center>
                        <p>
                          Ingresa una nueva contraseña para tu cuenta Siam
                          Portal tematico tortugas de colombia.
                        </p>
                      </center>
                    </div>
                    <input
                      type="password"
                      class="form-control"
                      id="psw1"
                      name="psw1"
                      placeholder="Contraseña nueva"
                      autocomplete="off"
                      // minlength="6"
                      ref={register({ required: true })}
                    />
                    <span className="text-danger text-small d-block mb-2">
                      {errors.psw1 && <span>Este campo es requerido</span>}
                    </span>
                    <input
                      type="password"
                      class="form-control"
                      id="psw2"
                      name="psw2"
                      // minlength="6"
                      placeholder="Vuelve a escribir la contraseña"
                      autocomplete="off"
                      ref={register({ required: true })}
                    />
                    <span className="text-danger text-small d-block mb-2">
                      {errors.psw2 && <span>Este campo es requerido</span>}
                    </span>
                    <div class="row mb-3">
                      <div class="col-md-6 my-3">
                        <button type="submit" className="btn btn-primary">
                          Enviar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <div class="form-row"></div>
                  </div>
                </div>
              </form>
            )}
          </>
        )}
      </section>
      {<ModalLogin show={modal} hide={hide} />}
      <Footer />
    </div>
  );
}

let mapState = (state) => {
  return {
    fetching_update_psw: state.user.fetching_update_psw,
    msg_update_psw: state.user.msg_update_psw,
    error_update_psw: state.user.error_update_psw,
  };
};

// console.log("entra")
const mapDispatch = (dispatch) => {
  return {
    update_psw_Action: (props) => dispatch(update_psw_Action(props)),
  };
};
export default connect(mapState, mapDispatch)(Changepass);
