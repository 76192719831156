import React from "react";

export default function ContentsNido({
   errors,
   register,
   onChanges,
   arrary_referentes,
   porcentajeEclosion,
   porcentajeEmergencia,
   longitudNido,
   latitudNido,
   fechaAvistamiento,
}) {
   return (
      <>
         <div>
            <div className='grupo-inputs mb-3'>
               <span className='tit-grupo-inputs'>Seguimiento del nido</span>
               <div className='form-row'>
                  <div className='col-sm-3 col-md-2 mb-3'>
                     <label for='v17'>Latitud</label>
                     <input
                        ref={register({})}
                        type='text'
                        className='form-control deshabilitado'
                        id='latitud_nido'
                        name='latitud_nido'
                        value={latitudNido}
                        placeholder='00.000000'
                        readOnly
                     />
                     <span className='text-danger text-small d-block mb-2'>
                        {errors.latitud_nido && (
                           <span>Este campo es requerido</span>
                        )}
                     </span>
                  </div>
                  <div className='col-sm-3 col-md-2 mb-3'>
                     <label for='v18'>Longitud</label>
                     <input
                        ref={register({})}
                        type='text'
                        className='form-control deshabilitado'
                        id='longitud_nido'
                        name='longitud_nido'
                        value={longitudNido}
                        placeholder='00.000000'
                        readOnly
                     />
                     <span className='text-danger text-small d-block mb-2'>
                        {errors.longitud_nido && (
                           <span>Este campo es requerido</span>
                        )}
                     </span>
                  </div>
                  <div className='col-sm-3 col-md-3 mb-3'>
                     <label for='v22'>Hora del registro</label>
                     <input
                        ref={register({})}
                        type='text'
                        className='form-control'
                        id='avistamiento'
                        name='avistamiento'
                        placeholder='00:00'
                        value={fechaAvistamiento}
                        disabled
                     />
                     <span className='text-danger text-small d-block mb-2'>
                        {errors.avistamiento && (
                           <span>Este campo es requerido</span>
                        )}
                     </span>
                  </div>
                  <div className='col-sm-3 col-md-5 mb-3'>
                     <label for='v20'>¿Posición del nido? </label>
                     <select
                        ref={register({})}
                        className='custom-select'
                        id='posicion_nido'
                        name='posicion_nido'
                     >
                        <option selected disabled value='Sin option pnido'>
                           Seleccione...
                        </option>
                        {arrary_referentes.map((item) =>
                           item.tabla === 1107 ? (
                              <option
                                 value={item.cod_alfabetico}
                                 key={item.cod_numerico}
                              >
                                 {item.descripcion}
                              </option>
                           ) : (
                              []
                           )
                        )}
                     </select>
                     <span className='text-danger text-small d-block mb-2'>
                        {errors.posicion_nido && (
                           <span>Este campo es requerido</span>
                        )}
                     </span>
                  </div>
               </div>
               <div className='form-row'>
                  <div className='col-sm-6 col-md-4 mb-3'>
                     <label for='v21'>Manejo del nido</label>
                     <select
                        ref={register({})}
                        className='custom-select'
                        id='manejo'
                        name='manejo'
                        onChange={(e) => onChanges(e)}
                     >
                        <option selected disabled value=''>
                           Seleccione...
                        </option>
                        {arrary_referentes.map((item) =>
                           item.tabla === 1106 ? (
                              <option
                                 // value={item.cod_numerico}
                                 // key={item.cod_numerico}
                                 value={item.cod_alfabetico}
                                 key={item.cod_alfabetico}
                              >
                                 {item.descripcion}
                              </option>
                           ) : (
                              []
                           )
                        )}
                     </select>
                     <span className='text-danger text-small d-block mb-2'>
                        {errors.manejo && <span>Este campo es requerido</span>}
                     </span>
                  </div>
                  <div className='col-sm-3 col-md-2 mb-3'>
                     <label for='v19'>Núm. Huevos</label>
                     <input
                        ref={register({})}
                        type='number'
                        onChange={(e) => onChanges(e)}
                        className='form-control'
                        id='numero_huevos_puestos'
                        name='numero_huevos_puestos'
                        placeholder='00'
                     />
                     <span className='text-danger text-small d-block mb-2'>
                        {errors.numero_huevos_puestos && (
                           <span>Este campo es requerido</span>
                        )}
                     </span>
                  </div>
                  <div className='col-sm-4 col-md-3 mb-3'>
                     <label for='v03'>Fecha de eclosion</label>
                     <input
                        ref={register({
                           /*required: true*/
                        })}
                        onChange={(e) => onChanges(e)}
                        type='datetime-local'
                        className='form-control'
                        id='fechaEclosion'
                        name='fechaEclosion'
                        placeholder='dd/mm/aa 00:00'
                        onChange={(e) => onChanges(e)}
                     />

                     <span className='text-danger text-small d-block mb-2'>
                        {errors.fechaEclosion && (
                           <span>Este campo es requerido</span>
                        )}
                     </span>
                  </div>
                  <div className='col-sm-3 col-md-3 mb-3'>
                     <label for='v24'>Núm. huevos eclosionado</label>
                     <input
                        ref={register({})}
                        type='number'
                        onChange={(e) => onChanges(e)}
                        className='form-control'
                        id='nro_huevos_eclosionado'
                        name='nro_huevos_eclosionado'
                        placeholder='00'
                     />
                     <span className='text-danger text-small d-block mb-2'>
                        {errors.nro_huevos_eclosionado && (
                           <span>Este campo es requerido</span>
                        )}
                     </span>
                  </div>
               </div>
               <div className='form-row d-flex align-items-end '>
                  <div className='col-sm-3 col-md-2 mb-3'>
                     <label for='v23'>Dias de incubación</label>
                     <input
                        ref={register({})}
                        type='number'
                        onChange={(e) => onChanges(e)}
                        className='form-control'
                        id='dias_incubacion'
                        name='dias_incubacion'
                        placeholder='00'
                     />
                     <span className='text-danger text-small d-block mb-2'>
                        {errors.dias_incubacion && (
                           <span>Este campo es requerido</span>
                        )}
                     </span>
                  </div>
                  <div className='col-sm-3 col-md-3 mb-3'>
                     <label for='v23'>Núm. Neonatos Emergente</label>
                     <input
                        ref={register({})}
                        type='number'
                        onChange={(e) => onChanges(e)}
                        className='form-control'
                        id='neonatos_emergentes'
                        name='neonatos_emergentes'
                        placeholder='00'
                     />
                     <span className='text-danger text-small d-block mb-2'>
                        {errors.neonatos_emergentes && (
                           <span>Este campo es requerido</span>
                        )}
                     </span>
                  </div>
                  <div className='col-sm-3 col-md-3 mb-3'>
                     <label for='v25'>Núm. Neonatos liberados</label>
                     <input
                        ref={register({})}
                        type='number'
                        className='form-control'
                        id='nro_neonatos_libr'
                        name='nro_neonatos_libr'
                        placeholder='00'
                     />
                     <span className='text-danger text-small d-block mb-2'>
                        {errors.nro_neonatos_libr && (
                           <span>Este campo es requerido</span>
                        )}
                     </span>
                  </div>
                  <div className='col-sm-3 col-md-2 mb-3'>
                     <label for='v26'>% de eclosión</label>
                     <input
                        ref={register({})}
                        onChange={(e) => onChanges(e)}
                        type='number'
                        className='form-control'
                        id='porc_eclocion'
                        name='porc_eclocion'
                        value={porcentajeEclosion}
                        placeholder='00'
                        disabled
                     />
                     <span className='text-danger text-small d-block mb-2'>
                        {errors.porc_eclocion && (
                           <span>Este campo es requerido</span>
                        )}
                     </span>
                  </div>
                  <div className='col-sm-3 col-md-2 mb-3'>
                     <label for='v27'>% de emergencia</label>
                     <input
                        ref={register({})}
                        onChange={(e) => onChanges(e)}
                        type='number'
                        className='form-control'
                        id='porc_emergencia'
                        name='porc_emergencia'
                        value={porcentajeEmergencia}
                        placeholder='00'
                        disabled
                     />
                     <span className='text-danger text-small d-block mb-2'>
                        {errors.porc_emergencia && (
                           <span>Este campo es requerido</span>
                        )}
                     </span>
                  </div>
                  <div className='col-sm-10 col-md-12 mb-3'>
                     <label for='v28'>Observaciones</label>
                     <textarea
                        ref={register({})}
                        className='form-control'
                        id='observaciones_nido'
                        name='observaciones_nido'
                        rows='3'
                     ></textarea>
                     <span className='text-danger text-small d-block mb-2'>
                        {errors.observaciones_nido && (
                           <span>Este campo es requerido</span>
                        )}
                     </span>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
}
