import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Nav from "components/base/navs/NavHome";
import Header from "components/base/headers/Header";
import NavRaiz from "components/base/navs/NavRaiz";
import Footer from "components/base/Footer";
import Progress from "components/base/Progress";
import {
   getMonitoreoUserAction,
   updateMonitoreoAction,
} from "redux/monitoreoDuck";
import ListaMuestreos from "../compartidos/ListaMuestreos";
import { restoreReferentesAction } from "redux/referentesDuck";
import { setUpdatingMonitoreoAction } from "redux/monitoreoDuck";
import { TXT_APP } from "Routes";

function CurarMonitoreos({
   //Action
   getMonitoreoUserAction,
   isAddMonitoreo_user,
   fetching_get_user_monitoreos,
   isUpdate_monitoreo,
   arrary_monitoreos_user,
   access,
   isUpdate,
   user,
   setUpdatingMonitoreoAction,
}) {
   let txt = TXT_APP();

   useEffect(() => {
      // if (isUpdate) {
      getMonitoreoUserAction({ token: user.access, id: user.userInfo.id });
      updateMonitoreoAction([]);
      // }
   }, []);
   let lista = arrary_monitoreos_user.filter(
      (valorActual, indiceActual, arreglo) => {
         return (
            arreglo.findIndex(
               (valorDelArreglo) =>
                  valorDelArreglo.id_muestreotx === valorActual.id_muestreotx
            ) === indiceActual
         );
      }
   );

   let onCerrar = (muestra) => {
      console.log("LA MUESTRA:", muestra);
      setUpdatingMonitoreoAction({
         token: access,
         id_estacion: muestra,
         is_active: "N",
         observacion: "CER",
      });

      getMonitoreoUserAction({ token: user.access, id: user.userInfo.id });
   };

   return (
      <div className='content-principal  content-internas'>
         <div className='temporal-cerar'></div>
         <header className=' internas-h '>
            <Nav />
            <Header titulo={txt.tituloPanelCurador} />
         </header>
         <section class='menubar'>
         <NavRaiz />
         </section>
         <section className='contents-interna sig interna-componente'>
            <div className='container-xl my-3'>
               <div className='row'>
                  <div className='col-md-12'>
                     <ul className='nav nav-tabs' id='myTab' role='tablist'>
                        <li className='nav-item' role='presentation'>
                           <a
                              className='nav-link active'
                              id='home-tab'
                              data-toggle='tab'
                              href='#monitoreos'
                              role='tab'
                              aria-controls='monitoreos'
                              aria-selected='true'
                           >
                              Monitoreos
                           </a>
                        </li>
                     </ul>
                     {fetching_get_user_monitoreos ? (
                        <Progress />
                     ) : (
                        <ListaMuestreos
                           lista={lista}
                           modulo={txt.modulo_investigador}
                           onCerrar={onCerrar}
                        />
                     )}
                  </div>
               </div>
               <p>&nbsp;</p>
            </div>
         </section>
         <Footer />
      </div>
   );
}

let mapState = (state) => {
   return {
      access: state.user.user.access,
      arrary_monitoreos_user: state.monitoreos.arrary_monitoreos_user,
      //monitoreos del usuario
      isAddMonitoreo_user: state.monitoreos.isAddMonitoreo_user,
      fetching_get_user_monitoreos:
         state.monitoreos.fetching_get_user_monitoreos,
      isUpdate_monitoreo: state.monitoreos.isUpdate_monitoreo,
      user: state.user.user,
   };
};
// sacar los dispatch de (store) que tiene redux y los coloque en las props de este componente.
const mapDispatch = (dispatch) => {
   return {
      getMonitoreoUserAction: (props) =>
         dispatch(getMonitoreoUserAction(props)),
      setUpdatingMonitoreoAction: (props) =>
         dispatch(setUpdatingMonitoreoAction(props)),
   };
};

// connect(mapState,mapDispatch)(this funtion)
export default connect(mapState, mapDispatch)(CurarMonitoreos);
