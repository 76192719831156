import React, { useState, useEffect } from "react";
import Nav from "components/base/navs/NavHome";
import MenuHome from "components/base/navs/NavRaiz";
import Footer from "components/base/Footer";
import { Link, useHistory } from "react-router-dom";
import HeaderVisor from "components/base/headers/HeaderVisor";
import { URL_APP ,TXT_APP} from "Routes";
import {
  MapContainer,
  MapConsumer,
  TileLayer,
  Marker,
  Popup,
} from "react-leaflet";
import "../../../../node_modules/leaflet/dist/leaflet.css";
import { connect } from "react-redux";
import { getObservacionesAction } from "redux/naturalistaDuck";
import { getAllMonitoreoAction } from "redux/monitoreoDuck";
import Loader from "react-loader-spinner";
import "leaflet-draw";

import L from "leaflet";
var editableLayers = new L.FeatureGroup();
const iAm = L.icon({
  iconSize: [41, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  shadowAnchor: [0, 41],
  iconUrl:  process.env.PUBLIC_URL + "/static/images/icons/pinAmarillo.png",
  shadowUrl: "https://unpkg.com/leaflet@1.7/dist/images/marker-shadow.png",
});
const iRojo = L.icon({
  iconSize: [41, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  shadowAnchor: [0, 41],
  iconUrl: process.env.PUBLIC_URL +"/static/images/icons/pinRojo.png",
  shadowUrl: "https://unpkg.com/leaflet@1.7/dist/images/marker-shadow.png",
});
const iAzul = L.icon({
  iconSize: [41, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  shadowAnchor: [0, 41],
  iconUrl: process.env.PUBLIC_URL +"/static/images/icons/pinAzul.png",
  shadowUrl: "https://unpkg.com/leaflet@1.7/dist/images/marker-shadow.png",
});
const iVerde = L.icon({
  iconSize: [41, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  shadowAnchor: [0, 41],
  iconUrl: process.env.PUBLIC_URL +"/static/images/icons/pinVerde.png",
  shadowUrl: "https://unpkg.com/leaflet@1.7/dist/images/marker-shadow.png",
});
// const icon3 = L.icon(className="fas fa-map-marker-alt");
const VisorMapa = ({ datosMonitoreos, arrary_observaciones, filtros }) => {
  const history = useHistory();
  let url = URL_APP();

  const [edLayer, setEdLayer] = useState("");
  var observaciones = "0";
  var monitoreos = "0";
  const [list, setList] = useState([]);

  const [list1, setList1] = useState([]);

  //   var list = [];
  //   var list1 = [];

  if (edLayer != "") {
    var buff = [];
    if (list.length>0){
      buff = list.filter((value) =>
        edLayer.contains({
          lat: value.location.split(",")[0],
          lng: value.location.split(",")[1],
        })
      );
    }
    // console.log(buff);
    observaciones = buff;

    var buff1 = [];
    if (list1.length>0){
      buff1 = list1.filter((value) =>
        edLayer.contains({
          lat: value.lat,
          lng: value.long,
        })
      );
   //console.log(buff1.length);
    }
    monitoreos = buff1;
  }

  useEffect(() => {
    // console.log(list1);
    // console.log(list);
    if (filtros.avistamientos == "" ) {
      setList(
        arrary_observaciones.map((observaciones) => {
          return {
            ...observaciones,
            lat: observaciones.geojson.coordinates[1],
            long: observaciones.geojson.coordinates[0],
          };
        })
      );
    }
    if (filtros.monitoreo == ""  && datosMonitoreos.length>0){

      setList1(
        datosMonitoreos.filter((monitoreo) => {
          let lat = monitoreo.latitud_individuo
            ? monitoreo.latitud_individuo
            : monitoreo.latitud_nido;
          let long = monitoreo.longitud_individuo
            ? monitoreo.longitud_individuo
            : monitoreo.longitud_nido;
          if (lat != null && long != null) {
            monitoreo["lat"] = lat;
            monitoreo["long"] = long;
            return true;
          }
        })
      );
    }



    if (filtros.avistamientos != "") {
      var buff = arrary_observaciones;
      if (filtros.avistamientos.inv) {
        buff = buff.filter(
          (value) => value.user.id == filtros.avistamientos.inv
        );
      }
      if (filtros.avistamientos.Fini) {
        buff = buff.filter(
          (value) => value.observed_on > filtros.avistamientos.Fini
        );
      }
      if (filtros.avistamientos.Ffin) {
        buff.filter((value) => value.observed_on < filtros.avistamientos.Ffin);
      }
      setList(buff);
    }

    if (filtros.monitoreo != "" && datosMonitoreos.length> 0) {
      var buff1 = datosMonitoreos;
      if (filtros.monitoreo.conse) {
        buff1 = buff1.filter((value) => value.conse == filtros.monitoreo.conse);
      }

      if (filtros.monitoreo.Fini1) {
        buff1 = buff1.filter(
          (value) => value.fecha.split(" ")[0] > filtros.monitoreo.Fini1
        );
      }
      if (filtros.monitoreo.Ffin1) {
        buff1 = buff1.filter(
          (value) => value.fecha.split(" ")[0] < filtros.monitoreo.Ffin1
        );
      }
      setList1(buff1);
    }
  }, [filtros, datosMonitoreos, arrary_observaciones]);
  

  return (
    list1.length > 0 ||
    list.length > 0 ? (
      <>
        <MapContainer
          center={[11.2402975, -74.2284027]}
          zoom={8}
          style={{
            height: "80vh",
          }}
          whenCreated={(map) => {
            map.addLayer(editableLayers);
            var drawPluginOptions = {
              position: "topright",
              draw: {
                polygon: false,
                polyline: false,
                circle: false, // Turns off this drawing tool
                rectangle: true,
                marker: false,
                circlemarker: false,
              },
              edit: {
                featureGroup: editableLayers, //REQUIRED!!
                remove: true,
              },
            };
            // Initialise the draw control and pass it the FeatureGroup of editable layers
            var drawControl = new L.Control.Draw(drawPluginOptions);
            map.addControl(drawControl);
            return <div id="map"></div>;
          }}
        >
          <div
            style={{
              position: "absolute",
              zIndex: "1500",
              margin: "0px 50px",
            }}
          >
            <div className="float-left my-3 ml-3">
              <div className="btn-group" role="group">
                <a href="javascript:void(0)" className="btn btn-light f-14 active">
                  <i className="fa fa-map-marker"></i> Mapa
                </a>
                <button
                  onClick={() =>
                    history.push(url.lista_consulta, {
                      observaciones:
                        observaciones == "0" ? list : observaciones,
                      monitoreos: monitoreos == "0" ? list1 : monitoreos,
                    })
                  }
                  className="btn btn-light f-14"
                >
                  <i className="fa fa-list"></i> Lista
                </button>
              </div>
              <br />
              <img src={ process.env.PUBLIC_URL +"/static/images/leyenda.png"} alt="..." />
            </div>
          </div>
          <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
          {/* <TileLayer
            attribution='&copy; <a href="http://www.google.cn">GoogleMaps</a> contributors'
            url="http://www.google.cn/maps/vt?lyrs=s@190&gl=cn&x={x}&y={y}&z={z}"
          /> */}
          <MapConsumer>
            {(map) => {
              map.on("draw:created", function (e) {
                var layer = e.layer;
                setEdLayer(layer._bounds);
                // console.log(e.layer._bounds);

                editableLayers.addLayer(layer);
              });

              map.on("draw:deleted	", function (e) {
                var layer = e.layer;
                // console.log(e.layer._bounds);
                setEdLayer("");
                //  setObservaciones("0");
                //  setMonitoreos("0");

                editableLayers.removeLayer(layer);
              });
              return <div id="map"></div>;
            }}
          </MapConsumer>
          {observaciones != "0"
            ? observaciones.length>0 && observaciones.map((value) => (
                <Marker
                  position={[
                    value.location.split(",")[0],
                    value.location.split(",")[1],
                  ]}
                  icon={iAzul}
                >
                  <Popup>
                    {value ? (
                      <div>
                        {value.id}
                        <br />
                        <span>{value.species_guess}</span>
                        <br />
                        <a href={value.uri}>{value.uri}</a>

                        <img src={value.photos[0].url} />
                      </div>
                    ) : (
                      ""
                    )}
                    <br />
                  </Popup>
                </Marker>
              ))
            : list.length>0 && list.map((value) => (
                <Marker
                  position={[
                    value.location.split(",")[0],
                    value.location.split(",")[1],
                  ]}
                  icon={iVerde}
                >
                  <Popup>
                    {value ? (
                      <div>
                        {value.id}
                        <br />
                        <span>{value.species_guess}</span>
                        <br />
                        <a href={value.uri}>{value.uri}</a>

                        <img src={value.photos[0].url} />
                      </div>
                    ) : (
                      ""
                    )}
                    <br />
                  </Popup>
                </Marker>
              ))}
          {monitoreos != "0"
            ? monitoreos.length>0 && monitoreos.map((value) => (
                <Marker position={[value.lat, value.long]} icon={iAm}>
                  <Popup>
                    {value ? (
                      <div>
                        {value.id_muestreotx}
                        <br />
                        <span>{value.id_especie_des}</span>
                        <br />
                        <span>{value.fecha}</span>
                        <br />
                        <span>{value.nom_estacion}</span>
                        <br />
                        <span>{value.uac_des}</span>
                        <br />
                        <span>{value.departamento}</span>
                      </div>
                    ) : (
                      ""
                    )}
                    <br />
                  </Popup>
                </Marker>
              ))
            :  list1.length>0 &&  list1.map((value) => (
                <Marker position={[value.lat, value.long]} icon={iRojo}>
                  <Popup>
                    {value ? (
                      <div>
                        {value.id_muestreotx}
                        <br />
                        <span>{value.id_especie_des}</span>
                        <br />
                        <span>{value.fecha}</span>
                        <br />
                        <span>{value.nom_estacion}</span>
                        <br />
                        <span>{value.uac_des}</span>
                        <br />
                        <span>{value.departamento}</span>
                      </div>
                    ) : (
                      ""
                    )}
                    <br />
                  </Popup>
                </Marker>
              ))}
        </MapContainer>
      </>
    ) : (
      <div
        style={{
          width: "100%",
          height: "100",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loader
          type="ThreeDots"
          color="#11273c"
          height="100"
          width="100"
        />
      </div>
    )
  );
};

// funcion principal

function Visor({
  access,
  fetching_observaciones,
  error_user,
  arrary_observaciones,
  getObservacionesAction,
  getAllMonitoreoAction,
  arrary_all_monitoreos,
}) {
  const [filtros, setFiltros] = useState({ avistamientos: "", monitoreo: "" });
  let url = URL_APP();
  let txt = TXT_APP();

  const [monitoreosF, setMonitoreosF] = useState([]);
  const [avistamientosF, setAvistamientosF] = useState([]);

  useEffect(() => {
    // console.log("render");
    // console.log(arrary_observaciones)
    getObservacionesAction();
    getAllMonitoreoAction({ token: "" });
    setMonitoreosF(arrary_all_monitoreos);
    setAvistamientosF(arrary_observaciones);
  }, []);

  return (
    <div className="content-principal  content-internas">
      <header className=" internas-h ">
        <Nav />
        {arrary_observaciones.length || arrary_all_monitoreos.length && (
          <HeaderVisor
            filtrar={(data) => setFiltros(data)}
            investigadores={arrary_observaciones}
            title = "Registros Disponibles"
          />
        )}
      </header>
      <section className="menubar">
        <MenuHome />
      </section>

      <section className="">
        <div className="container-fluid p-0">
          <div className="container-fluid p-0">
            {arrary_observaciones.length>0 || arrary_all_monitoreos.length>0 ? (
              <VisorMapa
                datosMonitoreos={arrary_all_monitoreos}
                arrary_observaciones={arrary_observaciones}
                filtros={filtros}
              />
            ) : (
              <div
                style={{
                  width: "100%",
                  height: "100",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Loader
                  type="ThreeDots"
                  color="#11273c"
                  height="100"
                  width="100"
                />
              </div>
            )}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
// sacar los state de (store) que tiene redux y los coloque en las props de este componente.
let mapState = (state) => {
  return {
    fetching_observaciones: state.naturalista.fetching_observaciones,
    error_user: state.naturalista.error,
    arrary_observaciones: state.naturalista.arrary_observaciones,
    arrary_all_monitoreos: state.monitoreos.arrary_all_monitoreos,
    access: state.user.user.access,
  };
};
// sacar los dispatch de (store) que tiene redux y los coloque en las props de este componente.
const mapDispatch = (dispatch) => {
  return {
    getObservacionesAction: (props) => dispatch(getObservacionesAction(props)),
    getAllMonitoreoAction: (props) => dispatch(getAllMonitoreoAction(props)),
  };
};

// connect(mapState,mapDispatch)(this funtion)
export default connect(mapState, mapDispatch)(Visor);
