import React, { useState, useEffect } from "react";
import { URL_APP } from "Routes";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { Button } from "react-bootstrap";
import Nav from "components/base/navs/NavHome";
import Header from "components/base/headers/Header";
import Footer from "components/base/Footer";
import MenuCurador from "components/base/navs/NavCurador";
import { Link, useHistory } from "react-router-dom";
import { Alert, Modal } from "react-bootstrap";
import { TXT_APP } from "Routes";

function CurarDetalleMuestras({ view_monitoreo }) {
   let txt = TXT_APP();
   let url = URL_APP();
   const history = useHistory();

   //==========================================================================================================================

   console.log("VIEW MONITOREO", view_monitoreo);

   //Se llena el encabezado del formulario
   const { register, handleSubmit, errors, onChange, setValue } = useForm({
      defaultValues: {
         ...view_monitoreo,

         //datos generales
         consecutivo: view_monitoreo.id_consecutivo,
         encontro_nido: view_monitoreo.id_muestreo_enlace === 11 ? "Si" : "No",
         intercepto_hembra:
            view_monitoreo.id_muestreo_enlace === 11 ? "Si" : "No",
         numero_huevos_puestos: view_monitoreo.nro_huevos_puestos,
         nro_huevos_eclosionado: view_monitoreo.nro_huevos_eclosion,
         nro_neonatos_libr: view_monitoreo.nro_neonatos_lbr,
         sector: view_monitoreo.sector,
         transecto: view_monitoreo.transecto,
         avistamiento: view_monitoreo.fecha,
         especie: view_monitoreo.id_especie_des,
         //hembra
         id_p_enc_1: view_monitoreo.pos_marca_encon_01_des,
         id_p_enc_2: view_monitoreo.pos_marca_encon_02_des,
         id_p_imp_1: view_monitoreo.pos_marca_impl_01_des,
         id_p_imp_2: view_monitoreo.pos_marca_impl_02_des,
         //nido

         // posicion_nido_des:

         //individuo
      },
   });

   let porcentajeEclosion = (
      (view_monitoreo.nro_huevos_eclosion / view_monitoreo.nro_huevos_puestos) *
      100
   ).toFixed(2);
   let porcentajeEmergencia = (
      (view_monitoreo.neonatos_emergentes / view_monitoreo.nro_huevos_puestos) *
      100 *
      10
   ).toFixed(1);

   //   setPorcentajeEclosion(parseFloat(((nroHuevosEclosionados/nroHuevos)*100)).toFixed(2))
   //   setPorcentajeEmergencia(parseFloat(((nroNeonatosEmergente/nroHuevos)*100)*10).toFixed(1))

   const onSubmit = (data, e) => {};

   let onChanges = (e) => {};

   return (
      <div>
         {false ? (
            <div></div>
         ) : (
            <div>
               <div className='content-principal  content-internas'>
                  <div className='temporal-cerar'></div>
                  <header className=' internas-h '>
                     <Nav />
                     <Header titulo={txt.detalle_muestra} />
                  </header>
                  <section class='menubar'>
                     <MenuCurador />
                  </section>
                  <section className='contents-interna sig interna-componente'>
                     <div className='container-xl my-3'>
                        <div className='row'>
                           <div className='col-md-12'>
                              <div class='container-xl my-3'>
                                 <h3 className='title-form-monitoreo'>
                                    Infirmacion a Curar del Formulario de
                                    monitoreo de playas de reproducción de
                                    tortugas marinas en Colombia
                                 </h3>
                                 <div className='encabezado-form '>
                                    <h6 className='mb-3'>
                                       Muestra :{view_monitoreo.id_muestratex}{" "}
                                    </h6>
                                    <p>
                                       <Link
                                          className='mr-2 btn btn-light btn-w-110 f-14'
                                          exact
                                          to='/curador/curar-detalle-muestreo'
                                       >
                                          Atras
                                       </Link>
                                    </p>{" "}
                                    &nbsp;{" "}
                                 </div>
                                 <form onSubmit={handleSubmit(onSubmit)}>
                                    {/* Datos generales */}
                                    <div className='cuerpo-form'>
                                       <div>
                                          <h6>Datos generales</h6>
                                          <div className='form-row'>
                                             <div className='col-sm-3 col-md-3 mb-3'>
                                                <label for='v11'>
                                                   Consecutivo
                                                </label>
                                                <input
                                                   ref={register({})}
                                                   type='text'
                                                   disabled
                                                   className='form-control'
                                                   id='consecutivo'
                                                   name='consecutivo'
                                                   placeholder='i-00000'
                                                />
                                                <span className='text-danger text-small d-block mb-2'>
                                                   {errors.consecutivo && (
                                                      <span>
                                                         Este campo es requerido
                                                      </span>
                                                   )}
                                                </span>
                                             </div>
                                             <div className='col-sm-3 col-md-5 mb-3'>
                                                <label for='v12'>Especie</label>
                                                <input
                                                   ref={register({})}
                                                   type='text'
                                                   disabled
                                                   className='form-control'
                                                   id='especie'
                                                   name='especie'
                                                   placeholder='i-00000'
                                                />
                                             </div>
                                             <div className='col-sm-3 col-md-2 mb-3'>
                                                <label for='v13'>
                                                   Núm. de transecto
                                                </label>
                                                <input
                                                   ref={register({
                                                      /*required: true*/
                                                   })}
                                                   type='text'
                                                   disabled
                                                   className='form-control'
                                                   id='transecto'
                                                   name='transecto'
                                                   placeholder=''
                                                />
                                                <span className='text-danger text-small d-block mb-2'>
                                                   {errors.transecto && (
                                                      <span>
                                                         Este campo es requerido
                                                      </span>
                                                   )}
                                                </span>
                                             </div>
                                             <div className='col-sm-3 col-md-2 mb-3'>
                                                <label for='v14'>
                                                   Núm. de sector
                                                </label>
                                                <input
                                                   ref={register({
                                                      /*required: true*/
                                                   })}
                                                   type='text'
                                                   disabled
                                                   className='form-control'
                                                   id='sector'
                                                   name='sector'
                                                   placeholder=''
                                                />
                                                <span className='text-danger text-small d-block mb-2'>
                                                   {errors.sector && (
                                                      <span>
                                                         Este campo es requerido
                                                      </span>
                                                   )}
                                                </span>
                                             </div>
                                          </div>
                                          <div className='form-row'>
                                             <div className='col-sm-3 col-md-2 mb-3'>
                                                <label for='v15'>
                                                   Se encontro nido
                                                </label>
                                                <input
                                                   ref={register({
                                                      /*required: true*/
                                                   })}
                                                   type='text'
                                                   disabled
                                                   className='form-control'
                                                   id='encontro_nido'
                                                   name='encontro_nido'
                                                   placeholder=''
                                                />
                                             </div>
                                             <div className='col-sm-3 col-md-2 mb-3'>
                                                <label for='v16'>
                                                   Interceptar hembras
                                                </label>
                                                <input
                                                   ref={register({
                                                      /*required: true*/
                                                   })}
                                                   type='text'
                                                   disabled
                                                   className='form-control'
                                                   id='intercepto_hembra'
                                                   name='intercepto_hembra'
                                                   placeholder=''
                                                />
                                             </div>
                                             <div className='col-sm-3 col-md-2 mb-3'>
                                                <label for='v15'>Latitud</label>
                                                <input
                                                   ref={register({})}
                                                   onChange={(e) =>
                                                      onChanges(e)
                                                   }
                                                   type='text'
                                                   disabled
                                                   className='form-control'
                                                   id='latitud_individuo'
                                                   name='latitud_individuo'
                                                   placeholder='00,000000'
                                                />
                                                <span className='text-danger text-small d-block mb-2'>
                                                   {errors.latitud_individuo && (
                                                      <span>
                                                         Este campo es requerido
                                                      </span>
                                                   )}
                                                </span>
                                             </div>
                                             <div className='col-sm-3 col-md-2 mb-3'>
                                                <label for='v16'>
                                                   Longitud
                                                </label>
                                                <input
                                                   ref={register({})}
                                                   onChange={(e) =>
                                                      onChanges(e)
                                                   }
                                                   type='text'
                                                   disabled
                                                   className='form-control'
                                                   id='longitud_individuo'
                                                   name='longitud_individuo'
                                                   placeholder='00,000000'
                                                />
                                                <span className='text-danger text-small d-block mb-2'>
                                                   {errors.longitud_individuo && (
                                                      <span>
                                                         Este campo es requerido
                                                      </span>
                                                   )}
                                                </span>
                                             </div>
                                             <div className='col-sm-3 col-md-4 mb-3'>
                                                <label for='v14'>
                                                   Fecha y hora del registro
                                                </label>
                                                <input
                                                   ref={register({})}
                                                   onChange={(e) =>
                                                      onChanges(e)
                                                   }
                                                   type='text'
                                                   disabled
                                                   className='form-control'
                                                   id='avistamiento'
                                                   name='avistamiento'
                                                />
                                                <span className='text-danger text-small d-block mb-2'>
                                                   {errors.avistamiento && (
                                                      <span>
                                                         Este campo es requerido
                                                      </span>
                                                   )}
                                                </span>
                                             </div>
                                          </div>
                                       </div>

                                       {/* hembra */}
                                       {(view_monitoreo.id_muestreo_enlace ===
                                          1 ||
                                          view_monitoreo.id_muestreo_enlace ===
                                             11) && (
                                          <div className='grupo-inputs mb-3'>
                                             <span className='tit-grupo-inputs'>
                                                Seguimiento de la Hembra
                                             </span>
                                             <div className='form-row'>
                                                <div className='col-sm-6 col-md-6'>
                                                   <div className='input-group mb-2'>
                                                      <input
                                                         ref={register({})}
                                                         type='text'
                                                         disabled
                                                         className='form-control'
                                                         placeholder='Cod. Marca 1 encontrada'
                                                         id='cdg_marca_encont_01'
                                                         name='cdg_marca_encont_01'
                                                         style={{
                                                            width: "100px",
                                                         }}
                                                      />
                                                      <span className='text-danger text-small d-block mb-2'>
                                                         {errors.cdg_marca_encont_01 && (
                                                            <span>
                                                               Este campo es
                                                               requerido
                                                            </span>
                                                         )}
                                                      </span>
                                                      <input
                                                         ref={register({})}
                                                         type='text'
                                                         disabled
                                                         className='form-control'
                                                         placeholder='Cod. Marca 2 encontrada'
                                                         id='id_p_enc_1'
                                                         name='id_p_enc_1'
                                                         style={{
                                                            width: "100px",
                                                         }}
                                                      />
                                                   </div>
                                                   <div className='input-group mb-2'>
                                                      <input
                                                         ref={register({})}
                                                         type='text'
                                                         disabled
                                                         className='form-control'
                                                         placeholder='Cod. Marca 2 encontrada'
                                                         id='cdg_marca_encont_02'
                                                         name='cdg_marca_encont_02'
                                                         style={{
                                                            width: "100px",
                                                         }}
                                                      />
                                                      <span className='text-danger text-small d-block mb-2'>
                                                         {errors.cdg_marca_encont_02 && (
                                                            <span>
                                                               Este campo es
                                                               requerido
                                                            </span>
                                                         )}
                                                      </span>

                                                      <input
                                                         ref={register({})}
                                                         type='text'
                                                         disabled
                                                         className='form-control'
                                                         placeholder='Cod. Marca 2 encontrada'
                                                         id='id_p_enc_2'
                                                         name='id_p_enc_2'
                                                         style={{
                                                            width: "100px",
                                                         }}
                                                      />
                                                   </div>
                                                   <div className='input-group mb-2'>
                                                      <input
                                                         ref={register({})}
                                                         type='text'
                                                         disabled
                                                         className='form-control'
                                                         placeholder='Cod. Marca 1 implantada'
                                                         id='cdg_marca_impl_01'
                                                         name='cdg_marca_impl_01'
                                                         style={{
                                                            width: "100px",
                                                         }}
                                                      />

                                                      <span className='text-danger text-small d-block mb-2'>
                                                         {errors.cdg_marca_impl_01 && (
                                                            <span>
                                                               Este campo es
                                                               requerido
                                                            </span>
                                                         )}
                                                      </span>

                                                      <input
                                                         ref={register({})}
                                                         type='text'
                                                         disabled
                                                         className='form-control'
                                                         placeholder='Cod. Marca 2 encontrada'
                                                         id='id_p_imp_1'
                                                         name='id_p_imp_1'
                                                         style={{
                                                            width: "100px",
                                                         }}
                                                      />
                                                   </div>
                                                   <div className='input-group mb-2'>
                                                      <input
                                                         ref={register({})}
                                                         type='text'
                                                         disabled
                                                         className='form-control'
                                                         placeholder='Cod. Marca 2 implantada'
                                                         id='cdg_marca_impl_02'
                                                         name='cdg_marca_impl_02'
                                                         style={{
                                                            width: "100px",
                                                         }}
                                                      />
                                                      <span className='text-danger text-small d-block mb-2'>
                                                         {errors.cdg_marca_impl_02 && (
                                                            <span>
                                                               Este campo es
                                                               requerido
                                                            </span>
                                                         )}
                                                      </span>

                                                      <input
                                                         ref={register({})}
                                                         type='text'
                                                         disabled
                                                         className='form-control'
                                                         placeholder='Cod. Marca 2 encontrada'
                                                         id='id_p_imp_2'
                                                         name='id_p_imp_2'
                                                         style={{
                                                            width: "100px",
                                                         }}
                                                      />
                                                   </div>
                                                </div>
                                                <div className='col-sm-6 col-md-2'>
                                                   <div className='col  mb-3'>
                                                      <label for='v38'>
                                                         LCC en cm
                                                      </label>
                                                      <input
                                                         ref={register({})}
                                                         type='number'
                                                         disabled
                                                         className='form-control'
                                                         id='largo_caparazon'
                                                         name='largo_caparazon'
                                                         placeholder='00'
                                                      />
                                                      <span className='text-danger text-small d-block mb-2'>
                                                         {errors.largo_caparazon && (
                                                            <span>
                                                               Este campo es
                                                               requerido
                                                            </span>
                                                         )}
                                                      </span>
                                                   </div>
                                                   <div className='col mb-3'>
                                                      <label for='v39'>
                                                         ACC en cm
                                                      </label>
                                                      <input
                                                         ref={register({})}
                                                         type='number'
                                                         disabled
                                                         className='form-control'
                                                         id='ancho_caparazon'
                                                         name='ancho_caparazon'
                                                         placeholder='00'
                                                      />
                                                      <span className='text-danger text-small d-block mb-2'>
                                                         {errors.ancho_caparazon && (
                                                            <span>
                                                               Este campo es
                                                               requerido
                                                            </span>
                                                         )}
                                                      </span>
                                                   </div>
                                                </div>
                                                <div className='col-sm-10 col-md-4 mb-3'>
                                                   <label for='v40'>
                                                      Observaciones
                                                   </label>
                                                   <textarea
                                                      ref={register({})}
                                                      className='form-control'
                                                      id='observaciones_marca'
                                                      name='observaciones_marca'
                                                      rows='5'
                                                      disabled
                                                   ></textarea>
                                                   <span className='text-danger text-small d-block mb-2'>
                                                      {errors.observaciones_marca && (
                                                         <span>
                                                            Este campo es
                                                            requerido
                                                         </span>
                                                      )}
                                                   </span>
                                                </div>
                                             </div>
                                          </div>
                                       )}
                                       {/* nido, y relocalizacion */}

                                       {(view_monitoreo.id_muestreo_enlace ===
                                          10 ||
                                          view_monitoreo.id_muestreo_enlace ===
                                             11) && (
                                          <>
                                             <div>
                                                <div className='grupo-inputs mb-3'>
                                                   <span className='tit-grupo-inputs'>
                                                      Seguimiento del nido
                                                   </span>
                                                   <div className='form-row'>
                                                      <div className='col-sm-3 col-md-2 mb-3'>
                                                         <label for='v17'>
                                                            Latitud
                                                         </label>
                                                         <input
                                                            ref={register({})}
                                                            type='text'
                                                            disabled
                                                            className='form-control deshabilitado'
                                                            id='latitud_nido'
                                                            name='latitud_nido'
                                                            placeholder='00.000000'
                                                            readOnly
                                                         />
                                                         <span className='text-danger text-small d-block mb-2'>
                                                            {errors.latitud_nido && (
                                                               <span>
                                                                  Este campo es
                                                                  requerido
                                                               </span>
                                                            )}
                                                         </span>
                                                      </div>
                                                      <div className='col-sm-3 col-md-2 mb-3'>
                                                         <label for='v18'>
                                                            Longitud
                                                         </label>
                                                         <input
                                                            ref={register({})}
                                                            type='text'
                                                            disabled
                                                            className='form-control deshabilitado'
                                                            id='longitud_nido'
                                                            name='longitud_nido'
                                                            placeholder='00.000000'
                                                            readOnly
                                                         />
                                                         <span className='text-danger text-small d-block mb-2'>
                                                            {errors.longitud_nido && (
                                                               <span>
                                                                  Este campo es
                                                                  requerido
                                                               </span>
                                                            )}
                                                         </span>
                                                      </div>
                                                      <div className='col-sm-3 col-md-3 mb-3'>
                                                         <label for='v22'>
                                                            Hora del registro
                                                         </label>
                                                         <input
                                                            ref={register({})}
                                                            className='form-control'
                                                            id='avistamiento'
                                                            name='avistamiento'
                                                            placeholder='00:00'
                                                            value={
                                                               view_monitoreo.fecha
                                                            }
                                                            disabled
                                                         />
                                                         <span className='text-danger text-small d-block mb-2'>
                                                            {errors.avistamiento && (
                                                               <span>
                                                                  Este campo es
                                                                  requerido
                                                               </span>
                                                            )}
                                                         </span>
                                                      </div>
                                                      <div className='col-sm-3 col-md-5 mb-3'>
                                                         <label for='v20'>
                                                            ¿Posición del nido?{" "}
                                                         </label>
                                                         <input
                                                            ref={register({})}
                                                            type='text'
                                                            disabled
                                                            className='form-control'
                                                            id='posicion_nido'
                                                            name='posicion_nido'
                                                            placeholder='00:00'
                                                            value={
                                                               view_monitoreo.fecha
                                                            }
                                                            disabled
                                                         />
                                                      </div>
                                                   </div>
                                                   <div className='form-row'>
                                                      <div className='col-sm-6 col-md-4 mb-3'>
                                                         <label for='v21'>
                                                            Manejo del nido
                                                         </label>
                                                         <input
                                                            ref={register({})}
                                                            type='text'
                                                            disabled
                                                            className='form-control'
                                                            id='manejo'
                                                            name='manejo'
                                                            placeholder='00:00'
                                                            value={
                                                               view_monitoreo.fecha
                                                            }
                                                            disabled
                                                         />
                                                      </div>
                                                      <div className='col-sm-3 col-md-2 mb-3'>
                                                         <label for='v19'>
                                                            Núm. Huevos
                                                         </label>
                                                         <input
                                                            ref={register({})}
                                                            type='number'
                                                            disabled
                                                            onChange={(e) =>
                                                               onChanges(e)
                                                            }
                                                            className='form-control'
                                                            id='numero_huevos_puestos'
                                                            name='numero_huevos_puestos'
                                                            placeholder='00'
                                                         />
                                                         <span className='text-danger text-small d-block mb-2'>
                                                            {errors.numero_huevos_puestos && (
                                                               <span>
                                                                  Este campo es
                                                                  requerido
                                                               </span>
                                                            )}
                                                         </span>
                                                      </div>
                                                      <div className='col-sm-4 col-md-3 mb-3'>
                                                         <label for='v03'>
                                                            Fecha de eclosion..
                                                         </label>
                                                         <input
                                                            defaultValue={
                                                               view_monitoreo.fecha_eclosion
                                                            }
                                                            ref={register({
                                                               /*required: true*/
                                                            })}
                                                            onChange={(e) =>
                                                               onChanges(e)
                                                            }
                                                            type='text'
                                                            disabled
                                                            className='form-control'
                                                            id='fechaEclosion'
                                                            name='fechaEclosion'
                                                            placeholder='dd/mm/aa 00:00'
                                                            onChange={(e) =>
                                                               onChanges(e)
                                                            }
                                                         />

                                                         <span className='text-danger text-small d-block mb-2'>
                                                            {errors.fechaEclosion && (
                                                               <span>
                                                                  Este campo es
                                                                  requerido
                                                               </span>
                                                            )}
                                                         </span>
                                                      </div>
                                                      <div className='col-sm-3 col-md-3 mb-3'>
                                                         <label for='v24'>
                                                            Núm. huevos
                                                            eclosionado
                                                         </label>
                                                         <input
                                                            ref={register({})}
                                                            type='number'
                                                            disabled
                                                            onChange={(e) =>
                                                               onChanges(e)
                                                            }
                                                            className='form-control'
                                                            id='nro_huevos_eclosionado'
                                                            name='nro_huevos_eclosionado'
                                                            placeholder='00'
                                                         />
                                                         <span className='text-danger text-small d-block mb-2'>
                                                            {errors.nro_huevos_eclosionado && (
                                                               <span>
                                                                  Este campo es
                                                                  requerido
                                                               </span>
                                                            )}
                                                         </span>
                                                      </div>
                                                   </div>
                                                   <div className='form-row d-flex align-items-end '>
                                                      <div className='col-sm-3 col-md-2 mb-3'>
                                                         <label for='v23'>
                                                            Dias de incubación
                                                         </label>
                                                         <input
                                                            ref={register({})}
                                                            type='number'
                                                            disabled
                                                            onChange={(e) =>
                                                               onChanges(e)
                                                            }
                                                            className='form-control'
                                                            id='dias_incubacion'
                                                            name='dias_incubacion'
                                                            placeholder='00'
                                                         />
                                                         <span className='text-danger text-small d-block mb-2'>
                                                            {errors.dias_incubacion && (
                                                               <span>
                                                                  Este campo es
                                                                  requerido
                                                               </span>
                                                            )}
                                                         </span>
                                                      </div>
                                                      <div className='col-sm-3 col-md-3 mb-3'>
                                                         <label for='v23'>
                                                            Núm. Neonatos
                                                            Emergente
                                                         </label>
                                                         <input
                                                            ref={register({})}
                                                            type='number'
                                                            disabled
                                                            onChange={(e) =>
                                                               onChanges(e)
                                                            }
                                                            className='form-control'
                                                            id='neonatos_emergentes'
                                                            name='neonatos_emergentes'
                                                            placeholder='00'
                                                         />
                                                         <span className='text-danger text-small d-block mb-2'>
                                                            {errors.neonatos_emergentes && (
                                                               <span>
                                                                  Este campo es
                                                                  requerido
                                                               </span>
                                                            )}
                                                         </span>
                                                      </div>
                                                      <div className='col-sm-3 col-md-3 mb-3'>
                                                         <label for='v25'>
                                                            Núm. Neonatos
                                                            liberados
                                                         </label>
                                                         <input
                                                            ref={register({})}
                                                            type='number'
                                                            disabled
                                                            className='form-control'
                                                            id='nro_neonatos_libr'
                                                            name='nro_neonatos_libr'
                                                            placeholder='00'
                                                         />
                                                         <span className='text-danger text-small d-block mb-2'>
                                                            {errors.nro_neonatos_libr && (
                                                               <span>
                                                                  Este campo es
                                                                  requerido
                                                               </span>
                                                            )}
                                                         </span>
                                                      </div>
                                                      <div className='col-sm-3 col-md-2 mb-3'>
                                                         <label for='v26'>
                                                            % de eclosión
                                                         </label>
                                                         <input
                                                            ref={register({})}
                                                            onChange={(e) =>
                                                               onChanges(e)
                                                            }
                                                            type='number'
                                                            disabled
                                                            className='form-control'
                                                            id='porc_eclocion'
                                                            name='porc_eclocion'
                                                            value={
                                                               porcentajeEclosion
                                                            }
                                                            placeholder='00'
                                                            disabled
                                                         />
                                                         <span className='text-danger text-small d-block mb-2'>
                                                            {errors.porc_eclocion && (
                                                               <span>
                                                                  Este campo es
                                                                  requerido
                                                               </span>
                                                            )}
                                                         </span>
                                                      </div>
                                                      <div className='col-sm-3 col-md-2 mb-3'>
                                                         <label for='v27'>
                                                            % de emergencia
                                                         </label>
                                                         <input
                                                            ref={register({})}
                                                            onChange={(e) =>
                                                               onChanges(e)
                                                            }
                                                            type='number'
                                                            disabled
                                                            className='form-control'
                                                            id='porc_emergencia'
                                                            name='porc_emergencia'
                                                            value={
                                                               porcentajeEmergencia
                                                            }
                                                            placeholder='00'
                                                            disabled
                                                         />
                                                         <span className='text-danger text-small d-block mb-2'>
                                                            {errors.porc_emergencia && (
                                                               <span>
                                                                  Este campo es
                                                                  requerido
                                                               </span>
                                                            )}
                                                         </span>
                                                      </div>
                                                      <div className='col-sm-10 col-md-6 mb-3'>
                                                         <label for='v28'>
                                                            Observaciones
                                                         </label>
                                                         <textarea
                                                            ref={register({})}
                                                            className='form-control'
                                                            id='observaciones_nido'
                                                            name='observaciones_nido'
                                                            rows='3'
                                                            disabled
                                                         ></textarea>
                                                         <span className='text-danger text-small d-block mb-2'>
                                                            {errors.observaciones_nido && (
                                                               <span>
                                                                  Este campo es
                                                                  requerido
                                                               </span>
                                                            )}
                                                         </span>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                             <div>
                                                <div className='grupo-inputs mb-3'>
                                                   <span className='tit-grupo-inputs'>
                                                      Coordenadas del nido
                                                      relocalizado
                                                   </span>
                                                   <div className='form-row'>
                                                      <div className='col-sm-3 col-md-2 mb-3'>
                                                         <label for='v29'>
                                                            Latitud
                                                         </label>
                                                         <input
                                                            ref={register({})}
                                                            type='text'
                                                            disabled
                                                            className='form-control'
                                                            id='latitud_relo'
                                                            name='latitud_relo'
                                                            placeholder='00.000000'
                                                         />
                                                         <span className='text-danger text-small d-block mb-2'>
                                                            {errors.latitud_relo && (
                                                               <span>
                                                                  Este campo es
                                                                  requerido
                                                               </span>
                                                            )}
                                                         </span>
                                                      </div>
                                                      <div className='col-sm-3 col-md-2 mb-3'>
                                                         <label for='v30'>
                                                            Longitud
                                                         </label>
                                                         <input
                                                            ref={register({})}
                                                            type='text'
                                                            disabled
                                                            className='form-control'
                                                            id='longitud_relo'
                                                            name='longitud_relo'
                                                            placeholder='00.000000'
                                                         />
                                                         <span className='text-danger text-small d-block mb-2'>
                                                            {errors.longitud_relo && (
                                                               <span>
                                                                  Este campo es
                                                                  requerido
                                                               </span>
                                                            )}
                                                         </span>
                                                      </div>
                                                      <div className='col-sm-3 col-md-2 mb-3'>
                                                         <label for='v31'>
                                                            Prof. original en cm
                                                         </label>
                                                         <input
                                                            ref={register({})}
                                                            type='number'
                                                            disabled
                                                            className='form-control'
                                                            id='profundidad_original'
                                                            name='profundidad_original'
                                                            placeholder='00'
                                                         />
                                                         <span className='text-danger text-small d-block mb-2'>
                                                            {errors.profundidad_original && (
                                                               <span>
                                                                  Este campo es
                                                                  requerido
                                                               </span>
                                                            )}
                                                         </span>
                                                      </div>
                                                      <div className='col-sm-3 col-md-3 mb-3'>
                                                         <label for='v32'>
                                                            Prof. relocalización
                                                            en cm
                                                         </label>
                                                         <input
                                                            ref={register({})}
                                                            type='number'
                                                            disabled
                                                            className='form-control'
                                                            id='profundidad_rlc'
                                                            name='profundidad_rlc'
                                                            placeholder='00'
                                                         />
                                                         <span className='text-danger text-small d-block mb-2'>
                                                            {errors.profundidad_rlc && (
                                                               <span>
                                                                  Este campo es
                                                                  requerido
                                                               </span>
                                                            )}
                                                         </span>
                                                      </div>
                                                      <div className='col-sm-3 col-md-3 mb-3'>
                                                         <label for='v33'>
                                                            Hora del siembra
                                                         </label>
                                                         <input
                                                            defaultValue={
                                                               view_monitoreo.fecha_final_mtortuga
                                                            }
                                                            ref={register({})}
                                                            type='text'
                                                            disabled
                                                            className='form-control'
                                                            id='hora_siembra'
                                                            name='hora_siembra'
                                                            placeholder='00:00'
                                                         />
                                                         <span className='text-danger text-small d-block mb-2'>
                                                            {errors.hora_siembra && (
                                                               <span>
                                                                  Este campo es
                                                                  requerido
                                                               </span>
                                                            )}
                                                         </span>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </>
                                       )}
                                    </div>
                                 </form>
                              </div>
                              <p>&nbsp;</p>
                           </div>
                        </div>
                        <p>&nbsp;</p>
                     </div>
                  </section>
                  <Footer />
               </div>
            </div>
         )}
      </div>
   );
}
let mapState = (state) => {
   return {
      view_monitoreo: state.monitoreos.view_monitoreo,
   };
};

const mapDispatch = (dispatch) => {
   return {};
};
export default connect(mapState, mapDispatch)(CurarDetalleMuestras);
