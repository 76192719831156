import React, { useEffect } from "react";
import Nav from "components/base/navs/NavHome";
import Header from "components/base/headers/Header";
import Footer from "components/base/Footer";
import { connect } from "react-redux";
import { getUserAllAction, updateUserAction } from "redux/userDuck";
import { URL_APP } from "Routes";
import { TXT_APP } from "Routes";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import { useToasts } from "react-toast-notifications";
import MenuRaiz from "components/base/navs/NavRaiz";

function getEntidad(id, array_instituciones) {
   return array_instituciones
      .filter((ent) => ent.codigo_in === "INVEMAR")
      .map((j) => j.nombre_in);
}

function CurarUsuarios({
   error_user,
   getUserAllAction,
   access,
   fetching_user_all,
   arrary_user_all,
   array_instituciones,
   updateUserAction,
   isUpdate,
}) {
   let url = URL_APP();
   let txt = TXT_APP();
   const { addToast } = useToasts();

   useEffect(() => {
      if (isUpdate) {
         updateUserAction([]);
      }
   }, [isUpdate]);

   useEffect(() => {
      getUserAllAction(access);
   }, []);

   useEffect(() => {
      if (error_user.length > 0)
         addToast(error_user, {
            appearance: "error",
            autoDismissTimeout: 3000,
            autoDismiss: true,
         });
   }, [error_user]);

   return (
      <div>
         <div className='content-principal  content-internas'>
            <div className='temporal-cerar'></div>
            <header className=' internas-h '>
               <Nav />
               <Header titulo={txt.tituloPanelCurador} />
            </header>
            <section class='menubar'>
               <MenuRaiz />
            </section>

            <section class='contents-interna sig interna-componente'>
               <div class='container-xl my-3'>
                  <div class='row'>
                     <div class='col-md-12'>
                        {/* <button onClick={efe}>buscar </button> */}
                        <ul class='nav nav-tabs' id='myTab' role='tablist'>
                           <li class='nav-item' role='presentation'>
                              <a
                                 class='nav-link active'
                                 id='home-tab'
                                 data-toggle='tab'
                                 href='#monitoreos'
                                 role='tab'
                                 aria-controls='monitoreos'
                                 aria-selected='true'
                              >
                                 Registros de Usuarios
                              </a>
                           </li>
                        </ul>
                        {fetching_user_all ? (
                           <div
                              style={{
                                 width: "100%",
                                 height: "100",
                                 display: "flex",
                                 justifyContent: "center",
                                 alignItems: "center",
                              }}
                           >
                              <Loader
                                 type='ThreeDots'
                                 color='#11273c'
                                 height='100'
                                 width='100'
                              />
                           </div>
                        ) : (
                           // <div class="tab-content" id="myTabContent">
                           //    <p>&nbsp;</p>
                           //    <h6>Buscando....</h6>
                           //    <p>&nbsp;</p>
                           // </div>
                           <div class='tab-content' id='myTabContent'>
                              <div
                                 class='tab-pane fade show active'
                                 id='monitoreos'
                                 role='tabpanel'
                                 aria-labelledby='monitoreos-tab'
                              >
                                 <p>&nbsp;</p>
                                 <h6>Usuarios Registrados</h6>
                                 <p>&nbsp;</p>

                                 {arrary_user_all.map((user) => (
                                    <div class='row registro-monitoreo mx-1 d-flex align-items-center'>
                                       <div class='col-6 col-sm-2 col-md-1'>
                                          <b>Username</b>
                                          <br />
                                          {user.nombre}
                                       </div>
                                       <div class='col-6 col-sm-2 col-md-1'>
                                          <b>Apellido</b>
                                          <br />
                                          {user.apellido}
                                       </div>

                                       <div class='col-12 col-sm-2 col-md-3'>
                                          <b>Institución</b>
                                          <br />
                                          {user.detalles.map((detalle) => (
                                             <p>
                                                {detalle.atributo ===
                                                "Vinculado a" ? (
                                                   <p>
                                                      {getEntidad(
                                                         detalle.valor,
                                                         array_instituciones
                                                      )}
                                                   </p>
                                                ) : (
                                                   []
                                                )}
                                             </p>
                                          ))}
                                       </div>
                                       <div class='col-12 col-sm-2 col-md-3'>
                                          <b>Correo</b>
                                          <br />
                                          {user.username_email}
                                       </div>
                                       <div class='col-6 col-sm-2 col-md-2'>
                                          {user.activo === "S" ? (
                                             <span class='mr-4 btn btn btn-success btn-w-110 f-14'>
                                                Activo
                                             </span>
                                          ) : (
                                             <span class='mr-4 btn btn-danger btn-w-110 f-14'>
                                                Desactivado
                                             </span>
                                          )}
                                       </div>
                                       <div class='col-6 col-sm-2 col-md-2 my-3'>
                                          <Link
                                             className='mr-4 btn btn-primary btn-w-110 f-14'
                                             onClick={() =>
                                                updateUserAction(user)
                                             }
                                             exact
                                             to='/curador/curar-detalle-usuarios'
                                          >
                                             Ver
                                          </Link>
                                       </div>
                                    </div>
                                 ))}
                              </div>
                           </div>
                        )}
                     </div>
                  </div>
                  <p>&nbsp;</p>
               </div>
            </section>
            <Footer />
         </div>
      </div>
   );
}
// sacar los state de (store) que tiene redux y los coloque en las props de este componente.
let mapState = (state) => {
   // const history = useHistory();
   //    console.log("estate:", state.referentes.arrary_instituciones);

   // if (state.user.user.access)
   return {
      fetching_user_all: state.user.fetching_user_all,
      error_user: state.user.error,
      access: state.user.user.access,
      isUpdate: state.user.isUpdate,
      arrary_user_all: state.user.arrary_user_all,
      array_instituciones: state.referentes.arrary_instituciones,
   };
   // else history.push("/");
};
// sacar los dispatch de (store) que tiene redux y los coloque en las props de este componente.
const mapDispatch = (dispatch) => {
   return {
      getUserAllAction: (props) => dispatch(getUserAllAction(props)),
      updateUserAction: (props) => dispatch(updateUserAction(props)),
   };
};

// connect(mapState,mapDispatch)(this funtion)
export default connect(mapState, mapDispatch)(CurarUsuarios);
