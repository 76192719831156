import React, { useState } from "react";
import { useHistory, NavLink } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { connect } from "react-redux";
import { resetMonitoreosAction } from "redux/monitoreoDuck";
import { resetReferentesAction } from "redux/referentesDuck";
import { logOutAction } from "redux/userDuck";
import ModalLogin from "components/base/modales/ModalLogin";
import MenuCurador from "./NavCurador";
import MenuInvestigador from "./NavInvestigador";
import MenuHome from "./NavPages";

function NavRaiz({
   loggedIn,
   logOutAction,
   resetMonitoreosAction,
   resetReferentesAction,
   roles,
}) {
   const [modal, setModal] = useState(false);
   const history = useHistory();

   //cerrar sesion
   const logOut = (e) => {
      e.preventDefault();
      //inicializar todos los Duck
      resetReferentesAction();
      resetMonitoreosAction();
      logOutAction();
      history.push("/avistamiento");
   };

   const hide = () => {
      setModal(false);
   };

   let show = () => {
      setModal(true);
   };

   return (
      <Nav className='navbar navbar-expand-lg navbar-light bg-light'>
         <div className='collapse navbar-collapse' id='navbarSupportedContent'>
            <ul className='navbar-nav invemar mr-auto'>
               {loggedIn ? (
                  (roles[0].id === 216 && <MenuCurador />) ||
                  (roles[0].id === 219 && <MenuInvestigador />)
               ) : (
                  <MenuHome />
               )}
            </ul>
            <ul className='navbar-nav invemar'>
               <li className='nav-item dropdown'>
                  <a
                     className='nav-link dropdown-toggle'
                     href='javascript:void(0)'
                     id='navbarDropdown'
                     role='button'
                     data-toggle='dropdown'
                     aria-haspopup='true'
                     aria-expanded='false'
                  >
                     <svg
                        width='2em'
                        height='2em'
                        viewBox='0 0 16 16'
                        className='bi bi-person-circle'
                        fill='currentColor'
                        xmlns='http://www.w3.org/2000/svg'
                     >
                        <path d='M13.468 12.37C12.758 11.226 11.195 10 8 10s-4.757 1.225-5.468 2.37A6.987 6.987 0 0 0 8 15a6.987 6.987 0 0 0 5.468-2.63z' />
                        <path
                           //   fill-rule="evenodd"
                           d='M8 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6z'
                        />
                        <path
                           //   fill-rule="evenodd"
                           d='M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zM0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8z'
                        />
                     </svg>
                  </a>
                  <div
                     className='dropdown-menu'
                     aria-labelledby='navbarDropdown'
                  >
                     {loggedIn ? (
                        <div>
                           <NavLink
                              type='button'
                              onClick={logOut}
                              className='dropdown-item'
                              activeclassname='active'
                              exact
                              to='/login'
                           >
                              Cerrar Sesion
                           </NavLink>
                        </div>
                     ) : (
                        <>
                           <button
                              className='dropdown-item'
                              onClick={() => show()}
                           >
                              {" "}
                              Iniciar Sesion
                           </button>
                           <NavLink
                              type='button'
                              className='dropdown-item'
                              activeclassname='active'
                              exact
                              to='/registrar'
                           >
                              Registrarse
                           </NavLink>
                        </>
                     )}
                  </div>
               </li>
            </ul>
            {modal && <ModalLogin show={modal} hide={hide} />}
         </div>
      </Nav>
   );
}
let mapState = (state) => {
   if (state && state.user.loggedIn) {
      return {
         loggedIn: state.user.loggedIn,
         roles: state.user.user.userInfo.roles,
      };
   }
   return {
      loggedIn: state.user.loggedIn,
   };
};

let mapDispatch = (dispatch) => {
   return {
      logOutAction: () => dispatch(logOutAction()),
      resetReferentesAction: () => dispatch(resetReferentesAction()),
      resetMonitoreosAction: (props) => dispatch(resetMonitoreosAction(props)),
   };
};
export default connect(mapState, mapDispatch)(NavRaiz);
