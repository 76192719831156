import React from "react";
import { NavLink, Link } from "react-router-dom";
import { URL_APP } from "Routes";

export default function MenuHome() {
   let url = URL_APP();

   return (
      <nav class='navbar navbar-expand-lg navbar-light bg-light'>
         <NavLink className='navbar-brand' to={url.home}>
            INICIO
         </NavLink>
         <button
            className='navbar-toggler'
            type='button'
            data-toggle='collapse'
            data-target='#navbarSupportedContent'
            aria-controls='navbarSupportedContent'
            aria-expanded='false'
            aria-label='Toggle navigation'
         >
            <span className='navbar-toggler-icon'></span>
         </button>
         <div class='collapse navbar-collapse' id='navbarSupportedContent'>
            <ul class='navbar-nav invemar mr-auto'>
               <li className='nav-item'>
                  <NavLink
                     activeStyle={{
                        fontWeight: "bold",
                        color: "#3338FF",
                     }}
                     className='nav-link'
                     to={url.page_avistamiento}
                  >
                     Registros biologicos
                  </NavLink>
               </li>

               <li class='nav-item'>
                  <NavLink
                     activeStyle={{
                        fontWeight: "bold",
                        color: "#3338FF",
                     }}
                     className='nav-link'
                     to={url.visor}
                  >
                     Registros disponibles
                  </NavLink>
               </li>
            </ul>
         </div>
      </nav>
   );
}
