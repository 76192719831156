import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Nav from "components/base/navs/NavHome";
import Header from "components/base/headers/Header";
import Footer from "components/base/Footer";
import MenuHome from "components/base/navs/NavRaiz";
import { TXT_APP } from "Routes";
import ModalLogin from "components/base/modales/ModalLogin";
import resetObservacionesAction from "redux/naturalistaDuck";
import Modal from "react-bootstrap/Modal";
import { Button, Form } from "react-bootstrap";

function AvistamientosPage({ resetObservacionesAction, loggedIn }) {
   let txt = TXT_APP();
   //para llamar el modal del login
   const [modal, setModal] = useState(false);
   const [showModalInfo, setShowModalInfo] = useState(false);

   // let onLogin = () => {
   //    if (!loggedIn) {
   //       setModal(true);
   //    } else setShowModalInfo(true);
   // };

   const hide = () => {
      setModal(false);
   };

   let show = () => {
      setModal(true);
   };

   let ocultarModalInfo = () => {
      setShowModalInfo(false);
   };

   let irPanelAdmin = () => {
      // console.log(roles.filter((rol) => rol.id === 216));
      // alert("ir al panel de administracio");
      ocultarModalInfo();
   };

   useEffect(() => {
      // resetObservacionesAction();
   }, []);

   return (
      <div>
         <div className='content-principal  content-internas'>
            <div className='temporal-cerar'></div>
            <header className=' internas-h '>
               <Nav />
               <Header titulo="Registros Biologicos" />
            </header>
            <section class='menubar'>
               <MenuHome />
            </section>
            <section class='contents-interna sig interna-componente'>
               <div class='container-xl my-3'>
                  <div class='row'>
                     <div class='col-md-12 mb-3'>
                        <p>&nbsp;</p>
                     </div>
                     <div class='col-md-6'>
                        <h3>Monitoreo</h3>
                        <p class='text-justify'>
                           La academia, instituciones gubernamentales, ONGs y
                           grupos de conservación, siguiendo metodologías
                           establecidas realizan un proceso sistemático de
                           recolectar y analizar información de las actividades
                           de anidación de las tortugas marinas, donde se
                           registra la salida de las hembras a la playa para la
                           postura de huevos, registro y marcaje de los
                           individuos, seguimiento, manejo y protección de nidos
                           y neonatos, esto con el objetivo de poder
                           determinarla la dinámica poblacional de las tortugas
                           marinas en Colombia.
                           <a
                              href='javascript:void(0)'
                              onClick={show}
                              class='text-primary'
                           >
                              Ir al sistema de monitoreo
                           </a>
                        </p>
                        <p>&nbsp;</p>
                        <h3>Avistamiento</h3>
                        <p class='text-justify'>
                           Espacio para que la ciudadanía (buzos, turistas,
                           residentes, comunidad en general) aporte al
                           conocimiento de las tortugas marinas, documentando
                           avistamientos de las diferentes especies en el país;
                           esta es información valiosa para la toma de
                           decisiones en cuanto a políticas de protección del
                           grupo y sus hábitats en el territorio nacional.
                           <a
                              href='https://colombia.inaturalist.org/projects/tortugas-marinas-en-colombia'
                              target='_blank'
                              class='text-primary'
                           >
                              Haz tu registro!
                           </a>
                        </p>
                        <p>&nbsp;</p>
                     </div>
                     <div class='col-md-6'>
                        <img
                           class='img-fluid'
                           src={ process.env.PUBLIC_URL +'/static/images/componente-t-t/Observaciones_mapa@2x.jpg'}
                           alt=''
                        />
                     </div>
                  </div>
               </div>
            </section>
            {<ModalLogin show={modal} hide={hide} />}
            <Footer />
         </div>
         <Modal
            show={showModalInfo}
            class='modal fade'
            tabindex='-1'
            aria-labelledby='exampleModalLabel'
            aria-hidden='true'
         >
            <Modal.Header>
               <Modal.Title style={{ color: "#30345b" }}>
                  Acceso a los módulos del componente Tortugas Marinas
               </Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <p>Ya tiene sesión iniciada ¿ Desea ir al panel de control ?</p>
            </Modal.Body>
            <Modal.Footer>
               <Button variant='secondary' onClick={ocultarModalInfo}>
                  Cancelar
               </Button>
               <Button variant='primary' onClick={() => irPanelAdmin()}>
                  Aceptar
               </Button>
            </Modal.Footer>
         </Modal>
      </div>
   );
}
let mapState = (state) => {
   return {
      loggedIn: state.user.loggedIn,
   };
};

const mapDispatch = (dispatch) => {
   return {
      resetObservacionesAction: (props) =>
         dispatch(resetObservacionesAction(props)),
   };
};
export default connect(mapState, mapDispatch)(AvistamientosPage);
