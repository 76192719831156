import React from "react";
import Nav from "components/base/navs/NavHome";
import Footer from "components/base/Footer";
import { URL_APP } from "Routes"
import {Link} from "react-router-dom"

export default function TCaguama() {
  let url = URL_APP();
  return (
    <div>
      <div className="content-principal  content-internas">
        
        <header className=" internas-h ">
          <Nav />
          <section class="titseccion bg-bluelight">
                <div class="container-xl h-100 d-flex align-items-center" style={{flexWrap: "wrap"}}>

                    <div class="col-12 col-sm-6 col-md-6">
                        <h4><i>Caretta caretta</i> - Tortuga caguama <span class="add-n-especie">(Linnaeus,1758)</span>
                        </h4>
                    </div>
                    {/* <!--div class="col-12 col-sm-6 col-md-6 d-flex justify-content-end">
                    <a href="https://minambiente.gov.co"><img src={ process.env.PUBLIC_URL +"http://www.invemar.org.co/Invemar3.0-theme/images/img/logos/min-ambiente-gov.jpg" width="240px" alt=""></a>
                  </div--> */}

                </div>
            </section>
        </header>
      
        <div
          class="content-principal  content-internas"
          style={{background: "#ffffff"}}
        >
          
            <section className="contents-interna content-blank interna-componente">
              <nav aria-label="breadcrumb px-0">
                <ol className="breadcrumb px-0">
                  <li className="breadcrumb-item">
                    <Link to={url.home}>Tortugas Marinas en Colombia</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Tortuga caguama
                  </li>
                </ol>
              </nav>
              <div className="container-xl mb-3">
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <div className="row mb-3">
                      <div className="col-md-5">
                        <div className="con_arbolfichaesp">
                          <div className="cont_listarbol">
                            <ul className="listaarbol_ficha">
                              <li
                                className="ini-arbol"
                               style={{fontStyle: "italic"}}
                              >
                                <span>REINO:</span> ANIMALIA
                                <ul>
                                  <li>
                                    <span>PHYLUM:</span> CHORDATA
                                    <ul>
                                      <li>
                                        <span>CLASE:</span> REPTILIA
                                        <ul>
                                          <li>
                                            <span>ORDEN:</span> TESTUDINES
                                            <ul>
                                              <li>
                                                <span>FAMILIA:</span>{" "}
                                                CHELONIIDAE
                                                <ul>
                                                  <li>
                                                    <span>GÉNERO:</span> CARETTA
                                                  </li>
                                                </ul>
                                              </li>
                                            </ul>
                                          </li>
                                        </ul>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <h6>Sinonimias</h6>
                        <p>
                          <i>
                            Halichelys atra, Testudo caretta, Thalassochelys
                            caretta, Thalassochelys corticata{" "}
                          </i>
                        </p>
                      </div>
                      <div className="col-md-4 mb-3">
                        <div
                          id="carouselExampleControls"
                          className="carousel slide"
                          data-ride="carousel"
                        >
                          <div className="carousel-inner">
                            <div className="carousel-item active">
                              <img
                                src={ process.env.PUBLIC_URL +"/static/images/componente-t-t/tcaguama-f1.jpg"}
                                className="d-block w-100"
                                alt="..."
                              />
                            </div>
                            <div className="carousel-item">
                              <img
                                src={ process.env.PUBLIC_URL +"/static/images/componente-t-t/tcaguama-f2.jpg"}
                                className="d-block w-100"
                                alt="..."
                              />
                            </div>
                            <div className="carousel-item">
                              <img
                                src={ process.env.PUBLIC_URL +"/static/images/componente-t-t/tcaguama-f3.jpg"}
                                className="d-block w-100"
                                alt="..."
                              />
                            </div>
                          </div>
                          <a
                            className="carousel-control-prev"
                            href="#carouselExampleControls"
                            role="button"
                            data-slide="prev"
                          >
                            <span
                              className="carousel-control-prev-icon"
                              aria-hidden="true"
                            ></span>
                            <span className="sr-only">Previous</span>
                          </a>
                          <a
                            className="carousel-control-next"
                            href="#carouselExampleControls"
                            role="button"
                            data-slide="next"
                          >
                            <span
                              className="carousel-control-next-icon"
                              aria-hidden="true"
                            ></span>
                            <span className="sr-only">Next</span>
                          </a>
                        </div>
                      </div>
                      <div className="col-md-3 mb-3">
                        <h6>Especies de tortugas marinas en Colombia</h6>
                        <div className="btn-group-vertical f-especie">
                          <a href="ficha-tortuga-carey" className="btn btn-light">
                            {" "}
                            <i>Eretmochelys imbricata</i>
                          </a>
                          <a href="ficha-tortuga-verde" className="btn btn-light">
                            {" "}
                            <i>Chelonia mydas</i>
                          </a>
                          <a href="javascript:void(0)" className="btn btn-light active">
                            {" "}
                            <i>Caretta caretta</i>
                          </a>
                          <a href="ficha-tortuga-laud" className="btn btn-light">
                            {" "}
                            <i>Dermochelys coriacea </i>
                          </a>
                          <a href="ficha-tortuga-golfina" className="btn btn-light">
                            {" "}
                            <i>Lepidochelys olivacea</i>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12" style={{textAlign: "justify"}}>
                        <h6>Nombres comunes</h6>
                        <p>
                          <i>
                            Boba, Tortuga parape, Tortuga carey, Tortuga de
                            carey, Tortuga fina, Hawksbill
                          </i>
                        </p>
                        <h6>Categoría de amenaza </h6>
                        <p>
                          Global: Vulnerable (VU) A2b <br /> Nacional: En
                          peligro crítico (CR) A2cd; D
                        </p>
                        <hr />

                        <div
                          className="row d-flex align-items-center"
                          style={{flexWrap: "wrap"}}
                        >
                          <div className="col-md-6">
                            <h6>Descripción</h6>
                            <p>
                              Es una tortuga grande, con longitud recta de
                              caparazón (LRC) entre 80 a 124 cm, las hembras
                              anidantes tienen entre 80-105 cm LRC. Es de cabeza
                              ancha (hasta 28 cm), muy grande y triangular, lo
                              que permite diferenciarla rápidamente de las demás
                              tortugas marinas; con mandíbulas fuertes, dos
                              pares de escamas prefrontales y comúnmente una
                              interprefrontal. Las aletas anteriores más
                              pequeñas en comparación con las otras especies,
                              gruesas y con dos uñas visibles en cada una
                              (Pritchard y Mortimer, 2000). Aletas posteriores
                              con dos o tres uñas. Caparazón en forma de corazón
                              (cordiforme), con cinco escudos laterales a cada
                              lado, siendo el primer par más pequeño y estando
                              en contacto con la escama nucal (Márquez, 1990).
                              Neonatos y juveniles con espinas cortas y romas
                              sobre los escudos del caparazón, las cuales forman
                              tres hileras longitudinales que van desapareciendo
                              a medida que el animal crece. Plastrón con tres
                              escudos inframarginales a cada lado, sin poros.
                              Cabeza de color café-rojizo, caparazón rojizo o
                              marrón y plastrón amarillo-marrón. Los neonatos
                              son marrón oscuro dorsalmente, pero más claros en
                              las aletas y el plastrón.
                            </p>
                          </div>
                          <div className="col-md-6 text-center ">
                            <img
                              className="img-fluid"
                              src={ process.env.PUBLIC_URL +"/static/images/componente-t-t/tcaguama-cd.jpg"}
                              alt=""
                            />
                            <cite>
                              Tomada de Ecker <i>et al</i>., 2000. Técnicas de
                              Investigación y Manejo para la Conservación de las
                              Tortugas Marinas
                            </cite>
                          </div>
                        </div>
                        <hr />
                        <h6>Distribución y hábitat</h6>
                        <div className="row mt-3">
                          <div
                            className="col-md-12 d-flex justify-content-center"
                            style={{flexWrap: "wrap"}}
                          >
                            <img
                              className="w-75"
                              src={ process.env.PUBLIC_URL +"/static/images/componente-t-t/tcaguama-md.jpg"}
                              alt=""
                            />
                            <cite>
                              Mapa de distribución de <i>Caretta caretta</i>.
                              www.fao.org/fishery/en., Acceso 27 Nov. 2020
                            </cite>
                            <p className="mt-3">
                              Distribución circumglobal, habitualmente en aguas
                              templadas y algunas veces en aguas tropicales y
                              subtropicales (NOAA Fisheries, 2006). Las
                              principales playas de anidación están en el
                              sureste de los Estados Unidos, La Florida y
                              Carolina del Sur, y en Yucatán (México). También
                              desova en Belice, Guatemala, Nicaragua, Panamá,
                              Colombia, Venezuela, Puerto Rico, Jamaica, Cuba y
                              Brasil. Se han encontrado en las islas japonesas
                              de Okinawa y Australia. Es la segunda especie más
                              distribuida mundialmente y es una de las más
                              explotadas. Se sabe que los juveniles de caguama,
                              migran desde el Japón hasta áreas de alimentación
                              en el Pacifico mexicano alrededor de bahía
                              Magdalena y la península de Baja California
                              (Amorocho, 2014). En Colombia se tiene reportes en
                              los departamento del Atlántico, Archipiélago de
                              San Andrés, Providencia y Santa Catalina, Bolívar,
                              La Guajira, Magdalena y Sucre. Según
                              Rueda-Almonacid (2011), hay registros anecdóticos
                              de C. caretta en la Isla Gorgona y Bahía Solano en
                              el Pacífico colombiano, sin embargo, éstos no han
                              sido corroborados (Amorocho com. pers.).
                            </p>
                          </div>
                        </div>
                        <hr />
                        <h6>Ciclo de vida</h6>
                        <p>
                          La especie alcanza la madurez sexual entre los 12 y 35
                          años; no anidan todos los años, sino que se reproducen
                          cada dos a cuatro años (Erhart <i>et al</i>. 2003), y
                          pueden reanidar de dos hasta cinco veces por
                          temporada, con un intervalo aproximado de 12-17 días
                          entre posturas. En el Caribe colombiano la estación o
                          temporada de anidación se extiende desde abril hasta
                          agosto, con un pico de anidación en junio. Las
                          posturas fluctúan entre 80-120 huevos (promedio 110
                          huevos). El tamaño de los huevos varía entre 34,7-55,2
                          mm de diámetro, y el de los neonatos entre 33,5-55 mm
                          de LRC (Márquez, 1990). La especie presenta
                          determinación sexual dependiente de la temperatura
                          (DST) con un patrón que se caracteriza por obtener una
                          mayor proporción de machos, en temperaturas menores a
                          la pivotal, patrón que siguen las tortugas marinas;
                          pero no se ha evaluado la temperatura pivotal promedio
                          para las colonias colombianas. A nivel global tiene un
                          intervalo de 28,2 a 29,7 ºC para la temperatura
                          pivotal. El cortejo y apareamiento ocurre en las rutas
                          de migración entre los hábitats de forrajeo y
                          anidación, no cerca a las playas de desove, como suele
                          ocurrir en otras especies. Al concluir la estación
                          reproductiva las hembras migran a zonas de forrajeo
                          distantes. Al eclosionar los neonatos migran hasta
                          altamar, en donde permanecen entre 1-7 años en las
                          masas flotantes de Sargassum, alimentándose de la
                          fauna que vive allí. La dieta de juveniles, subadultos
                          y adultos es principalmente carnívora, incluyendo
                          fauna bentónica como cangrejos, moluscos, camarones,
                          estrellas de mar y otros invertebrados marinos, como
                          medusas, y esporádicamente de algas y plantas
                          vasculares.
                        </p>
                        <hr />
                        <h6>Estado de Conservación</h6>
                        <p>
                          Kaufmann (1973) estimó colonias de anidación mayores a
                          400 hembras/año en la antigua Reserva de Buritaca
                          (entre las desembocaduras de los ríos Buritaca y Don
                          Diego), pero este índice proviene de una extrapolación
                          de avistamientos esporádicos a una escala
                          espacio-temporal reducida (Amorocho com. pers.). Tufts
                          (1972) documentó que en las playas de Buritaca- Don
                          Diego y Quintana en el departamento de Magdalena,
                          arribaban hasta 600 hembras/año. Sin embargo, Amorocho
                          (2003) considera que esta cifra debió ser cercana a
                          las 200 hembras/temporada y que hoy en día el número
                          de hembras anidantes no supera los seis individuos por
                          año. Según Amaya-Espinel y Zapata (2014) esta especie
                          está prácticamente extinta en Colombia, y las pocas
                          hembras que aún anidan lo hacen únicamente entre el
                          río Palomino (La Guajira) y el río Piedras
                          (Magdalena). Sin embargo, Borrero-Avellaneda{" "}
                          <i>et al</i>. (2015) reportan que hay anidaciones en
                          bahía Portete, bahía Hondita y Punta Gallinas, La
                          Guajira.
                        </p>
                        <hr />
                        <h6>Medidas de Conservación</h6>
                        <p>
                          En el ámbito nacional está protegida por medidas a
                          nivel general como el Decreto 1681 de 1978 del
                          Inderena, Acuerdo 021 de 1991 del Inderena, Resolución
                          2879 de 1995 de Corpoguajira, Resolución 1644 de 1998
                          de Corpamag y Artículo 328 del Código Penal, entre
                          otras; y a nivel internacional, se encuentra en el
                          Apéndice I de CITES, en el Apéndice I y II de la
                          Convención de Bonn y en el Anexo II del Protocolo
                          SPAW, lo cual genera acciones de cooperación para la
                          conservación de la especie.
                        </p>
                        <hr />
                        <h6>Ficha SiAM</h6>
                        <a href="https://siam.invemar.org.co/sibm-historia-natural-especies-ficha/70.55.3562">
                          https://siam.invemar.org.co/sibm-historia-natural-especies-ficha/70.55.3562
                        </a>
                        <h6>Ficha SIB</h6>
                        <a href="https://catalogo.biodiversidad.co/file/56d8fb313c16479905cba980/summary">
                          https://catalogo.biodiversidad.co/file/56d8fb313c16479905cba980/summary
                        </a>
                        <hr />
                        <h6>Referencias Generales</h6>
                        <p>
                          *Amorocho, D. 2014. <i>Caretta caretta</i>. 253-255.
                          En: Amaya-Espinel, J. y Zapata, L. (Eds). Guía de las
                          especies migratorias de la biodiversidad en Colombia.
                          Insectos, murciélagos, tortugas marinas, mamíferos
                          marinos y dulceacuícolas. Vol. 3. Ministerio de
                          Ambiente y Desarrollo Sostenible / WWF-Colombia.
                          Bogotá, D.C. Colombia. 370 p. *Páez, V., Ramírez, C. y
                          Barrientos-Muñoz, K.. 2015. <i>Caretta caretta</i>.
                          118-121. En: Morales-Betancourt, M., Lasso, C., Páez,
                          V. y Bock, B. Libro rojo de reptiles de Colombia.
                          Instituto de Investigación de Recursos Biológicos
                          Alexander von Humboldt (IAvH), Universidad de
                          Antioquia. Bogotá, D.C. Colombia. 258 p. *Casale, P. y
                          Tucker, A. 2017. <i>Caretta caretta</i> (amended
                          version of 2015 assessment). The IUCN Red List of
                          Threatened Species 2017:
                          e.T3897A119333622. https://dx.doi.org/10.2305/IUCN.UK.2017-2.RLTS.T3897A119333622.en.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          
          <Footer />
        </div>
      </div>
    </div>
  );
}
