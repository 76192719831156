import React, { useState,useEffect} from "react";
import Modal from "react-bootstrap/Modal";
import { Button} from "react-bootstrap";
import {deleteMonitoreoAction } from "redux/monitoreoDuck";
import { connect } from "react-redux";
import Progress from "components/base/Progress";


function ListaMuestras({
   deleteMuestra,
   muestra,
   onEdicion,
   isCurar,
   deleteMonitoreoAction,
   error_delete,
   fetching_monitoreo_delete,
   msg_delete,
   is_monitoreo_delete,
   access,
}) {
   
   const [mensajeEstado, setMensajeEstado] = useState(false);
   const [show, setShow] = useState(false);
   const [showDelete, setShowDelete] = useState(false);
   const [muestraDelete, setMuestraDelete] = useState(0);

   useEffect(() => {  
      if(is_monitoreo_delete){
         handleCloseDelete()

      }   
  }, [is_monitoreo_delete]);

     
   // Modal msg
   const handleShow = () => {
      setShow(true);
   }; 
  const handleClose = () => {
      setShow(false);
   };
   // Modal eliminar
   const handleShowDelete = (muestra) => {      
      setShowDelete(true);
      setMuestraDelete(muestra)
   }; 
  const handleCloseDelete = () => {
      setShowDelete(false);
   };

   let mensajeNota =(msg)=>{
      setMensajeEstado(msg) 
      handleShow()

   }
   
  
    return (
        <div className="row registro-monitoreo">
         <div className="col-6 col-sm-2 col-md-2">
         <b>Id Muestra</b>
         <br />
         {muestra.id_muestratex}
         </div>  
         <div className="col-6 col-sm-2 col-md-2">
            <b> consecutivo</b>
            <br />
            {muestra.id_consecutivo}
         </div> 
         <div className="col-6 col-sm-2 col-md-2">
            <b>Fecha</b>
            <br />
            {muestra.fecha_inicial_mtortuga}
         </div>          
         <div className="col-6 col-sm-2 col-md-2">
            <b>Especie</b>
            <br />
            {muestra.id_especie_des}
         </div>
         <div class="col-6 col-sm-2 col-md-2">

            {!isCurar&&(
               muestra.notas === "S" ? (
                  <span onClick={()=>mensajeNota("Evento del monitoreo realizado correctamente")} class="mr-5 btn btn btn btn-success btn-w-110 f-14">
                  Completo
                  </span>
               ) : (
                  <span onClick={()=>mensajeNota(muestra.notas)} class="mr-5 btn btn-danger btn-w-110 f-14">
                  Incompleto
                  </span>
               )

            )}                                
            </div>  
         <div className="col d-flex align-items-center crudicons">
               {/* {isCurar?( */}
                  {isCurar ? 
                  (
                     <button onClick={() => onEdicion(muestra)} class="btn"><i class="fa f-20 fa-eye"></i></button>

                  ):
                  (
                     <>
                        <a onClick={() => handleShowDelete(muestra.id_muestratex)} class="mr-4" href="javascript:void(0)"><i class="fa f-20 fa-trash-o"></i></a>
                        <a class="mr-4" href="javascript:void(0)"><i class="fa f-20 fa-edit"></i></a>
                        {/* <a href="javascript:void(0)"><i class="fa f-20 fa-eye"></i></a> */}
                     </>
                  )}
         </div>
         
            {/* Modal estado */}
            <Modal show={show}>
            <Modal.Header style={{ color: "#30345b" }}>
                  <b>Estado del monitoreo</b>
            </Modal.Header>
            <Modal.Body>          
                     <p>{mensajeEstado}</p>      
            </Modal.Body>
            <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                     Aceptar
                  </Button>                    
                  
            </Modal.Footer>
            </Modal>

            {/* Modal eliminar */}
            <Modal show={showDelete}>
            <Modal.Header style={{ color: "#30345b" }}>
                  <b>Confirmar eliminacion de muestra</b>
            </Modal.Header>
            <Modal.Body> 
               {fetching_monitoreo_delete?(
                  <Progress />
               ):(<>
                  <p>Id Muestra : {muestraDelete.id_muestra}</p>         
                  <p>¿Desea eliminar este registro?</p> 
                  </>  
               )}              
            </Modal.Body>
            <Modal.Footer>
               <Button variant="secondary" onClick={deleteMuestra}>
                     Aceptar
               </Button>      
            </Modal.Footer>
            </Modal>
         </div>
    )
}
let mapState = (state) => {
     return { 
         access: state.user.user.access,
         error_delete:state.monitoreos.error_delete,
         fetching_monitoreo_delete:state.monitoreos.fetching_monitoreo_delete,
         msg_delete:state.monitoreos.msg_delete,
         is_monitoreo_delete:state.monitoreos.is_monitoreo_delete
      };    
   };
const mapDispatch = (dispatch) => {
   return {
      deleteMonitoreoAction: (props) => dispatch(deleteMonitoreoAction(props)),
   };
};
// connect(mapState,mapDispatch)(this funtion)
export default connect(mapState, mapDispatch)(ListaMuestras);