import React from "react";
import Nav from "components/base/navs/NavHome";
import Footer from "components/base/Footer";
import { URL_APP } from "Routes"
import {Link} from "react-router-dom"


export default function TCarey() {
  let url = URL_APP();
  return (
    <div>
      <div className="content-principal  content-internas">
        <div className="temporal-cerar"></div>
        <header className=" internas-h ">
          <Nav />
          <section class="titseccion bg-bluelight">
                <div class="container-xl h-100 d-flex align-items-center" style={{flexWrap: "wrap"}}>

                    <div class="col-12 col-sm-6 col-md-6">
                        <h4><i>Lepidochelys olivacea</i> - Tortuga golfina <br/><span class="add-n-especie">(Eschscholtz, 1829)</span>
                        </h4>
                    </div>
                    {/* <!--div class="col-12 col-sm-6 col-md-6 d-flex justify-content-end">
                    <a href="https://minambiente.gov.co"><img src={ process.env.PUBLIC_URL +"http://www.invemar.org.co/Invemar3.0-theme/images/img/logos/min-ambiente-gov.jpg" width="240px" alt=""></a>
                  </div--> */}

                </div>
            </section>
        </header>
        <div
          class="content-principal  content-internas"
          style={{background: "#ffffff"}}
        >
            <section class="contents-interna content-blank interna-componente">
              <nav aria-label="breadcrumb px-0">
                <ol class="breadcrumb px-0">
                  <li class="breadcrumb-item">
                    <Link to={url.home}>Tortugas Marinas en Colombia</Link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Tortuga golfina
                  </li>
                </ol>
              </nav>
              <div class="container-xl mb-3">
                <div class="row">
                  <div class="col-md-12 mb-3">
                    <div class="row mb-3">
                      <div class="col-md-5">
                        <div class="con_arbolfichaesp">
                          <div class="cont_listarbol">
                            <ul class="listaarbol_ficha">
                              <li class="ini-arbol"style={{fontStyle: "italic"}}>
                                <span>REINO:</span> ANIMALIA
                                <ul>
                                  <li>
                                    <span>PHYLUM:</span> CHORDATA
                                    <ul>
                                      <li>
                                        <span>CLASE:</span> REPTILIA
                                        <ul>
                                          <li>
                                            <span>ORDEN:</span> TESTUDINES
                                            <ul>
                                              <li>
                                                <span>FAMILIA:</span>{" "}
                                                CHELONIIDAE
                                                <ul>
                                                  <li>
                                                    <span>GÉNERO:</span>{" "}
                                                    LEPIDOCHELYS
                                                  </li>
                                                </ul>
                                              </li>
                                            </ul>
                                          </li>
                                        </ul>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <h6>Sinonimias</h6>
                        <p>
                          <i>Chelonia olivacea </i>
                        </p>
                      </div>
                      <div class="col-md-4 mb-3">
                        <div
                          id="carouselExampleControls"
                          class="carousel slide"
                          data-ride="carousel"
                        >
                          <div class="carousel-inner">
                            <div class="carousel-item active">
                              <img
                                src={ process.env.PUBLIC_URL +"/static/images/componente-t-t/tgolfina-f1.jpg"}
                                class="d-block w-100"
                                alt="..."
                              />
                            </div>
                            <div class="carousel-item">
                              <img
                                src={ process.env.PUBLIC_URL +"/static/images/componente-t-t/tgolfina-f2.jpg"}
                                class="d-block w-100"
                                alt="..."
                              />
                            </div>
                            <div class="carousel-item">
                              <img
                                src={ process.env.PUBLIC_URL +"/static/images/componente-t-t/tgolfina-f3.jpg"}
                                class="d-block w-100"
                                alt="..."
                              />
                            </div>
                          </div>
                          <a
                            class="carousel-control-prev"
                            href="#carouselExampleControls"
                            role="button"
                            data-slide="prev"
                          >
                            <span
                              class="carousel-control-prev-icon"
                              aria-hidden="true"
                            ></span>
                            <span class="sr-only">Previous</span>
                          </a>
                          <a
                            class="carousel-control-next"
                            href="#carouselExampleControls"
                            role="button"
                            data-slide="next"
                          >
                            <span
                              class="carousel-control-next-icon"
                              aria-hidden="true"
                            ></span>
                            <span class="sr-only">Next</span>
                          </a>
                        </div>
                      </div>
                      <div class="col-md-3 mb-3">
                        <h6>Especies de tortugas marinas en Colombia</h6>
                        <div class="btn-group-vertical f-especie">
                          <a href="ficha-tortuga-carey" class="btn btn-light">
                            {" "}
                            <i>Eretmochelys imbricata</i>
                          </a>
                          <a href="ficha-tortuga-verde" class="btn btn-light">
                            {" "}
                            <i>Chelonia mydas</i>
                          </a>
                          <a href="ficha-tortuga-caguama" class="btn btn-light">
                            {" "}
                            <i>Caretta caretta</i>
                          </a>
                          <a href="ficha-tortuga-laud" class="btn btn-light">
                            {" "}
                            <i>Dermochelys coriacea </i>
                          </a>
                          <a href="javascript:void(0)" class="btn btn-light  active">
                            {" "}
                            <i>Lepidochelys olivacea</i>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12" style={{textAlign: "justify"}}>
                        <h6>Nombres comunes</h6>
                        <p>
                          <i>
                            Tortuga olivácea, golfina, lora, caguama del
                            Pacífico, guía, gritona, Olive Ridley{" "}
                          </i>
                        </p>
                        <h6>Categoría de amenaza </h6>
                        <p>
                          Global: Vulnerable (VU) A2bd <br /> Nacional:
                          Vulnerable (VU) D1
                        </p>
                        <hr />

                        <div
                          class="row d-flex align-items-center"
                          style={{flexWrap: "wrap"}}
                        >
                          <div class="col-md-6">
                            <h6>Descripción</h6>
                            <p>
                              Es la más pequeña de las tortugas marinas,con una
                              la longitud recta del caparazón (LRC) entre 60-70
                              cm (Pritchard y Plotkin, 1995). Cabeza
                              relativamente grande, triangular, mandíbula en
                              forma de pico; con un ancho de hasta 13 cm y dos
                              pares de escamas pre-frontales. Los adultos con
                              caparazón casi circular, con cinco o más escudos
                              laterales a cada lado, frecuentemente con una
                              configuración asimétrica, que la diferencia de las
                              demás tortugas marinas (Pritchard y Mortimer,
                              2000). Plastrón con un poro pequeño cerca del
                              margen posterior de cada uno de los cuatro escudos
                              inframarginales. Adultos con caparazón de color
                              verde oliva o gris oscuro. Plastrón de color crema
                              en adultos y de color blanco en juveniles.
                              Neonatos de color gris oscuro (Pritchard y
                              Plotkin, 1995; Miller, 1997; Pritchard y Mortimer,
                              2000). Con dos uñas en cada aleta, aunque algunos
                              adultos pueden perder la uña secundaria en las
                              aletas delanteras. Se alimentan de una gran
                              variedad de presas como crustáceos, moluscos y
                              peces (Pritchard y Plotkin 1995, Bjorndal 1997).
                            </p>
                          </div>
                          <div class="col-md-6 text-center ">
                            <img
                              class="img-fluid"
                              src={ process.env.PUBLIC_URL +"/static/images/componente-t-t/tgolfina-cd.jpg"}
                              alt=""
                            />
                            <cite>
                              Tomada de Ecker <i>et al</i>., 2000. Técnicas de
                              Investigación y Manejo para la Conservación de las
                              Tortugas Marinas
                            </cite>
                          </div>
                        </div>
                        <hr />
                        <h6>Distribución y hábitat</h6>
                        <div class="row mt-3">
                          <div
                            class="col-md-12 d-flex justify-content-center"
                            style={{flexWrap: "wrap"}}
                          >
                            <img
                              class="w-75"
                              src={ process.env.PUBLIC_URL +"/static/images/componente-t-t/tgolfina-md.jpg"}
                              alt=""
                            />
                            <cite>
                              Mapa de distribución de{" "}
                              <i>Lepidochelys olivacea</i>.
                              www.fao.org/fishery/en., Acceso 27 Nov. 2020
                            </cite>
                            <p class="mt-3">
                              Especie pantropical de amplia distribución
                              geográfica. Los principales lugares de desove en
                              el hemisferio occidental están en el litoral
                              Pacífico de México, y en menor cantidad en playas
                              de Guatemala, Costa Rica, Nicaragua y Colombia.
                              También desovan en grandes cantidades en Guyana,
                              Surinam y Brasil. Es la especie más frecuente en
                              el Pacífico colombiano, y sus principales playas
                              de anidación están dentro y en las proximidades de
                              los Parques Utría y Sanquianga (Amorocho{" "}
                              <i>et al</i>., 1992). Una pequeña colonia de esta
                              especie anida anualmente en playa Palmeras en el
                              Parque Nacional Natural Gorgona (Camayo y
                              Amorocho, 2009). Aparentemente la especie realiza
                              sus migraciones siguiendo rutas cercanas a las
                              costas, en agrupaciones o flotillas de decenas de
                              individuos, sin embargo sus movimientos
                              migratorios no están claramente definidos en el
                              Pacífico Oriental.
                            </p>
                          </div>
                        </div>
                        <hr />
                        <h6>Ciclo de vida</h6>
                        <p>
                          La tortuga golfina forrajea en alta mar, tanto en
                          aguas superficiales como a profundidades hasta de 150
                          m. Los neonatos permanecen en una fase pelágica a la
                          deriva, dispersándose con las principales corrientes
                          lejos de su lugar de nacimiento, y comparten hábitat
                          con juveniles y subadultos (Kopitsky <i>et al</i>.
                          2000) hasta que alcanza la madurez sexual (Musick y
                          Limpus, 1997). Los machos y hembras activas
                          reproductivamente migran hacia las zonas costeras y se
                          concentran cerca de las playas de anidación; sin
                          embargo, algunos machos parecen permanecer en aguas
                          oceánicas y aparearse con las hembras en las rutas
                          hacia las playas (Plotkin <i>et al</i>. 1996; Kopitsky{" "}
                          <i>et al</i>., 2000). Alcanzan la madurez sexual entre
                          los 15-20 años (Zug <i>et al</i>., 2006), y anidan
                          anualmente (Pritchard y Plotkin, 1995), pudiendo
                          reanidar hasta tres veces por temporada con un
                          intervalo variable, pero generalmente entre 14-28 días
                          (Pritchard, 1969; Kalb y Owens, 1994; Plotkin, 1994,
                          Barrientos <i>et al</i>. 2014). La temporada de
                          anidación se extiende desde julio hasta diciembre
                          (Amorocho <i>et al</i>. 1992; Barrientos-Muñoz{" "}
                          <i>et al</i>., 2014), con un pico de anidación desde
                          la segunda quincena de agosto y todo el mes de
                          septiembre (Hinestroza y Páez, 2001; Barrientos-Muñoz
                          y Ramírez-Gallego, 2008). Se encuentran entre julio y
                          noviembre en el Playón del Valle, PNN Sanquianga, PNN
                          Utría y las playas de La Cuevita y San Pichí (Amorocho{" "}
                          <i>et al</i>., 1992; Ceballos-Fonseca <i>et al</i>.,
                          2003), siendo La Cuevita o playa El Valle junto con
                          las playas del PNN Sanquianga los sitios más
                          importantes de anidación para la especie en Sudamérica
                          (Martínez y Páez, 2000; Hinestroza y Páez, 2001;
                          Barrientos-Muñoz <i>et al</i>., 2014). Las nidadas
                          tienen de 87 a 110 huevos por nido (Pritchard, 1969;
                          Plotkin, 1994; Barrientos-Muñoz <i>et al</i>. 2014).
                          En el Pacífico, la temperatura pivotal registrada es
                          de 30,4 °C (Wibbels, 2007) y con una temperatura letal
                          de 35,9 °C (Valverde <i>et al</i>. 2010). Durante
                          monitoreos sistemáticos en El Valle, se colectaron
                          datos de temperaturas de incubación (Martínez y Páez,
                          2000; Hinestroza y Páez, 2001; Barrientos-Muñoz{" "}
                          <i>et al</i>., 2014), y a partir de la temperatura
                          pivotal para todo el Pacífico se estimaron
                          proporciones sexuales de 3:1, 6:1 y 1:0,
                          respectivamente, siendo posiblemente la única colonia
                          anidante que está generando machos para todo el
                          Pacífico Oriental (Barrientos-Muñoz y Ramírez-Gallego,
                          en preparación). En el PNN Gorgona en 1994 y 1995 la
                          proporción sexual fue 1:1 a temperatura pivotal
                          (Amorocho com. pers). Anidan de forma simultánea
                          -cientos de hembras- en una sola playa en un período
                          de varios días, evento que se conoce como una
                          “arribada” (Pritchard y Plotkin, 1995; Márquez{" "}
                          <i>et al</i>., 1996; Plotkin <i>et al</i>., 1997;
                          Chaves <i>et al</i>., 2005). Al concluir la estación
                          reproductiva, las hembras y machos migran a bahías y
                          estuarios costeros, pero también se han observado
                          individuos en algunos hábitats oceánicos a 2.400 km de
                          la costa más cercana (Cornelius y Robinson, 1986;
                          Pritchard y Plotkin, 1995). Las migraciones post-cría
                          son complejas, con rutas diferentes cada año (Plotkin
                          1994) y sin corredores migratorios aparentes (Morreale{" "}
                          <i>et al</i>., 2007).
                        </p>
                        <hr />
                        <h6>Estado de Conservación</h6>
                        <p>
                          Tradicionalmente la especie y sus subproductos han
                          sido una fuente importante de alimento para las
                          comunidades del litoral Pacífico colombiano. El saqueo
                          de nidos y hembras anidantes de golfina por parte de
                          humanos y animales domésticos puede llegar al 100%,
                          siendo una amenaza constante (Ramírez-Gallego y
                          Barrientos-Muñoz, 2008). En el Valle (Chocó) las
                          comunidades afrocolombianas tienen acceso a una cuota
                          anual de tortugas para su consumo; sin embargo, no hay
                          vigilancia acerca de cuantas tortugas son capturadas y
                          en que época del año. La captura incidental en la
                          pesca artesanal es muy común en El Valle, con
                          trasmallos y redes de monofilamento, artes de pesca
                          más frecuentes en las aguas del corregimiento, que
                          capturan individuos de <i>Lepidochelys olivacea</i>,{" "}
                          <i>Chelonia mydas</i> y <i>Eretmochelys imbricata</i>{" "}
                          (Barrientos et al. 2013, 2014). En la década de los
                          ochenta se estimó que 8.321 tortugas eran atrapadas
                          anualmente en redes camaroneras de los barcos del
                          Pacífico colombiano (Duque-Goodman 1988). Al igual que
                          para todas las especies de tortugas, el calentamiento
                          global es una amenaza, no solo por la pérdida de
                          playas para anidar y el incremento de la muerte
                          embrionaria ocasionada por alteraciones en los
                          regímenes hidrológicos, sino por el aumento en las
                          temperaturas de incubación, de las cuales dependen las
                          proporciones sexuales primarias (Ihlow <i>et al</i>.,
                          2012).
                        </p>
                        <hr />
                        <h6>Medidas de Conservación</h6>
                        <p>
                          En el ámbito nacional está protegida por otras medidas
                          a nivel general Decreto N°1681 de 1978 del Inderena,
                          Acuerdo 021 de 1991 del Inderena, Artículo 328 del
                          Código Penal, entre otras y a nivel internacional, se
                          encuentra en el Apéndice I de CITES, en el Apéndice I
                          y II de la Convención de Bonn y en el Anexo II del
                          Protocolo SPAW, lo cual genera acciones de cooperación
                          para la conservación de la especie.
                        </p>
                        <hr />
                        <h6>Ficha SiAM</h6>
                        <a href="https://siam.invemar.org.co/sibm-historia-natural-especies-ficha/70.55.3572">
                          https://siam.invemar.org.co/sibm-historia-natural-especies-ficha/70.55.3572
                        </a>
                        <h6>Ficha SIB</h6>
                        <a href="https://catalogo.biodiversidad.co/file/56d98b1a3c16479905cba99a/summary">
                          https://catalogo.biodiversidad.co/file/56d98b1a3c16479905cba99a/summary
                        </a>
                        <hr />
                        <h6>Referencias Generales</h6>
                        <p>
                          *Amorocho, D. 2014. <i>Lepidochelys olivacea</i>.
                          251-252. En: Amaya-Espinel, J. y Zapata, L. (Eds).
                          Guía de las especies migratorias de la biodiversidad
                          en Colombia. Insectos, murciélagos, tortugas marinas,
                          mamíferos marinos y dulceacuícolas. Vol. 3. Ministerio
                          de Ambiente y Desarrollo Sostenible / WWF-Colombia.
                          Bogotá, D.C. Colombia. 370 p. *Barrientos-Muñoz, K.,
                          Ramírez, C. y Páez, V. 2015.{" "}
                          <i>Lepidochelys olivacea</i>. 161-165. En:
                          Morales-Betancourt, M., Lasso, C., Páez, V. y Bock, B.
                          Libro rojo de reptiles de Colombia. Instituto de
                          Investigación de Recursos Biológicos Alexander von
                          Humboldt (IAvH), Universidad de Antioquia. Bogotá,
                          D.C. Colombia. 258 p. *Abreu-Grobois, A y Plotkin, P.
                          (IUCN SSC Marine Turtle Specialist Group). 2008. 
                          <i>Lepidochelys olivacea</i>. The IUCN Red List of
                          Threatened Species 2008: e.T11534A3292503.
                           https://dx.doi.org/10.2305/IUCN.UK.2008.RLTS.T11534A3292503.en.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <Footer />
      </div>
    </div>
  );
}
