import React from "react";
import { NavLink, Link } from "react-router-dom";
import { URL_APP } from "Routes";

export default function MenuInvestigador() {
   let url = URL_APP();

   return (
      <nav class='navbar navbar-expand-lg navbar-light bg-light'>
         <NavLink class='navbar-brand' to={url.panel_investigador}>
            MENÚ
         </NavLink>
         <button
            class='navbar-toggler'
            type='button'
            data-toggle='collapse'
            data-target='#navbarSupportedContent'
            aria-controls='navbarSupportedContent'
            aria-expanded='false'
            aria-label='Toggle navigation'
         >
            <span class='navbar-toggler-icon'></span>
         </button>
         <div class='collapse navbar-collapse' id='navbarSupportedContent'>
            <ul class='navbar-nav invemar mr-auto'>
               <li className='nav-item'>
                  <Link
                     className='nav-link'
                     exact to={url.naturalista}
                     target='_blank'
                  >
                     Registrar avistamientos
                  </Link>
               </li>
               <li className='nav-item'>
                  <NavLink
                     activeStyle={{
                        fontWeight: "bold",
                        color: "#3338FF",
                     }}
                     className='nav-link'
                     to={url.formulario}
                  >
                     Formulario de monitoreo
                  </NavLink>
               </li>
               <li className='nav-item'>
                  <NavLink
                     activeStyle={{
                        fontWeight: "bold",
                        color: "#3338FF",
                     }}
                     className='nav-link'
                     to={url.listado_monitoreos}
                  >
                     Listado de monitoreo
                  </NavLink>
               </li>
               <li className='nav-item'>
                  <NavLink
                     activeStyle={{
                        fontWeight: "bold",
                        color: "#3338FF",
                     }}
                     className='nav-link'
                     to={url.graficas}
                  >
                     Reporte de monitoreo
                  </NavLink>
               </li>
               {/* <li class="nav-item">
                            <NavLink activeStyle={{
                            fontWeight: "bold",
                            color: "#3338FF"
                        }} className="nav-link" to={url.graficas}>
                            Carga Masiva de Datos
                        </NavLink>
                        </li> */}
               <li class='nav-item'>
                  <NavLink
                     activeStyle={{
                        fontWeight: "bold",
                        color: "#3338FF",
                     }}
                     className='nav-link'
                     to={url.visor}
                  >
                     Visor
                  </NavLink>
               </li>
            </ul>
         </div>
      </nav>
   );
}
