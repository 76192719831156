import React from 'react'
import { connect } from "react-redux";


function DatosGenerales({register,errors,onChanges,arrary_especies}) {
    return (
        <div>
             <h6>Datos generales</h6>
            <div className="form-row">
                <div className="col-sm-3 col-md-3 mb-3">
                    <label for="v11">Consecutivo</label>
                    <input
                        ref={register({
                        /*required: true*/
                        })}
                        type="text"
                        className="form-control"
                        id="consecutivo"
                        name="consecutivo"
                        placeholder="i-00000"
                    />
                    <span className="text-danger text-small d-block mb-2">
                        {errors.consecutivo && (
                        <span>Este campo es requerido</span>
                        )}
                    </span>
                </div>
                <div className="col-sm-3 col-md-5 mb-3">
                    <label for="v12">Especie</label>
                    <select
                        ref={register({
                        /*required: true*/
                        })}
                        className="custom-select"
                        id="id_especie"
                        name="id_especie"
                    >
                        <option selected disabled value="">
                        Seleccione...
                        </option>
                        {arrary_especies.map((item) => (
                        <option
                            value={item.cod_letras}
                            key={item.cod_letras}
                        >
                            {item.descripcion}
                        </option>
                        ))}
                    </select>
                    <span className="text-danger text-small d-block mb-2">
                        {errors.id_especie && (
                        <span>Este campo es requerido</span>
                        )}
                    </span>
                </div>
                <div className="col-sm-3 col-md-2 mb-3">
                    <label for="v13">Núm. de transecto</label>
                    <input
                        ref={register({
                        /*required: true*/
                        })}
                        type="text"
                        className="form-control"
                        id="transecto"
                        name="transecto"
                        placeholder=""
                    />
                    <span className="text-danger text-small d-block mb-2">
                        {errors.transecto && (
                        <span>Este campo es requerido</span>
                        )}
                    </span>
                </div>
                <div className="col-sm-3 col-md-2 mb-3">
                    <label for="v14">Núm. de sector</label>
                    <input
                        ref={register({
                        /*required: true*/
                        })}
                        type="text"
                        className="form-control"
                        id="sector"
                        name="sector"
                        placeholder=""
                    />
                    <span className="text-danger text-small d-block mb-2">
                        {errors.sector && (
                        <span>Este campo es requerido</span>
                        )}
                    </span>
                </div>
            </div>
            <div className="form-row">
                <div className="col-sm-3 col-md-2 mb-3">
                    <label for="v15">Se encontro nido</label>
                    <input
                        ref={register({
                        /*required: true*/
                        })}
                        type="text"
                        className="form-control"
                        id="encontro_nido"
                        name="encontro_nido"
                        placeholder=""
                    />                 
                </div>
                <div className="col-sm-3 col-md-2 mb-3">
                    <label for="v16">Interceptar hembras</label>
                    <input
                        ref={register({
                        /*required: true*/
                        })}
                        type="text"
                        className="form-control"
                        id="intercepto_hembra"
                        name="intercepto_hembra"
                        placeholder=""
                    />
               
                </div>
                <div className="col-sm-3 col-md-2 mb-3">
                        <label for="v15">Latitud</label>
                        <input
                        ref={register({})}
                        onChange={(e) => onChanges(e)}
                        type="text"
                        className="form-control"
                        id="latitud_individuo"
                        name="latitud_individuo"
                        placeholder="00,000000"
                        />
                        <span className="text-danger text-small d-block mb-2">
                        {errors.latitud_individuo && (
                            <span>Este campo es requerido</span>
                        )}
                        </span>
                    </div>
                <div className="col-sm-3 col-md-2 mb-3">
                        <label for="v16">Longitud</label>
                        <input
                        ref={register({})}
                        onChange={(e) => onChanges(e)}
                        type="text"
                        className="form-control"
                        id="longitud_individuo"
                        name="longitud_individuo"
                        placeholder="00,000000"
                        />
                        <span className="text-danger text-small d-block mb-2">
                        {errors.longitud_individuo && (
                            <span>Este campo es requerido</span>
                        )}
                        </span>
                    </div>
                <div className="col-sm-3 col-md-2 mb-3">
                        <label for="v14">Fecha y hora del registro</label>
                        <input
                        ref={register({})}
                        onChange={(e) => onChanges(e)}
                        type="text"
                        className="form-control"
                        id="avistamiento"
                        name="avistamiento"                       
                        />
                        <span className="text-danger text-small d-block mb-2">
                        {errors.avistamiento && (
                            <span>Este campo es requerido</span>
                        )}
                        </span>
                    </div>
            </div>
        </div>
    )
}
let mapState = (state) => {  
    return {
       arrary_especies: state.referentes.arrary_especies,
    };
 }; 
 const mapDispatch = (dispatch) => {
    return {        
    };
 };
 export default connect(mapState, mapDispatch)(DatosGenerales);
