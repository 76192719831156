import React from "react";
import { Link } from "react-router-dom";
import { URL_APP } from "Routes";
import { TXT_APP } from "Routes";

export default function HeaderHome() {
   let url = URL_APP();
   let txt = TXT_APP();

   return (
      <div className='content-principal bg-home'>
         <div className='temporal-cerar'></div>

         <header class='home-h'>
            <div class='logo-invemar-mobile'>
               <a title='invemar' href='http://www.invemar.org.co/'>
                  <img src={ process.env.PUBLIC_URL +'/static/images/logo-invemar.png'} alt='invemar' />
               </a>
            </div>
            <div class='menu-mobil' id='showMenu'>
               <a href='javascript:void(0)'>
                  <i class='fa fa-bars font-menu-mobil'></i>
               </a>
            </div>




            <nav className="nav-principal nav-interiores" id="nav_principal">
            <div className="btn-hide-menu" id="hideMenuInt">
               <a href="javascript:void(0)">
                  <img src={ process.env.PUBLIC_URL +'/static/images/btn-cerrar.png'} alt="" />
                  Cerrar
               </a>
            </div>
            <ul>
               <li>
                  <i className="fa fa-home margin-icon-1 font-size-20"></i>
                  <a href="/" target="">
                     Inicio
                  </a>
               </li>
               <li>
                  <a href="https://siam.invemar.org.co/descripcion" target="">
                     ¿Qué es SiAM?
                  </a>
               </li>
               <li>
                  <a href="https://siam.invemar.org.co/cifras_siam" target="">
                     Cifras SIAM
                  </a>
               </li>

               <li>
                  <a href="https://siam.invemar.org.co/mapa-sitio" target="">
                     Mapa sitio
                  </a>
               </li>
               <li>
                  <a
                     href="http://www.invemar.org.co/web/guest/acuerdo-de-acceso-uso-a-datos"
                     target="_blank"
                  >
                     Solicitud de información
                  </a>
               </li>
               <li>
                  <a href="https://siam.invemar.org.co/noticias" target="">
                     Noticias
                  </a>
               </li>
               <li className="targetli">
                  <a href="javascript:void(0)">Herramientas</a>
                  <div className="submenu">
                     <div className="submenu-item">
                        <a href="javascript:void(0)">Transversales</a>
                        <ul className="submenusub">
                           <li>
                              <a href="https://siam.invemar.org.co/informacion-geografica" target="">
                                 Sección de Información Geográfica
                              </a>
                           </li>

                           <li>
                              <a href="https://siam.invemar.org.co/documentos" target="">
                                 Recursos Centro Documentación
                              </a>
                           </li>

                           <li>
                              <a href="https://siam.invemar.org.co/galerias" target="">
                                 Galerías
                              </a>
                           </li>

                           <li>
                              <a href="https://siam.invemar.org.co/herramientas" target="">
                                 Herramientas
                              </a>
                           </li>

                           <li>
                              <a href="https://siam.invemar.org.co/campanas-proyectos" target="">
                                 Proyectos y Estaciones
                              </a>
                           </li>
                        </ul>
                     </div>
                  </div>
               </li>
               <li>
                  <a href="https://siam.invemar.org.co/contactenos" target="">
                     Contáctenos
                  </a>
               </li>
            </ul>
            <ul className="nav-ayudas">
               <li>
                  <a href="https://siam.invemar.org.co/buscador">
                     <i className="fa fa-search" title="Buscar"></i>
                  </a>
               </li>
               {/* <li>
                  <a
                     href="http://ayudas.invemar.org.co/web/siam"
                     target="_blank"
                  >
                     <i className="fa fa-question-circle" title="Ayuda"></i>
                  </a>
               </li> */}
               <li>
                  <a href="https://siam.invemar.org.co/informacion-geografica">
                     <i
                        className="fa fa-globe"
                        title="Información Geográfica"
                     ></i>
                  </a>
               </li>
            </ul>
         </nav>
           
           


           
           
           
            <div class='clear-1'></div>
         </header>

         <section class='resume-home'>
            <h1>
               Tortugas Marinas <br />
               en Colombia{" "}
               <img
                  class='logo-ma-portada'
                  src={ process.env.PUBLIC_URL +'http://www.invemar.org.co/Invemar3.0-theme/images/img/logos/minambiente.png'}
                  alt=''
               />
            </h1>
            <p align='justify'>
               De las siete especies de tortugas marinas que circundan el
               ambiente marino en el mundo, cinco se registran en las costas del
               país, en zonas de alimentación o anidación,{" "}
               <i>
                  Dermochelys coriacea, Chelonia mydas agassizii, Caretta caretta,
                  Eretmochelys imbricata, Lepidochelys olivacea.
               </i>
            </p>
            <a href='#s1' class='botong boton-vermas'>
               Saber más
            </a>
            <Link exact to={url.page_avistamiento} className='botong boton-vermas'>
               Registros biologicos
            </Link>
            <Link exact to={url.visor} className='botong boton-vermas'>
               Consultar registros disponibles
            </Link>

            <div class='l-especies d-none	d-sm-none d-md-block'>
               <a class='btn btn-info btn-sm btn-l-especie' href='#tca'>
                  Tortuga carey
               </a>
               <a class='btn btn-info btn-sm btn-l-especie' href='#tve'>
                  Tortuga verde
               </a>
               <a class='btn btn-info btn-sm btn-l-especie' href='#tbo'>
                  Tortuga caguama
               </a>
               <a class='btn btn-info btn-sm btn-l-especie' href='#tla'>
                  Tortuga laúd
               </a>{" "}
               <a class='btn btn-info btn-sm btn-l-especie' href='#tlo'>
                  Tortuga golfina
               </a>
            </div>
         </section>

         <div class='buscador-home' hidden='true'>
            <div class='buscador-home-int'>
               <form
                  id='siam_searcher_alphanumeric'
                  method='POST'
                  action='/buscador'
               >
                  <input
                     type='hidden'
                     name='csrfmiddlewaretoken'
                     value='qPxSeqY4R9Bi3QUrk6MSvUAL5yfa98SuXEoNlJoG6DEgiQCKgDp1uhaFmsBj43gZ'
                  />
                  <input
                     id='id_q'
                     maxlength='200'
                     name='q'
                     placeholder='Buscador SiAM'
                     type='text'
                  />
                  <div class='icon-buscador'>
                     <a
                        id='siam_searcher_search_alphanumeric'
                        href='javascript:void(0)'
                     >
                        <i class='fa fa-search'></i>
                     </a>
                  </div>
               </form>
            </div>
         </div>
         <section class='btn-inicio-siam'>
            <nav class='nav-siam'>
               <ul>
                  <li class='nav-siam-item col4'>
                     <a href='#s2' target='_self'>
                        <span>
                           <div class='icon-nav-siam'>
                              <img
                                 src={ process.env.PUBLIC_URL +'/static/images/home-options/comp-tourtugas/biologia-tortugas.png'}
                                 alt=''
                              />
                           </div>
                           <span>
                              <h5>Conozca Su biología y ecología</h5>
                              <p>
                                 Descubra la biología y ecología de estos
                                 fascinantes reptiles marinos.
                              </p>
                           </span>
                        </span>
                     </a>
                  </li>
                  <li class='nav-siam-item col4'>
                     <a href='#s3' target='_self'>
                        <span>
                           <div class='icon-nav-siam'>
                              <img
                                 src={ process.env.PUBLIC_URL +'/static/images/home-options/comp-tourtugas/pq-tortugas.png'}
                                 alt=''
                              />
                           </div>
                           <span>
                              <h5>Importancia de las Tortugas</h5>
                              <p>
                                 Entérate que importancia tienen las Tortugas
                                 Marinas en su ecosistema.
                              </p>
                           </span>
                        </span>
                     </a>
                  </li>

                  <li class='nav-siam-item col4'>
                     <a href='#s4' target='_self'>
                        <span>
                           <div class='icon-nav-siam'>
                              <img
                                 src={ process.env.PUBLIC_URL +'/static/images/home-options/comp-tourtugas/pe-tortugas.png'}
                                 alt=''
                              />
                           </div>
                           <span>
                              <h5>¿Por qué están amenazadas?</h5>
                              <p>
                                 Descubra porque las Tortugas Marinas se
                                 consideran amenazadas.
                              </p>
                           </span>
                        </span>
                     </a>
                  </li>

                  <li class='nav-siam-item col4'>
                     <a href='#s5' target='_self'>
                        <span>
                           <div class='icon-nav-siam'>
                              <img
                                 src={ process.env.PUBLIC_URL +'/static/images/home-options/comp-tourtugas/leg-tortugas.png'}
                                 alt=''
                              />
                           </div>
                           <span>
                              <h5>Legislación y planes</h5>
                              <p>
                                 Encuentre las leyes más recientes y planes de
                                 conservación de la Tortugas Marinas.
                              </p>
                           </span>
                        </span>
                     </a>
                  </li>
               </ul>
            </nav>
         </section>
      </div>
   );
}
