import axios from "axios";
//constantes
let initialState = {
   fetching_get_muestreos: false,
   fetching_get_muestras: false,
   fetching_get_monitoreos: false,

   msg_set_monitoreos: "",
   error_muestreos: "",
   arrary_muestreos: [],
   arrary_muestras: [],
   arrary_monitoreos: [],
   arrary_all_monitoreos: [],
   view_monitoreo: null,
   isRegister: false,
   isError: false,
   isAddMonitoreo: false,
   //all monitoreos
   fetching_get_all_monitoreos: true,
   msg_get_all_monitoreos: "",
   isGet_all_monitoreos: false,
   isError_get_all_monitoreos: false,

   //monitoreos por usuario
   fetching_get_user_monitoreos: false,
   msg_get_user_monitoreos: "",
   arrary_monitoreos_user: [],

   isUpdating: false,

   //monitoreo update
   fetching_monitoreo_update: false,
   monitoreo_update: [],
   isUpdate_monitoreo: false,

   //muestra update
   fetching_muestra_update: false,
   muestra_update: [],
   isUpdate_muestra: false,

   //eliminar muestra
   msg_delete: "",
   fetching_monitoreo_delete: false,
   is_monitoreo_delete: false,
};

let TIME = 10000;

let RESET = "RESET";

let MONITOREO_UPDATE = "MONITOREO_UPDATE";

let MUESTRA_UPDATE = "MUESTRA_UPDATE";

let VIEW_MONITOREO = "VIEW_MONITOREO";

//DELETE MUESTRA
let SET_DELETE_MUESTRA = "SET_DELETE_MUESTRA";
let SET_DELETE_MUESTRA_SUCCESS = "SET_DELETE_MUESTRA_SUCCESS";
let SET_DELETE_MUESTRA_ERROR = "SET_DELETE_MUESTRA_ERROR";

//UPDATING MONITOREO
let SET_MONITOREO_UPDATING = "MONITOREO_UPDATING";
let SET_MONITOREO_UPDATING_SUCCESS = "MONITOREO_UPDATING_SUCCESS";
let SET_MONITOREO_UPDATING_ERROR = "MONITOREO_UPDATING_ERROR";

//UPDATE MUESTRA
let UPDATE_MUESTRA = "UPDATE_MUESTRA";
let UPDATE_MUESTRA_SUCCESS = "UPDATE_MUESTRA_SUCCESS";
let UPDATE_MUESTRA_ERROR = "UPDATE_MUESTRA_ERROR";

//REGISTRO MONITOREO
let SET_MONITOREO = "SET_MONITOREO";
let SET_MONITOREO_SUCCESS = "SET_MONITOREO_SUCCESS";
let SET_MONITOREO_ERROR = "SET_MONITOREO_ERROR";

//GET MONITOREO
let GET_MONITOREO = "GET_MONITOREO";
let GET_MONITOREO_SUCCESS = "GET_MONITOREO_SUCCESS";
let GET_MONITOREO_ERROR = "GET_MONITOREO_ERROR";

//GET MONITOREO
let GET_USER_MONITOREO = "GET_USER_MONITOREO";
let GET_USER_MONITOREO_SUCCESS = "GET_USER_MONITOREO_SUCCESS";
let GET_USER_MONITOREO_ERROR = "GET_USER_MONITOREO_ERROR";

//GET MONITOREO
let GET_ALL_MONITOREO = "GET_ALL_MONITOREO";
let GET_ALL_MONITOREO_SUCCESS = "GET_ALL_MONITOREO_SUCCESS";
let GET_ALL_MONITOREO_ERROR = "GET_ALL_MONITOREO_ERROR";

//MUESTREOS
let GET_MUESTREOS = "GET_MUESTREOS";
let GET_MUESTREOS_SUCCESS = "GET_MUESTREOS_SUCCESS";
let GET_MUESTREOS_ERROR = "GET_MUESTREOS_ERRORS";

//MUESTRAS
let GET_MUESTRAS = "GET_MUESTRAS";
let GET_MUESTRAS_SUCCESS = "GET_MUESTRAS_SUCCESS";
let GET_MUESTRAS_ERROR = "GET_MUESTRAS_ERRORS";

//URLS
let URL_MUESTREOS = `${process.env.REACT_APP_API_URL}/get-muestreos`;

let URL_MUESTRAS = `${process.env.REACT_APP_API_URL}/get-muestras`;
//guarda un monitoreo
let URL_SET_MONITOREO = `${process.env.REACT_APP_API_URL}/set-form`;
//obtiene los monitoreos de una estacion especifica
let URL_GET_MONITOREO = `${process.env.REACT_APP_API_URL}/get-monitoreo/`;
//obtiene todos los monitoreo del la aplicacion
let URL_GET_ALL_MONITOREO = `${process.env.REACT_APP_API_URL}/get-all-monitoreos`;
//obtiene todos los monitoreos de un usuario
let URL_GET_USER_MONITOREO = `${process.env.REACT_APP_API_URL}/get-user-monitoreos`;
//actualiza un solo monitoreo
let URL_MONITOREO_UPDATING = `${process.env.REACT_APP_API_URL}/monitoreo-update/`;
//Eliminar monitoreo
let URL_SET_DELETE_MUESTRA = `${process.env.REACT_APP_API_URL}/muestra-delete/`;
//Update muestra
let URL_UPDATE_MUESTRA = `${process.env.REACT_APP_API_URL}/muestra-update/`;

//Reducer
export default function reducer(state = initialState, action) {
   switch (action.type) {
      //CARGANDO USUARIO PARA UPDATE ACTIVO
      case VIEW_MONITOREO:
         return {
            ...state,
            view_monitoreo: action.payload.view_monitoreo,
         };
      case RESET:
         return { ...initialState };

      //CARGANDO MUESTRA PARA UPDATE ACTIVO
      case MUESTRA_UPDATE:
         return {
            ...state,
            muestra_update: action.payload.muestra_update,
            isUpdate_muestra: action.payload.isUpdate_muestra,
         };
      //CARGANDO MONITOREO PARA UPDATE ACTIVO
      case MONITOREO_UPDATE:
         return {
            ...state,
            monitoreo_update: action.payload.monitoreo_update,
            isUpdate_monitoreo: action.payload.isUpdate_monitoreo,
         };

      //ACTUALIZACION DE LA MUESTRA----------------------------------------------------------------------
      case UPDATE_MUESTRA:
         return {
            ...state,
            fetching_update_muestra: true,
            msg_update_muestra: "",
            isUpdate_muestra_user: false,
         };
      case UPDATE_MUESTRA_SUCCESS:
         return {
            ...state,
            msg_delete: action.payload.msg_delete,
            fetching_update_muestra: false,
            isUpdate_muestra_user: action.payload.isUpdate_muestra_user,
         };
      case UPDATE_MUESTRA_ERROR:
         return {
            ...state,
            msg_delete: action.payload.msg_delete,
            fetching_update_muestra: false,
            isUpdate_muestra_user: false,
         };

      //GET MONITOREOS POR USUARIO
      case SET_DELETE_MUESTRA:
         return {
            ...state,
            fetching_monitoreo_delete: true,
            msg_delete: "",
            is_monitoreo_delete: false,
         };
      case SET_DELETE_MUESTRA_SUCCESS:
         return {
            ...state,
            msg_delete: action.payload.msg_delete,
            fetching_monitoreo_delete: false,
            is_monitoreo_delete: true,
         };
      case SET_DELETE_MUESTRA_ERROR:
         return {
            ...state,
            msg_delete: action.payload.msg_delete,
            fetching_monitoreo_delete: false,
            is_monitoreo_delete: false,
         };

      //GET MONITOREOS POR USUARIO
      case SET_MONITOREO_UPDATING:
         return {
            ...state,
            fetching_monitoreo_update: true,
            msg_set_monitoreos: "",
         };
      case SET_MONITOREO_UPDATING_SUCCESS:
         return {
            ...state,
            msg_set_monitoreos: action.payload.msg_set_monitoreos,
            fetching_monitoreo_update: false,
            isUpdate_monitoreo: true,
         };
      case SET_MONITOREO_UPDATING_ERROR:
         return {
            ...state,
            msg_set_monitoreos: action.payload.msg_set_monitoreos,
            fetching_monitoreo_update: false,
         };
      //GET MONITOREOS POR USUARIO
      case GET_USER_MONITOREO:
         return {
            ...state,
            fetching_get_user_monitoreos: true,
         };
      case GET_USER_MONITOREO_SUCCESS:
         return {
            ...state,
            arrary_monitoreos_user: action.payload.arrary_monitoreos_user,
            fetching_get_user_monitoreos: false,
         };
      case GET_USER_MONITOREO_ERROR:
         return {
            ...state,
            msg_get_user_monitoreos: action.payload.error,
            fetching_get_user_monitoreos:
               action.payload.fetching_get_user_monitoreos,
         };

      //GET ALL MONITOREOS
      case GET_ALL_MONITOREO:
         return {
            ...state,
            fetching_get_all_monitoreos: true,
            msg_get_all_monitoreos: "",
            isGet_all_monitoreos: false,
            isError_get_all_monitoreos: false,
            isRegister: false,
         };
      case GET_ALL_MONITOREO_SUCCESS:
         return {
            ...state,
            fetching_get_all_monitoreos: false,
            arrary_all_monitoreos: action.payload.arrary_all_monitoreos,
            isGet_all_monitoreos: action.payload.isGet_all_monitoreos,
         };
      case GET_ALL_MONITOREO_ERROR:
         return {
            ...state,
            msg_get_all_monitoreos: action.payload.msg_get_all_monitoreos,
            isError: action.payload.isError,
            fetching_get_all_monitoreos: false,
            isError_get_all_monitoreos: false,
         };

      //SET MONITOREOS
      case SET_MONITOREO:
         return {
            ...state,
            fetching_set_monitoreos: true,
            msg_set_monitoreos: "",
            isRegister: false,
            isError: false,
         };
      case SET_MONITOREO_SUCCESS:
         return {
            ...state,
            fetching_set_monitoreos: false,
            msg_set_monitoreos: action.payload.msg_set_monitoreos,
            isRegister: action.payload.isRegister,
            isError: false,
         };
      case SET_MONITOREO_ERROR:
         return {
            ...state,
            msg_set_monitoreos: action.payload.msg_set_monitoreos,
            isError: action.payload.isError,
            fetching_set_monitoreos: false,
         };
      //GET MONITOREOS
      case GET_MONITOREO:
         return {
            ...state,
            fetching_get_monitoreos: true,
            isRegister: false,
         };
      case GET_MONITOREO_SUCCESS:
         return {
            ...state,
            arrary_monitoreos: action.payload.arrary_monitoreos,
            fetching_get_monitoreos: false,
            isAddMonitoreo: action.payload.isAddMonitoreo,
         };
      case GET_MONITOREO_ERROR:
         return {
            ...state,
            error: action.payload.error,
            fetching_referentes: false,
         };

      // GET MUESTREOS
      case GET_MUESTREOS:
         return {
            // ...state,
            // fetching_referentes: true,
         };
      case GET_MUESTREOS_SUCCESS:
         return {
            // ...state,
            // fetching_referentes: false,
            // error: action.payload.error,
         };
      case GET_MUESTREOS_ERROR:
         return {
            // ...state,
            // arrary_referentes: action.payload.arrary_referentes,
            // fetching_referentes: action.payload.fetching_referentes,
         };
      // GET MUESTRAS
      case GET_MUESTRAS:
         return {
            // ...state,
            // fetching_referentes: action.payload.fetching_referentes,
         };
      case GET_MUESTRAS_SUCCESS:
         return {
            // ...state,
            // fetching_referentes: action.payload.fetching_referentes,
            // error: action.payload.error,
         };
      case GET_MUESTRAS_ERROR:
         return {
            // ...state,
            // arrary_referentes: action.payload.arrary_referentes,
            // fetching_referentes: action.payload.fetching_referentes,
         };
      default:
         return state;
   }
}

// Action 1 Reset Monitoreos
export let resetMonitoreosAction = () => (dispatch, getState) => {
   // Aqui la funcion para cerrar sesion en django
   dispatch({
      type: RESET,
   });
};

// Action 2 - registrar monitoreos
export let setRegistrarMonitoreoAction = ({ token, data, nota }) => async (
   dispatch,
   getState
) => {
   //  console.log("DATA MONITOREO", data);
   //   console.log("DATA TOKEN", token);
   console.log("Duck:", nota);
   dispatch({
      type: SET_MONITOREO,
   });

   const request = async (token, data, nota) => {
      // console.log("ACCES  TOKEN:", token);
      data["nota"] = nota;
      const response = await axios({
         method: "POST",
         url: URL_SET_MONITOREO,
         // crossdomain: true,
         headers: { Authorization: `Bearer ${token}` },
         data: data,
      });
      const resp = await response.data;
      return resp;
   };
   //PETICION POST
   request(token, data, nota)
      .then((res) => {
         dispatch({
            type: SET_MONITOREO_SUCCESS,
            payload: {
               msg_set_monitoreos: res.msg,
               isRegister: true,
            },
         });
         // getMonitoreoAction(token)
         setTimeout(function () {
            dispatch({
               type: SET_MONITOREO_SUCCESS,
               payload: {
                  msg: "",
                  isRegister: false,
               },
            });
         }, 500);
      })
      .catch((error) => {
         // console.log("ERROR:",error)
         dispatch({
            type: SET_MONITOREO_ERROR,
            payload: {
               // msg_set_monitoreos: error.response.data.message,
               isError: true,
            },
         });

         setTimeout(function () {
            dispatch({
               type: SET_MONITOREO_ERROR,
               payload: {
                  msg_set_monitoreos: "",
                  isError: false,
               },
            });
         }, TIME);
      });
   // saveStorage(getState());
};
// Action 3 -get monitoreo por id
export let getMonitoreoAction = ({ token, id_monitoreo }) => (
   dispatch,
   getState
) => {
   dispatch({
      type: GET_MONITOREO,
   });

   const request = async (token, id_monitoreo) => {
      const response = await axios({
         method: "GET",
         url: URL_GET_MONITOREO + "?id=" + id_monitoreo,
         crossdomain: true,
         headers: { Authorization: `Bearer ${token}` },
         // data: id_monitoreo,
      });
      const data = await response.data;
      return data;
   };

   //PETICION GET
   request(token, id_monitoreo)
      .then((res) => {
         dispatch({
            type: GET_MONITOREO_SUCCESS,
            payload: {
               arrary_monitoreos: res,
               isAddMonitoreo: true,
            },
         });
         saveStorage(getState());
      })
      .catch((error) => {
         dispatch({
            type: GET_MONITOREO_ERROR,
            payload: {
               error: error.message, // error.response.data.msg
            },
         });
      });
   saveStorage(getState());
};

//Action 4 -elimina muestra por id
export let deleteMonitoreoAction = ({ token, id_muestra }) => (
   dispatch,
   getState
) => {
   // console.log("TOKEN:",token," muestra_id",id_muestra)

   dispatch({
      type: SET_DELETE_MUESTRA,
   });

   //axios
   const request = async (token, id_muestra) => {
      const response = await axios({
         method: "PUT",
         url: URL_SET_DELETE_MUESTRA + "?id=" + id_muestra,
         crossdomain: true,
         headers: { Authorization: `Bearer ${token}` },
         data: { id_muestra: id_muestra },
      });
      const data = await response.data;
      return data;
   };

   //PETICION GET
   request(token, id_muestra)
      .then((res) => {
         dispatch({
            type: SET_DELETE_MUESTRA_SUCCESS,
            payload: {
               msg_delete: res.message,
            },
         });
      })
      .catch((error) => {
         dispatch({
            type: SET_DELETE_MUESTRA_ERROR,
            payload: {
               error_delete: error.message, // error.response.data.msg
            },
         });
      });

   setTimeout(function () {
      dispatch({
         type: SET_DELETE_MUESTRA_SUCCESS,
         payload: {
            msg_delete: "",
         },
      });
   }, TIME);
};

// Action 5 -get monitoreo por usuario
export let getMonitoreoUserAction = ({ token, id }) => (dispatch, getState) => {
   dispatch({
      type: GET_USER_MONITOREO,
   });

   const request = async (token, id) => {
      const response = await axios({
         method: "GET",
         url: URL_GET_USER_MONITOREO + "?id=" + id,
         crossdomain: true,
         headers: { Authorization: `Bearer ${token}` },
         // data: id_monitoreo,
      });
      const data = await response.data;
      return data;
   };

   //PETICION GET
   request(token, id)
      .then((res) => {
         //  console.log(res)
         dispatch({
            type: GET_USER_MONITOREO_SUCCESS,
            payload: {
               arrary_monitoreos_user: res,
            },
         });
         // saveStorage(getState());
      })
      .catch((error) => {
         //  console.log("ERROR",error);

         dispatch({
            type: GET_USER_MONITOREO_ERROR,
            payload: {
               msg_get_user_monitoreos: error.message, // error.response.data.msg
               fetching_get_user_monitoreos: false,
            },
         });
         setTimeout(function () {
            dispatch({
               type: GET_USER_MONITOREO_ERROR,
               payload: {
                  msg_get_user_monitoreos: "",
               },
            });
         }, TIME);
      });

   // saveStorage(getState());
};

// Action 6 - Carga un monitoreo para mostrar en otro componente
export let viewMonitoreoAction = (muestra) => (dispatch, getState) => {
   dispatch({
      type: VIEW_MONITOREO,
      payload: {
         view_monitoreo: muestra,
      },
   });
   saveStorage(getState());
};

// Action 7 - se carga el monitoreo que esta en proceso de actualizacion
export let updateMonitoreoAction = (monitoreo) => (dispatch, getState) => {
   if (monitoreo.length === 0) {
      dispatch({
         type: MONITOREO_UPDATE,
         payload: {
            monitoreo_update: monitoreo,
            isUpdate_monitoreo: false,
         },
      });
   } else {
      dispatch({
         type: MONITOREO_UPDATE,
         payload: {
            monitoreo_update: monitoreo,
            isUpdate_monitoreo: true,
         },
      });
   }
   saveStorage(getState());
};

// Action 8 - obtener muestra para actualizar
export let updateMuestraAction = (muestra) => (dispatch, getState) => {
   if (muestra.length === 0) {
      dispatch({
         type: MUESTRA_UPDATE,
         payload: {
            muestra_update: muestra,
            isUpdate_muestra: false,
         },
      });
   } else {
      dispatch({
         type: MUESTRA_UPDATE,
         payload: {
            muestra_update: muestra,
            isUpdate_muestra: true,
         },
      });
   }
   saveStorage(getState());
};

// Action 9 - Actualizando la muestra
export let setUpdatingMuestraAction = ({
   token,
   id_muestra,
   data,
   nota,
   id_muestreo,
}) => (dispatch, getState) => {
   // console.log("id Muestra: ",id_muestra," token",token,"data: ",data)

   dispatch({
      type: UPDATE_MUESTRA,
   });

   const request = async (token, id_muestra, data, nota, id_muestreo) => {
      const response = await axios({
         method: "put",
         url: URL_UPDATE_MUESTRA,
         // crossdomain: true,
         headers: { Authorization: `Bearer ${token}` },
         data: {
            id_muestra: id_muestra,
            data: data,
            nota: nota,
            id_muestreo: id_muestreo,
         },
      });
      const res = await response.data;
      return res;
   };

   //PETICION GET
   request(token, id_muestra, data, nota, id_muestreo)
      .then((res) => {
         dispatch({
            type: UPDATE_MUESTRA_SUCCESS,
            payload: {
               msg_update_muestra: res.message,
               isUpdate_muestra_user: true,
            },
         });
         setTimeout(function () {
            dispatch({
               type: UPDATE_MUESTRA_SUCCESS,
               payload: {
                  msg_update_muestra: "",
                  isUpdate_muestra_user: false,
               },
            });
         }, 100);
      })
      .catch((error) => {
         dispatch({
            type: UPDATE_MUESTRA_ERROR,
            payload: {
               error_update_muestra: error.message,
            },
         });
         setTimeout(function () {
            dispatch({
               type: UPDATE_MUESTRA_ERROR,
               payload: {
                  error_update_muestra: "",
               },
            });
         }, TIME);
      });
};

// Action 10 - obtener usuario para actualizar
export let setUpdatingMonitoreoAction = ({
   token,
   id_estacion,
   is_active,
   observacion,
}) => (dispatch, getState) => {
   // console.log("token:",token," id estacion:",id_estacion,"is Active",is_active)
   dispatch({
      type: SET_MONITOREO_UPDATING,
   });

   const request = async (token, id_estacion, is_active, observacion) => {
      const response = await axios({
         method: "put",
         url: URL_MONITOREO_UPDATING,
         // crossdomain: true,
         headers: { Authorization: `Bearer ${token}` },
         data: {
            id_estacion: id_estacion,
            is_active: is_active,
            obs: observacion,
         },
      });
      const data = await response.data;
      return data;
   };

   //PETICION GET
   request(token, id_estacion, is_active, observacion)
      .then((res) => {
         dispatch({
            type: SET_MONITOREO_UPDATING_SUCCESS,
            payload: {
               msg_set_monitoreos: res.message,
            },
         });
         saveStorage(getState());
      })
      .catch((error) => {
         dispatch({
            type: SET_MONITOREO_UPDATING_ERROR,
            payload: {
               error: error.message,
            },
         });
      });
};

// Action 11 obtiene todos los monitoreos
export let getAllMonitoreoAction = ({ token }) => (dispatch, getState) => {
   // console.log("DATA MONITOREO", id_monitoreo);
   // console.log("DATA TOKEN BUSCAR TODSOS LOS MONITOREOS", token);

   dispatch({
      type: GET_ALL_MONITOREO,
   });

   //limpiar update monitoreo
   dispatch({
      type: MONITOREO_UPDATE,
      payload: {
         isUpdate_monitoreo: false,
      },
   });

   const request = async (token) => {
      if (token === "") {
         const response = await axios({
            method: "GET",
            url: URL_GET_ALL_MONITOREO,
            // crossdomain: true,
         });
         const data = await response.data;
         return data;
      } else {
         const response = await axios({
            method: "GET",
            url: URL_GET_ALL_MONITOREO,
            // crossdomain: true,
            headers: { Authorization: `Bearer ${token}` },
         });
         const data = await response.data;
         return data;
      }
   };

   //PETICION GET
   request(token)
      .then((res) => {
         dispatch({
            type: GET_ALL_MONITOREO_SUCCESS,
            payload: {
               arrary_all_monitoreos: res,
               isGet_all_monitoreos: true,
            },
         });
         // setTimeout(function () {
         //    dispatch({
         //       type: GET_ALL_MONITOREO_SUCCESS,
         //       payload: {
         //          isGet_all_monitoreos: false,
         //       },
         //    });
         // }, TIME);
         saveStorage(getState());
      })
      .catch((error) => {
         //  console.log(error);
         dispatch({
            type: GET_ALL_MONITOREO_ERROR,
            payload: {
               msg_get_all_monitoreos: error.message, // error.response.data.msg
            },
         });
      });
};

// Action 13 Action 1 - get muestreos
export let getMuestreosAction = () => (dispatch, getState) => {
   dispatch({
      //   type: GET_MUESTREOS,
      //   payload: {
      //      fetching_referentes: true,
      //   },
   });
   axios
      .get(URL_MUESTREOS, {
         crossdomain: true,
      })
      .then((res) => {
         dispatch({
            // type: GET_MUESTREOS_SUCCESS,
            // payload: {
            //    arrary_referentes: res.data,
            //    fetching_referentes: false,
            // },
         });
      })
      .catch((error) => {
         // console.log("referentes", error);
         //  dispatch({
         //     type: GET_MUESTREOS_ERROR,
         //     payload: {
         //        error: error.response,
         //     },
         //  });
      });
};

// Action 14 -get muestras
export let getMuestrasAction = () => (dispatch, getState) => {
   dispatch({
      //   type: GET_MUESTRAS,
      //   payload: {
      //      fetching_instituciones: true,
      //   },
   });
   //consultando la api
   axios
      .get(URL_MUESTRAS, {
         crossdomain: true,
      })
      .then((res) => {
         dispatch({
            // type: GET_MUESTRAS_SUCCESS,
            // payload: {
            //    arrary_instituciones: res.data,
            //    fetching_instituciones: false,
            // },
         });
         saveStorage(getState());
      })
      .catch((error) => {
         console.log("instituciones", error);
         dispatch({
            // type: GET_MUESTRAS_ERROR,
            // payload: {
            //    error: error.response,
            // },
         });
      });
};

// aux 1
function saveStorage(storage) {
   localStorage.storage = JSON.stringify(storage);
}

//aux 2
export let restoreMonitoreosAction = () => (dispatch, getState) => {
   let storage = localStorage.getItem("storage");
   storage = JSON.parse(storage);
   if (storage && storage.user) {
      console.log("ESTORAGE:", storage);
      dispatch({
         type: GET_ALL_MONITOREO_SUCCESS,
         payload: {
            arrary_all_monitoreos: storage.monitoreos.arrary_all_monitoreos,
            isGet_all_monitoreos: storage.monitoreos.isGet_all_monitoreos,
         },
      });
      dispatch({
         type: GET_MONITOREO_SUCCESS,
         payload: {
            arrary_monitoreos: storage.monitoreos.arrary_monitoreos,
         },
      });
      dispatch({
         type: GET_USER_MONITOREO_SUCCESS,
         payload: {
            arrary_monitoreos_user: storage.monitoreos.arrary_monitoreos_user,
         },
      });
      dispatch({
         type: MONITOREO_UPDATE,
         payload: {
            monitoreo_update: storage.monitoreos.monitoreo_update,
            isUpdate_monitoreo: storage.monitoreos.isUpdate_monitoreo,
         },
      });

      dispatch({
         type: VIEW_MONITOREO,
         payload: {
            view_monitoreo: storage.monitoreos.view_monitoreo,
         },
      });
   }
};
