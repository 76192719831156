import React, { useState, useEffect } from "react";
import Nav from "components/base/navs/NavHome";
import Header from "components/base/headers/Header";
import MenuCurador from "components/base/navs/NavCurador";
import Footer from "components/base/Footer";
import { useHistory } from "react-router-dom";
import { URL_APP } from "Routes";
import { TXT_APP } from "Routes";
import {
   setUpdatingMonitoreoAction,
   getMonitoreoAction,
   viewMonitoreoAction,
} from "redux/monitoreoDuck";
import Hederform from "../compartidos/Hederform";
import { connect } from "react-redux";
import ListaMuestras from "../compartidos/ListaMuestras";

function CurarDetalleMuestreo({
   monitoreo_update,
   arrary_all_monitoreos,
   viewMonitoreoAction,
   isGet_all_monitoreos,
}) {
   let txt = TXT_APP();
   let url = URL_APP();
   const [onEdicion, setOnEdicion] = useState(false);
   const [muestraEditar, setMuestraEditar] = useState(false);
   const history = useHistory();

   useEffect(() => {
      //Se busca todos las muestras de este monitoreo
      // getMonitoreoAction({
      //     token:access,
      //     id_monitoreo:monitoreo_update.cod_estacion,
      //     });
   }, []);

   const OnEdicion = (muestra) => {
      viewMonitoreoAction(muestra);
      history.push(url.curar_detalle_muestras);
   };

   return (
      <div>
         <div className='content-principal  content-internas'>
            <div className='temporal-cerar'></div>
            <header className=' internas-h '>
               <Nav />
               <Header titulo={txt.tituloPanelCurador} />
            </header>

            <section class='menubar'>
               <MenuCurador />
            </section>

            <section class='contents-interna sig interna-componente'>
               <div class='container-xl my-3'>
                  <div class='row'>
                     <div class='col-md-12'>
                        <h3 class='title-form-monitoreo'>
                           Monitoreo - {monitoreo_update.prefijo_estacion}
                        </h3>
                        <Hederform
                           monitoreo_update={monitoreo_update}
                           modulo={txt.modulo_cutrador}
                        />
                        <p>&nbsp;</p>
                     </div>
                  </div>
                  {isGet_all_monitoreos &&
                     arrary_all_monitoreos
                        .filter(
                           (ent) =>
                              ent.id_muestreotx ===
                              monitoreo_update.id_muestreotx
                        )
                        .map((muestra) => (
                           <ListaMuestras
                              muestra={muestra}
                              onEdicion={OnEdicion}
                              isCurar={true}
                           />
                        ))}
                  <p>&nbsp;</p>
               </div>
            </section>
            <Footer />
         </div>
      </div>
   );
}
let mapState = (state) => {
   return {
      access: state.user.user.access,
      isUpdate: state.monitoreos.isUpdate,
      monitoreo_update: state.monitoreos.monitoreo_update,
      arrary_monitoreos: state.monitoreos.arrary_monitoreos,
      arrary_all_monitoreos: state.monitoreos.arrary_all_monitoreos,
      isUpdating: state.monitoreos.isUpdating,
      fetching_monitoreo_update: state.monitoreos.fetching_monitoreo_update,
      view_monitoreo: state.monitoreos.view_monitoreo,
      isGet_all_monitoreos: state.monitoreos.isGet_all_monitoreos,
   };
};
// sacar los dispatch de (store) que tiene redux y los coloque en las props de este componente.
const mapDispatch = (dispatch) => {
   return {
      setUpdatingMonitoreoAction: (props) =>
         dispatch(setUpdatingMonitoreoAction(props)),
      getMonitoreoAction: (props) => dispatch(getMonitoreoAction(props)),
      viewMonitoreoAction: (props) => dispatch(viewMonitoreoAction(props)),
   };
};

// connect(mapState,mapDispatch)(this funtion)
export default connect(mapState, mapDispatch)(CurarDetalleMuestreo);
