import React from "react";
import Nav from "components/base/navs/NavHome"
import Header from "components/base/headers/Header"
import Contents from "./Contents"
import Footer from "components/base/Footer"
import NavRaiz from "components/base/navs/NavRaiz";
import {URL_APP, TXT_APP} from '../../../../Routes'

function Formulario() {
  let url =  URL_APP()
  let txt =  TXT_APP()
  return (
    <div>   
        <div className="content-principal  content-internas">  
            <div className="temporal-cerar"></div>      
            <header className=" internas-h ">          
                <Nav/>     
                <Header titulo={txt.tituloFormulario}/>                         
            </header>  
            <section class="menubar">   
            <NavRaiz />
            </section>  
            <Contents/>      
            <Footer/>   
        </div>           
    </div>
  )
}

export default Formulario;
