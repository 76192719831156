import React from 'react'

export default function Hembra({monitoreo_update,errors,register,arrary_referentes}) {

    return (
        <div>
        <div className="grupo-inputs mb-3">
            <span className="tit-grupo-inputs">
                Seguimiento de la Hembra
            </span>
            <div className="form-row">
                <div className="col-sm-6 col-md-6">
                    <div className="input-group mb-2">
                    <input
                        ref={register({})}
                        type="text"
                        className="form-control"
                        placeholder="Cod. Marca 1 encontrada"
                        id="cdg_marca_encont_01"
                        name="cdg_marca_encont_01"
                        style={{ width: "100px" }}
                    />
                    <span className="text-danger text-small d-block mb-2">
                        {errors.cdg_marca_encont_01 && (
                            <span>Este campo es requerido</span>
                        )}
                    </span>
                    <select
                        defaultValue={monitoreo_update.pos_marca_encon_01_des}
                        ref={register({})}
                        className="custom-select"
                        id="id_p_enc_1"
                        name="id_p_enc_1"
                    >
                        <option selected disabled value="">
                            Posición
                        </option>

                        {arrary_referentes.map((item) =>
                            item.tabla === 1108 ? (
                                <option
                                value={item.cod_alfabetico}
                                key={item.cod_numerico}
                                >
                                {item.descripcion}
                                </option>
                            ) : (
                                []
                            )
                        )}
                    </select>
                    <span className="text-danger text-small d-block mb-2">
                        {errors.id_p_enc_1 && (
                            <span>Este campo es requerido</span>
                        )}
                    </span>
                    </div>
                    <div className="input-group mb-2">
                    <input
                        ref={register({})}
                        type="text"
                        className="form-control"
                        placeholder="Cod. Marca 2 encontrada"
                        id="cdg_marca_encont_02"
                        name="cdg_marca_encont_02"
                        style={{ width: "100px" }}
                    />
                    <span className="text-danger text-small d-block mb-2">
                        {errors.cdg_marca_encont_02 && (
                            <span>Este campo es requerido</span>
                        )}
                    </span>

                    <select
                        defaultValue={monitoreo_update.pos_marca_encon_02_des}
                        ref={register({})}
                        className="custom-select"
                        id="id_p_enc_2"
                        name="id_p_enc_2"
                    >
                        <option selected disabled value="">
                            Posición
                        </option>

                        {arrary_referentes.map((item) =>
                            item.tabla === 1108 ? (
                                <option
                                value={item.cod_alfabetico}
                                key={item.cod_numerico}
                                >
                                {item.descripcion}
                                </option>
                            ) : (
                                []
                            )
                        )}
                    </select>
                    <span className="text-danger text-small d-block mb-2">
                        {errors.id_p_enc_2 && (
                            <span>Este campo es requerido</span>
                        )}
                    </span>
                    </div>
                    <div className="input-group mb-2">
                    <input
                        ref={register({})}
                        type="text"
                        className="form-control"
                        placeholder="Cod. Marca 1 implantada"
                        id="cdg_marca_impl_01"
                        name="cdg_marca_impl_01"
                        style={{ width: "100px" }}
                    />
                    <span className="text-danger text-small d-block mb-2">
                        {errors.cdg_marca_impl_01 && (
                            <span>Este campo es requerido</span>
                        )}
                    </span>
                    <select
                        defaultValue={monitoreo_update.pos_marca_impl_01_des}
                        ref={register({})}
                        className="custom-select"
                        id="id_p_imp_1"
                        name="id_p_imp_1"
                    >
                        <span className="text-danger text-small d-block mb-2">
                            {errors.codigo_in && (
                                <span>
                                Este campo es requerido
                                </span>
                            )}
                        </span>
                        <option selected disabled value="">
                            Posición
                        </option>

                        {arrary_referentes.map((item) =>
                            item.tabla === 1108 ? (
                                <option
                                value={item.cod_alfabetico}
                                key={item.cod_numerico}
                                >
                                {item.descripcion}
                                </option>
                            ) : (
                                []
                            )
                        )}
                    </select>
                    <span className="text-danger text-small d-block mb-2">
                        {errors.id_p_imp_1 && (
                            <span>Este campo es requerido</span>
                        )}
                    </span>
                    </div>
                    <div className="input-group mb-2">
                    <input
                        ref={register({})}
                        type="text"
                        className="form-control"
                        placeholder="Cod. Marca 2 implantada"
                        id="cdg_marca_impl_02"
                        name="cdg_marca_impl_02"
                        style={{ width: "100px" }}
                    />
                    <span className="text-danger text-small d-block mb-2">
                        {errors.cdg_marca_impl_02 && (
                            <span>Este campo es requerido</span>
                        )}
                    </span>
                    <select
                        defaultValue={monitoreo_update.pos_marca_impl_02_des}
                        ref={register({})}
                        className="custom-select"
                        id="id_p_imp_2"
                        name="id_p_imp_2"
                    >
                        <option selected disabled value="">
                            Posición
                        </option>

                        {arrary_referentes.map((item) =>
                            item.tabla === 1108 ? (
                                <option
                                value={item.cod_alfabetico}
                                key={item.cod_numerico}
                                >
                                {item.descripcion}
                                </option>
                            ) : (
                                []
                            )
                        )}
                    </select>
                    <span className="text-danger text-small d-block mb-2">
                        {errors.id_p_imp_2 && (
                            <span>Este campo es requerido</span>
                        )}
                    </span>
                    </div>
                </div>
                <div className="col-sm-6 col-md-2">
                    <div className="col  mb-3">
                    <label for="v38">LCC en cm</label>
                    <input
                        ref={register({})}
                        type="number"
                        className="form-control"
                        id="largo_caparazon"
                        name="largo_caparazon"
                        placeholder="00"
                    />
                    <span className="text-danger text-small d-block mb-2">
                        {errors.largo_caparazon && (
                            <span>Este campo es requerido</span>
                        )}
                    </span>
                    </div>
                    <div className="col mb-3">
                    <label for="v39">ACC en cm</label>
                    <input
                        ref={register({})}
                        type="number"
                        className="form-control"
                        id="ancho_caparazon"
                        name="ancho_caparazon"
                        placeholder="00"
                    />
                    <span className="text-danger text-small d-block mb-2">
                        {errors.ancho_caparazon && (
                            <span>Este campo es requerido</span>
                        )}
                    </span>
                    </div>
                </div>
                <div className="col-sm-10 col-md-4 mb-3">
                    <label for="v40">Observaciones</label>
                    <textarea
                    ref={register({                       
                    })}                  
                    className="form-control"
                    id="observaciones_marca"
                    name="observaciones_marca"
                    rows="5"                   
                    ></textarea>
                    <span className="text-danger text-small d-block mb-2">
                    {errors.observaciones_marca && (
                        <span>Este campo es requerido</span>
                    )}
                    </span>
                </div>
            </div>
        </div>
    </div>
    )
}
