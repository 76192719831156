import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Nav from "components/base/navs/NavHome";
import Header from "components/base/headers/Header";
import MenuCurador from "components/base/navs/NavCurador";
import Footer from "components/base/Footer";
import { useForm } from "react-hook-form";
import { URL_APP } from "Routes";
import { useHistory } from "react-router-dom";
import { updatingUserAction,updateUserAction, } from "redux/userDuck";
import Modal from "react-bootstrap/Modal";
import { Button, Form, Alert } from "react-bootstrap";

import Loader from "react-loader-spinner";

function getEntidad(id, array_instituciones) {
   return array_instituciones
      .filter((ent) => ent.codigo_in === id)
      .map((j) => j.nombre_in);
}

function DetalleUsuarios({
   instituciones,
   msg_register,
   isUpdate,
   user_update,
   updatingUserAction,
   arrary_instituciones,
   access,
   fetching_user_updating,
   updateUserAction,
}) {
   let url = URL_APP();
   const [fetchingUpdate, setfetchingUpdate] = useState(false);
   const [show, setShow] = useState(false);
   const [show_descartar, setShow_descartar] = useState(false);
   const history = useHistory();
   let codigo_in = "";
   let celular = "";

   //buscar si hay institucion y celular detalles
   if (user_update.detalles) {
      user_update.detalles.map((item) => {
         if (item.atributo === "Vinculado a") codigo_in = item.valor;
         if (item.atributo === "Télefono") celular = item.valor; //console.log("ok");
      });
   }

   //llenando el formulario con el usuario a validar
   const { register, handleSubmit, errors } = useForm({
      defaultValues: {
         ...user_update,
         celular: celular === "" ? "0000000000" : celular,
         codigo_in:
            codigo_in === ""
               ? "No ingreso Intitucion"
               : getEntidad(codigo_in, instituciones),
      },
   });



   const cancelarUpdate = () => {
      updateUserAction([]);
      history.push(url.curar_usuarios);
};


   //se env actualizr usuario
   const onSubmit = (data) => {
      // console.log(data)
    
      //   updatingUserAction(user_update);
      //   setShow(false);
   };

   const enviarEmail = (data) => {
      console.log("OBSERVACION", data.observacion);     
      setShow_descartar(false);
   };

   useEffect(() => {
      if (fetching_user_updating) {
         setfetchingUpdate(true);
         setTimeout(function () {
            setShow(false);
            setfetchingUpdate(false);
            history.push(url.curar_usuarios);
         }, 2000);
      }
   }, [fetching_user_updating]);




   const onUpdate = (active) => {
      user_update.activo = active;
      updatingUserAction({
         token: access,
         user: user_update,
      });
      };

   

   const handleClose = () => {
      setShow(false);
   };

   const handleShow = () => {
      setShow(true);
   };

   const handleClose_descartar = () => {
      setShow_descartar(false);
   };

   const handleShow_descartar = () => {
      setShow_descartar(true);
   };

   //    alert(JSON.stringify(user_update.detalles[0].valor));
   return (
      <div className="content-principal  content-internas">
         <div className="temporal-cerar"></div>
         <header className=" internas-h ">
            <Nav />
            <Header />
         </header>
         <section class="menubar">   
            <MenuCurador />
         </section> 
         <section className="contents-interna sig interna-componente">
            <form onSubmit={handleSubmit(onSubmit)} className="container-xl my-3">
               <div className="row">
                  <Alert
                     variant={user_update.activo === "S" ? "success" : "danger"}
                     className="container-xl my-3"
                  >
                     {user_update.activo === "S"
                        ? "Usuario activo"
                        : "Usuario desactivado"}
                  </Alert>

                  <div className="col-md-12 mb-3">
                     <h6>Informacion del usuarios</h6>
                  </div>
                  <div className="col-md-12">
                     <div className="form-row">
                        <div className="col-sm-6 col-md-4 mb-3">
                           <label for="v01">Nombres *</label>
                           <input
                              type="text"
                              className="form-control"
                              id="nombre"
                              name="nombre"
                              placeholder="nombre"
                              autocomplete="off"
                              ref={register({})}
                              disabled
                           />
                           <span className="text-danger text-small d-block mb-2">
                              {errors.nombre && (
                                 <span>Este campo es requerido</span>
                              )}
                           </span>
                        </div>
                        <div class="col-sm-6 col-md-4 mb-3">
                           <label for="v02">Apellidos *</label>
                           <input
                              type="text"
                              class="form-control"
                              id="apellido"
                              name="apellido"
                              placeholder="apellido"
                              autocomplete="off"
                              ref={register({})}
                              disabled
                           />
                           <span className="text-danger text-small d-block mb-2">
                              {errors.apellido && (
                                 <span>Este campo es requerido</span>
                              )}
                           </span>
                        </div>
                     </div>

                     <div class="form-row">
                        <div class="col-sm-6 col-md-4 mb-3">
                           <label for="v03">Institución *</label>
                           <input
                              type="text"
                              class="form-control"
                              name="codigo_in"
                              id="codigo_in"
                              placeholder="apellido"
                              autocomplete="off"
                              ref={register({})}
                              disabled
                           />
                           <span className="text-danger text-small d-block mb-2">
                              {errors.codigo_in && (
                                 <span>Este campo es requerido</span>
                              )}
                           </span>
                        </div>
                        <div class="col-sm-6 col-md-4 mb-3">
                           <label for="v05">Correo electrónico *</label>
                           <input
                              autocomplete="off"
                              type="email"
                              class="form-control"
                              id="username_email"
                              name="username_email"
                              placeholder="Correo electrónico"
                              ref={register({})}
                              disabled
                           />
                           <span className="text-danger text-small d-block mb-2">
                              {errors.username_email && (
                                 <span>Este campo es requerido</span>
                              )}
                           </span>
                        </div>
                     </div>
                     <div class="form-row">
                        <div class="col-sm-6 col-md-3 mb-3">
                           <label for="v06">Celular *</label>
                           <input
                              type="number"
                              class="form-control"
                              id="celular"
                              name="celular"
                              placeholder="300 000 0000"
                              ref={register({})}
                              disabled
                           />
                           <span className="text-danger text-small d-block mb-2">
                              {errors.celular && (
                                 <span>Este campo es requerido</span>
                              )}
                           </span>
                        </div>
                     </div>
                  </div>
               </div>

               <div class="row mb-3">
                  <div class="col-md-6 my-3">
                    
                    
                  {user_update.activo === "S" ? (<>
                     <button className="btn btn-danger btn-w-110 mb-3"   onClick={handleShow_descartar} >
                        Inhabilitar
                     </button>                  
                   &nbsp;{" "}
                  </>                     
                  ) :( 
                     <button className="btn btn-success btn-w-110 mb-3" onClick={handleShow} >
                      Validar
                   </button>

                  )}                                              
                  &nbsp;{" "}
                  <button className="btn btn-secondary btn-w-110 mb-3"  onClick={cancelarUpdate} >
                     Cancelar
                  </button>
                    
                  </div>
               </div>

               <p>&nbsp;</p>
            </form>

            {/* descartar  */}
            <Modal show={show_descartar} onHide={handleClose_descartar}>
               <Modal.Header style={{ color: "#30345b" }}>
                  <b>Descartar Usuario </b>
               </Modal.Header>
               <Form onSubmit={handleSubmit(enviarEmail)}>
                  <Modal.Body>
                     {fetchingUpdate ? (
                        <div
                        style={{
                           width: "100%",
                           height: "100",
                           display: "flex",
                           justifyContent: "center",
                           alignItems: "center",
                        }}
                     >
                        <Loader
                           type="ThreeDots"
                           color="#11273c"
                           height="100"
                           width="100"
                        />
                     </div>
                     ) : (
                        <div class="form-row">
                           <div class="col-12 col-md-12 my-3">
                              <label for="v1">Observaciones</label>
                              <textarea
                                 ref={register({})}
                                 class="form-control"
                                 id="v1"
                                 rows="3"
                                 name="observacion"
                              ></textarea>
                           </div>
                        </div>
                     )}
                  </Modal.Body>
                  <Modal.Footer>
                     <Button
                        variant="secondary"
                        onClick={handleClose_descartar}
                     >
                        Cancelar
                     </Button>
                     <Button variant="danger" onClick={() => onUpdate("N")}>
                        Inhabilitar
                     </Button>
                  </Modal.Footer>
               </Form>
            </Modal>


            {/* Confirmar la validacion */}
            <Modal show={show} onHide={handleClose}>
               <Modal.Header style={{ color: "#30345b" }}>
                  <b>Confimar </b>
               </Modal.Header>
               <Modal.Body>
                  {fetchingUpdate ? (
                     <div
                        style={{
                           width: "100%",
                           height: "100",
                           display: "flex",
                           justifyContent: "center",
                           alignItems: "center",
                        }}
                     >
                        <Loader
                           type="ThreeDots"
                           color="#11273c"
                           height="100"
                           width="100"
                        />
                     </div>
                  ) : (
                     <p>¿Confirma validar este usuario?</p>
                  )}
               </Modal.Body>
               <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                     Cancelar
                  </Button>
                  
                  <Button variant="success" onClick={() => onUpdate("S")}>
                     Validar
                  </Button>
               </Modal.Footer>
            </Modal>
        
        
         </section>
         <Footer />

         {/* hide */}
         <input
            id="llavepassword"
            name="llavepassword"
            type="hidden"
            value=""
            ref={register({})}
         ></input>
         <input
            id="activo"
            name="activo"
            type="hidden"
            value="S"
            ref={register({})}
         ></input>
      </div>
   );
}
let mapState = (state) => {
   return {
      instituciones: state.referentes.arrary_instituciones,
      msg_register: state.user.msg,
      access: state.user.user.access,
      isUpdate: state.user.isUpdate,
      user_update: state.user.user_update,
      fetching_user_updating: state.user.fetching_user_updating,
   };
};

const mapDispatch = (dispatch) => {
   return {
      updatingUserAction: (props) => dispatch(updatingUserAction(props)),
      updateUserAction: (props) => dispatch(updateUserAction(props)),
   };
};
export default connect(mapState, mapDispatch)(DetalleUsuarios);
