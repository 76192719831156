import React, { useState, useEffect } from "react";
import Nav from "components/base/navs/NavHome";
import Header from "components/base/headers/Header";
import MenuInvestigador from "components/base/navs/NavInvestigador";
import Footer from "components/base/Footer";
import { URL_APP } from "Routes";
import { TXT_APP } from "Routes";
import { connect } from "react-redux";
import { useToasts } from "react-toast-notifications";
import Loader from "react-loader-spinner";
import {
   updateMuestraAction,
   deleteMonitoreoAction,
   getMonitoreoUserAction,
   setUpdatingMonitoreoAction,
   restoreMonitoreosAction,
} from "redux/monitoreoDuck";
import { useHistory } from "react-router-dom";
import ListaMuestras from "../compartidos/ListaMuestras";
import Hederform from "../compartidos/Hederform";
import Modal from "react-bootstrap/Modal";
import { Alert, Button } from "react-bootstrap";
import Progress from "components/base/Progress";

function DetalleMuestreo({
   fetching_get_user_monitoreos,
   updateMuestraAction,
   isUpdate,
   monitoreo_update,
   arrary_monitoreos_user,
   getMonitoreoUserAction,
   // deleteMuestra,
   muestra,
   onEdicion,
   isCurar,
   deleteMonitoreoAction,
   error_delete,
   fetching_monitoreo_delete,
   msg_delete,
   is_monitoreo_delete,
   access,
   user,
   state,
   setUpdatingMonitoreoAction,
   restoreMonitoreosAction,
}) {
   let url = URL_APP();
   let txt = TXT_APP();
   const history = useHistory();
   const [showMonitoreoCerrado, setShowMonitoreoCerrado] = useState(false);
   const [showEventos, setShoweventos] = useState(false);
   const [mensajeEstado, setMensajeEstado] = useState(false);
   const [show, setShow] = useState(false);
   const [showCerrarMonitoreo, setShowCerrarMonitoreo] = useState(false);
   const [showDelete, setShowDelete] = useState(false);
   const [muestraDelete, setMuestraDelete] = useState(0);
   const [monitoreoItem, setMonitoreoItem] = useState([]);

   useEffect(() => {
      // restoreMonitoreosAction();
      //se actualiza la lista de muestras
      getMonitoreoUserAction({ token: user.access, id: user.userInfo.id });
      //se elmina la muestra cargada para edicion
      updateMuestraAction([]);
   }, []);

   useEffect(() => {
      if (is_monitoreo_delete)
         //se actualiza la lista de muestras
         getMonitoreoUserAction({ token: user.access, id: user.userInfo.id });
      //se elmina la muestra cargada para edicion
      handleCloseDelete();
   }, [is_monitoreo_delete]);

   const OnEdicion = (muestra) => {
      // viewMonitoreoAction(muestra)
      history.push(url.formulario_edit);
      //se guarda el estado de la muestra que se va editar para el formulario
      updateMuestraAction(muestra);
   };

   let deleteMuestra = () => {
      //eliminar registro
      deleteMonitoreoAction({
         token: access,
         id_muestra: muestraDelete,
      });
   };

   const onback = (muestra) => {
      history.goBack();
   };

   // Modal msg
   const handleShow = () => {
      setShow(true);
   };
   const handleClose = () => {
      setShow(false);
   };

   // Modal eventos
   const handleShowEventtos = () => {
      setShoweventos(true);
   };
   const handleCloseEventos = () => {
      setShoweventos(false);
   };

   // Modal eliminar
   const handleShowDelete = (muestra) => {
      setShowDelete(true);
      setMuestraDelete(muestra);
   };

   const handleCloseDelete = () => {
      setShowDelete(false);
   };

   // Modal cerrar monitoreo
   const handleShowCerrarMonitoreo = () => {
      setShowCerrarMonitoreo(true);
   };
   const handleCloseCerrarMonitoreo = () => {
      setShowCerrarMonitoreo(false);
      setMonitoreoItem([]);
   };

   let mensajeNota = (msg) => {
      setMensajeEstado(msg);
      handleShow();
   };
   // Modal monitoreo cerrado correctamente
   const handleShowMonitoreoCerrado = () => {
      setShowMonitoreoCerrado(true);
   };
   const handleCloseMonitoreoCerrado = () => {
      setShowMonitoreoCerrado(false);
      history.goBack();
   };
   const onCerrarMonitoreo = () => {
      if (arrary_monitoreos_user.length > 0) {
         arrary_monitoreos_user
            .filter(
               (ent) => ent.id_muestreotx === monitoreo_update.id_muestreotx
            )
            .map(
               (muestra) =>
                  muestra.notas !== "H" &&
                  setMonitoreoItem([
                     ...monitoreoItem,
                     {
                        id_muestratex: muestra.id_muestratex,
                     },
                  ])
            );
         if (monitoreoItem.length > 0) {
            handleShowCerrarMonitoreo();
         } else {
            //cambia el estado del monitopreo a R = revision
            setUpdatingMonitoreoAction({
               token: access,
               id_estacion: monitoreo_update.id_estacion,
               is_active: "N",
               observacion: "R",
            });
            handleShowMonitoreoCerrado();
         }
      } else {
         handleShowEventtos();
      }
   };
   console.log(monitoreo_update);
   return (
      <div className='content-principal  content-internas'>
         <div className='temporal-cerar'></div>
         <header className=' internas-h '>
            <Nav />
            <Header titulo={txt.tituloDetalleMonitoreos} />
         </header>
         <section class='menubar'>
            <MenuInvestigador />
         </section>
         <section class='contents-interna sig interna-componente'>
            <div class='container-xl my-3'>
               <div class='row'>
                  <div class='col-md-12'>
                     <h3 class='title-form-monitoreo'>
                        Monitoreo - {monitoreo_update.prefijo_estacion} -
                        muestreo - {monitoreo_update.id_muestreotx}{" "}
                     </h3>
                     <Hederform
                        onCerrarMonitoreo={onCerrarMonitoreo}
                        monitoreo_update={monitoreo_update}
                        modulo={txt.modulo_investigador}
                     />
                     <p>&nbsp;</p>
                  </div>
               </div>
               {fetching_get_user_monitoreos ? (
                  <Progress />
               ) : (
                  arrary_monitoreos_user
                     .filter(
                        (ent) =>
                           ent.id_muestreotx === monitoreo_update.id_muestreotx
                     )
                     .map((muestra) => (
                        <>
                           {muestra.id_muestratex && (
                              <div className='row registro-monitoreo'>
                                 <div className='col-6 col-sm-2 col-md-2'>
                                    <b>Id Muestra</b>
                                    <br />
                                    {muestra.id_muestratex}
                                 </div>
                                 <div className='col-6 col-sm-2 col-md-2'>
                                    <b> consecutivo</b>
                                    <br />
                                    {muestra.id_consecutivo}
                                 </div>
                                 <div className='col-6 col-sm-2 col-md-2'>
                                    <b>Fecha</b>
                                    <br />
                                    {muestra.fecha_inicial_mtortuga}
                                 </div>
                                 <div className='col-6 col-sm-2 col-md-2'>
                                    <b>Especie</b>
                                    <br />
                                    {muestra.id_especie_des}
                                 </div>
                                 <div class='col-6 col-sm-2 col-md-2'>
                                    {muestra.notas === "H" ? (
                                       <span
                                          onClick={() =>
                                             mensajeNota(
                                                "Evento del monitoreo realizado correctamente"
                                             )
                                          }
                                          class='mr-5 btn btn btn btn-success btn-w-110 f-14'
                                       >
                                          Finalizado
                                       </span>
                                    ) : (
                                       <span
                                          onClick={() =>
                                             mensajeNota(muestra.notas)
                                          }
                                          class='mr-5 btn btn-danger btn-w-110 f-14'
                                       >
                                          Pendiente
                                       </span>
                                    )}
                                 </div>
                                 <div className='col d-flex align-items-center crudicons'>
                                    {monitoreo_update.notas_generales ===
                                       "ACT" ||
                                    monitoreo_update.notas_generales ===
                                       "CER" ? (
                                       []
                                    ) : (
                                       <>
                                          <a
                                             onClick={() =>
                                                handleShowDelete(
                                                   muestra.id_muestratex
                                                )
                                             }
                                             class='mr-4'
                                             href='\\'
                                          >
                                             <i class='fa f-20 fa-trash-o'></i>
                                          </a>
                                          <a
                                             class='mr-4'
                                             onClick={() => OnEdicion(muestra)}
                                             href='\\'
                                          >
                                             <i class='fa f-20 fa-edit'></i>
                                          </a>
                                       </>
                                    )}
                                 </div>
                              </div>
                           )}
                        </>
                     ))
               )}
               <p>&nbsp;</p>
            </div>
         </section>
         <Footer />
         {/* Modal estado */}
         <Modal show={show}>
            <Modal.Header style={{ color: "#30345b" }}>
               <b>Estado del monitoreo</b>
            </Modal.Header>
            <Modal.Body>
               <p>{mensajeEstado}</p>
            </Modal.Body>
            <Modal.Footer>
               <Button variant='secondary' onClick={handleClose}>
                  Aceptar
               </Button>
            </Modal.Footer>
         </Modal>

         {/* Modal eliminar */}
         <Modal show={showDelete}>
            <Modal.Header style={{ color: "#30345b" }}>
               <b>Confirmar eliminacion de muestra</b>
            </Modal.Header>
            <Modal.Body>
               {fetching_monitoreo_delete ? (
                  <Progress />
               ) : (
                  <>
                     <p>Id Muestra : {muestraDelete}</p>
                     <p>¿Desea eliminar este registro?</p>
                  </>
               )}
            </Modal.Body>
            <Modal.Footer>
               <Button variant='secondary' onClick={handleCloseDelete}>
                  Cancelar
               </Button>
               <Button variant='primary' onClick={deleteMuestra}>
                  Aceptar
               </Button>
            </Modal.Footer>
         </Modal>

         {/* Modal Cerrar monitoreo */}
         <Modal show={showCerrarMonitoreo}>
            <Modal.Header style={{ color: "#30345b" }}>
               <b>Estado del monitoreo</b>
            </Modal.Header>
            <Modal.Body>
               <p>
                  No se puede cerrar el monitoreo las siguientes muestras
                  presenta inconvenientes
               </p>
               {monitoreoItem.map((item) => (
                  <ul>
                     <li>
                        <p>{item.id_muestratex}</p>
                     </li>
                  </ul>
               ))}
            </Modal.Body>
            <Modal.Footer>
               <Button variant='primary' onClick={handleCloseCerrarMonitoreo}>
                  Aceptar
               </Button>
            </Modal.Footer>
         </Modal>

         {/* Modal  monitoreo cerrado corectamente */}
         <Modal show={showMonitoreoCerrado}>
            <Modal.Header style={{ color: "#30345b" }}>
               <b>Estado del monitoreo</b>
            </Modal.Header>
            <Modal.Body>
               <p>Monitoreo cerrado correctamente</p>
            </Modal.Body>
            <Modal.Footer>
               <Button variant='primary' onClick={handleCloseMonitoreoCerrado}>
                  Aceptar
               </Button>
            </Modal.Footer>
         </Modal>

         {/* Modal  no se puede cerrar monitoreo por que no tiene eventos  */}
         <Modal show={showEventos}>
            <Modal.Header style={{ color: "#30345b" }}>
               <b>Estado del monitoreo</b>
            </Modal.Header>
            <Modal.Body>
               <p>Monitoreo no se puede cerrar por que no tiene eventos</p>
            </Modal.Body>
            <Modal.Footer>
               <Button variant='primary' onClick={handleCloseEventos}>
                  Aceptar
               </Button>
            </Modal.Footer>
         </Modal>
      </div>
   );
}
let mapState = (state) => {
   return {
      access: state.user.user.access,
      error_delete: state.monitoreos.error_delete,
      fetching_monitoreo_delete: state.monitoreos.fetching_monitoreo_delete,
      msg_delete: state.monitoreos.msg_delete,
      is_monitoreo_delete: state.monitoreos.is_monitoreo_delete,

      arrary_monitoreos_user: state.monitoreos.arrary_monitoreos_user,
      monitoreo_update: state.monitoreos.monitoreo_update,
      isUpdate: state.monitoreos.isUpdate,
      user: state.user.user,
      fetching_get_user_monitoreos:
         state.monitoreos.fetching_get_user_monitoreos,
   };
};
// sacar los dispatch de (store) que tiene redux y los coloque en las props de este componente.
const mapDispatch = (dispatch) => {
   return {
      updateMuestraAction: (props) => dispatch(updateMuestraAction(props)),
      deleteMonitoreoAction: (props) => dispatch(deleteMonitoreoAction(props)),
      getMonitoreoUserAction: (props) =>
         dispatch(getMonitoreoUserAction(props)),
      setUpdatingMonitoreoAction: (props) =>
         dispatch(setUpdatingMonitoreoAction(props)),

      restoreMonitoreosAction: (props) =>
         dispatch(restoreMonitoreosAction(props)),
   };
};

// connect(mapState,mapDispatch)(this funtion)
export default connect(mapState, mapDispatch)(DetalleMuestreo);
