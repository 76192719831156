import React from "react";
import Nav from "components/base/navs/NavHome";
import Footer from "components/base/Footer";
import { URL_APP } from "Routes"
import {Link} from "react-router-dom"


export default function TLaud() {
  let url = URL_APP();
  return (
    <div>
      <div className="content-principal  content-internas">
        <div className="temporal-cerar"></div>
        <header className=" internas-h ">
          <Nav />
         
            <section class="titseccion bg-bluelight">
                <div class="container-xl h-100 d-flex align-items-center" style={{flexWrap: "wrap"}}>

                    <div class="col-12 col-sm-6 col-md-6">
                        <h4><i>Dermochelys coriacea</i> - Tortuga laúd <span class="add-n-especie">(Vandelli, 1761)</span>
                        </h4>
                    </div>
                    {/* <!--div class="col-12 col-sm-6 col-md-6 d-flex justify-content-end">
                    <a href="https://minambiente.gov.co"><img src={ process.env.PUBLIC_URL +"http://www.invemar.org.co/Invemar3.0-theme/images/img/logos/min-ambiente-gov.jpg" width="240px" alt=""></a>
                  </div--> */}

                </div>
            </section>
           
        </header>
        <div
          class="content-principal  content-internas"
          style={{background: "#ffffff"}}
        >
            <section className="contents-interna content-blank interna-componente">
              <nav aria-label="breadcrumb px-0">
                <ol className="breadcrumb px-0">
                  <li className="breadcrumb-item">
                    <Link to={url.home}>Tortugas Marinas en Colombia</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Tortuga laúd
                  </li>
                </ol>
              </nav>
              <div className="container-xl mb-3">
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <div className="row mb-3">
                      <div className="col-md-5">
                        <div className="con_arbolfichaesp">
                          <div className="cont_listarbol">
                            <ul className="listaarbol_ficha">
                              <li
                                className="ini-arbol"
                               style={{fontStyle: "italic"}}
                              >
                                <span>REINO:</span> ANIMALIA
                                <ul>
                                  <li>
                                    <span>PHYLUM:</span> CHORDATA
                                    <ul>
                                      <li>
                                        <span>CLASE:</span> REPTILIA
                                        <ul>
                                          <li>
                                            <span>ORDEN:</span> TESTUDINES
                                            <ul>
                                              <li>
                                                <span>FAMILIA:</span>{" "}
                                                DERMOCHELYIDAE
                                                <ul>
                                                  <li>
                                                    <span>GÉNERO:</span>{" "}
                                                    DERMOCHELYS
                                                  </li>
                                                </ul>
                                              </li>
                                            </ul>
                                          </li>
                                        </ul>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <h6>Sinonimias</h6>
                        <p>
                          <i>Sphargis coriacea, Testudo coriacea </i>
                        </p>
                      </div>
                      <div className="col-md-4 mb-3">
                        <div
                          id="carouselExampleControls"
                          className="carousel slide"
                          data-ride="carousel"
                        >
                          <div className="carousel-inner">
                            <div className="carousel-item active">
                              <img
                                src={ process.env.PUBLIC_URL +"/static/images/componente-t-t/tlaud-f1.jpg"}
                                className="d-block w-100"
                                alt="..."
                              />
                            </div>
                            <div className="carousel-item">
                              <img
                                src={ process.env.PUBLIC_URL +"/static/images/componente-t-t/tlaud-f2.jpg"}
                                className="d-block w-100"
                                alt="..."
                              />
                            </div>
                            <div className="carousel-item">
                              <img
                                src={ process.env.PUBLIC_URL +"/static/images/componente-t-t/tlaud-f3.jpg"}
                                className="d-block w-100"
                                alt="..."
                              />
                            </div>
                          </div>
                          <a
                            className="carousel-control-prev"
                            href="#carouselExampleControls"
                            role="button"
                            data-slide="prev"
                          >
                            <span
                              className="carousel-control-prev-icon"
                              aria-hidden="true"
                            ></span>
                            <span className="sr-only">Previous</span>
                          </a>
                          <a
                            className="carousel-control-next"
                            href="#carouselExampleControls"
                            role="button"
                            data-slide="next"
                          >
                            <span
                              className="carousel-control-next-icon"
                              aria-hidden="true"
                            ></span>
                            <span className="sr-only">Next</span>
                          </a>
                        </div>
                      </div>
                      <div className="col-md-3 mb-3">
                        <h6>Especies de tortugas marinas en Colombia</h6>
                        <div className="btn-group-vertical f-especie">
                          <a href="ficha-tortuga-carey" className="btn btn-light">
                            {" "}
                            <i>Eretmochelys imbricata</i>
                          </a>
                          <a href="ficha-tortuga-verde" className="btn btn-light">
                            {" "}
                            <i>Chelonia mydas</i>
                          </a>
                          <a href="ficha-tortuga-caguama" className="btn btn-light">
                            {" "}
                            <i>Caretta caretta</i>
                          </a>
                          <a href="javascript:void(0)" className="btn btn-light active">
                            {" "}
                            <i>Dermochelys coriacea </i>
                          </a>
                          <a href="ficha-tortuga-golfina" className="btn btn-light">
                            {" "}
                            <i>Lepidochelys olivacea</i>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12" style={{textAlign: "justify"}}>
                        <h6>Nombres comunes</h6>
                        <p>
                          <i>
                            Caná, tortuga canal, laúd, baula, tinglada, cardón,
                            galápago, jachep, Leatherback
                          </i>
                        </p>
                        <h6>Categoría de amenaza </h6>
                        <p>
                          Global: Vulnerable (VU) A2bd <br />
                          Nacional: En peligro crítico (CR) A2cd
                        </p>
                        <hr />

                        <div
                          className="row d-flex align-items-center"
                          style={{flexWrap: "wrap"}}
                        >
                          <div className="col-md-6">
                            <h6>Descripción</h6>
                            <p>
                              Es la tortuga marina más grande, con una longitud
                              recta del caparazón (LRC) en las hembras
                              reproductivas de 154,7 cm (n = 131, Rueda{" "}
                              <i>et al</i>. 1992a). La cabeza es ovalada y
                              cubierta con piel (sin escamas en los adultos),
                              con un ancho hasta de 25 cm y grandes ojos; con
                              una pequeña muesca a cada lado de la mandíbula
                              superior, dejando dos cúspides maxilares
                              conspicuas, en forma de “W” (Pritchard y Mortimer,
                              2000; Wyneken 2004). Aletas delanteras
                              extremadamente largas y sin escamas, todas las
                              aletas desprovistas de uñas. Caparazón alargado
                              con siete crestas longitudinales en el dorso,
                              blando con textura de cuero. Las crías están
                              cubiertas con pequeñas escamas embebidas a lo
                              largo de las crestas (Pritchard y Mortimer, 2000).
                              Plastrón relativamente pequeño y flexible.
                              Coloración predominantemente negra dorsalmente,
                              con moteado blanco (Wyneken, 2004).
                            </p>
                          </div>
                          <div className="col-md-6 text-center ">
                            <img
                              className="img-fluid"
                              src={ process.env.PUBLIC_URL +"/static/images/componente-t-t/tlaud-cd.jpg"}
                              alt=""
                            />
                            <cite>
                              Tomada de Ecker <i>et al</i>., 2000. Técnicas de
                              Investigación y Manejo para la Conservación de las
                              Tortugas Marinas
                            </cite>
                          </div>
                        </div>
                        <hr />
                        <h6>Distribución y hábitat</h6>
                        <div className="row mt-3">
                          <div
                            className="col-md-12 d-flex justify-content-center"
                            style={{flexWrap: "wrap"}}
                          >
                            <img
                              className="w-75"
                              src={ process.env.PUBLIC_URL +"/static/images/componente-t-t/tlaud-md.jpg"}
                              alt=""
                            />
                            <cite>
                              Mapa de distribución de{" "}
                              <i>Dermochelys coriacea</i>.
                              www.fao.org/fishery/en., Acceso 27 Nov. 2020.
                            </cite>
                            <p className="mt-3">
                              Distribución circumglobal, se extiende a través de
                              todos los océanos a excepción de la Antártida. Las
                              principales playas de anidación están en el
                              litoral Pacífico de México, la costa Atlántica y
                              Pacífica de Costa Rica, Trinidad y Tobago, Guayana
                              Francesa, Islas Vírgenes, Puerto Rico y República
                              Dominicana. También desovan en menor cantidad en
                              Guatemala, Panamá y las islas de Barlovento y
                              Sotavento. En Colombia se han reportado anidando
                              en el departamento del Magdalena en las playas
                              Buritaca-Don Diego, Isla de Salamanca,
                              Palomino–Mendihuaca y el Parque Nacional Natural
                              Tayrona (Nicéforo 1953, Medem 1962, Pinzón y
                              Saldaña 1999, Sánchez 2002, Gutiérrez y Merizalde
                              2001, Álvarez-León 2001, Marrugo y Vásquez 2001);
                              en el departamento de Antioquia en el Cerro del
                              Águila-Punta Arenas, Necoclí (Rueda <i>et al</i>.
                              1992a); y en el departamento de Chocó en el Golfo
                              de Urabá (Rueda <i>et al</i>. 1992a, Jiménez y
                              Martínez 1988, MMA 2002). Amorocho <i>et al</i>.
                              (1992) con base en información obtenida de
                              habitantes locales del litoral Pacífico,
                              reportaron la anidación esporádica de esta especie
                              hacia el norte en Bahía Octavia, y hacia el sur
                              Parque Sanquianga. Sin embargo, se desconocen
                              reportes de anidación posteriores a esa
                              publicación. Los pescadores locales del
                              corregimiento El Valle, Bahía Solano (Chocó), dan
                              testimonio que en la década de los 80 se
                              presentaba esporádicamente la anidación de hembras
                              de baula en la playa El Valle -donde anida la
                              tortuga golfina (<i>Lepidochelys olivacea</i>),
                              pero éstas eran sacrificadas y sus nidos
                              saqueados. Presenta migración latitudinal,
                              longitudinal, transfronteriza y local. En el
                              Atlántico se conoce migraciones desde Canadá hasta
                              las Islas Vírgenes. En el 2009, un individuo
                              marcado satelitalmente en Canadá, desovó en La
                              Playona, en Acandí (Urabá chocoano). Para el
                              Pacífico Oriental se conocen migraciones desde el
                              Parque Marino Las Baulas (Costa Rica) hasta las
                              islas Galápagos.
                            </p>
                          </div>
                        </div>
                        <hr />
                        <h6>Ciclo de vida</h6>
                        <p>
                          Según Dutton y Stewart (2013) no hay consenso acerca
                          de la edad de maduración sexual: 13-14 años (Zug y
                          Parham, 1996), 12-14 años (Dutton <i>et al</i>.,
                          2005), 16 años (Jones <i>et al</i>., 2011) y 25-29
                          años (Avens <i>et al</i>., 2009). No anidan todos los
                          años, sino que tienen intervalos de dos a tres años y
                          pueden reanidar de 4-11 veces por temporada,
                          generalmente dejando 9-10 días entre dos desoves
                          consecutivos (Alvarado y Murphy, 2000). Las nidadas
                          tienen en promedio 81 huevos con yema y 34 huevos
                          infértiles por nido (Patiño-Martínez <i>et al</i>.
                          2010), y se incuban durante aproximadamente 60 días en
                          nidos que van desde 0,6 m a 1 m de profundidad
                          (Whitmore y Dutton, 1985).
                        </p>
                        <p>
                          En el Caribe colombiano la estación de anidación va de
                          finales de febrero hasta principios de julio, y en el
                          Golfo de Urabá se presentan dos picos de anidación,
                          uno en abril y otro en mayo (Rueda <i>et al</i>.
                          1992a). En otras partes del Caribe la temperatura
                          pivotal promedio varía entre 29,3–29,5 °C, pero en
                          Colombia aún no se ha estimado. Cuando se acerca la
                          temporada de desove, los adultos migran miles de
                          kilómetros desde zonas de forrajeo neríticas y
                          oceánicas hasta las áreas reproductivas en el trópico
                          (Stewart <i>et al</i>. 2013), y se mantienen
                          relativamente cerca de las playas de anidación para el
                          cortejo y apareamiento (James <i>et al</i>. 2005). Las
                          hembras pueden viajar entre playas adyacentes o
                          distantes (Plotkin, 2003) dentro de una temporada de
                          anidación. Al concluir la estación reproductiva las
                          hembras y machos migran de nuevo largas distancias a
                          través de aguas oceánicas. Ésta es la tortuga marina
                          de mayor dispersión y una de las especies de
                          vertebrados marinos que realiza las migraciones más
                          largas del planeta. Además, es la tortuga marina que
                          se sumerge a mayor profundidad (aproximadamente 120
                          m), en busca de su dieta especializada que incluye
                          animales gelatinosos (medusas y cténoforos), y la que
                          soporta las temperaturas ambientales más bajas (Eckert{" "}
                          <i>et al</i>., 2012).
                        </p>
                        <p>
                          Actualmente, entre febrero y julio llegan
                          aproximadamente 250 hembras a desovar a La Playona,
                          Acandí y otras playas del Golfo de Urabá. Esta es la
                          colonia más importante de Colombia, y la segunda en
                          Centroamérica después de la de Tortuguero en Costa
                          Rica. Al parecer la abundancia de medusas, su alimento
                          favorito, entre diciembre y marzo, está relacionada
                          con la presencia de baulas o canás, como se les conoce
                          localmente en esta zona del Darién - Chocó, Caribe. En
                          el Pacífico colombiano hay reportes de desove, aunque
                          esporádicos, en playas de bahía Octavia y en la playa
                          de El Valle en Chocó. Individuos de esta especie han
                          sido capturados por pescadores artesanales en
                          cercanías de la bocana del río Guapi (Rueda, 2002;
                          CIMAD datos sin publicar). Actividad reproductiva de
                          la tortuga baula también ha sido reportada en playas
                          del Parque Nacional Natural Sanquianga y las
                          poblaciones de San Juan de la Costa y Milagros en
                          Nariño (Amorocho <i>et al</i>., 1992).
                        </p>
                        <hr />
                        <h6>Estado de Conservación</h6>
                        <p>
                          En las playas de Acandí y La Playona la captura
                          incidental con redes de enmalle de la pesquería
                          artesanal sacrifica al año de 5 a 20 individuos
                          adultos (Patiño-Martinez <i>et al</i>., 2012,
                          Fundación Mamá Basilia com. pers.). El número y el
                          tipo de amenazas varían entre los diferentes hábitats,
                          como playas de anidación y hábitats marinos de
                          inter-anidación. En el ambiente terrestre, la
                          degradación de las playas de anidación, generalmente
                          por erosión costera y uso para fines comerciales de
                          éstas, el sacrificio masivo y continuo de individuos
                          adultos (principalmente en la media y alta Guajira) y
                          la recolección ilegal de huevos, siguen siendo
                          amenazas constantes en todas las playas en que la
                          especie anida. Rueda <i>et al</i>. (1992b),
                          comprobaron que los pescadores e indígenas de La
                          Guajira consumen regularmente tortuga caná. Estas
                          tortugas también son utilizadas para extraer aceite.
                          En el Pacífico el uso de la especie es poco frecuente,
                          debido a que anida de manera muy esporádica y solo es
                          avistada o capturada incidentalmente por pescadores.
                          Al igual que las otras especies de tortugas marinas,
                          la caná ha sido severamente diezmada en los últimos 20
                          años en las playas de Mexiquillo (México) y Guanacaste
                          (Costa Rica), principales áreas de reproducción en el
                          Pacífico Oriental Tropical, debido al saqueo de sus
                          nidos, el sacrificio de individuos en mar, la captura
                          incidental en artes de pesca y la contaminación
                          marina. Esta última es tal vez la peor amenaza, dado
                          que esta especie se alimenta exclusivamente de agua
                          malas (medusas), es común que confunda su alimento con
                          bolsas plásticas que al ser ingeridas le causan la
                          muerte.
                        </p>
                        <hr />
                        <h6>Medidas de Conservación</h6>
                        <p>
                          En el ámbito nacional está protegida por medidas a
                          nivel general como el Decreto 1681 de 1978 del
                          Inderena, Acuerdo 021 de 1991 del Inderena, Resolución
                          2879 de 1995 de Corpoguajira, Resolución 1644 de 1998
                          de Corpamag y Artículo 328 del Código Penal, entre
                          otras; y a nivel internacional, se encuentra en el
                          Apéndice I de CITES, en el Apéndice I y II de la
                          Convención de Bonn y en el Anexo II del Protocolo
                          SPAW, lo cual genera acciones de cooperación para la
                          conservación de la especie.
                        </p>
                        <hr />
                        <h6>Ficha SiAM</h6>
                        <a href="https://siam.invemar.org.co/sibm-historia-natural-especies-ficha/70.55.3574">
                          https://siam.invemar.org.co/sibm-historia-natural-especies-ficha/70.55.3574
                        </a>
                        <h6>Ficha SIB</h6>
                        <a href="https://catalogo.biodiversidad.co/file/56d8ed5d3c16479905cba97e/summary">
                          https://catalogo.biodiversidad.co/file/56d8ed5d3c16479905cba97e/summary
                        </a>
                        <hr />
                        <h6>Referencias Generales</h6>
                        <p>
                          *Amorocho, D. 2014. <i>Dermochelys coriacea</i>. Pp.
                          256-259. En: Amaya-Espinel, J. y Zapata, L.
                          (Editores). Guía de las especies migratorias de la
                          biodiversidad en Colombia. Insectos, murciélagos,
                          tortugas marinas, mamíferos marinos y dulceacuícolas.
                          Vol. 3. Ministerio de Ambiente y Desarrollo Sostenible
                          / WWF-Colombia. Bogotá, D.C. Colombia. 370 pp.
                          *Ramírez, C., Páez, V., y Barrientos-Muñoz, K.. 2015.{" "}
                          <i>Dermochelys coriacea</i>. Pp. 122-126. En:
                          Morales-Betancourt, M., Lasso, C., Páez, V. y Bock, B.
                          Libro rojo de reptiles de Colombia. Instituto de
                          Investigación de Recursos Biológicos Alexander von
                          Humboldt (IAvH), Universidad de Antioquia. Bogotá,
                          D.C. Colombia. 258 pp. *Wallace, B., Tiwari, M. y
                          Girondot, M. 2013. <i>Dermochelys coriacea</i>. The
                          IUCN Red List of Threatened Species 2013:
                          e.T6494A43526147. 
                          https://dx.doi.org/10.2305/IUCN.UK.20132.RLTS.T6494A43526147.en.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <Footer />
      </div>
    </div>
  );
}
