import React, { useState, useEffect } from "react";
import Nav from "components/base/navs/NavHome";
import MenuHome from "components/base/navs/NavRaiz";
import Header from "components/base/headers/Header";
import Footer from "components/base/Footer";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
// import Recaptcha from "react-recaptcha";
import ReCAPTCHA from "react-google-recaptcha";
import { Alert } from "react-bootstrap";

import { registerLoginAction } from "redux/userDuck";
import { getInstitucionesAction } from "redux/referentesDuck";
import { URL_APP, TXT_APP } from "Routes";

function Register({
   instituciones,
   msg,
   registerLoginAction,
   getInstitucionesAction,
   error,
}) {
   let txt = TXT_APP();
   let url = URL_APP();
   useEffect(() => {
      getInstitucionesAction();
   }, []);

   const [recaptcha, setRecaptcha] = useState(false);
   const [recaptchaMsg, setRecaptchaMsg] = useState(false);
   const { register, handleSubmit, errors, onChange } = useForm();
   const [resetForm, setResetForm] = useState({});
   const [errorPassword, setErrorPassword] = useState(false);

   //para llamar el modal del login
   const [show, setShow] = useState(false);
   let mostrar = () => {
      setShow(true);
   };
   let ocultar = () => {
      setShow(false);
   };

   const onSubmit = (data, e) => {
      // e.preventDefault();
      if (recaptcha) {
         if (data.password === data.password2) {
            registerLoginAction(data);
            setResetForm(e);
         } else {
            setErrorPassword(true);
            setTimeout(function () {
               setErrorPassword(false);
            }, 5000);
         }
      } else {
         setRecaptchaMsg(true);
         setTimeout(function () {
            setRecaptchaMsg(false);
         }, 3000);
      }
   };

   let onChangeRecaptcha = (value) => {
      setRecaptcha(true);
      setRecaptchaMsg(false);
   };

   return (
      <div className='content-principal  content-internas'>
         <div className='temporal-cerar'></div>
         <header className=' internas-h '>
            <Nav />
            <Header />
         </header>
         <section class='menubar'>
            <MenuHome />
         </section>
         <section class='contents-interna sig interna-componente'>
            {errorPassword && (
               <Alert variant={"danger"} className='container-xl my-3'>
                  Error La contraseña de verificación no coincide.
               </Alert>
            )}

            {error.length > 0 ? (
               <Alert variant={"danger"} className='container-xl my-3'>
                  {error}
               </Alert>
            ) : (
               []
            )}

            {msg.length > 0 ? (
               <>
                  {resetForm.target.reset()}
                  <Alert variant={"success"} className='container-xl my-3'>
                     {msg}
                  </Alert>
               </>
            ) : (
               []
            )}

            <form onSubmit={handleSubmit(onSubmit)} class='container-xl my-3'>
               <div class='row'>
                  <div class='col-md-12 mb-3'>
                     <h6>Registro de usuarios</h6>
                  </div>
                  <div class='col-md-12'>
                     <div class='form-row'>
                        <div class='col-sm-6 col-md-4 mb-3'>
                           <label for='v01'>Nombres *</label>
                           <input
                              type='text'
                              class='form-control'
                              id='nombre'
                              name='nombre'
                              placeholder='nombre'
                              autocomplete='off'
                              ref={register({ required: true })}
                           />
                           <span className='text-danger text-small d-block mb-2'>
                              {errors.nombre && (
                                 <span>Este campo es requerido</span>
                              )}
                           </span>
                        </div>
                        <div class='col-sm-6 col-md-4 mb-3'>
                           <label for='v02'>Apellidos *</label>
                           <input
                              type='text'
                              class='form-control'
                              id='apellido'
                              name='apellido'
                              placeholder='apellido'
                              autocomplete='off'
                              ref={register({ required: true })}
                           />
                           <span className='text-danger text-small d-block mb-2'>
                              {errors.apellido && (
                                 <span>Este campo es requerido</span>
                              )}
                           </span>
                        </div>
                     </div>

                     <div class='form-row'>
                        <div class='col-sm-6 col-md-4 mb-3'>
                           <label for='v03'>Institución *</label>
                           {/* <input type="text" class="form-control" id="v03" placeholder="Institución" ref={register({ required: true })} /> */}
                           <select
                              className='form-control'
                              name='codigo_in'
                              id='codigo_in'
                              ref={register({ required: true })}
                           >
                              <option selected disabled value=''>
                                 Seleccione...
                              </option>
                              {instituciones.map((item) => (
                                 <option
                                    value={item.codigo_in}
                                    key={item.codigo_in}
                                 >
                                    {item.nombre_in}
                                 </option>
                              ))}
                           </select>
                           <span className='text-danger text-small d-block mb-2'>
                              {errors.codigo_in && (
                                 <span>Este campo es requerido</span>
                              )}
                           </span>
                        </div>

                        <div class='col-sm-6 col-md-4 mb-3'>
                           <label for='v05'>Correo electrónico *</label>
                           <input
                              autocomplete='off'
                              type='email'
                              class='form-control'
                              id='username_email'
                              name='username_email'
                              placeholder='Correo electrónico'
                              ref={register({ required: true })}
                           />
                           <span className='text-danger text-small d-block mb-2'>
                              {errors.username_email && (
                                 <span>Este campo es requerido</span>
                              )}
                           </span>
                        </div>
                     </div>

                     <div class='form-row'>
                        <div class='col-sm-6 col-md-3 mb-3'>
                           <label for='v06'>telefono *</label>
                           <input
                              // pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                              type='number'
                              class='form-control'
                              id='telefono'
                              name='telefono'
                              placeholder='300 000 0000'
                              ref={register({ required: true })}
                           />
                           <span className='text-danger text-small d-block mb-2'>
                              {errors.telefono && (
                                 <span>Este campo es requerido</span>
                              )}
                           </span>
                        </div>
                     </div>

                     <div class='form-row'>
                        <div class='col-sm-6 col-md-4 mb-3'>
                           <label for='v07'>Contraseña *</label>
                           <input
                              type='password'
                              class='form-control'
                              id='password'
                              name='password'
                              placeholder=''
                              ref={register({
                                 required: {
                                    value: true,
                                    message: "Este campo es requerido",
                                 },
                                 minLength: {
                                    value: 6,
                                    message: "Minimo 6 Caracteres",
                                 },
                              })}
                           />
                           <span className='text-danger text-small d-block mb-2'>
                              {errors.password && (
                                 <span>{errors.password.message}</span>
                              )}
                           </span>
                        </div>
                        <div class='col-sm-6 col-md-4 mb-3'>
                           <label for='v08'>
                              Vuelva a escribir la contraseña *
                           </label>
                           <input
                              type='password'
                              class='form-control'
                              id='password2'
                              name='password2'
                              placeholder=''
                              ref={register({
                                 required: {
                                    value: true,
                                    message: "Este campo es requerido",
                                 },
                                 minLength: {
                                    value: 6,
                                    message: "Minimo 6 Caracteres",
                                 },
                              })}
                           />
                           <span className='text-danger text-small d-block mb-2'>
                              {errors.password2 && (
                                 <span>{errors.password2.message}</span>
                              )}
                           </span>
                        </div>
                     </div>
                  </div>
               </div>
               <div class='row mb-3'>
                  <div class='col-md-6 my-3'>
                     <button type='submit' className='btn btn-primary'>
                        Registrarse
                     </button>
                  </div>
                  <div class='col-md-6'>
                     <ReCAPTCHA
                        sitekey='6Ldmx_QmAAAAAMyq-oyprlp-4i2Dxrz7zuGZlTVV'
                        onChange={onChangeRecaptcha}
                     />
                     <span className='text-danger text-small d-block mb-2'>
                        {recaptchaMsg ? (
                           <span>Este campo es requerido</span>
                        ) : (
                           []
                        )}
                     </span>
                  </div>
               </div>

               <p>&nbsp;</p>
               <input
                  id='llavepassword'
                  name='llavepassword'
                  type='hidden'
                  value=''
                  ref={register({})}
               ></input>
            </form>
   
   
         </section>
         <Footer />
      </div>
   );
}

let mapState = (state) => {
   return {
      instituciones: state.referentes.arrary_instituciones,
      msg: state.user.msg,
      error: state.user.error,
   };
};

// console.log("entra")
const mapDispatch = (dispatch) => {
   return {
      registerLoginAction: (props) => dispatch(registerLoginAction(props)),
      getInstitucionesAction: () => dispatch(getInstitucionesAction()),
   };
};
export default connect(mapState, mapDispatch)(Register);
