import React from "react";
import { NavLink } from "react-router-dom";
import { URL_APP } from "Routes";

export default function MenuInvestigador() {
   let url = URL_APP();
   return (
      <nav class='navbar navbar-expand-lg navbar-light bg-light'>
         <NavLink class='navbar-brand' to={url.panel_curador}>
            MENÚ
         </NavLink>
         <button
            class='navbar-toggler'
            type='button'
            data-toggle='collapse'
            data-target='#navbarSupportedContent'
            aria-controls='navbarSupportedContent'
            aria-expanded='false'
            aria-label='Toggle navigation'
         >
            <span class='navbar-toggler-icon'></span>
         </button>
         <div class='collapse navbar-collapse' id='navbarSupportedContent'>
            <ul class='navbar-nav invemar mr-auto'>
               <li className='nav-item'>
                  <NavLink
                     activeStyle={{
                        fontWeight: "bold",
                        color: "#3338FF",
                     }}
                     className='nav-link'
                     to={url.curar_monitoreo}
                  >
                     Curaduría de monitoreos
                  </NavLink>
               </li>
               <li className='nav-item'>
                  <NavLink
                     activeStyle={{
                        fontWeight: "bold",
                        color: "#3338FF",
                     }}
                     className='nav-link'
                     to={url.curar_usuarios}
                  >
                     Administración de usuarios
                  </NavLink>
               </li>
            </ul>
            {/* <MenuLogin /> */}
         </div>
      </nav>
   );
}
