import React from "react";
import { connect } from "react-redux";
import Nav from "components/base/navs/NavHome";
import Header from "components/base/headers/Header";
import Footer from "components/base/Footer";
import { useHistory } from "react-router-dom";
import { URL_APP, TXT_APP } from "Routes";
import MenuRaiz from "components/base/navs/NavRaiz";

function Panel() {
   let url = URL_APP();
   let txt = TXT_APP();
   const history = useHistory();

   let irUsuarios = () => {
      history.push(url.curar_usuarios);
   };

   let irMonitoreos = () => {
      history.push(url.curar_monitoreo);
   };

   return (
      <div>
         <div className='content-principal  content-internas'>
            <div className='temporal-cerar'></div>
            <header className=' internas-h '>
               <Nav />
               <Header titulo={txt.tituloPanelCurador} />
            </header>
            <section class='menubar'>
               <MenuRaiz />
            </section>
            <section className='contents-interna sig interna-componente'>
               <div className='container-xl my-3'>
                  <div className='row'>
                     <div className='col-md-4'>
                        <a href='javascript:void(0)' onClick={irMonitoreos}>
                           <div className='card w-100 mb-5 mx-0'>
                              <img
                                 src={ process.env.PUBLIC_URL +'/static/images/componente-t-t/curacionMonitoreo@2x.jpg'}
                                 className='card-img-top'
                                 alt='...'
                              />
                              <div className='card-body'>
                                 <p className='card-text'>
                                    <i
                                       className='fa fa-check-square-o'
                                       aria-hidden='true'
                                    ></i>{" "}
                                    Curaduría de monitoreos
                                 </p>
                              </div>
                           </div>
                        </a>
                     </div>
                     <div className='col-md-4'>
                        <a href='javascript:void(0)' onClick={irUsuarios}>
                           <div className='card w-100 mb-5 mx-0'>
                              <img
                                 src={ process.env.PUBLIC_URL +'/static/images/componente-t-t/curacioUsuarios@2x.jpg'}
                                 className='card-img-top'
                                 alt='...'
                              />
                              <div className='card-body'>
                                 <p className='card-text'>
                                    <i
                                       className='fa fa-users'
                                       aria-hidden='true'
                                    ></i>{" "}
                                    Administración de usuarios
                                 </p>
                              </div>
                           </div>
                        </a>
                     </div>
                  </div>

                  <p>&nbsp;</p>
               </div>
            </section>
            <Footer />
         </div>
      </div>
   );
}

let mapState = (state) => {
   return {};
};

const mapDispatch = (dispatch) => {
   return {};
};
export default connect(mapState, mapDispatch)(Panel);
