import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import generateStore from "./redux/store";
import { ToastProvider } from "react-toast-notifications";
// import { createBrowserHistory} from "history";




//   const history = createBrowserHistory({
//     basename: '/tortugas'
//   });


// console.log =()=>{}
let store = generateStore();
let WithRouter = () => (
   <BrowserRouter >
      <WithStore />
   </BrowserRouter>
);
let WithStore = () => (
   <Provider store={store}>
      <WithToast />
   </Provider>
);
let WithToast = () => (
   <ToastProvider>
     <App />
   </ToastProvider>
);

ReactDOM.render(<WithRouter />, document.getElementById("root"));
