import React from "react";
import defaultVariables from "Routes";

export default function ContentsNidoRelo({ errors, register }) {
   let dfv = defaultVariables();
   return (
      <div>
         <div className='grupo-inputs mb-3'>
            <span className='tit-grupo-inputs'>
               Coordenadas del nido relocalizado
            </span>
            <div className='form-row'>
               <div className='col-sm-3 col-md-2 mb-3'>
                  <label for='v29'>Latitud</label>
                  <input
                     ref={register({})}
                     type='number'
                     className='form-control'
                     id='latitud_relo'
                     name='latitud_relo'
                     min={dfv.latitud_min}
                     max={dfv.latitud_max}
                     placeholder={dfv.placeholder_coordinates}
                  />
                  <span className='text-danger text-small d-block mb-2'>
                     {errors.latitud_relo && (
                        <span>Este campo es requerido</span>
                     )}
                  </span>
               </div>
               <div className='col-sm-3 col-md-2 mb-3'>
                  <label for='v30'>Longitud</label>

                  <input
                     ref={register({
                        required: true,
                        max: dfv.latitud_max,
                        min: dfv.latitud_min,
                        maxLength: 12,
                        pattern: /00.000000/i,
                        placeholder: dfv.placeholder_coordinates,
                     })}
                     type='number'
                     className='form-control'
                     id='longitud_relo'
                     name='longitud_relo'
                  />
                  <span className='text-danger text-small d-block mb-2'>
                     {errors.longitud_relo && (
                        <span>Este campo es requerido</span>
                     )}
                  </span>
               </div>
               <div className='col-sm-3 col-md-2 mb-3'>
                  <label for='v31'>Prof. original en cm</label>
                  <input
                     ref={register({})}
                     type='number'
                     className='form-control'
                     id='profundidad_original'
                     name='profundidad_original'
                     placeholder='00'
                  />
                  <span className='text-danger text-small d-block mb-2'>
                     {errors.profundidad_original && (
                        <span>Este campo es requerido</span>
                     )}
                  </span>
               </div>
               <div className='col-sm-3 col-md-3 mb-3'>
                  <label for='v32'>Prof. relocalización en cm</label>
                  <input
                     ref={register({})}
                     type='number'
                     className='form-control'
                     id='profundidad_rlc'
                     name='profundidad_rlc'
                     placeholder='00'
                  />
                  <span className='text-danger text-small d-block mb-2'>
                     {errors.profundidad_rlc && (
                        <span>Este campo es requerido</span>
                     )}
                  </span>
               </div>
               <div className='col-sm-3 col-md-3 mb-3'>
                  <label for='v33'>Hora del siembra</label>
                  <input
                     ref={register({})}
                     type='time'
                     className='form-control'
                     id='hora_siembra'
                     name='hora_siembra'
                     placeholder='00:00'
                  />
                  <span className='text-danger text-small d-block mb-2'>
                     {errors.hora_siembra && (
                        <span>Este campo es requerido</span>
                     )}
                  </span>
               </div>
            </div>
         </div>
      </div>
   );
}
