import React from 'react'
import { Link } from "react-router-dom";
import { URL_APP } from "../../../../Routes";

export default function Encabezado({register}) {
    let url = URL_APP();
    return (
            <div className="encabezado-form ">
                <h6 className="mb-3">Datos del encabezado</h6>
                <div className="form-row">  
                    <div className="col-sm-12 col-md-3 mb-3 ">
                        <label for="v01">Código de ingresos</label>                             
                        <input 
                            ref={register({
                            /*required: true*/
                            })}
                            type="text"
                            className="form-control  deshabilitado"
                            id="cod_ingreso"
                            name="cod_ingreso"                                            
                            placeholder="Codigo ingreso"
                            readonly
                        />                    
                    </div>
                    <div className="col-md-3 col-md-3 mb-3">
                        <label for="v05">Responsable</label>
                        <input
                            ref={register({
                                /*required: true*/
                            })}
                            type="text"
                            className="form-control   deshabilitado"
                            id="Responsable"
                            name="Responsable"
                            placeholder="Responsable"            
                            readonly
                        />
                        <input
                            id="id_responsable"
                            name="id_responsable"
                            type="hidden"
                            ref={register({
                                /*required: true*/
                            })}
                        ></input>            
                    </div>
                    <div className="col-sm-6 col-md-6 mb-3">
                            <label for="v01">Unid. costeras</label>                        
                            <input
                            ref={register({
                                /*required: true*/
                            })}
                            type="text"
                            className="form-control   deshabilitado"
                            id="id_auc"
                            name="id_auc"
                            placeholder="Responsable"            
                            readonly
                        />                     
                
                        </div>
                </div>
                <div className="form-row">  
                    <div className="col-sm-6 col-md-4 mb-3">
                        <label for="v02">Institución</label>

                        <input
                            ref={register({
                                /*required: true*/
                            })}
                            type="text"
                            className="form-control   deshabilitado"
                            id="id_institucion"
                            name="id_institucion"
                            placeholder="Responsable"            
                            readonly
                        /> 
                    
                    
                    </div>
                    <div className="col-sm-12 col-md-4 mb-3">
                    <label for="v06">Area protegida</label>{" "}
                    
                    
                    <input
                            ref={register({
                                /*required: true*/
                            })}
                            type="text"
                            className="form-control   deshabilitado"
                            id="id_zonas_protegidas"
                            name="id_zonas_protegidas"
                            placeholder="Responsable"            
                            readonly
                        />                 
            
                    </div>
                    <div className="col-sm-4 col-md-4 mb-3">
                        <label for="v07">Playa</label>
                        <input
                            ref={register({
                                /*required: true*/
                            })}
                            type="text"
                            className="form-control"
                            id="playa"
                            name="playa"
                            placeholder="Lugar"
                                                />
                    
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-sm-4 col-md-3 mb-3">
                    <label for="v02">Departamento</label>
                    <input
                            ref={register({
                                /*required: true*/
                            })}
                            type="text"
                            className="form-control   deshabilitado"
                            id="id_departamento"
                            name="id_departamento"
                            placeholder="Responsable"            
                            readonly
                        />   
                    
                    </div>
                    <div className="col-sm-4 col-md-3 mb-3">
                    <label for="v02">Municipio</label>                        
                    <input
                            ref={register({
                                /*required: true*/
                            })}
                            type="text"
                            className="form-control   deshabilitado"
                            id="id_municipio"
                            name="id_municipio"
                            placeholder="Responsable"            
                            readonly
                        />  
                        
                    </div>
                    <div className="col-sm-4 col-md-3 mb-3">
                        <label for="v03">Fecha inicial</label>                     
                        <input
                            ref={register({                         
                            })}
                            type="text"
                            className="form-control"
                            id="fecha_inicio_monitoreo"
                            name="fecha_inicio_monitoreo" 
                            readonly                    
                        /> 
                    
                
                    </div>
                    <div className="col-sm-4 col-md-3 mb-3">
                        <label for="v04">Fecha final</label>
                        <input
                            ref={register({
                                /*required: true*/
                            })}                        
                            type="text"
                            className="form-control"
                            name="fecha_fin_monitoreo"
                            id="fecha_fin_monitoreo"
                            placeholder="dd/mm/aa"
                            readonly
                        />
                        
                    
                    </div>
                    <div className="col-sm-6 col-md-6 mb-3">
                        <div className="grupo-inputs">
                            <span className="tit-grupo-inputs-c">
                                Coordenadas iniciales
                            </span>
                            <div className="form-row">
                                <div className="col-sm-6 col-md-6 mb-3">
                                <label for="v09">Latitud</label>
                                <input
                                    ref={register({
                                        /*required: true*/
                                    })}
                                    type="text"
                                    className="form-control"
                                    id="latitud_inicial"
                                    name="latitud_inicial"
                                    // placeholder="00,000000"
                                />                          
                                </div>
                                <div className="col-sm-6 col-md-6 mb-3">
                                <label for="v10">Longitud</label>
                                <input
                                    ref={register({                             
                                    })}
                                    type="text"
                                    className="form-control"
                                    id="longitud_inicial"
                                    name="longitud_inicial"
                                />                           
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 mb-3">
                        <div className="grupo-inputs">
                            <span className="tit-grupo-inputs-c">
                                Coordenadas finales
                            </span>
                            <div className="form-row">
                                <div className="col-sm-6 col-md-6 mb-3">
                                <label for="v07">Latitud</label>
                                <input
                                    ref={register({
                                        /*required: true*/
                                    })}
                                    type="text"
                                    className="form-control"
                                    id="latitud_final"
                                    name="latitud_final"
                                    placeholder="00,000000"
                                />                         
                                </div>
                                <div className="col-sm-6 col-md-6 mb-3">
                                <label for="v08">Longituddd</label>
                                <input
                                    ref={register({
                                        /*required: true*/
                                    })}
                                    type="text"
                                    className="form-control"
                                    id="longitud_final"
                                    name="longitud_final"
                                    placeholder="00,000000"
                                />                         
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
                {/* botones de investigador y curador */}
                <div className="form-row">      
                    <div className="col-md-12 mb-3 d-flex align-items-end">
                        <div>                                                    
                        <Link className="btn btn-light f-14" to={url.detalle_muestreo}>
                            Ir lista de monitoreos
                        </Link>                                   
                        </div>
                    </div>                          
                </div>                                
            </div>
    )
}
