import React, { useState, useEffect } from "react";
import { URL_APP } from "Routes";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { Button } from "react-bootstrap";
import ContentsIndividuo from "./formulario/ContentsIndividuo";
import ContentsNido from "./formulario/ContentsNido";
import ContentsNidoRelo from "./formulario/ContentsNidoRelo";
import { Alert, Modal } from "react-bootstrap";
import {  useHistory,Link } from "react-router-dom";
import ContentsOcultos from "./formulario/ContentsOcultos"
import {
   setRegistrarMonitoreoAction,
   getMuestreosAction,
   getMuestrasAction,
   getMonitoreoAction,
   updateMuestraAction,
} from "redux/monitoreoDuck";
import Nav from "components/base/navs/NavHome"
import MenuInvestigador from "components/base/navs/NavInvestigador";
import Header from "components/base/headers/Header"
import Footer from "components/base/Footer"
import {TXT_APP} from '../../../Routes'


//formatos de fechas
function convertirFecha(fecha,formato){
   var date = new Date(fecha);   
   if(formato==="f1"){
      if(date.getHours() < 10 && date.getMinutes() < 10) 
         return date.getDate()+"/"+ (date.getMonth()+1)+"/"+date.getFullYear() +" 0"+date.getHours()+":0"+date.getMinutes() 
      else if(date.getHours() < 10) 
         return date.getDate()+"/"+ (date.getMonth()+1)+"/"+date.getFullYear() +" 0"+date.getHours()+":"+date.getMinutes()
      else if(date.getMinutes() < 10) 
         return date.getDate()+"/"+ (date.getMonth()+1)+"/"+date.getFullYear() +" "+date.getHours()+":0"+date.getMinutes()
      else
         return date.getDate()+"/"+ (date.getMonth()+1)+"/"+date.getFullYear() +" "+date.getHours()+":"+date.getMinutes() 

   }else if(formato==="f2"){
      if(date.getDate() < 10) 
         return  date.getFullYear() +""+ (date.getMonth()+1)+"0"+(date.getDate()) 
      else
         return  date.getFullYear() +""+ (date.getMonth()+1)+""+(date.getDate()) 
   } else{     
      return date.getDate()+"/"+ (date.getMonth()+1)+"/"+date.getFullYear() 
   } 
}

function AddMuestra({fetching_monitoreo_update,updateMuestraAction,isUpdate,monitoreo_update,arrary_monitoreos_user,
   //listas controladas
   arrary_referentes,
   arrary_instituciones,
   arrary_especies,
   arrary_uac,
   arrary_departamentos,
   arrary_municipios,
   arrary_zonas_protegidas,
   
   //usuario 
   user,
   access,

   

   //muestreo o monitoreos hablo de lo mismo 
   getMonitoreoAction,
   setRegistrarMonitoreoAction,
   getMuestreosAction,
   getMuestrasAction,

  //muestras
   error,
   arrary_muestreos,
   arrary_muestras,
   fetching_get_muestreos,
   fetching_get_muestras,
   fetching_set_monitoreos,
  
   msg_set_monitoreos,
   isRegister,
   isError,
   arrary_monitoreos,
   isAddMonitoreo
}) {
    let url =  URL_APP()
  let txt =  TXT_APP()
   const history = useHistory(); 
   const [ acunCodigoIngreso, setAcunCodigoIngreso] = useState(["" ,"", ""]);
   const [ codigoIngreso, setcodigoIngreso] = useState("");
   const [ nroHuevos, setNroHuevos] = useState(0);
   const [ nroNeonatosEmergente, setNroNeonatosEmergente] = useState(0);
   const [ nroHuevosEclosionados, setNroHuevosEclosionados] = useState(0);
   const [ porcentajeEclosion, setPorcentajeEclosion] = useState(0);
   const [ porcentajeEmergencia, setPorcentajeEmergencia] = useState(0);
   const [encontroNido, setEncontroNido] = useState(false);
   const [encontroHembra, setEncontroHembra] = useState(false);
   const [nidoRelocalizado, setNidoRelocalizado] = useState(false);
   const [latitudNido, setLatitudNido] = useState("");
   const [longitudNido, setLongitudNido] = useState(""); 
   const [muestreo_enlace, setMuestreo_enlace] = useState("");    
   const [fechaInicial, setFechaInicial] = useState(""); 
   const [fechaFinal, setFechaFinal] = useState(""); 
   const [fechaAvistamiento, setFechaAvistamiento] = useState(""); 
   const [fechaEclosion, setFechaEclosion] = useState("");
   const [id_estacion, setId_estacion] = useState(0); 
   const [disabledTxt, setDisabledTxt] = useState(false); 
   const [show, setShow] = useState(false);
  


   const onGuardar = () => {
    // setShow(false);
    alert("guardando")
 };


//==========================================================================================================================   
useEffect(() => {

   if (isRegister) {

      getMonitoreoAction({
         token:access ,
         id_monitoreo:codigoIngreso
      });      
     
      // mostrar el modal de notificacion
      handleShow()
      //se ocultan datos (individuo , nido , relocalizacion nido)
      setEncontroHembra(false)
      setEncontroNido(false) 
      setNidoRelocalizado(false) 
      setDisabledTxt(true)  
   }
  
}, [isRegister]);

useEffect(() => {
   
   setFechaInicial(monitoreo_update.fecha_inicial_mtortuga)
   setFechaFinal(monitoreo_update.fecha_final_mtortuga)
  
}, []);



const onSubmit = (data, e) => { 

    /*Antes de guardar se verifica el estado del monitoreo con los siguientes criterios:
      1. Campos Requerido en Datos generales
         1.1. Consecutivo
         1.2. Especie
         1.3. Núm. de transecto
         1.4. Núm. de sector
         1.5. Latitud
         1.6. Longitud
         1.7. Fecha y hora del registro */
          
         // encontroNido        nidoRelocalizado;
         let respuesta_error = false
         let observacion_error = ""
        
         if(!Boolean(data.consecutivo)){             
            respuesta_error = true
            observacion_error = observacion_error + "Consecutivo,"
         } 
         if(!Boolean(data.id_especie)){
            respuesta_error = true
            observacion_error = observacion_error + "Especie,"
         } 
         if(!Boolean(data.transecto)){
            respuesta_error = true
            observacion_error = observacion_error + "Transecto,"
         } 
         if(!Boolean(data.sector)){
            respuesta_error = true
            observacion_error = observacion_error + "Sector,"
         }
         if(!Boolean(data.latitud_individuo)){
            respuesta_error = true
            observacion_error = observacion_error + "Latitud individuo,"

         }
         if(!Boolean(data.longitud_individuo)){
            respuesta_error = true
            observacion_error = observacion_error + "Longitud individuo,"
         } 

         if(!Boolean(data.hora)){
            respuesta_error = true
            observacion_error = observacion_error + "Hora avistamiento,"
         }          
         
        
      



  /* 2. Si seleciono Interceptar hembras por lo menos debe de tener estos campos diligenciado:
      2.1. Cod. marca por lo menos que tenga dos no importando si es encontrada o implantada
      2.2. posicion de la marca seleccionada(que coorresponda a la los codigo que lleno anterioermente)
      2.3. Lcc (largo curvo del caparazon)
      2.4. Acc (Ancho curvo del caparazon)*/         
      if(encontroHembra){
        // 2.1.
         let isCodMarca = 0
         let obsCodMarca =""
         if(!Boolean(data.cdg_marca_encont_01)){
            obsCodMarca = obsCodMarca + "Codigo Marca encontrada 1,"
         }else isCodMarca++
         if(!Boolean(data.cdg_marca_encont_02)){
            obsCodMarca = obsCodMarca + "Codigo Marca encontrada 2,"
         }else isCodMarca++
         if(!Boolean(data.cdg_marca_impl_01)){
            obsCodMarca = obsCodMarca + "Codigo Marca implantada 1,"
         }else isCodMarca++
         if(!Boolean(data.cdg_marca_impl_02)){
            obsCodMarca = obsCodMarca + "Codigo Marca implantada 2,"
         }else isCodMarca++
      
         if(isCodMarca < 2){
            observacion_error = observacion_error + obsCodMarca
            respuesta_error = true
         }

         //2.2.   
         let isCodPosicionMarca = 0
         let obsCodPosicionMarca =""
         if(!Boolean(data.id_p_enc_1)){
            obsCodPosicionMarca = obsCodPosicionMarca + "Posicion Marca encontrada 1,"
         }else isCodPosicionMarca++
         if(!Boolean(data.id_p_enc_2)){
            obsCodPosicionMarca = obsCodPosicionMarca + "Posicion Marca encontrada 2,"
         }else isCodPosicionMarca++
         if(!Boolean(data.id_p_imp_1)){
            obsCodPosicionMarca = obsCodPosicionMarca + "Posicion Marca implantada 1,"
         }else isCodPosicionMarca++
         if(!Boolean(data.id_p_imp_2)){
            obsCodPosicionMarca = obsCodPosicionMarca + "Posicion Marca implantada 2,"
         }else isCodPosicionMarca++
      
         if(isCodPosicionMarca < 2){
            observacion_error = observacion_error + obsCodPosicionMarca
            respuesta_error = true
         }

         //2.3. Lcc (largo curvo del caparazon)
         if(!Boolean(data.largo_caparazon)){
            respuesta_error = true
            observacion_error = observacion_error + "Largo caparazon,"
         } 

         //2.4. Acc (Ancho curvo del caparazon)*/
         if(!Boolean(data.ancho_caparazon)){
            respuesta_error = true
            observacion_error = observacion_error + "Ancho caparazon,"
         } 
      }//encontroHembra

      // console.log(respuesta_error)
      // console.log(observacion_error)


  /* 3. Si se encontro nido por lo menos debe de tener estos campos diligenciado:
      3.1. Posición del nido
      3.2. Manejo del nido
      3.3. Núm. Huevos
      3.4. Fecha de eclosion
      3.5. Núm. huevos eclosionado
      3.6. Dias de incubación
      3.7. Núm. Neonatos Emergente
      3.8. Núm. Neonatos liberados*/
      if(encontroNido){
         if(!Boolean(data.posicion_nido)){
            respuesta_error = true
            observacion_error = observacion_error + "Posicion nido,"
         } 
         if(!Boolean(data.manejo)){
            respuesta_error = true
            observacion_error = observacion_error + "Manejo,"
         }
         if(!Boolean(data.numero_huevos_puestos)){
            respuesta_error = true
            observacion_error = observacion_error + "Numero huevos,"
         }
         if(!Boolean(data.fecha_eclosion)){
            respuesta_error = true
            observacion_error = observacion_error + "Fecha eclosion,"
         }
         if(!Boolean(data.nro_huevos_eclosionado)){
            respuesta_error = true
            observacion_error = observacion_error + "Nro. huevos eclosionado,"
         }
         if(!Boolean(data.dias_incubacion)){
            respuesta_error = true
            observacion_error = observacion_error + "Dias incubacion,"
         }
         if(!Boolean(data.neonatos_emergentes)){
            respuesta_error = true
            observacion_error = observacion_error + "Neonatos emergentes,"
         }
         if(!Boolean(data.nro_neonatos_libr)){
            respuesta_error = true
            observacion_error = observacion_error + "Nro. neonatos libr,"
         }
      }
  
  
      /* 4. Si selecciono el Manejo del nido es diferente (Seguimineto in situ)a por lo menos debe de tener estos campos diligenciado:
      4.1. Latitud de relocalizacion
      4.2. Longitud de relocalizacion
      4.3. Prof. original en cm
      4.4. Prof. relocalización en cm
      4.5. Hora del siembra */
      if(nidoRelocalizado){
         if(!Boolean(data.latitud_relo)){
            respuesta_error = true
            observacion_error = observacion_error + "Latitud relo.,"
         }
         if(!Boolean(data.longitud_relo)){
            respuesta_error = true
            observacion_error = observacion_error + "Longitud relo.,"
         }
         if(!Boolean(data.profundidad_original)){
            respuesta_error = true
            observacion_error = observacion_error + "Profundidad original,"
         }
         if(!Boolean(data.profundidad_rlc)){
            respuesta_error = true
            observacion_error = observacion_error + "Profundidad rlc.,"
         }
         if(!Boolean(data.hora_siembra)){
            respuesta_error = true
            observacion_error = observacion_error + "Hora siembra,"
         }
      }

   setRegistrarMonitoreoAction({
      token: access,
      data: data,
      nota:respuesta_error?"PEN,"+observacion_error:"H"
   }) 
};  


        //Se llena el formulario
    const { register, handleSubmit, errors } = useForm({
        defaultValues: {
                // ...monitoreo_update,
                cod_ingreso:monitoreo_update.prefijo_estacion,
                Responsable:monitoreo_update.proyecto_financio,
                id_auc:monitoreo_update.uac_des,
                id_institucion:monitoreo_update.uac_sigla,
                id_zonas_protegidas:monitoreo_update.zona_protegida,
                playa:monitoreo_update.nom_estacion,
                id_departamento:monitoreo_update.departamento,
                id_municipio:monitoreo_update.municipio,
                fecha_inicio_monitoreo:monitoreo_update.fecha_inicial_mtortuga,
                fecha_fin_monitoreo:monitoreo_update.fecha_final_mtortuga,
                latitud_inicial:monitoreo_update.latitudinicio_loc,
                latitud_final:monitoreo_update.latitudfin_loc,
                longitud_inicial:monitoreo_update.longitudinicio_loc,
                longitud_final:monitoreo_update.longitudfin_loc,                
            },
    });


// Editar muestra
const OnEdicion = (id) => {     
   
   history.push(url.curar_detalle_muestras);

      // let muestra = arrary_monitoreos.filter((ent) => ent.id_muestra === id.id_muestra).map((j) => j)
      // setOnEdicion(true)
      // setMuestraEditar(muestra)
};



   const onCerrarMonitoreo=()=>{
      //validar monitoreo
      history.push(url.listado_monitoreos);
   }
  
   let onChanges = (e) => {  
      switch (e.target.name) {
         case "id_auc":         
               acunCodigoIngreso[0]=e.target.value                   
            break;
         case "id_institucion":
               acunCodigoIngreso[1]= e.target.value;            
            break;            
         case "fecha_inicio_monitoreo":    
               setFechaInicial(convertirFecha(e.target.value,"f1"))             
               acunCodigoIngreso[2] = convertirFecha(e.target.value,"f2")
            break; 
         case "fecha_fin_monitoreo":
               setFechaFinal(convertirFecha(e.target.value,"f1"))          
            break; 
            case "fechaEclosion":
               setFechaEclosion(convertirFecha(e.target.value,"f1"))           
            break;           
            
         case "avistamiento_nido_o_hembra":
               setFechaAvistamiento(convertirFecha(e.target.value,"f1"))          
            break;  
         case "encontro_nido":        
            if(e.target.value==="1")             
               setEncontroNido(true)             
            else
               setEncontroNido(false)              
            break;
         case "intercepto_hembra":     
            if(e.target.value==="1")
               setEncontroHembra(true)
            else
               setEncontroHembra(false)
               setNidoRelocalizado(false)
            break; 
         case "manejo":     
            if(e.target.value !== "IS")
               setNidoRelocalizado(true)
            else
               setNidoRelocalizado(false)                    
            break
         case "neonatos_emergentes":
            setNroNeonatosEmergente(e.target.value)
            break       
         case "numero_huevos_puestos":
            setNroHuevos(e.target.value)           
            break;
         case "nro_huevos_eclosionado":     
            setNroHuevosEclosionados(e.target.value)            
            break;
            case "longitud_individuo":     
               setLongitudNido(e.target.value)                      
            break;
            case "latitud_individuo":     
               setLatitudNido(e.target.value)  
            break;             
         default:
            break;
      }
      
      //campo q enlaza a una hembra con un nido 
      if(encontroNido && encontroHembra)
         setMuestreo_enlace("11")
      else if(encontroHembra ) 
         setMuestreo_enlace("1")  
      else if(encontroNido ) 
         setMuestreo_enlace("10") 
      else
         setMuestreo_enlace("")       
   
      //construyendo codigo de ingreso 
      setcodigoIngreso(acunCodigoIngreso[0]+" "+acunCodigoIngreso[1]+" "+acunCodigoIngreso[2])
      //construyendo porcentaje de eclosion
      setPorcentajeEclosion(parseFloat(((nroHuevosEclosionados/nroHuevos)*100)).toFixed(2))      
      //porcentaje de emergencia 
      setPorcentajeEmergencia(parseFloat(((nroNeonatosEmergente/nroHuevos)*100)).toFixed(2))
   };
   // modal monitoreo guardado
   const handleShow = () => {
      setShow(true)
   };
   const handleClose = () => {
      setShow(false)
   };

   //ir al listado de monitoreos
   let irBack = () =>{
      setShow(false)
      history.push(url.detalle_muestreo);
   }

   return (<div>
            {false ?( <div></div>
            ):(


         <div className="content-principal  content-internas">  
            <div className="temporal-cerar"></div>      
            <header className=" internas-h ">          
                <Nav/>     
                <Header titulo={txt.tituloFormulario}/>                         
            </header>  
            <section class="menubar">   |
              <MenuInvestigador />
            </section> 

               <section className="contents-interna sig interna-componente">              
                  <div className="container-xl my-3">
                     <div className="row">
                        <div className="col-md-12">
                           <div class="container-xl my-3">
                              <h3 className="title-form-monitoreo" >
                                 Formulario de monitoreo de playas de reproducción de
                                 tortugas marinas en Colombia                        
                              </h3>
                              {/* Alert mensajes */}
                                 <div>
                                 {msg_set_monitoreos && (
                                       <Alert variant={"success"} className="container-xl my-3">
                                          {msg_set_monitoreos}
                                       </Alert>
                                    )}
                                 {isError ? (
                                    <Alert variant={"danger"} className="container-xl my-3">
                                       {msg_set_monitoreos}
                                    </Alert>
                                 ) : (<div></div>)} 
                              </div>
                                 <form onSubmit={handleSubmit(onSubmit)}> 
                                 <div className="encabezado-form ">
                                    <h6 className="mb-3">Datos del encabezado</h6>
                                    <div className="form-row">  
                                       <div className="col-sm-12 col-md-3 mb-3 ">
                                                <label for="v01">Código de ingresos</label>                             
                                                <input 
                                                   ref={register({
                                                   /*required: true*/
                                                   })}
                                                   type="text"
                                                   className="form-control  deshabilitado"
                                                   id="cod_ingreso"
                                                   name="cod_ingreso"                                            
                                                   placeholder="Codigo ingreso"
                                                   readonly
                                                />                    
                                             </div>
                                       <div className="col-md-3 col-md-3 mb-3">
                                             <label for="v05">Responsable</label>
                                             <input
                                                ref={register({
                                                   /*required: true*/
                                                })}
                                                type="text"
                                                className="form-control   deshabilitado"
                                                id="Responsable"
                                                name="Responsable"
                                                placeholder="Responsable"            
                                                readonly
                                             />
                                             <input
                                                id="id_responsable"
                                                name="id_responsable"
                                                type="hidden"
                                                ref={register({
                                                   /*required: true*/
                                                })}
                                             ></input>            
                                       </div>
                                       <div className="col-sm-6 col-md-6 mb-3">
                                                <label for="v01">Unid. costeras</label>                        
                                                <input
                                                ref={register({
                                                   /*required: true*/
                                                })}
                                                type="text"
                                                className="form-control   deshabilitado"
                                                id="id_auc"
                                                name="id_auc"
                                                placeholder="Responsable"            
                                                readonly
                                             />                     
                                    
                                             </div>
                                    </div>
                                    <div className="form-row">  
                                       <div className="col-sm-6 col-md-4 mb-3">
                                             <label for="v02">Institución</label>

                                             <input
                                                ref={register({
                                                   /*required: true*/
                                                })}
                                                type="text"
                                                className="form-control   deshabilitado"
                                                id="id_institucion"
                                                name="id_institucion"
                                                placeholder="Responsable"            
                                                readonly
                                             /> 
                                       
                                       
                                       </div>
                                       <div className="col-sm-12 col-md-4 mb-3">
                                       <label for="v06">Area protegida</label>{" "}                      
                                       <input
                                                ref={register({
                                                   /*required: true*/
                                                })}
                                                type="text"
                                                className="form-control   deshabilitado"
                                                id="id_zonas_protegidas"
                                                name="id_zonas_protegidas"
                                                placeholder="Responsable"            
                                                readonly
                                             />                 
                                 
                                       </div>
                                       <div className="col-sm-4 col-md-4 mb-3">
                                             <label for="v07">Playa</label>
                                             <input
                                                ref={register({
                                                   /*required: true*/
                                                })}
                                                type="text"
                                                className="form-control"
                                                id="playa"
                                                name="playa"
                                                placeholder="Lugar"
                                                                     />
                                       
                                       </div>
                                    </div>
                                    <div className="form-row">
                                       <div className="col-sm-4 col-md-3 mb-3">
                                       <label for="v02">Departamento</label>
                                       <input
                                                ref={register({
                                                   /*required: true*/
                                                })}
                                                type="text"
                                                className="form-control   deshabilitado"
                                                id="id_departamento"
                                                name="id_departamento"
                                                placeholder="Responsable"            
                                                readonly
                                             />   
                                       
                                       </div>
                                       <div className="col-sm-4 col-md-3 mb-3">
                                       <label for="v02">Municipio</label>                        
                                       <input
                                                ref={register({
                                                   /*required: true*/
                                                })}
                                                type="text"
                                                className="form-control   deshabilitado"
                                                id="id_municipio"
                                                name="id_municipio"
                                                placeholder="Responsable"            
                                                readonly
                                             />  
                                             
                                       </div>
                                       <div className="col-sm-4 col-md-3 mb-3">
                                             <label for="v03">Fecha inicial</label>                     
                                             <input
                                                ref={register({                         
                                                })}
                                                type="text"
                                                className="form-control"
                                                id="fecha_inicio_monitoreo"
                                                name="fecha_inicio_monitoreo" 
                                                readonly                    
                                             /> 
                                       
                                    
                                       </div>
                                       <div className="col-sm-4 col-md-3 mb-3">
                                             <label for="v04">Fecha final</label>
                                             <input
                                                ref={register({
                                                   /*required: true*/
                                                })}                        
                                                type="text"
                                                className="form-control"
                                                name="fecha_fin_monitoreo"
                                                id="fecha_fin_monitoreo"
                                                placeholder="dd/mm/aa"
                                                readonly
                                             />
                                             
                                       
                                       </div>
                                       <div className="col-sm-6 col-md-6 mb-3">
                                             <div className="grupo-inputs">
                                                <span className="tit-grupo-inputs-c">
                                                   Coordenadas iniciales
                                                </span>
                                                <div className="form-row">
                                                   <div className="col-sm-6 col-md-6 mb-3">
                                                   <label for="v09">Latitud</label>
                                                   <input
                                                         ref={register({
                                                            /*required: true*/
                                                         })}
                                                         type="text"
                                                         className="form-control"
                                                         id="latitud_inicial"
                                                         name="latitud_inicial"
                                                         // placeholder="00,000000"
                                                   />                          
                                                   </div>
                                                   <div className="col-sm-6 col-md-6 mb-3">
                                                   <label for="v10">Longitud</label>
                                                   <input
                                                         ref={register({                             
                                                         })}
                                                         type="text"
                                                         className="form-control"
                                                         id="longitud_inicial"
                                                         name="longitud_inicial"
                                                   />                           
                                                   </div>
                                                </div>
                                             </div>
                                       </div>
                                       <div className="col-sm-6 col-md-6 mb-3">
                                             <div className="grupo-inputs">
                                                <span className="tit-grupo-inputs-c">
                                                   Coordenadas finales
                                                </span>
                                                <div className="form-row">
                                                   <div className="col-sm-6 col-md-6 mb-3">
                                                   <label for="v07">Latitud</label>
                                                   <input
                                                         ref={register({
                                                            /*required: true*/
                                                         })}
                                                         type="text"
                                                         className="form-control"
                                                         id="latitud_final"
                                                         name="latitud_final"
                                                         placeholder="00,000000"
                                                   />                         
                                                   </div>
                                                   <div className="col-sm-6 col-md-6 mb-3">
                                                   <label for="v08">Longituddd</label>
                                                   <input
                                                         ref={register({
                                                            /*required: true*/
                                                         })}
                                                         type="text"
                                                         className="form-control"
                                                         id="longitud_final"
                                                         name="longitud_final"
                                                         placeholder="00,000000"
                                                   />                         
                                                   </div>
                                                </div>
                                             </div>
                                       </div>
                                    </div> 
                                    <div className="form-row">
                                       <div className="col-md-4 mb-3 d-flex align-items-end">
                                          <div>
                                             <Link className="btn btn-light f-14" to={url.detalle_muestreo}>
                                                Ir lista de monitoreos
                                             </Link>
                                             &nbsp;{" "}    
                                          </div>
                                       </div>
                                    </div> 
                                 </div>     
                  
                                                             
                                 {/* Datos generales */}
                                 <div className="cuerpo-form">                                 
                                    <h6>Datos generales</h6>
                                    <div className="form-row">
                                       <div className="col-sm-3 col-md-3 mb-3">
                                          <label for="v11">Consecutivo</label>
                                          <input
                                             ref={register({
                                                /*required: true*/
                                             })}
                                             type="text"
                                             className="form-control"
                                             id="consecutivo"
                                             name="consecutivo"
                                             placeholder="i-00000"
                                          />
                                          <span className="text-danger text-small d-block mb-2">
                                             {errors.consecutivo && (
                                                <span>Este campo es requerido</span>
                                             )}
                                          </span>
                                       </div>
                                       <div className="col-sm-3 col-md-5 mb-3">
                                          <label for="v12">Especie</label>
                                          <select
                                             ref={register({
                                                /*required: true*/
                                             })}
                                             className="custom-select"
                                             id="id_especie"
                                             name="id_especie"
                                          >
                                             <option selected disabled value="">
                                                Seleccione...
                                             </option>
                                             {arrary_especies.map((item) => (
                                                <option
                                                   value={item.cod_letras}
                                                   key={item.cod_letras}
                                                >
                                                   {item.descripcion}
                                                </option>
                                             ))}
                                          </select>
                                          <span className="text-danger text-small d-block mb-2">
                                             {errors.id_especie && (
                                                <span>Este campo es requerido</span>
                                             )}
                                          </span>
                                       </div>
                                       <div className="col-sm-3 col-md-2 mb-3">
                                          <label for="v13">Núm. de transecto</label>
                                          <input
                                             ref={register({
                                                /*required: true*/
                                             })}
                                             type="text"
                                             className="form-control"
                                             id="transecto"
                                             name="transecto"
                                             placeholder=""
                                          />
                                          <span className="text-danger text-small d-block mb-2">
                                             {errors.transecto && (
                                                <span>Este campo es requerido</span>
                                             )}
                                          </span>
                                       </div>
                                       <div className="col-sm-3 col-md-2 mb-3">
                                          <label for="v14">Núm. de sector</label>
                                          <input
                                             ref={register({
                                                /*required: true*/
                                             })}
                                             type="text"
                                             className="form-control"
                                             id="sector"
                                             name="sector"
                                             placeholder=""
                                          />
                                          <span className="text-danger text-small d-block mb-2">
                                             {errors.sector && (
                                                <span>Este campo es requerido</span>
                                             )}
                                          </span>
                                       </div>
                                    </div>
                                    <div className="form-row">
                                       <div className="col-sm-3 col-md-2 mb-3">
                                          <label for="v15">Se encontro nido</label>
                                          <select
                                             className="custom-select"
                                             id="encontro_nido"
                                             name="encontro_nido"
                                             onChange={(e) => onChanges(e)}
                                          >
                                             <option selected disabled value="">
                                                Seleccione...
                                             </option>
                                             <option key="encontro_nido_1" value="1">
                                                Si
                                             </option>
                                             <option key="encontro_nido_2" value="0">
                                                No
                                             </option>
                                          </select>
                                          <span className="text-danger text-small d-block mb-2">
                                             {errors.encontro_nido && (
                                                <span>Este campo es requerido</span>
                                             )}
                                          </span>
                                       </div>
                                       <div className="col-sm-3 col-md-2 mb-3">
                                          <label for="v16">Interceptar hembras</label>
                                          <select
                                             ref={register({
                                                /*required: true*/
                                             })}
                                             
                                             className="custom-select"
                                             id="intercepto_hembra"
                                             name="intercepto_hembra"
                                             onChange={(e) => onChanges(e)}
                                          >
                                             <option selected disabled value="">
                                                Seleccione...
                                             </option>
                                             <option key="intercepto_hembra_1" value="1">
                                                Si
                                             </option>
                                             <option key="intercepto_hembra_2" value="0">
                                                No
                                             </option>
                                          </select>
                                          <span className="text-danger text-small d-block mb-2">
                                             {errors.intercepto_hembra && (
                                                <span>Este campo es requerido</span>
                                             )}
                                          </span>
                                       </div>
                                       <div className="col-sm-3 col-md-2 mb-3">
                                             <label for="v15">Latitud</label>
                                             <input
                                                ref={register({})}
                                                onChange={(e) => onChanges(e)}
                                                type="text"
                                                className="form-control"
                                                id="latitud_individuo"
                                                name="latitud_individuo"
                                                placeholder="00,000000"
                                             />
                                             <span className="text-danger text-small d-block mb-2">
                                                {errors.latitud_individuo && (
                                                   <span>Este campo es requerido</span>
                                                )}
                                             </span>
                                          </div>
                                       <div className="col-sm-3 col-md-2 mb-3">
                                             <label for="v16">Longitud</label>
                                             <input
                                                ref={register({})}
                                                onChange={(e) => onChanges(e)}
                                                type="text"
                                                className="form-control"
                                                id="longitud_individuo"
                                                name="longitud_individuo"
                                                placeholder="00,000000"
                                             />
                                             <span className="text-danger text-small d-block mb-2">
                                                {errors.longitud_individuo && (
                                                   <span>Este campo es requerido</span>
                                                )}
                                             </span>
                                          </div>
                                       <div className="col-sm-3 col-md-2 mb-3">
                                             <label for="v14">Fecha y hora del registro</label>
                                             <input
                                                ref={register({})}
                                                onChange={(e) => onChanges(e)}
                                                type="datetime-local"
                                                className="form-control"
                                                id="avistamiento_nido_o_hembra"
                                                name="avistamiento_nido_o_hembra"
                                                placeholder="00:00"
                                             />
                                             <span className="text-danger text-small d-block mb-2">
                                                {errors.avistamiento && (
                                                   <span>Este campo es requerido</span>
                                                )}
                                             </span>
                                          </div>
                                    </div>
                                    {/* individuo, nido, relocalizacion */}
                                    <div>
                                       {/* individuo */}                     
                                       {encontroHembra?
                                          <ContentsIndividuo errors={errors} register={register} arrary_referentes={arrary_referentes}/>              
                                       :<div></div>}  
                                       {/* nido */}   
                                       {encontroNido?
                                          <ContentsNido errors={errors} register={register} onChanges={onChanges} arrary_referentes={arrary_referentes} porcentajeEclosion={porcentajeEclosion} porcentajeEmergencia={porcentajeEmergencia} longitudNido={longitudNido} latitudNido={latitudNido} fechaAvistamiento={fechaAvistamiento}/>                   
                                       :<div></div>} 
                                       {/* nido relocalizado */}   
                                       {nidoRelocalizado ?
                                          <ContentsNidoRelo errors={errors} register={register} />
                                       :<div></div>}  
                                    </div>
                                    {/* fotos y btn guardar */}
                                    <div className="form-row">
                                       <div className="col-md-6">
                                          <div className="form-group">
                                             {/* <label for="exampleFormControlFile1">
                                                Seleccione foto del individuo
                                             </label>
                                             <input
                                                ref={register({})}
                                                type="file"
                                                className="form-control-file"
                                                name="file"
                                                id="exampleFormControlFile1"
                                             />
                                             <span className="text-danger text-small d-block mb-2">
                                                {errors.codigo_in && (
                                                   <span>Este campo es requerido</span>
                                                )}
                                             </span> */}
                                          </div>
                                       </div>                      
                                       <div className="col-md-6 align-btns">                
                                       <Button  type="submit"  className="btn btn-primary ">
                                             <i className="fa fa-plus"> Guardar</i>
                                          </Button>
                                       </div>
                                    </div>
                                 </div>                                    
                                 {/* Campos ocultos */}
                                 <ContentsOcultos errors={errors} register={register} encontroNido={encontroNido} encontroHembra={encontroHembra} nidoRelocalizado={nidoRelocalizado} muestreo_enlace={muestreo_enlace} id_estacion={id_estacion} fechaInicial={fechaInicial} fechaFinal={fechaFinal} fechaEclosion={fechaEclosion} fechaAvistamiento={fechaAvistamiento} />
                            </form>
                           </div>
                           <p>&nbsp;</p>
                        </div>
                     </div>                
                     <p>&nbsp;</p>
                  </div>
               </section>
        <Footer/>  
      </div>
        )} 
         {/* modal msg monitoreo realizado  */}
         <Modal show={show} >
            <Modal.Header style={{ color: "#30345b" }}>
               <b>Monitoreo</b>     
            </Modal.Header>
            <Modal.Body>
               <p>Monitoreo registrado correctamente</p>     
            </Modal.Body>
            <Modal.Footer>      
               <a className="btn btn-primary " href="javascript:void(0)"  onClick={irBack}>
                  Ver monitoreo
               </a>       
            </Modal.Footer>
         </Modal>     
      </div>     
   
   );
}
let mapState = (state) => {  
   return {
      arrary_referentes: state.referentes.arrary_referentes,
      arrary_instituciones: state.referentes.arrary_instituciones,
      arrary_especies: state.referentes.arrary_especies,
      arrary_uac: state.referentes.arrary_uac,
      arrary_departamentos: state.referentes.arrary_departamentos,
      arrary_municipios: state.referentes.arrary_municipios,
      arrary_zonas_protegidas: state.referentes.arrary_zonas_protegidas,
      error_referentes: state.referentes.error,

      // get muestras - muestreos - monitoreos  
      arrary_monitoreos: state.monitoreos.arrary_monitoreos,
      arrary_muestreos: state.monitoreos.arrary_muestreos,
      arrary_muestras: state.monitoreos.arrary_muestras,

      // fetching
      fetching_get_muestreos: state.monitoreos.fetching_get_muestreos,
      fetching_get_muestras: state.monitoreos.fetching_get_muestras,
      fetching_set_monitoreos: state.monitoreos.fetching_set_monitoreos,
      fetching_get_monitoreos: state.monitoreos.fetching_get_monitoreos,
      msg_set_monitoreos: state.monitoreos.msg_set_monitoreos,
      isAddMonitoreo: state.monitoreos.isAddMonitoreo,
      isRegister: state.monitoreos.isRegister,
      isError : state.monitoreos.isError,

      //usuarios
      user: state.user.user,
      access: state.user.user.access,
      arrary_monitoreos_user:state.monitoreos.arrary_monitoreos_user,
        monitoreo_update:state.monitoreos.monitoreo_update,
        isUpdate:state.monitoreos.isUpdate
   };
};

const mapDispatch = (dispatch) => {
   return {
      setRegistrarMonitoreoAction: (props) =>
      dispatch(setRegistrarMonitoreoAction(props)),
      getMuestreosAction: () => dispatch(getMuestreosAction()),
      getMuestrasAction: () => dispatch(getMuestrasAction()),
      getMonitoreoAction: (props) => dispatch(getMonitoreoAction(props)),   
      updateMuestraAction: (props) => dispatch(updateMuestraAction(props)), 
   };
};
export default connect(mapState, mapDispatch)(AddMuestra);
