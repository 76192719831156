import React, { useState, useEffect } from "react";
import Nav from "components/base/navs/NavHome";
import Header from "components/base/headers/Header";
import MenuCurador from "components/base/navs/NavCurador";
import Footer from "components/base/Footer";
import { TXT_APP } from "Routes";
import { connect } from "react-redux";
import {
   getAllMonitoreoAction,
   updateMonitoreoAction,
} from "redux/monitoreoDuck";
import ListaMuestreos from "../compartidos/ListaMuestreos";
import Progress from "components/base/Progress";
import MenuRaiz from "components/base/navs/NavRaiz";

function CurarMonitoreos({
   fetching_get_all_monitoreos,
   access,
   arrary_all_monitoreos,
   getAllMonitoreoAction,
   updateMonitoreoAction,
   isUpdate_monitoreo,
   isGet_all_monitoreos,
}) {
   let txt = TXT_APP();

   useEffect(() => {
      getAllMonitoreoAction({ token: access });
      updateMonitoreoAction([]);
   }, []);
   let lista = [];

   if (isGet_all_monitoreos) {
      lista = arrary_all_monitoreos.filter(
         (valorActual, indiceActual, arreglo) => {
            return (
               arreglo.findIndex(
                  (valorDelArreglo) =>
                     valorDelArreglo.id_muestreotx === valorActual.id_muestreotx
               ) === indiceActual
            );
         }
      );
   }

   //    isUpdate_monitoreo
   return (
      <div className='content-principal  content-internas'>
         <div className='temporal-cerar'></div>
         <header className=' internas-h '>
            <Nav />
            <Header titulo={txt.tituloPanelCurador} />
         </header>
         <section class='menubar'>
            <MenuRaiz />
         </section>
         <section class='contents-interna sig interna-componente'>
            <div class='container-xl my-3'>
               <div class='row'>
                  <div class='col-md-12'>
                     <ul class='nav nav-tabs' id='myTab' role='tablist'>
                        <li class='nav-item' role='presentation'>
                           <a
                              class='nav-link active'
                              id='home-tab'
                              data-toggle='tab'
                              href='#monitoreos'
                              role='tab'
                              aria-controls='monitoreos'
                              aria-selected='true'
                           >
                              Monitoreos
                           </a>
                        </li>
                     </ul>
                     {fetching_get_all_monitoreos ? (
                        <Progress />
                     ) : (
                        <ListaMuestreos
                           lista={lista}
                           modulo={txt.modulo_cutrado}
                        />
                     )}
                  </div>
               </div>
               <p>&nbsp;</p>
            </div>
         </section>
         <Footer />
      </div>
   );
}

let mapState = (state) => {
   console.log(state.monitoreos.arrary_all_monitoreos);

   return {
      access: state.user.user.access,
      fetching_get_all_monitoreos: state.monitoreos.fetching_get_all_monitoreos,
      arrary_all_monitoreos: state.monitoreos.arrary_all_monitoreos,
      msg_get_all_monitoreos: state.monitoreos.msg_get_all_monitoreos,
      isUpdate: state.monitoreos.isUpdate,
      isUpdate_monitoreo: state.monitoreos.isUpdate_monitoreo,
      isGet_all_monitoreos: state.monitoreos.isGet_all_monitoreos,
   };
};
// sacar los dispatch de (store) que tiene redux y los coloque en las props de este componente.
const mapDispatch = (dispatch) => {
   return {
      getAllMonitoreoAction: (props) => dispatch(getAllMonitoreoAction(props)),
      updateMonitoreoAction: (props) => dispatch(updateMonitoreoAction(props)),
   };
};

// connect(mapState,mapDispatch)(this funtion)
export default connect(mapState, mapDispatch)(CurarMonitoreos);
