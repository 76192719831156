import React from 'react'



export default function ContentsOcultos({errors,register,encontroNido,encontroHembra,nidoRelocalizado,muestreo_enlace,id_estacion,fechaInicial,fechaFinal,fechaEclosion,fechaAvistamiento}) {
   //iten de los campos en bd
    
   // console.log("nido:",encontroNido,"HEMBRA:",encontroHembra,"NIDOr:",nidoRelocalizado)
   
   
   let items = [
        { nombre: "item_id_auc", value: 857 },
        { nombre: "item_id_zonas_protegidas", value: 23 },
        { nombre: "id_tipo_registro", value: "0" },
        { nombre:"item_id_departamento", value: 858 },
        { nombre:"item_id_municipio", value:9 },      { nombre:"item_playa", value:8 },
        { nombre:"item_latitud_inicial", value:10 },
        { nombre:"item_longitud_inicial", value:12 },
        { nombre:"item_latitud_final", value:11 },
        { nombre:"item_longitud_final", value:13 },
        { nombre:"item_fecha_inicial", value:132 },
        { nombre:"item_fecha_final", value:133 },
        { nombre:"proyecto_financio", value:"CONVENIO Minambiente  521/2020" },
        { nombre:"idproyecto", value:6 },      
        { nombre:"pais", value:"CO" },
        { nombre:"nodo_padre", value:"0" },
        { nombre:"nodo_final", value:1 },
        { nombre:"tipo_nod", value:10 },
        { nombre:"vigente", value:"N" },//<-------------------------esta estacion queda en No activa 
        { nombre:"consecutivo_estacion", value:"1" },
        { nombre:"id_metodologia", value:"794" },
        { nombre:"tipo_nodo", value:"40" },      
     ]
    
    return (
        <div>
            {items.map((stateItem, index) => (                           
                <input
                id={stateItem.nombre}
                name={stateItem.nombre}
                type="hidden"
                value={stateItem.value}
                ref={register({})}
                ></input>                                                 
            ))}  

                     {/* Datos del individuo  */}
                     {!encontroNido &&(
                     <>
                           <input
                           id="latitud_nido"
                           name="latitud_nido"
                           type="hidden"
                           value=""
                           ref={register({})}
                           ></input>
                        <input
                           id="manejo"
                           name="manejo"
                           type="hidden"
                           value=""
                           ref={register({})}
                        ></input>
                        <input
                           id="longitud_nido"
                           name="longitud_nido"
                           type="hidden"
                           value=""
                           ref={register({})}
                        ></input>

                        {/* <input
                           id="avistamiento"
                           name="avistamiento"
                           type="hidden"
                           value=""
                           ref={register({})}
                        ></input> */}
                        <input
                           id="posicion_nido"
                           name="posicion_nido"
                           type="hidden"
                           value=""
                           ref={register({})}
                        ></input>
                           <input
                           id="numero_huevos_puestos"
                           name="numero_huevos_puestos"
                           type="hidden"
                           value=""
                           ref={register({})}
                        ></input>

                        <input
                           id="fechaEclosion"
                           name="fechaEclosion"
                           type="hidden"
                           value=""
                           ref={register({})}
                        ></input>

                        <input
                           id="nro_huevos_eclosionado"
                           name="nro_huevos_eclosionado"
                           type="hidden"
                           value=""
                           ref={register({})}
                        ></input>

                        <input
                           id="dias_incubacion"
                           name="dias_incubacion"
                           type="hidden"
                           value=""
                           ref={register({})}
                        ></input>
                        <input
                           id="neonatos_emergentes"
                           name="neonatos_emergentes"
                           type="hidden"
                           value=""
                           ref={register({})}
                        ></input>
                           <input
                           id="nro_neonatos_libr"
                           name="nro_neonatos_libr"
                           type="hidden"
                           value=""
                           ref={register({})}
                        ></input>
                        <input
                           id="porc_eclocion"
                           name="porc_eclocion"
                           type="hidden"
                           value=""
                           ref={register({})}
                        ></input>
                        <input
                           id="porc_emergencia"
                           name="porc_emergencia"
                           type="hidden"
                           value=""
                           ref={register({})}
                        ></input>
                           <input
                           id="observaciones_nido"
                           name="observaciones_nido"
                           type="hidden"
                           value=""
                           ref={register({})}
                        ></input>
                        </>
                     )}
                     {/* Datos del individuo  */}
                     {!encontroHembra &&(
                     <>
                           <input
                           id="cdg_marca_encont_01"
                           name="cdg_marca_encont_01"
                           type="hidden"
                           value=""
                           ref={register({})}
                           ></input>
                           <input
                           id="id_p_enc_1"
                           name="id_p_enc_1"
                           type="hidden"
                           value=""
                           ref={register({})}
                           ></input>


                           <input
                           id="cdg_marca_encont_02"
                           name="cdg_marca_encont_02"
                           type="hidden"
                           value=""
                           ref={register({})}
                           ></input>
                           <input
                           id="id_p_enc_2"
                           name="id_p_enc_2"
                           type="hidden"
                           value=""
                           ref={register({})}
                           ></input>
                           <input
                           id="cdg_marca_impl_01"
                           name="cdg_marca_impl_01"
                           type="hidden"
                           value=""
                           ref={register({})}
                           ></input>
                           <input
                           id="id_p_imp_1"
                           name="id_p_imp_1"
                           type="hidden"
                           value=""
                           ref={register({})}
                           ></input>

                           <input
                           id="cdg_marca_impl_02"
                           name="cdg_marca_impl_02"
                           type="hidden"
                           value=""
                           ref={register({})}
                           ></input>
                           <input
                           id="id_p_imp_2"
                           name="id_p_imp_2"
                           type="hidden"
                           value=""
                           ref={register({})}
                           ></input> 

                              <input
                           id="largo_caparazon"
                           name="largo_caparazon"
                           type="hidden"
                           value=""
                           ref={register({})}
                           ></input>

                           <input
                           id="ancho_caparazon"
                           name="ancho_caparazon"
                           type="hidden"
                           value=""
                           ref={register({})}
                           ></input>  

                              <input
                           id="observaciones_marca"
                           name="observaciones_marca"
                           type="hidden"
                           value=""
                           ref={register({})}
                           ></input>                                      
                           </>                                     
                     )}
                     {/* Datos oculto relocalizado  */}
                     {!nidoRelocalizado &&(
                        <>
                           <input
                              id="latitud_relo"
                              name="latitud_relo"
                              type="hidden"
                              value=""
                              ref={register({})}
                           ></input>
                           <input
                              id="longitud_relo"
                              name="longitud_relo"
                              type="hidden"
                              value=""
                              ref={register({})}
                           ></input>
                              <input
                              id="profundidad_original"
                              name="profundidad_original"
                              type="hidden"
                              value=""
                              ref={register({})}
                           ></input>
                              <input
                              id="profundidad_rlc"
                              name="profundidad_rlc"
                              type="hidden"
                              value=""
                              ref={register({})}
                           ></input>
                           <input
                              id="hora_siembra"
                              name="hora_siembra"
                              type="hidden"
                              value=""
                              ref={register({})}
                           ></input>
                        </>                 
                     )}
                     {/* ocultos */}
                     <div>
                     <input
                        id="id_muestreo_enlace"
                        name="id_muestreo_enlace"
                        type="hidden"
                        value={muestreo_enlace}
                        ref={register({})}
                     ></input>
                     <input
                        id="id_estacion"
                        name="id_estacion"
                        type="hidden"
                        value={id_estacion}
                        ref={register({})}
                     ></input>
                     <input
                        id="fecha_inicial"
                        name="fecha_inicial"
                        type="hidden"
                        value={fechaInicial}
                        ref={register({})}
                     ></input>
                     <input
                        id="fecha_final"
                        name="fecha_final"
                        type="hidden"
                        value={fechaFinal}
                        ref={register({})}
                     ></input>
                     <input
                        id="fecha_eclosion"
                        name="fecha_eclosion"
                        type="hidden"
                        value={fechaEclosion}
                        ref={register({})}
                     ></input>                     
                     <input
                        id="hora"
                        name="hora"
                        type="hidden"
                        value={fechaAvistamiento}
                        ref={register({})}
                     ></input>

                     </div>

        </div>
    )
}
